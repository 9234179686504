import React, { memo, useEffect } from 'react'
import { VaultWrapper, VaultTitle, Title, VaultContent } from './styled'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Bg from '@/assets/img_slices/vault.png'

export default memo(function PrecautionsPage() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VaultWrapper>
      <VaultTitle>
        <Title>
          <Link to={'/precautions'}>{t('att.title')}</Link>
        </Title>
      </VaultTitle>
      <VaultContent>
        <div className="title">{t('att.topic1')}</div>
        <div className="item">
          {t('att.topic1.info1')} <br />
          {t('att.topic1.info2')} <br />
          {t('att.topic1.info3')} <br />
          {t('att.topic1.info4')}
        </div>
        <div className="title">{t('att.topic2')}</div>
        <div className="item">
          {t('att.topic2.info1')} <span>{t('att.topic2.info1.link')}</span>
          <br />
          {t('att.topic2.info2')} <br />
          {t('att.topic2.info3')}
        </div>
        <div className="title">{t('att.topic3')}</div>
        <div className="item">
          {t('att.topic3.info1')} <br />
          {t('att.topic3.info2')} <br />
        </div>
      </VaultContent>
    </VaultWrapper>
  )
})
