import styled from "styled-components";

export const EchartsWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 5rem;
  margin-bottom: 5rem !important;
  ${({ theme }) => theme.mediaWidth.screenXl`
      padding: 5rem 20px 0 20px;
  `}
  ${({ theme }) => theme.mediaWidth.screenMd`
      padding: 6.75rem 20px 0 20px;
  `}
`

export const EchartsTitle = styled.div`
  width: 100%;
  height: 6.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #00477c;
  }
  /* ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `} */
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 3rem;
    line-height: 3rem;
  `}
`

export const EchartsTopic = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
font-weight: 400;
${({ theme }) => theme.mediaWidth.screenMd`
  display:block;
  `}
.left{
font-size: 1.125rem;

color: #5D6175;
display: flex;
.allt{
    margin-left: 1rem;
}
${({ theme }) => theme.mediaWidth.screenMd`
   flex-wrap: wrap;
   flex: 1;
   display:block;
   font-size: 1.625rem;
   .allt{
    margin-left: 0rem;
}
  `}
}
.right{
font-size: 0.875rem;
color: #9596A5;
display: flex;
align-items: center;

${({ theme }) => theme.mediaWidth.screenMd`
   flex-wrap: wrap;
   flex: 1;
   margin-top:1rem;
   justify-content: space-between;
  `}
.item{
 
  height: 1.875rem;
  line-height: 1.875rem;
    border-radius: 1rem;
    padding:0 1.375rem;
    border: 1px solid #DEDEDE;
    cursor: pointer;
    &.active{
    background-color: #00C28E;
    color: white;
    border: transparent;
    }
    &:not(:first-child){
        margin-left: 1rem!important;
    }
    ${({ theme }) => theme.mediaWidth.screenMd`
     height: 3.75rem;
     line-height: 3.75rem;
     border-radius: 2rem;
     text-align:center;
     flex:1;
     font-size: 1.375rem;
     padding:0;
   &:nth-child(odd){
    margin-left:0;
   }
   &:nth-child(n+2){
    margin-bottom:0.5rem;
   }
  `}
}
}
`

export const EchartsDiv = styled.div`
margin-top: 1rem;
`