import styled, { css } from 'styled-components'

export const CreateContent = styled.div`
  max-width: 1200px;
  margin: 40px auto;
  border: 1px solid #cdd8e2;
`

export const CreateType = styled.div`
  max-width: 1200px;
  display: flex;
  margin-bottom: 2rem;
  .item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f8fa;
    color: #9596a5;
    font-size: 1.75rem;
    font-weight: 400;
    height: 80px;
    cursor: pointer;
    ${(props) =>
      props.theme.mediaWidth.screenMd(
        () => css`
          height: 60px;
        `,
      )}
    &.active {
      background-color: white;
      color: #00c28e;
      border-top: 2px solid #00c28e;
    }
    &:hover {
      background-color: white;
      color: #00c28e;
      border-top: 2px solid #00c28e;
    }
  }
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #363639;
  margin-bottom: 1rem;
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        font-size: 2.125rem;
      `,
    )}
`

export const TitleTrue = styled.div`
  font-size: 1.125rem;
  font-weight: 400;
  color: #87868d;
  line-height: 2rem;
  margin-bottom: 1rem;
  span {
    color: #e6110c;
    margin-right: 0.25rem;
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        font-size: 1.5rem;
      `,
    )}
`

export const UploadCusDiv = styled.div`
  position: absolute;
  z-index: 1;
  height: calc(16.25rem - 2.5rem);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 0.8rem; */
  .ant-btn {
    width: 100%;
    color: ${(props) => props.theme.gray};
    font-size: 1rem;
    font-weight: 600;
  }

  video {
    width: 100%;
    height: 100%;
  }
  ${(props) =>
    props.theme.mediaWidth.screenLg(
      () => css`
        width: 100%;
      `,
    )}
`

export const Apply = styled.div`
  max-width: 1200px;
  margin: 40px auto;
  background: #f7f8fa;
  border-radius: 1rem;
  height: 30.5rem;
  padding: 11rem 0;
  .applyBtn {
    width: 18.75rem;
    height: 3.75rem;
    text-align: center;
    line-height: 3.75rem;
    font-size: 1.25rem;
    color: white;
    background-color: #00c28e;
    border-radius: 2rem;
    margin: 0 auto;
    cursor: pointer;
  }
  .applyTips {
    margin-top: 3rem;
    color: #87868d;
    font-size: 1.125rem;
    text-align: center;
  }
`
