const privacy = {
  'privacy.title': '隐私政策',
  'privacy.content1':
    'Carbon-Exchange非常重视保护用户的隐私和个人信息保护。您在使用我们的产品/服务时，我们可能会收集和使用您的相关信息。我们的《Carbon-Exchange隐私政策》以下简称“隐私政策”向您说明了我们如何收集、使用、保存、披露和共享您在使用我们的网站以及我们在移动设备上提供的产品和服务时提供给我们的信息。',
  'privacy.content2':
    '我们依据相关法律法规和技术规范收集和使用您的个人信息，以帮助我们向您提供更优质的产品和/或服务。请您在使用Carbon-Exchange前，仔细阅读并充分理解本指引。请您注意，只有在您确认同意后，我们才会开始收集、使用、处理和存储您的个人信息。',
  'privacy.content3':
    '如在特定场景下，您以「不链接钱包」的方式使用Carbon-Exchange，我们将尽最大努力减少信息收集并向您提供浏览功能，但这将导致您无法完整使用Carbon-Exchange的其他各项功能或服务。如果您不同意Carbon-Exchange收集您的任何个人信息，请您立即停止使用并退出Carbon-Exchange。',
  'privacy.content4':
    '出本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新隐私政策。您在同意本应用政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。',
  'privacy.content5': '一、适用范围',
  'privacy.content6': '（1）在您使用本应用服务时，您根据本应用要求链接您的区块链地址。',
  'privacy.content7':
    '（2）在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录您的浏览和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；',
  'privacy.content8': '（3）本应用通过合法途径从商业伙伴处取得用户个人数据。',
  'privacy.content9': ' 二、信息使用',
  'privacy.content10':
    '（1）本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独过共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。',
  'privacy.content11':
    '（2）本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。',
  'privacy.content12':
    '（3）为服务用户的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。',
  'privacy.content13': '三、信息披露',
  'privacy.content14': '在如下情况下，本应用将依据您的个人原因或法律的规定全部或部分的披露您的个人信息：',
  'privacy.content15': '（1）经您事先同意，向第三方披露；',
  'privacy.content16': '（2）为提供您所要求的产品和服务，而必须和地方分享您的个人信息；',
  'privacy.content17': '（3）根据法律法规的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；',
  'privacy.content18': '（4）如您出现违反有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；',
  'privacy.content19': '（5）如您是适格的指示产权投诉人并已提起诉讼，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；',
  'privacy.content20':
    '（6）在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提交其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。',
  'privacy.content21': '（7)其它本应用根据法律、法规或者网站政策认为合适的披露。',
  'privacy.content22': ' 四、信息存储和交换',
  'privacy.content23':
    '本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。',
  'privacy.content24': '五、信息安全',
  'privacy.content25':
    '（1）本应用账号均有安全保护功能，请妥善保管您的私钥和助记词。本应用使用物理、管理和技术保护措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时业请您注意在信息网络上不存在“完善的安全措施”。',
  'privacy.content26':
    '（2）您应对您的数字钱包的安全负责，我们敦促您采取措施确保其安全。如果您发现与您的钱包相关的问题，请联系您的钱包提供商。',
  'privacy.content27': '六、隐私政策的更改',
  'privacy.content28':
    '（1）如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们透露这些信息。',
  'privacy.content29': '（2）本公司保留随时修改政策的权利，因此经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知',
}

export default privacy
