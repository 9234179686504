import useWeb3StoreHooks from '@/hooks/useWeb3StoreHooks'
import type { StorageClientTypes } from '@/contracts/web3StorageInit'
import { useEffect, useState } from 'react'
// import { create } from 'ipfs-http-client'
import axios from 'axios'
import { isJson } from '@/utils'
import { useDispatch } from 'react-redux'
import { SaveInfoWeb3Storage } from '@/store/info/action'
import { retrieveFiles } from '@/common'
// import { Web3Storage } from 'web3.storage/dist/bundle.esm.min.js'

interface Type {
  isRefreshData: boolean
}

export const useWeb3StorageLocalHooks = (props: Type) => {
  const { isRefreshData } = props
  const dispatch = useDispatch()

  const web3Store: StorageClientTypes = useWeb3StoreHooks()
  const { client } = web3Store

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    getLocal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshData])

  const getLocal = async () => {
    let address = localStorage.getItem('web3storage_key') || ''
    if (address !== (process.env as any).REACT_APP_LICENSE) {
      let localLength = localStorage.getItem('web3storage_number') || '0'
      for (let i = 0; i < Number(localLength); i++) {
        localStorage.removeItem(`web3storage_list_${i}`)
      }
      localStorage.removeItem('web3storage_number')
      getList([])
    } else {
      let localLength = localStorage.getItem('web3storage_number') || '0'
      let localWeb3StorageList = []
      for (let i = 0; i < Number(localLength); i++) {
        let obj: any = localStorage.getItem(`web3storage_list_${i}`)
        let listObj = JSON.parse(obj)
        localWeb3StorageList.push(...listObj)
      }
      getList(localWeb3StorageList)
    }
  }


  const getList = async (localWeb3StorageList: any[]) => {
    if (localWeb3StorageList.length === 0) localStorage.setItem('web3storage_key', (process.env as any).REACT_APP_LICENSE)
    let axiosUploadList: { cid: string; name: string }[] = []
    let arrList: { cid: string; jsonSource: any }[] = []
    let NoLocalList = axiosUploadList.filter((item) => !localWeb3StorageList.some((ele) => ele.cid === item.cid))
    let YesLocalList = localWeb3StorageList.filter((item) => axiosUploadList.some((ele) => ele.cid === item.cid))
    for (let i = 0; i < NoLocalList.length; i++) {
      let item = NoLocalList[i]
      let axiosDataFetch = await axios.get(`https://api.web3.storage/car/${item.cid}`, {
        headers: {
          'Content-Type': 'application/vnd.ipld.car',
          Authorizations: `Bearer ${(process.env as any).REACT_APP_LICENSE}`,
        },
      })
      let json = axiosDataFetch.data.substr(axiosDataFetch.data.indexOf(`{"name`), axiosDataFetch.data.length)
      if (isJson(json))
        arrList.push({
          cid: item.cid,
          jsonSource: await JSON.parse(json),
        })
    }
    let list = [...YesLocalList, ...arrList]

    if (list.length > 0) {
      let sizeObj = getLocalStorageSize(JSON.stringify(list))
      let sizeArrLength = Math.ceil(sizeObj.size / 5242880)
      let newResult = await getArrGrouping(list, Math.ceil(list.length / sizeArrLength))
      localStorage.setItem('web3storage_number', newResult.length.toString())
      for (let i = 0; i < newResult.length; i++) {
        localStorage.setItem(`web3storage_list_${i}`, JSON.stringify(newResult[i]))
      }
    }

    dispatch(SaveInfoWeb3Storage(list))
    setLoading(false)
  }

  const getLocalStorageSize = (str: string) => {
    let size = JSON.stringify(str).length * 2
    const arr = ['bytes', 'KB', 'MB', 'GB', 'TB']
    let sizeUnit = 0
    while (size > 1024) {
      size /= 1024
      ++sizeUnit
    }
    return {
      size: Math.ceil(size),
      name: `size:${size.toFixed(2)}${arr[sizeUnit]}`,
    }
  }

  const getArrGrouping = async (arr: any[], size: number) => {
    const arrNum = Math.ceil(arr.length / size)
    let index = 0
    let resIndex = 0
    const result = []
    while (index < arrNum) {
      result[index] = arr.slice(resIndex, size + resIndex)
      resIndex += size
      index++
    }
    return result
  }

  return { loading }
}
