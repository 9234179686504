import styled, { css } from 'styled-components'

export const ComLayout = styled.div``

export const ComLayoutTwo = styled.div``

export const ComContent = styled(ComLayout)``

export const ComMyLayout = styled.div``

export const ComLayoutTwo1 = styled.div``

export const webLayoutAdaptationMax = css`
  max-width: min(75rem, 120rem - 45rem);
  margin: 0 auto;
`

export const webLayoutAdaptation = css`
  max-width: min(62.5%, 100% - 37.5%);
  margin: 0 auto;
`

export const h5LayoutAdaptation = css`
  max-width: min(92.5%, 100% - 7.5%);
  margin: 0 auto;
`

export const webTopBarAdaptationMax = css`
  max-width: min(1740px, 1920px - 180px);
  margin: 0 auto;
`

export const webTopBarAdaptation = css`
  max-width: min(90.63%, 100% - 9.37%);
  margin: 0 auto;
`

export const h5TopBarAdaptation = css`
  max-width: min(91.5%, 100% - 8.5%);
  margin: 0 auto;
`

export const proTopBarAdaptationMax = css`
  max-width: min(75rem, 120rem - 45rem);
  margin: 0 auto;
`

export const proTopBarAdaptation = css`
  max-width: min(90.63%, 100% - 9.37%);
  margin: 0 auto;
`

export const h5proTopBarAdaptation = css`
  max-width: min(91.5%, 100% - 8.5%);
  margin: 0 auto;
`
