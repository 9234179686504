import React, { memo, useState } from 'react'
import { MyDrawer, SideMenuWrapper, StyledNavLink } from './styled'
import { Drawer } from 'antd'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { setListInfoSwitch } from '@/common/init'
import type { MenuListType as ListType } from '@/common/data.d'
import ConnectWallet from '@/components/ConnectWallet'
import SwitchLanguage from '@/components/SwitchLanguage'
import PAGE from '@/assets/page_slices/close.png'

export default memo(function SideMenuH5Page() {
  const { i18n } = useTranslation()
  const [isMenuShow, setIsMenuShow] = useState(false)

  const [{ list }] = useState<{ list: ListType[] }>(() => {
    let { list } = setListInfoSwitch()
    return { list }
  })

  const oddEvent = (match: any, location: any, item: any) => {
    if (!match || item.url === '') {
      return false
    }
    const hash = location.hash
    const itemHash = `#${item.url.substring(item.url.lastIndexOf('#') + 1, item.url.length)}`
    if (hash === itemHash) return true
    else return false
  }

  return (
    <SideMenuWrapper>
      {!isMenuShow && <MenuOutlined className="iconss" onClick={() => setIsMenuShow(true)} />}
      {isMenuShow && <CloseOutlined className="iconss" onClick={() => setIsMenuShow(false)} />}
      <Drawer
        className="h5-drawer"
        closable={false}
        placement="top"
        height={'90%'}
        onClose={() => setIsMenuShow(false)}
        visible={isMenuShow}
      >
        <MyDrawer>
          <div className="close">
            <img src={PAGE} alt="" style={{ width: '1.4375rem' }} onClick={() => setIsMenuShow(false)} />
          </div>
          <div className="myNavlink">
            {list.map((item) => {
              if (item.enName === 'About') {
                return (
                  <StyledNavLink
                    key={item.key}
                    to={item.url === '' ? {} : item.url}
                    isActive={(match, location) => oddEvent(match, location, item)}
                    onClick={() => setIsMenuShow(false)}
                  >
                    <div className="navlink-child-title">{i18n.language === 'en' ? item.enName : item.name}</div>
                  </StyledNavLink>
                )
              } else
                return (
                  <StyledNavLink
                    to={item.url === '' ? {} : item.url}
                    key={item.key}
                    onClick={() => {
                      setIsMenuShow(false)
                      if (item.key === 'blog') {
                        window.open('https://medium.com/@Carbon-Exchange', '_blank')
                      }
                      if (item.key === 'doc') {
                        window.open('https://carbon-exchange.gitbook.io/carbon-exchange-1/', '_blank')
                      }
                    }}
                  >
                    <div className="navlink-child-title">{i18n.language === 'en' ? item.enName : item.name}</div>
                  </StyledNavLink>
                )
            })}
          </div>
          <div style={{ borderTop: '1px solid #E5E5E5' }}>
            <SwitchLanguage />
          </div>
          <ConnectWallet
            isTop={true}
            close={() => {
              setIsMenuShow(false)
            }}
          />
        </MyDrawer>
      </Drawer>
    </SideMenuWrapper>
  )
})
