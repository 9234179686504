import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Image, message, Radio, Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import EN_ICON from '@/assets/svg/en.svg'
import ZH_ICON from '@/assets/svg/zh.svg'
import { DownOutlined } from '@ant-design/icons'
import { AdaptFontSize } from '@/utils'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import LANG from '@/assets/page_slices/lang.png'
import DOWN from '@/assets/phone_slices/down.png'

const SwitchLanguageWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  z-index: 10;
  .language-list-active {
    color: #00477c;
    border-left: 0.125rem solid #00477c;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`margin-right: 10px;`}
`

const Language = styled.div`
  padding: 1.125rem 0.8125rem 1.625rem 0.8125rem;
`

const LanguageList = styled.div`
  width: 100%;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 400;
  color: #293543;
  transition: all 0.3s;
  text-align: center;
  :hover {
    color: #00477c;
    border-left: 0.125rem solid #00477c;
  }
  &:not(:first-child) {
    margin-top: 1rem;
  }
`

const LanguageTitle = styled.div`
  margin-right: 2rem;
  margin-left: 1.375rem;
  font-size: 0.88rem;
  font-weight: 500;
  color: #00477c;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.screenLg`
    min-width: 5.63rem;
    margin-right: 0rem;
  `}
`

const SwitchH5 = styled.div`
  width: 100%;
  margin-bottom: 3.125rem;
  border-bottom: 1px solid #e5e5e5;
  padding: 1.75rem 0;
  .choose {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .lans {
    font-size: 1.5rem;
    color: #5d6175;
    line-height: 5rem;
    padding: 1rem 0 0 4.1875rem;
  }
`

export default memo(function SwitchLanguagePage() {
  const [move, setMoveSwitch] = useState(false)
  const { i18n, t } = useTranslation()
  const { windowSize } = useWindowSizeHooks()
  const [lang, setLang] = useState(false)

  const languageChange = (str: 'en' | 'zh') => i18n.changeLanguage(str)

  const languageChangeSwitch = (str: 'en' | 'zh') => {
    languageChange(str)
    message.info({
      content: t('app.switch.language.tips', { msg: str === 'zh' ? '简体中文' : 'English' }),
      className: 'message-global',
    })
    setMoveSwitch(false)
  }

  const onChange = (str: any) => {
    languageChangeSwitch(str)
  }

  return (
    <>
      {windowSize.innerWidth > AdaptFontSize && (
        <SwitchLanguageWrapper className="SwitchLanguageWrapper">
          <Popover
            overlayClassName="languageOverlayClassName1"
            visible={move}
            onVisibleChange={(visible) => setMoveSwitch(visible)}
            content={
              <Language>
                <LanguageList className={i18n.language === 'zh' ? 'language-list-active' : ''} onClick={() => languageChangeSwitch('zh')}>
                  简体中文
                </LanguageList>
                <LanguageList className={i18n.language === 'en' ? 'language-list-active' : ''} onClick={() => languageChangeSwitch('en')}>
                  English
                </LanguageList>
              </Language>
            }
            arrowPointAtCenter={true}
            getPopupContainer={() => (document as any).getElementsByClassName('SwitchLanguageWrapper')[0]}
          >
            <LanguageTitle>
              <Image src={LANG} width="1.45rem" preview={false} />
              {/* <Image src={i18n.language === 'zh' ? ZH_ICON : EN_ICON} width="1.25rem" height="1.25rem" preview={false} /> */}
              {/* <span style={{ margin: '0 0.38rem' }}>{i18n.language === 'zh' ? '中文' : 'English'}</span>
              <DownOutlined style={{ color: '#5F6469', lineHeight: '.88rem' }} /> */}
            </LanguageTitle>
          </Popover>
        </SwitchLanguageWrapper>
      )}
      {windowSize.innerWidth <= AdaptFontSize && (
        <SwitchH5>
          <div
            className="choose"
            onClick={() => {
              setLang((n) => {
                return !n
              })
            }}
          >
            <img src={LANG} alt="" />
            <img src={DOWN} alt="" />
          </div>
          {lang && (
            <div className="lans">
              <div className="lanItem" onClick={() => onChange('en')}>
                English
              </div>
              <div className="lanItem" onClick={() => onChange('zh')}>
                简体中文
              </div>
            </div>
          )}
        </SwitchH5>
      )}
    </>
  )
})
