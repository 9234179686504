import styled, { css } from 'styled-components'
import nftAbout from '@/assets/img_slices/nftAbout.png'

export const SetWrapper = styled.div`
  padding-top: 5rem;
`

export const HomeContent = styled.div`
  max-width: 1200px;
  margin: 2rem auto 4rem auto;
  ${({ theme }) => theme.mediaWidth.screenMd`
    padding:1rem;
  `}
  .title {
    color: #5d6175;
    font-size: 1.625rem;
    /* display: flex; */
    margin-bottom: 2rem;
    .line {
      cursor: pointer;
      &:hover {
        color: #00c28e;
      }
    }
  }
`

export const MyLink = styled.div`
  margin-top: 1rem;
  .topic {
    color: #00c28e;
    font-size: 1rem;
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          font-size: 1.875rem;
        `,
      )}
  }
  .owner {
    color: #9596a5;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          font-size: 1.5rem;
        `,
      )}
  }
  .name {
    color: #37383d;
    font-size: 1.5rem;
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          font-size: 3rem;
        `,
      )}
  }
  .des {
    margin-top: 1.5rem;
    border: 1px solid #dedede;
    border-radius: 1rem;
    .nocan {
      padding: 1rem 0;
      width: 100%;
      text-align: center;
      img {
        width: 60%;
        ${({ theme }) => theme.mediaWidth.screenMd`
                 width: 100%;
             `}
      }
    }
    .nocanTips {
      color: #9596a5;
      font-size: 1rem;
      text-align: center;
      margin-bottom: 1rem;
    }
    .heder {
      display: flex;
      padding: 1rem 2rem;
      color: #37383d;
      font-size: 1.25rem;
      background-color: #f5f6f8;
      border-radius: 1rem 1rem 0 0;
      align-items: center;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 1.875rem;
          `,
        )}
      img {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 10px;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              width: 2.25rem;
              height: 2.25rem;
            `,
          )}
      }
    }
    .desTips {
      padding: 1.4rem 2rem;
      .canCast {
        color: #9596a5;
        font-size: 1.125rem;
        margin-top: 0.8rem;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              width: 100%;
              text-align: center;
              font-size: 1.75rem;
            `,
          )}
      }
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 2rem;
          `,
        )}
      .tips {
        color: #5d6175;
        font-size: 1.125rem;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 1.625rem;
            `,
          )}
      }
      .price {
        display: flex;
        align-items: center;
        color: #00c28e;
        font-size: 1.875rem;
        margin-bottom: 0.8rem;
        font-weight: 500;
        span {
          margin-left: 10px;
          font-family: DINPro-Medium;
        }
      }
      .linkBtn {
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              width: 100%;
              height: 4rem;
              line-height: 4rem;
              font-size: 2.5rem;
            `,
          )}
        width: 18rem;
        height: 3.2rem;
        border-radius: 2rem;
        text-align: center;
        color: white;
        font-size: 1.6rem;
        line-height: 3.2rem;
        background-color: #00c28e;
        margin: 1.5rem 0 0 0;
        cursor: pointer;
      }
    }
  }
`

export const Details = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${({ theme }) =>
    theme.mediaWidth.screenMd(
      () => css`
        flex-wrap: wrap;
      `,
    )}
  .detailLeft {
    /* width: calc((100% - 2.5rem) / 2); */
    flex: 0.425;
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          flex: 1;
        `,
      )}
    /* padding-right: ; */
    .mainImg {
      max-width: 100%;
      max-height: 31.25rem;
    }
    .leftBottom {
      margin-top: 1.6rem;
      border: 1px solid #dedede;
      border-radius: 1rem;
      .header {
        padding: 1.4rem 2rem;
        background: #f5f6f8;
        color: #37383d;
        font-size: 1.25rem;
        border-radius: 1rem 1rem 0 0;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 1.875rem;
              padding: 1rem 2rem;
            `,
          )}
      }
      .content {
        padding: 1.4rem 2rem;
        .contentItem {
          &:not(:first-child) {
            margin-top: 1rem;
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.125rem;
          ${({ theme }) =>
            theme.mediaWidth.screenMd(
              () => css`
                font-size: 1.75rem;
              `,
            )}
          .name {
            flex: 1;
            color: #9596a5;
          }
          .value {
            flex: 1;
            color: #5d6175;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .detailRight {
    /* width: calc((100% - 2.5rem) / 2); */
    margin-left: 3rem;
    flex: 0.575;
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          flex: 1;
          margin: 1.6rem 0 0;
        `,
      )}

    .buyBtn {
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            font-size: 2.5rem;
          `,
        )}
      width: 18rem;
      height: 3.8rem;
      border-radius: 2rem;
      text-align: center;
      color: white;
      font-size: 1.6rem;
      line-height: 3.8rem;
      background-color: #00c28e;
      margin: 1.5rem 0 0 0;
      cursor: pointer;
    }
    .des {
      margin-top: 1.5rem;
      border: 1px solid #dedede;
      border-radius: 1rem;
      .nocan {
        padding: 1rem 0;
        width: 100%;
        text-align: center;
        img {
          width: 60%;
          ${({ theme }) => theme.mediaWidth.screenMd`
                 width: 100%;
             `}
        }
      }
      .nocanTips {
        color: #9596a5;
        font-size: 1rem;
        text-align: center;
        margin-bottom: 1rem;
      }
      .heder {
        display: flex;
        padding: 1rem 2rem;
        color: #37383d;
        font-size: 1.25rem;
        background-color: #f5f6f8;
        border-radius: 1rem 1rem 0 0;
        align-items: center;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 1.875rem;
            `,
          )}
        img {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 10px;
          ${({ theme }) =>
            theme.mediaWidth.screenMd(
              () => css`
                width: 2.25rem;
                height: 2.25rem;
              `,
            )}
        }
      }
      .desTips {
        padding: 1.4rem 2rem;
        .nodes {
          text-align: center;
          color: #9596a5;
          font-size: 1.125rem;
        }
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 2rem;
            `,
          )}
        .tips {
          color: #5d6175;
          font-size: 1.125rem;
          ${({ theme }) =>
            theme.mediaWidth.screenMd(
              () => css`
                font-size: 1.625rem;
              `,
            )}
        }
        .price {
          display: flex;
          align-items: center;
          color: #00c28e;
          font-size: 1.875rem;
          margin-bottom: 0.8rem;
          font-weight: 500;
          span {
            margin-left: 10px;
            font-family: DINPro-Medium;
          }
        }
        .linkBtn {
          ${({ theme }) =>
            theme.mediaWidth.screenMd(
              () => css`
                width: 100%;
                height: 4rem;
                line-height: 4rem;
                font-size: 2.5rem;
              `,
            )}
          width: 18rem;
          height: 3.2rem;
          border-radius: 2rem;
          text-align: center;
          color: white;
          font-size: 1.6rem;
          line-height: 3.2rem;
          background-color: #00c28e;
          margin: 1.5rem 0 0 0;
          cursor: pointer;
        }
      }
      .metaContent {
        padding: 1.4rem 2rem;
        .metaItem {
          width: 100%;
          display: flex;
          font-size: 1.125rem;
          ${({ theme }) =>
            theme.mediaWidth.screenMd(
              () => css`
                font-size: 1.75rem;
              `,
            )}
          &:not(:first-child) {
            margin-top: 0.8rem;
          }
          .detailTitle {
            color: #9596a5;
            flex: 0.4;
          }
          .detailValue {
            color: #5d6175;
            flex: 0.6;
            /* white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; */
            padding: 0 1rem 0 0;
          }
        }
      }
    }
  }
`

export const CastModal = styled.div`
  text-align: center;
  .topic {
    font-size: 1.5rem;
    color: #00c28e;
    margin: 1.875rem 0;
  }
  .tips {
    font-size: 1.25rem;
    color: #9596a5;
  }
  .castBtn {
    width: 14.625rem;
    height: 3.75rem;
    text-align: center;
    line-height: 3.75rem;
    border-radius: 1.875rem;
    color: white;
    font-size: 1.25rem;
    margin: 1.25rem auto 0 auto;
    background: #00c28e;
    cursor: pointer;
  }
`
