/* eslint-disable */
import React, { memo, useState, useEffect, useRef } from 'react'
import { ComLayoutTwo, IGOnftWrapper, IGOnftContent, ContentList } from './styled'
import { Col, Row, message, Button, Modal, Pagination, Input, Radio, Select } from 'antd'
import { useIGOnftHooks } from '@/hooks/useIGOnftHooks'
import NoData from '@/components/NoData'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BACK from '@/assets/page_slices/back.png'
import not_opened from '@/assets/img_slices/not_opened.png'
import email_send from '@emailjs/browser'

import useWeb3StoreHooks from '@/hooks/useWeb3StoreHooks'
import type { IIeoData, StorageClientTypes } from '@/contracts/web3StorageInit'

import { S3 } from '@aws-sdk/client-s3'

//读数据
import { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { bucket, params, worldCode } from '@/utils'
import Web3 from 'web3'
import { DelSpining, SaveSpining } from '@/store/user/action'
import i18n from '@/utils/i18n'

const { Option } = Select
const publicKey = 'K9tvr8x1Z-3q0LuOV'
const templateID = 'template_523ted5'
const serviceID = 'service_0oyvb6p'
enum InputType {
  walletAddress,
  email,
  phone,
  nation,
  img,
  serialNum,
  standard,
  num,
  type,
  cycle,
  name,
  id,
  ieoName,
  projectOffcialLink,
}

export default memo(function IGOApplyPage(props: any) {
  const dispatch = useDispatch()
  const context = useWeb3React<Web3Provider>()
  const { active } = context
  const myAddress = useSelector((state: any) => state.userInfo.address)
  const { changeData } = props
  const { t } = useTranslation()
  let history = useHistory()
  const modalRef = useRef<any>(null)
  const web3Store: StorageClientTypes = useWeb3StoreHooks()
  const { makeFileObjects } = web3Store
  const [onApplyShow, setOnApplyShow] = useState(false)

  const { loading, listData, getList } = useIGOnftHooks()
  const [value, setValue] = useState(1)
  const [have, setHave] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [phoneType, setPhoneType] = useState('+86')

  /**
   * 钱包地址
   */
  const [walletAddress, setWelletAddress] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [nation, setNation] = useState('')
  /**
   * 序列号
   */
  const [serialNum, setSerialNum] = useState('')
  /**
   * 项目官方链接
   */
  const [projectOffcialLink, setProjectOffcialLink] = useState('')
  /**
   * 认证标准
   */
  const [standard, setStandard] = useState('')
  /**
   * 数量
   */
  const [num, setNum] = useState('')
  /**
   *项目类型
   */
  const [type, setType] = useState('')
  /**
   *碳资产年份
   */
  const [cycle, setCycle] = useState('')
  /**
   *项目名称
   */
  const [name, setName] = useState('')
  /**
   *项目id
   */
  const [id, setID] = useState('')
  /**
   *状态
   */
  const [ieoName, setIeoName] = useState('')

  const nftData: ConstantInitTypes = useDataHooks()
  const { constant, role, web3 } = nftData
  const [pageNum, setPageNum] = useState(1)

  useEffect(() => {
    console.log(listData)
  }, [])

  useEffect(() => {
    if (value === 1 || value === 2) {
      setHave(true)
    } else {
      setHave(false)
    }
  }, [value])

  useEffect(() => {
    if (!active || !myAddress) {
      setIsAdmin(false)
    } else {
      getRole()
    }
  }, [myAddress])

  const getRole = async () => {
    dispatch(SaveSpining(true))
    let data = await constant.ContractMemberRead.methods.hasRole(role, myAddress).call()
    setIsAdmin(data)
    setOnApplyShow(false)
    dispatch(DelSpining(false))
  }

  const getEmail = (email: string) => {
    var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
    return reg.test(email)
  }

  const getLength = (str: string) => {
    var realLength = 0,
      len = str.length,
      charCode = -1
    for (var i = 0; i < len; i++) {
      charCode = str.charCodeAt(i)
      if (charCode >= 0 && charCode <= 128) realLength += 1
      else realLength += 2
    }
    return realLength
  }

  const inputChange = (e: any, type: InputType) => {
    const temp: string = e.target.value
    switch (type) {
      case InputType.walletAddress:
        setWelletAddress(temp)
        break
      case InputType.email:
        setEmail(temp)
        break
      case InputType.phone:
        setPhone(temp)
        break
      case InputType.nation:
        setNation(temp)
        break
      case InputType.serialNum:
        setSerialNum(temp)
        break
      case InputType.projectOffcialLink:
        setProjectOffcialLink(temp)
        break
      case InputType.standard:
        setStandard(temp)
        break
      case InputType.num:
        setNum(temp)
        break
      case InputType.type:
        setType(temp)
        break
      case InputType.cycle:
        setCycle(temp)
        break
      case InputType.name:
        setName(temp)
        break
      case InputType.id:
        setID(temp)
        break
      case InputType.ieoName:
        setIeoName(temp)
        break
      default:
        break
    }
  }

  const carbonDatas = async () => {
    const data: any = await constant.ContractMemberRead.methods.getCarbonDatas(walletAddress, value - 1).call()
    return data
  }
  /**
   * 查看输入的信息是否符合规范
   * @returns
   */
  const judgeInput = (): boolean => {
    if (!ieoName) {
      message.warning(t('igo-input-tips18'))
      return false
    }
    if (typeof ieoName === 'number') {
      message.warning(t('igo-input-tipsNum'))
      return false
    }
    if (getLength(ieoName) > 20) {
      message.warning(t('igo-input-tips23'))
      return false
    }
    if (!walletAddress) {
      message.warning(t('igo-input-tips1'))
      return false
    }
    if (!web3.utils.isAddress(walletAddress)) {
      message.warning(t('igo-input-tips20'))
      return false
    }
    if (!email) {
      message.warning(t('igo-input-tips2'))
      return false
    }
    if (!getEmail(email)) {
      message.warning(t('igo-input-tips22'))
      return false
    }
    if (!phone) {
      message.warning(t('igo-input-tips3'))
      return false
    }
    if (Number(phone) < 0 || phone.length > 15) {
      message.warning(t('igo-input-tips21'))
      return false
    }
    if (!nation) {
      message.warning(t('igo-input-tips4'))
      return false
    }
    if (getLength(nation) > 100) {
      message.warning(t('igo-input-tips24'))
      return false
    }
    if (!serialNum) {
      message.warning(t('igo-input-tips6'))
      return false
    }
    if (getLength(serialNum) > 100) {
      message.warning(t('igo-input-tips26'))
      return false
    }
    if (!projectOffcialLink) {
      message.warning(t('igo-input-tips38'))
      return false
    }
    if (!standard) {
      message.warning(t('igo-input-tips8'))
      return false
    }
    if (getLength(standard) > 100) {
      message.warning(t('igo-input-tips28'))
      return false
    }
    if (!num || getLength(num) > 100) {
      message.warning(t('igo-input-tips9'))
      return false
    }
    if (getLength(num) > 100) {
      message.warning(t('igo-input-tips29'))
      return false
    }
    if (!type) {
      message.warning(t('igo-input-tips10'))
      return false
    }
    if (getLength(type) > 100) {
      message.warning(t('igo-input-tips30'))
      return false
    }
    if (!cycle) {
      message.warning(t('igo-input-tips11'))
      return false
    }
    if (getLength(cycle) > 100) {
      message.warning(t('igo-input-tips31'))
      return false
    }
    if (!name) {
      message.warning(t('igo-input-tips12'))
      return false
    }
    if (getLength(name) > 100) {
      message.warning(t('igo-input-tips32'))
      return false
    }
    if (!id || getLength(id) > 100) {
      message.warning(t('igo-input-tips13'))
      return false
    }
    if (getLength(id) > 100) {
      message.warning(t('igo-input-tips33'))
      return false
    }
    return true
  }
  /**
   * 清除所有输入框信息
   */
  const clearInput = () => {
    setWelletAddress('')
    setEmail('')
    setPhone('')
    setNation('')
    setSerialNum('')
    setStandard('')
    setNum('')
    setType('')
    setCycle('')
    setName('')
    setID('')
    setPhoneType('+86')
    setIeoName('')
  }
  const addBase = async (ieoData: IIeoData) => {
    await constant.ContractMember.methods
      .addBase(
        ieoData.walletAddress,
        web3.utils.asciiToHex(String(ieoData.ieoName)),
        web3.utils.asciiToHex(ieoData.email),
        Number(ieoData.phone),
      )
      .send({
        from: myAddress,
      })
      .on('transactionHash', function (hash: any) {
        console.log(hash)
      })
      .on('receipt', async (receipt: any) => {})
      .on('error', function (error: any, receipt: any) {
        message.error({
          content: error.message,
          className: 'message-global',
        })
        dispatch(DelSpining(false))
      })
  }
  const addCarbon = async (address: string, cid: string) => {
    const cid1 = Web3.utils.asciiToHex(cid)
    await constant.ContractMember.methods
      .addCarbon(address, 0, cid1)
      .send({
        from: myAddress,
      })
      .on('transactionHash', function (hash: any) {
        console.log(hash)
      })
      .on('receipt', async (receipt: any) => {
        console.log('addCarbon suss')
      })
      .on('error', function (error: any, receipt: any) {
        console.log('addCarbon error')
        message.error({
          content: error.message,
          className: 'message-global',
        })
        // setAddLoading(false)
        dispatch(DelSpining(false))
      })
  }
  const addClick = async () => {
    dispatch(SaveSpining(true))
    try {
      if (!judgeInput()) {
        dispatch(DelSpining(false))
        return
      }
      const isApply = await carbonDatas()
      if (isApply === '2') {
        dispatch(DelSpining(false))
        message.warning(t('igo-input-tipsApp'))
        return
      }

      let ieoData: IIeoData = {
        walletAddress: walletAddress,
        email: email,
        phone: phone,
        country: nation,
        serialNum: serialNum,
        projectOffcialLink: projectOffcialLink,
        standard: standard,
        amount: num,
        type: type,
        vintage: cycle,
        projectName: name,
        projectID: id,
        ieoName: ieoName,
      }
      let file: any = makeFileObjects(ieoData, ieoName)
      const s3 = new S3({
        endpoint: params.endpoint,
        credentials: {
          accessKeyId: params.accessKey,
          secretAccessKey: params.secretKey,
        },
        region: 'us-west-2',
      })
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = async () => {
        //文件数据
        const fileBody: any = reader.result
        const data_time = `${Date.now()}_${Math.ceil(Math.random() * 1000000000)}`
        const putObject = await s3.putObject({
          Bucket: bucket,
          Key: 'Ieo/' + data_time,
          Body: fileBody,
        })
        console.log(putObject)
        s3.headObject(
          {
            Bucket: bucket,
            Key: 'Ieo/' + data_time,
          },
          async (err, data: any) => {
            if (!err) {
              await addBase(ieoData)
              await addCarbon(ieoData.walletAddress, data_time)
              clearInput()
              message.success(t('igo-input-tips17'))
              setOnApplyShow(false)
              dispatch(DelSpining(false))
              getList()
            }
          },
        )
      }
    } catch {
      dispatch(DelSpining(false))
    }
  }

  const submitClick = async () => {
    dispatch(SaveSpining(true))
    if (!judgeInput()) {
      dispatch(DelSpining(false))
      return
    }
    // test@co2e.store

    email_send.send(
      serviceID,
      templateID,
      {
        to_email: 'test@co2e.store',
        from_name: 'Ieo 项目申请',
        ieo_name: ieoName,
        wallet_address: walletAddress,
        email: email,
        phone: phoneType + '-' + phone,
        nation: nation,
        seria_num: serialNum,
        projectOffcialLink: projectOffcialLink,
        standard: standard,
        num: num,
        type: type,
        cycle: cycle,
        name: name,
        id: id,
      },
      publicKey,
    )
    clearInput()
    message.success(t('igo-input-tips16'))
    setOnApplyShow(false)
    dispatch(DelSpining(false))
  }
  const back = () => {
    changeData()
    history.replace('./home')
  }

  const onChange = (e: any) => {
    setValue(e.target.value)
  }

  const prefixSelector = (
    <Select
      defaultValue={phoneType}
      value={phoneType}
      className="phoneSe"
      style={{ width: 80, fontSize: '0.875rem' }}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onChange={(e) => {
        setPhoneType(e)
      }}
    >
      {worldCode.map((item) => {
        return (
          <Option value={item.type}>
            <div> {item.type}</div>
            <div>{i18n.language === 'zh' ? item.label : item.enlabel}</div>
          </Option>
        )
      })}
    </Select>
  )
  return (
    <IGOnftWrapper ref={modalRef}>
      <>
        <IGOnftContent>
          <div className="h3">
            <div>{t('igonft.title.h3')}</div>
            <img src={BACK} alt="" onClick={back} />
          </div>
          <div className="h5">{t('igonft.title.h5')}</div>
          <ComLayoutTwo className="igonft-two">
            <ContentList gutter={{ xs: 12, sm: 24, lg: 80 }}>
              {!loading &&
                listData.length > 0 &&
                listData
                  .filter((item1, index) => {
                    if ((pageNum - 1) * 6 <= index && pageNum * 6 > index) {
                      return item1
                    }
                  })
                  .map((item, i) => (
                    <Col key={i} span={24} md={12}>
                      <Row className="ant-cols">
                        <Col span={24}>
                          <div className="right">
                            <span>{item.name}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ))}
              {!loading && listData.length === 0 && <NoData top={0} />}
            </ContentList>
            {!loading && listData.length > 0 && (
              <div className="pageCenter">
                <Pagination
                  defaultCurrent={pageNum}
                  total={listData.length}
                  current={pageNum}
                  pageSize={6}
                  showSizeChanger={false}
                  onChange={(current) => {
                    setPageNum(current)
                  }}
                />
              </div>
            )}
          </ComLayoutTwo>
          <Row>
            <Col span={24} className="igo-btn">
              {isAdmin ? (
                <>
                  <Button
                    className="my-home-btn details-btns"
                    onClick={() => {
                      clearInput(), setIsAdd(false), setOnApplyShow(true)
                    }}
                  >
                    {t('igonft.apply.btn')}
                  </Button>
                  <Button
                    className="my-home-btn details-btns last-btn"
                    onClick={() => {
                      clearInput(), setIsAdd(true), setOnApplyShow(true)
                    }}
                  >
                    {t('igonft.apply.add')}
                  </Button>
                </>
              ) : (
                <Button
                  className="my-home-btn details-btns"
                  onClick={() => {
                    clearInput(), setIsAdd(false), setOnApplyShow(true)
                  }}
                >
                  {t('igonft.apply.btn')}
                </Button>
              )}
            </Col>
          </Row>
        </IGOnftContent>
      </>
      <Modal
        className="myModal"
        visible={onApplyShow}
        style={{ borderRadius: '0.63rem' }}
        footer={null}
        maskClosable={false}
        onCancel={() => setOnApplyShow(false)}
        width="56.25rem"
        centered
        getContainer={modalRef.current}
        wrapClassName="wallets"
      >
        <div className="modal_title">{isAdd ? t('igonft.add.title') : t('igonft.apply.title')}</div>
        <div className="igo-modal-tips">
          <div>{t('igonft.apply.p1')}</div>
          <div>{t('igonft.apply.p2')}</div>
        </div>
        <div className="igo-basic">
          <div className="igo-basic-title">{t('igo-basic-title1')}</div>
          <div className="igo-basic-item">
            <div className="head">{t('igo-basic-title1-0')}</div>

            <Input
              value={ieoName}
              onChange={(e) => {
                inputChange(e, InputType.ieoName)
              }}
            />
          </div>
          <div className="igo-basic-item">
            <div className="head">{t('igo-basic-title1-1')}</div>
            <Input
              value={walletAddress}
              onChange={(e) => {
                inputChange(e, InputType.walletAddress)
              }}
            />
          </div>
          <div className="igo-basic-item">
            <div className="head">{t('igo-basic-title1-2')}</div>
            <Input
              value={email}
              onChange={(e) => {
                inputChange(e, InputType.email)
              }}
            />
          </div>
          <div className="igo-basic-item">
            <div className="head">{t('igo-basic-title1-3')}</div>
            <Input
              type="number"
              className="phoneInput"
              addonBefore={prefixSelector}
              value={phone}
              onChange={(e) => {
                inputChange(e, InputType.phone)
              }}
            />
          </div>
        </div>
        <div className="igo-basic">
          <div className="igo-basic-title">{t('igo-basic-title2')}</div>
          <div className="igo-basic-item">
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={1}>{t('igo-type1')}</Radio>
              {/* <Radio value={2} disabled>
                    {t('igo-type2')}
                  </Radio>
                  <Radio value={3} disabled>
                    {t('igo-type3')}
                  </Radio>
                  <Radio value={4} disabled>
                    {t('igo-type4')}
                  </Radio> */}
            </Radio.Group>
          </div>
          {have ? (
            <>
              <div className="igo-basic-item">
                <div className="head">{t('project.nft.detail.country')}</div>
                <Input
                  value={nation}
                  onChange={(e) => {
                    inputChange(e, InputType.nation)
                  }}
                />
              </div>
              <div className="igo-basic-item">
                <div className="head">{t('project.nft.detail.num')}</div>
                <Input
                  value={serialNum}
                  onChange={(e) => {
                    inputChange(e, InputType.serialNum)
                  }}
                />
              </div>
              <div className="igo-basic-item">
                <div className="head">{t('project.nft.detail.offcial')}</div>
                <Input
                  value={projectOffcialLink}
                  onChange={(e) => {
                    inputChange(e, InputType.projectOffcialLink)
                  }}
                />
              </div>
              <div className="igo-basic-item">
                <div className="head">{t('project.nft.detail.stan')}</div>
                <Input
                  value={standard}
                  onChange={(e) => {
                    inputChange(e, InputType.standard)
                  }}
                />
              </div>
              <div className="igo-basic-item">
                <div className="head">{t('project.nft.detail.amount')}</div>
                <Input
                  value={num}
                  onChange={(e) => {
                    inputChange(e, InputType.num)
                  }}
                />
              </div>
              <div className="igo-basic-item">
                <div className="head">{t('project.nft.detail.type')}</div>
                <Input
                  value={type}
                  onChange={(e) => {
                    inputChange(e, InputType.type)
                  }}
                />
              </div>
              <div className="igo-basic-item">
                <div className="head">{t('project.nft.detail.time')}</div>
                <Input
                  value={cycle}
                  onChange={(e) => {
                    inputChange(e, InputType.cycle)
                  }}
                />
              </div>
              <div className="igo-basic-item">
                <div className="head">{t('project.nft.detail.proName')}</div>
                <Input
                  value={name}
                  onChange={(e) => {
                    inputChange(e, InputType.name)
                  }}
                />
              </div>
              <div className="igo-basic-item">
                <div className="head">{t('project.nft.detail.proId')}</div>
                <Input
                  value={id}
                  onChange={(e) => {
                    inputChange(e, InputType.id)
                  }}
                />
              </div>
            </>
          ) : (
            <div className="opened">
              <img src={not_opened} alt="" style={{ width: '100%' }} />
              <div className="opened_tips">{t('igo-opened')}</div>
            </div>
          )}
        </div>
        {have && (
          <div className="modal_btn" onClick={isAdd ? addClick : submitClick}>
            {isAdd ? t('igonft.apply.btnAdd') : t('igo-btn')}{' '}
          </div>
        )}
      </Modal>
    </IGOnftWrapper>
  )
})
