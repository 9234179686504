const server = {
  'server.title': '服务条款',
  'server.content1':
    '欢迎您使用Carbon-Exchange交易。请您仔细阅读以下条款，并谨慎考虑是否接受本协议的所有条款。除非您接受本协议的所有条款，否则无法享受本平台通过任何渠道为您提供的服务，同时建议您立即停止使用我们的服务和其他操作。如果您接受此协议，将被视为对本文件内容的知晓、认可和同意。',
  'server.content2': '使用规则',
  'server.content3':
    '要使用Carbon-Exchange的服务，用户必须使用允许其在区块链上进行交易的第三方钱包。用户的区块链地址充当其在 Carbon-Exchange 上的身份。因此，用户将需要一个区块链地址和一个第三方钱包来访问该服务。用户在服务上的账户（“账户”）将与用户的区块链地址相关联。',
  'server.content4':
    '用户在 carbon-exchange 上的帐户将与用户链接的区块链地址相关联,将您的钱包用于服务，即表示您同意根据适用的钱包提供商的条款和条件使用该钱包。Carbon-Exchange 不是钱包的提供商、代理商、金融机构或债权人。Carbon-Exchange 对用户使用钱包不承担任何责任或义务，也不对将如何与任何特定钱包一起运行作出任何陈述或保证。用户将全权负责确保其钱包安全，并不应与任何人分享钱包的私钥或助记词。同样，用户对其账户和任何相关的钱包负全部责任，Carbon-Exchange不对与账户有关的任何行为或不作为或由于用户账户或钱包被盗用而造成对的任何损失承担责任。',
  'server.content5':
    '用户承诺不得以任何方式利用Carbon-Exchange直接或间接从事违反法律以及社会公德的行为，Carbon-Exchange有权对违反上述承诺的内容予以删除。',
  'server.content6': '用户不得利用Carbon-Exchange服务制作、上载、复制、发布、传播或者转载如下内容：',
  'server.content7': '反对宪法所确定的基本原则的；',
  'server.content8': '损害国家荣誉和利益的；',
  'server.content9': '危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
  'server.content10': '煽动民族仇恨、民族歧视，破坏民族团结的；',
  'server.content11': '侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；',
  'server.content12': '破坏国家宗教政策，宣扬邪教和封建迷信的；',
  'server.content13': '散布谣言，扰乱社会秩序，破坏社会稳定的；',
  'server.content14': '散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；',
  'server.content15': '侮辱或者诽谤他人，侵害他人合法权益的；',
  'server.content16': '含有法律、行政法规禁止的其他内容的信息。',
  'server.content17': '所有用户同意遵守Carbon-Exchange服务协议。',
  'server.content18':
    'Carbon-Exchange有权对用户使用Carbon-Exchange的情况进行审查和监督，如用户在使用Carbon-Exchange时违反任何上述规定，Carbon-Exchange或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用Carbon-Exchange的权利）以减轻用户不当行为造成的影响。',
  'server.content19': '个人隐私',
  'server.content20':
    '尊重用户个人隐私信息的私有性是Carbon-Exchange的一贯原则，Carbon-Exchange将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，Carbon-Exchange保证不对外公开或向第三方透露用户个人隐私信息，或用户在使用服务时存储的非公开内容。同时，为了运营和改善Carbon-Exchange的技术与服务，Carbon-Exchange将可能会自行收集使用或向第三方提供用户的非个人隐私信息，这将有助于Carbon-Exchange向用户提供更好的用户体验和服务质量。',
  'server.content21': '协议修改',
  'server.content22':
    '根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，Carbon-Exchange有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，Carbon-Exchange将会直接公布修改之后的协议内容，该公布行为视为Carbon-Exchange已经通知用户修改内容。Carbon-Exchange也可采用电子邮件或私信的传送方式，提示用户协议条款的修改、服务变更、或其它重要事项。',
  'server.content23':
    '如果不同意Carbon-Exchange对本协议相关条款所做的修改，用户有权并应当停止使用Carbon-Exchange。如果用户继续使用Carbon-Exchange，则视为用户接受Carbon-Exchange对本协议相关条款所做的修改。',
}

export default server
