const footer = {
  'footer.title': '碳交易中心',
  'footer.top1': '法律和隐私',
  'footer.top2': '服务条款',
  'footer.top3': '用户支持',
  'footer.top1.name1': '服务条款',
  'footer.top1.name2': '隐私保护',
  'footer.top1.name3': '免责声明',
  'footer.top1.name4': '执法请求',
  'footer.top2.name1': '申请IEO',
  'footer.top2.name2': '创建项目集',
  'footer.top2.name3': '碳银行',
  'footer.top2.name4': '碳治理',
  'footer.top3.name1': '帮助中心',
  'footer.top3.name2': '官方验证',
  'footer.top3.name3': '注意事项',
  'footer.top3.name4': '关于我们',
  footer_title: '碳交易中心',
}

export default footer
