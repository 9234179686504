const home = {
  'home.nft.title1': 'More',
  'home.nft.title2': 'Resources Introduction',
  'home.nft.vice.title': 'Every NFT resource is unique and enjoys universal access rights and special interests',
  'home.nft.check': 'Check Now',
  'home.about.title': 'About',
  'home.about.list.1':
    'The carbon exchange platform is a multi-chain NFT market, creating project incubation operation management, NFT design, casting, and sale of the NFT industry, providing secondary circulation of NFT works and artist IP incubation and operation services.',
  'home.about.list.2': `Introduce NFT-related projects to settle in, empower designers, celebrities and artists, and increase creators' economic income through digital works. At the same time, use incubation operation strategies to promote the circulation of NFT works, improve economic benefits, and promote the rapid development of the platform and partners.`,
  'home.about.list.3': 'Advantages',
  'home.about.list.4': '1. Ensure the sustainability of NFTs',
  'home.about.list.5':
    'There is currently an oversupply of low-value NFTs, and with a forecast that 90% of NFTs will lose value in the next few years, carbon exchange will focus on the most valuable 10%, focusing on the rarest and most exclusive NFT works.',
  'home.about.list.6': '2. Work innovation',
  'home.about.list.7':
    'At present, NFT has only scratched the surface in adding value to holders. How to empower requires more innovation. carbon exchange will integrate AR elements in the near future to enhance the relationship between physical assets and works, and realize value and interactivity.',
  'home.about.list.8': '3. Improve the ecological development of the platform',
  'home.about.list.9':
    'We are building a complete NFT industry ecosystem to provide security and convenience for participants in the NFT field.',
  'home.about.list.10': 'In the future, the ecosystem will be improved: GameFi, digital property management, open interfaces, etc.',
  'home.open.tips': 'version updating... please wait on',
  'home.exper.title': '即刻体验碳交易中心',
  'home.pro': '项目集',
  'home.igo.tips': '你是碳交易项目方可以提交申请',
  'home.pro.tips': '你是碳交易项目方，可以创建项目集，创建NFT',
  'home.igo.btn': '提交申请',
  'home.pro.btn': '创建',
  'home.show.title': '优秀项目集',
  'home.dao.title': '碳治理',
  'home.dao.tips': 'IEO和碳NFT体验，精选碳排放项目、理事会成员、碳治理、碳中和远景展望尽在DAO。',
  'home.dao.btn': '探索',
  'home.dao.tag1': '碳排放项目',
  'home.dao.tag2': '理事会成员',
  'home.dao.tag3': '碳治理',
  'home.dao.tag4': '碳中和',
  'home.more.title': '了解更多',
  'home.more.tips': '想了解数字碳资产的用途和交易方式吗?快来查看我们的帮助文章和指南吧',
  'home.more.btn': '查看',
  'home.banner.name1': '您值得信赖的碳资产',
  'home.banner.name2': '交易平台',
  'home.banner.tips': '碳资产交易、核销，为保护环境做出积极贡献。',
  'home.banner.tips1': '请先链接钱包',
}

export default home
