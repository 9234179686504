import type { CardType } from '@/common/data.d'

/** address interception */
export const formatStrAddress = (a: number, b: number, str: string) =>
  str.substring(0, a) + new Array(4).join('.') + str.substring(str.length - b, str.length)

/** Anchor */
export const scrollToAnchor = (anchorName: string) => {
  if (anchorName) {
    let anchorElement = document.getElementById(anchorName)
    if (anchorElement) anchorElement.scrollIntoView({ block: 'end' })
  }
}

/** Validation retains 6 decimal places */
export const validateValue = (value: any) => {
  let rs = /^[0-9]+(\.[0-9]{1,6})?$/
  if (!rs.test(value)) return false
  else return true
}

export const Adapth5 = 768
export const AdaptFontSize = 768

export const objArrayDuplicateRemovaltokenID = (oldArr: CardType[]) => {
  let obj: { [key: string]: boolean } = {}
  let arr_new: CardType[] = oldArr.reduce<CardType[]>((cur: any, next: any) => {
    if (!obj[next.tokenID]) {
      cur.push(next)
      obj[next.tokenID] = true
    }
    return cur
  }, [])

  return arr_new
}

export const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const fuzzyMatch = (str: string, key: string) => {
  let index = -1,
    flag = false
  let arr: any[] = key.split('')
  for (let i = 0; i < arr.length; i++) {
    if (str.indexOf(arr[i]) < 0) {
      break
    } else {
      let match = str.matchAll(arr[i])
      let next: any = match.next()
      while (!next.done) {
        if (next.value.index > index) {
          index = next.value.index
          if (i === arr.length - 1) {
            flag = true
          }
          break
        }
        next = match.next()
      }
    }
  }
  return flag
}

export const isJson = (str: any) => {
  if (typeof str == 'string') {
    try {
      // eslint-disable-next-line
      let obj = JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }
  return false
}

export const showMenu = [
  {
    index: 1,
    name: '碳资产',
    enName: 'Carbon assets',
    key: 'assets',
  },
  // {
  //   index: 2,
  //   name: '碳债券',
  //   enName: 'Carbon securities',
  //   key: 'bonds',
  // },
  // {
  //   index: 3,
  //   name: '碳期货',
  //   enName: 'Carbon futures',
  //   key: 'futures',
  // },
  // {
  //   index: 4,
  //   name: '碳票据',
  //   enName: 'Carbon bill',
  //   key: 'paper',
  // },
]

export const footerMenu = [
  {
    index: 1,
    name: 'NFT',
    enName: 'NFT',
    url: '/projectNft',
  },
  {
    index: 3,
    name: '关于',
    enName: 'About',
    url: '',
  },
  {
    index: 4,
    name: '返回交易中心',
    enName: 'Return Market',
    url: '/market',
  },
]

export const proType = [
  {
    label: '碳资产',
    enLabel: 'Carbon assets',
    value: '0',
  },
  // {
  //   label: '碳债券',
  //   enLabel: 'Carbon bonds',
  //   value: '1',
  // },
  // {
  //   label: '碳期货',
  //   enLabel: 'Carbon futures',
  //   value: '2',
  // },
  // {
  //   label: '碳票据',
  //   enLabel: 'Carbon paper',
  //   value: '3',
  // },
]

export const EchartsMenu = [
  {
    name: '近7天',
    enName: 'Nearly 7 days',
    key: 1,
  },
  {
    name: '近1月',
    enName: 'Nearly a month',
    key: 2,
  },
  {
    name: '近1年',
    enName: 'Nearly one year',
    key: 3,
  },
  {
    name: '所有时间',
    enName: 'All Time',
    key: 4,
  },
]

export const formatDate = (item_date: any) => {
  let date = new Date(parseInt(item_date) * 1000)
  let YY = date.getFullYear() + '-'
  let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return YY + MM + DD + ' ' + hh + mm + ss
}

export const everlandUrl = 'https://carbonexchange.4everland.store'

export const bucket = 'carbonexchange'

export const params = {
  endpoint: 'https://endpoint.4everland.co',
  accessKey: 'LKY9D7CH7DETOPQA16W5',
  secretKey: 'OVSTLjpuZPW5HIPN9E2j3Pqjauikku7YtHqFgHbB',
  sessionToken: '',
}

// export const everlandUrl = 'https://carbonexchangetest.4everland.store'

// export const bucket = 'carbonexchangetest'

// export const params = {
//   endpoint: 'https://endpoint.4everland.co',
//   accessKey: '8Z7UD1T2H3OB7OTJ1RB8',
//   secretKey: 'B9l6uGMcbSS1EcDwoYfUwJWCNH8PP4X+X+e7HjDz',
//   sessionToken: '',
// }

export const proLink = 'https://registry.verra.org/app/projectDetail/VCS/572'

// export const everlandUrl = 'https://tesbucket007.4everland.store'

// export const bucket = 'tesbucket007'

// export const params = {
//   endpoint: 'https://endpoint.4everland.co',
//   accessKey: '74PETHJ7XCX3OUCWKOFD',
//   secretKey: 'DdXup73KXNdWjI2F2FK91gHwd9yCQoj0BMVBtypt',
//   sessionToken: '',
// }

export const worldCode = [
  {
    type: '+86',
    value: /^(\+?0?86\-?)?1[345789]\d{9}$/,
    label: '中国大陆',
    enlabel: 'Chinese mainland',
  },
  {
    type: '+852',
    value: /^([5|6|9])\\d{7}$/,
    label: '中国香港',
    enlabel: 'Hong Kong',
  },
  {
    type: '+853',
    value: /^[6]\\d{7}$/,
    label: '中国澳门',
    enlabel: 'Macau',
  },
  {
    type: '+886',
    value: /^[0][9]\\d{8}$/,
    label: '中国台湾',
    enlabel: 'Taiwan',
  },
  {
    type: '+61',
    value: /^(\+?61|0)4\d{8}$/,
    label: '澳大利亚',
    enlabel: 'Chinese mainland',
  },
  {
    type: '+49',
    value: /^(\+?49[ \.\-])?([\(]{1}[0-9]{1,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/,
    label: '德国',
    enlabel: 'Australia',
  },
  {
    type: '+7',
    value: /^(\+?7|8)?9\d{9}$/,
    label: '俄罗斯',
    enlabel: 'Russia',
  },
  {
    type: '+33',
    value: /^(\+?33|0)[67]\d{8}$/,
    label: '法国',
    enlabel: 'France',
  },
  {
    type: '+82',
    value: /^(00)?82\-?0[71](?:\d{8,9})$/,
    label: '韩国',
    enlabel: 'Korea',
  },
  {
    type: '+1',
    value: /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/,
    label: '加拿大/美国',
    enlabel: 'Canada/USA',
  },
  {
    type: '+60',
    value: /^(\\+?6?01){1}(([145]{1}(\\-|\\s)?\\d{7,8})|([236789]{1}(\\s|\\-)?\\d{7}))$/,
    label: '马来西亚',
    enlabel: 'Malaysia',
  },
  {
    type: '+81',
    value: /^(\\+?81|0)\\d{1,4}[ \\-]?\\d{1,4}[ \\-]?\\d{4}$/,
    label: '日本',
    enlabel: 'Japan',
  },
  {
    type: '+66',
    value: /^(\\+?0?66\\-?)?\\d{10}$/,
    label: '泰国',
    enlabel: 'Thailand',
  },
  {
    type: '+65',
    value: /^(\\+?0?65\\-?)?\\d{10}$/,
    label: '新加坡',
    enlabel: 'Singapore',
  },
]
