import { proTopBarAdaptation, proTopBarAdaptationMax, h5proTopBarAdaptation } from '@/common/styled'
import styled, { css } from 'styled-components'

export const ImageDiv = styled.div`
  width: 100%;
  height: 31.25rem;
  border-radius: 0.31rem;
  box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
    height: 100%;
  }
`

export const CreateDetailsModalWrapper = styled.div`
  .meta-data {
    ${proTopBarAdaptation}
    @media (min-width: 1920px) {
      ${proTopBarAdaptationMax}
    }
    ${(props) =>
      props.theme.mediaWidth.screenMd(
        () => css`
          ${h5proTopBarAdaptation}
          .main-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .content-left {
              width: 100% !important;
              .ant-image {
                width: 100%;
              }
            }
            .content-right {
              width: 100% !important;
              margin-top: 2rem;
            }
          }
        `,
      )}

    .main-content {
      display: flex;
      justify-content: space-between;
      .content-left {
        width: 31.25rem;
      }
      .content-right {
        width: calc(100% - 34.25rem);
        h2 {
          font-size: 2rem;
          ${({ theme }) => theme.mediaWidth.screenMd`
           font-size: 3rem;
  `}
        }
      }
    }
  }
  .details-shop {
    .details-shop-left {
      display: flex;
      align-items: center;
      padding-top: 1.25rem;
      flex-direction: column;
      .ant-image {
        max-width: 95%;
        max-height: 37.5rem;
        border-radius: 0.31rem;
      }
      .ant-image-img {
        border-radius: 0.31rem;
        max-height: 37.5rem;
      }
    }
    .details-shop-right {
      display: flex;
      flex-direction: column;
      padding-top: 1.25rem;
      h2 {
        font-size: 1.88rem;
        margin: 1.69rem 0;
        font-weight: 700;
      }
      h4 {
        font-size: 1rem;
        font-weight: 400;
        color: #363639;
        margin-bottom: 1.88rem;
      }
    }
  }
`

export const PriceDiv = styled.div`
  font-size: 1.88rem;
  font-weight: 500;
  color: #5746fe;
  display: flex;
  align-items: center;
  .ant-image {
    margin-right: 0.75rem;
  }
`

export const DescribeDiv = styled.div`
  width: 100%;
  min-height: auto;
  border-radius: 0.63rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #dedede;
  .price-content {
    display: flex;
    flex-direction: column;
    padding: 1.4rem 2rem;
    .sell_btn {
      width: 18.125rem;
      height: 3.125rem;
      background: #00c28e;
      border-radius: 1.5625rem;
      line-height: 3.125rem;
      text-align: center;
      font-size: 1.625rem;
      color: white;
      font-weight: 400;
      margin-top: 1rem;
      cursor: pointer;
      ${({ theme }) => theme.mediaWidth.screenMd`
      width:100%;
      height:4rem;
      border-radius: 2.6875rem;
      font-size:2.5rem;
      line-height: 4rem;
  `}
    }
    h5 {
      font-size: 1rem;
      font-weight: 400;
      color: #9596a5;
      margin-top: 1rem;
      margin-bottom: 0;
      ${({ theme }) => theme.mediaWidth.screenMd`
   font-size:1.625rem;
  `}
    }
  }
  h3 {
    font-size: 1.125rem;
    font-weight: bold;
    color: #37383d;
    margin-bottom: 0rem;
    padding: 1rem 2rem;
    background-color: #f5f6f8;
    border-radius: 0.63rem 0.63rem 0 0;
    img {
      width: 1.25rem;
      margin-right: 5px;
    }
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          font-size: 1.875rem;
          img {
            width: 2.5rem;
          }
        `,
      )}
  }
  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }
  li {
    list-style-type: none;
    font-size: 1rem;
    font-weight: 400;
    color: #5d6175;
    /* margin-bottom: 2.19rem; */
    word-break: break-all;
    padding: 1.3125rem 1.875rem;
    &::marker {
      content: '';
    }
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          font-size: 1.75rem;
        `,
      )}
  }
  .info {
    margin: 0 0.88rem 2rem 2rem;
    font-size: 1.125rem;
    font-weight: 400;
    color: #5d6175;
    display: flex;
    .span {
      font-weight: 400;
      width: 44%;
      color: #9596a5;
    }
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          font-size: 1.75rem;
        `,
      )}
  }

  &.addDes {
    display: flex;
    justify-content: center;
    padding: 1.8125rem;
    .info {
      margin: 0;
    }
  }
`

export const CurrentThemeDiv = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #00c28e;
  ${({ theme }) => theme.mediaWidth.screenMd`
   
   font-size:1.875rem;
  `}
`

export const Details = styled.div`
  .des {
    margin-top: 1.5rem;
    border: 1px solid #dedede;
    border-radius: 1rem;
    .heder {
      display: flex;
      padding: 1rem 2rem;
      color: #37383d;
      font-size: 1.25rem;
      background-color: #f5f6f8;
      border-radius: 1rem 1rem 0 0;
      align-items: center;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 1.875rem;
          `,
        )}
      img {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 10px;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              width: 2.25rem;
              height: 2.25rem;
            `,
          )}
      }
    }
    .desTips {
      width: 100%;
      color: #5d6175;
      font-size: 1.125rem;
      padding: 1.4rem 2rem;
      word-break: break-all;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 2rem;
          `,
        )}
      .nodes {
        text-align: center;
        color: #9596a5;
        font-size: 1.125rem;
      }
    }
    .metaContent {
      padding: 1.4rem 2rem;
      .metaItem {
        width: 100%;
        display: flex;
        font-size: 1.125rem;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 1.75rem;
            `,
          )}
        &:not(:first-child) {
          margin-top: 0.8rem;
        }
        .detailTitle {
          color: #9596a5;
          flex: 0.4;
          width: 44%;
        }
        .detailValue {
          color: #5d6175;
          flex: 0.6;
          /* white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; */
        }
      }
    }
  }
`
