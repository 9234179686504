import ProjectTopBar from '@/components/ProjectTopBar'
import { Adapth5, formatStrAddress, proLink } from '@/utils'
import { message, Pagination } from 'antd'
import React, { memo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import noDes from '@/assets/home/noDes.png'
import features from '@/assets/page_slices/features.png'
import des from '@/assets/page_slices/des.png'
import des1 from '@/assets/page_slices/des1.png'
import details from '@/assets/page_slices/detail.png'
import details1 from '@/assets/page_slices/detail1.png'
import { SetWrapper, HomeContent, Details, ImageDiv } from './styled'
import i18n from '@/utils/i18n'
import back from '@/assets/page_slices/back.png'
import useDataHooks from '@/hooks/useDataHooks'
import { ConstantInitTypes } from '@/contracts/constantInit'
import ProjectFooter from '@/components/ProjectFooter'
import NoData from '@/components/NoData'
import { GetWeb3StorageJsonOneNew } from '@/common'
import Modelviewer from '@/components/ModelViewer'
import copy from '@/assets/copy.png'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import DEFAULT_IMG from '@/assets/default.png'
import { getData } from '@/api'
import NftCard from '@/components/NftCard'
import Web3 from 'web3'
import { DelSpining, SaveSpining } from '@/store/user/action'
import { useDispatch } from 'react-redux'
interface CardType {
  imageFiles: string
  coverFiles: string
  contracts_type: string
  isDefault: string
  name: string
  id: string
  description: string
  tokenID: string
  type: any
  num: any
  categoriesName: any
  attributes: any
  serialNumber: any
}

export default memo(function ProjectNft(props: any) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let history = useHistory()
  const { windowSize } = useWindowSizeHooks()
  const [pageNum, setPageNum] = useState(1)
  const nftData: ConstantInitTypes = useDataHooks()
  const { Blockchain, SharedToken_ADDRESS, constant, Shared1155Token_ADDRESS } = nftData
  const [currentStatus, setCurrentStatus] = useState<'list' | 'details'>('list')
  const [name1, setName1] = useState('')
  const [dataSource, setDataSource] = useState<any>([])
  const [searchName, setSearchName] = useState('')
  const [detail, setDetail] = useState<CardType>()
  const [type, setType] = useState<string>('.png')

  useEffect(() => {
    if (detail && detail.imageFiles) {
      let s = detail.imageFiles.substr(detail.imageFiles.lastIndexOf('.'))
      setType(s)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail])

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    // setDataLoading(true)
    const search = props.location.search
    const local = localStorage.getItem('itemDetail_id')
    historySearchSwitch('?key=' + local, search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const historySearchSwitch = async (search: any, search1: any) => {
    dispatch(SaveSpining(true))
    try {
      if (search) {
        let listSearch = search.slice(1).split('&')
        let list: { lable: string; value: any }[] = []
        listSearch.forEach((element: any) => {
          let objs = element.split('=')
          list.push({
            lable: objs[0],
            value: objs[1],
          })
        })
        if (list[0].lable === 'key' && list[0].value) {
          let navThemeCidV1 = Web3.utils.hexToAscii(list[0].value)
          const params: any = `
              query MyQuery {
                categoriesInfo(id: "${list[0].value}") {
                  account
                  carbonType
                  contractAddress
                  id
                  CtgNFTs {
                    carbonType
                    categoriesId
                    categoriesOwener
                    id
                    metadata_cid
                    minter
                    type
                    num
                    tokenID
                  }
                }
              }
          `
          const itemData: any = sessionStorage.getItem(navThemeCidV1)
          if (itemData) {
            setName1(JSON.parse(itemData).name)
          }

          const data: any = await getData(params)
          const result: any = data.data.categoriesInfo.CtgNFTs

          if (data && result.length > 0) {
            setDataSource(result)
          } else {
            setDataSource([])
          }

          if (search1 && result.length > 0) {
            setCurrentStatus('details')
            let listSearch1 = search1.slice(1).split('&')
            let list1: { lable: string; value: any }[] = []
            listSearch1.forEach((element: any) => {
              let objs = element.split('=')
              list1.push({
                lable: objs[0],
                value: objs[1],
              })
            })

            if (list1[0].lable === 'key' && list1[0].value) {
              let data = result.filter((item: any) => item.id === list1[0].value)
              if (data.length === 0) {
                message.error({
                  content: t('market.message.tips'),
                  className: 'message-global',
                })
                return false
              }
              if (data[0]) {
                dispatch(DelSpining(false))
                const metaUri: any =
                  data[0].type === 2
                    ? await constant.ContractShared1155TokenRead.methods.uri(data[0].tokenID).call()
                    : await constant.ContractMarketSharedTokenRead.methods.tokenURI(data[0].tokenID).call()

                const nft_data: any = await GetWeb3StorageJsonOneNew(metaUri)

                if (nft_data.axiosData) {
                  nft_data.axiosData.id = data[0].id
                  let s = nft_data.axiosData.imageFiles.substr(nft_data.axiosData.imageFiles.lastIndexOf('.'))
                  setType(s)
                  setDetail(nft_data.axiosData)
                } else {
                  nft_data.id = data[0].id
                  nft_data.type = data[0].type
                  nft_data.tokenID = data[0].tokenID
                  nft_data.imageFiles = nft_data.imageFiles || DEFAULT_IMG
                  nft_data.num = data[0].num
                  nft_data.serialNumber = nft_data.projectOffcialLink || ''
                  let s = nft_data.imageFiles.substr(nft_data.imageFiles.lastIndexOf('.'))
                  setType(s)
                  setDetail(nft_data)
                }
              }
            }
          }
        }
      }
      dispatch(DelSpining(false))
    } catch (error) {
      dispatch(DelSpining(false))
    }
  }

  const copyAddress = (val: any) => {
    let aux = document.createElement('input')
    aux.setAttribute('value', val)
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
    message.success({
      content: t('igonft.apply.copy.success'),
      className: 'message-global',
    })
  }

  return (
    <SetWrapper>
      <ProjectTopBar name={name1} background={`rgba(255,255,255,1)`} borderBottom={`1px solid rgba(229, 229, 229,1)`}></ProjectTopBar>
      <HomeContent>
        <div className="title">
          {currentStatus === 'list' ? (
            t('peoject.nft.title1')
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {t('peoject.nft.title1')} {t('peoject.nft.title2')}
              </div>
              <div>
                <img
                  style={{ cursor: 'pointer' }}
                  src={back}
                  alt=""
                  onClick={() => {
                    if (searchName) {
                      history.replace('./projectHome')
                    } else {
                      setCurrentStatus('list')
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {currentStatus === 'list' && (
          <>
            <div className="homeList">
              {dataSource
                .filter((item: any, i: any) => i < pageNum * 16 && i >= (pageNum - 1) * 16)
                .map((item: any) => {
                  return (
                    <div
                      className="listItem"
                      key={item.id}
                      onClick={() => {
                        history.replace('./projectNft?key=' + item.id)
                      }}
                    >
                      <NftCard detail={item} pageNum={pageNum} />
                    </div>
                  )
                })}
              <div className="bank" />
              <div className="bank" />
              <div className="bank" />
              <div className="bank" />
            </div>
            {dataSource.length > 0 && (
              <div style={{ textAlign: 'center' }}>
                <Pagination
                  defaultCurrent={pageNum}
                  showSizeChanger={false}
                  current={pageNum}
                  pageSize={16}
                  total={dataSource.length}
                  onChange={(current) => {
                    setPageNum(current)
                  }}
                />
              </div>
            )}
            {dataSource.length <= 0 && (
              <div style={{ marginBottom: '11rem' }}>
                <NoData top={6} />
              </div>
            )}
            {windowSize.innerWidth > Adapth5 && (
              <div className="about" id="about">
                {t('project.nft.bottom')}
              </div>
            )}
          </>
        )}
        {currentStatus === 'details' && (
          <Details>
            <div className="detailLeft">
              <ImageDiv>
                {(type === '.jpg' || type === '.jpeg' || type === '.png' || type === '.gif' || type === '.svg' || '.soogif') && (
                  <img src={detail?.imageFiles || detail?.coverFiles || ''} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                )}
                {(type === '.mp4' || type === '.webm') && (
                  <video src={detail?.imageFiles || detail?.coverFiles || ''} controls autoPlay loop></video>
                )}
                {(type === '.mp3' || type === '.wav' || type === '.ogg') && (
                  <audio controls>
                    <source src={detail?.imageFiles || detail?.coverFiles || ''} />
                  </audio>
                )}
                {type === '.gltf' && <Modelviewer src={detail?.imageFiles || detail?.coverFiles || ''} />}
              </ImageDiv>
              {windowSize.innerWidth > Adapth5 && (
                <div className="leftBottom">
                  <div className="header">
                    <img src={features} alt="" style={{ marginRight: '10px' }} />
                    {t('project.nft.features')}
                  </div>
                  <div className="content">
                    <div className="contentItem">
                      <div className="name">{t('project.nft.contentItem1')}</div>
                      <div
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        className="value"
                        onClick={() =>
                          copyAddress(detail?.type ? (detail?.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '')
                        }
                      >
                        {formatStrAddress(6, 4, detail?.type ? (detail?.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '')}
                        <img src={copy} alt="" style={{ height: '1.5rem', marginLeft: '0.5rem' }} />
                      </div>
                    </div>
                    <div className="contentItem">
                      <div className="name">{t('Token.ID')}</div>
                      <div className="value">{detail?.tokenID || '--'}</div>
                    </div>
                    <div className="contentItem">
                      <div className="name">{t('project.nft.contentItem2')}</div>
                      <div className="value"> {detail?.type === 2 ? 'ERC1155' : 'ERC721'}</div>
                    </div>
                    <div className="contentItem">
                      <div className="name">{t('project.nft.contentItem3')}</div>
                      <div className="value">{Blockchain}</div>
                    </div>
                    <div className="contentItem">
                      <div className="name">{t('project.nft.contentItem4')}</div>
                      <div className="value">{i18n.language === 'en' ? 'Carbon assets' : '碳资产'}</div>
                    </div>
                  </div>
                </div>
              )}
              {windowSize.innerWidth <= Adapth5 && (
                <>
                  <div className="topic">
                    {t('project.nft.detail.header')}
                    {name1}
                  </div>
                  <div className="rightName" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      {t('project.nft.detail.name')} {detail?.isDefault ? `${detail?.name}${detail.tokenID}` : detail?.name}
                    </div>
                    <div>x{detail?.num || 0}</div>
                  </div>
                  <div
                    className="buyBtn"
                    onClick={() => {
                      if (detail?.categoriesName) {
                        history.replace(`/market?prokey=${detail?.categoriesName}`)
                      }
                    }}
                  >
                    {t('project.nft.detail.buy')}
                  </div>
                </>
              )}
            </div>
            <div className="detailRight">
              {windowSize.innerWidth > Adapth5 && (
                <>
                  <div className="topic">
                    {t('project.nft.detail.header')}
                    {name1}
                  </div>
                  <div className="rightName" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      {t('project.nft.detail.name')} {detail?.isDefault ? `${detail?.name}${detail?.tokenID}` : detail?.name}
                    </div>
                    <div>x{detail?.num || 0}</div>
                  </div>
                  <div
                    className="buyBtn"
                    onClick={() => {
                      if (detail?.categoriesName) {
                        // history.replace(`/market?key=${detail?.tokenID}-${detail?.type}`)
                        history.replace(`/market?prokey=${detail?.categoriesName}`)
                      }
                    }}
                  >
                    {t('project.nft.detail.buy')}
                  </div>
                </>
              )}
              {windowSize.innerWidth <= Adapth5 && (
                <div className="leftBottom">
                  <div className="header">
                    <img src={features} alt="" style={{ marginRight: '10px' }} />
                    {t('project.nft.features')}
                  </div>
                  <div className="content">
                    <div className="contentItem">
                      <div className="name">{t('project.nft.contentItem1')}</div>
                      <div
                        className="value"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() =>
                          copyAddress(detail?.type ? (detail?.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '')
                        }
                      >
                        {formatStrAddress(6, 4, detail?.type ? (detail?.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '')}
                        <img src={copy} alt="" style={{ height: '2rem', marginLeft: '0.5rem', cursor: 'pointer' }} />
                      </div>
                    </div>
                    <div className="contentItem">
                      <div className="name">{t('Token.ID')}</div>
                      <div className="value">{detail?.tokenID || '--'}</div>
                    </div>
                    <div className="contentItem">
                      <div className="name">{t('project.nft.contentItem2')}</div>
                      <div className="value">{detail?.type === 2 ? 'ERC1155' : 'ERC721'}</div>
                    </div>
                    <div className="contentItem">
                      <div className="name">{t('project.nft.contentItem3')}</div>
                      <div className="value">{Blockchain}</div>
                    </div>
                    <div className="contentItem">
                      <div className="name">{t('project.nft.contentItem4')}</div>
                      <div className="value">{i18n.language === 'en' ? 'Carbon assets' : '碳资产'}</div>
                    </div>
                  </div>
                </div>
              )}
              <div className="des">
                <div className="heder">
                  <img src={windowSize.innerWidth <= Adapth5 ? des1 : des} alt="" />
                  {t('project.nft.detail.des')}
                </div>
                <div className="desTips">
                  {detail?.description ? (
                    detail?.description
                  ) : (
                    <div className="nodes">
                      <img src={noDes} alt="" />
                      <div>{i18n.language === 'en' ? 'No Data' : '暂无信息'}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="des">
                <div className="heder">
                  <img src={windowSize.innerWidth <= Adapth5 ? details1 : details} alt="" />
                  {t('project.nft.detail.dataDetail')}
                </div>
                <div className="metaContent">
                  <div className="metaItem">
                    <div className="detailTitle">{t('project.nft.detail.country')}</div>
                    <div className="detailValue">
                      {detail?.attributes
                        ? detail.attributes.filter((item: any) => item.trait_type === 'country' || item.trait_type === 'nation')[0].value
                        : '--'}
                    </div>
                  </div>
                  <div className="metaItem">
                    <div className="detailTitle">{t('project.nft.detail.num')}</div>
                    <div className="detailValue">
                      <div className="detailValue">
                        {detail?.attributes ? (
                          <a href={detail?.serialNumber || proLink} target="_blank" rel="noreferrer">
                            {detail?.attributes.filter((item: any) => item.trait_type === 'serialNum')[0].value || proLink}
                          </a>
                        ) : (
                          '--'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="metaItem">
                    <div className="detailTitle">{t('project.nft.detail.stan')}</div>
                    <div className="detailValue">
                      {detail?.attributes ? detail.attributes.filter((item: any) => item.trait_type === 'standard')[0].value : '--'}
                    </div>
                  </div>
                  <div className="metaItem">
                    <div className="detailTitle">{t('project.nft.detail.amount')}</div>
                    <div className="detailValue">
                      {detail?.attributes
                        ? detail.attributes.filter((item: any) => item.trait_type === 'amount' || item.trait_type === 'num')[0].value
                        : '--'}
                    </div>
                  </div>
                  <div className="metaItem">
                    <div className="detailTitle">{t('project.nft.detail.type')}</div>
                    <div className="detailValue">
                      {detail?.attributes ? detail.attributes.filter((item: any) => item.trait_type === 'type')[0].value : '--'}
                    </div>
                  </div>
                  <div className="metaItem">
                    <div className="detailTitle">{t('project.nft.detail.time')}</div>
                    <div className="detailValue">
                      {detail?.attributes
                        ? detail.attributes.filter((item: any) => item.trait_type === 'vintage' || item.trait_type === 'cycle')[0].value
                        : '--'}
                    </div>
                  </div>
                  <div className="metaItem">
                    <div className="detailTitle">{t('project.nft.detail.proName')}</div>
                    <div className="detailValue">
                      {detail?.attributes
                        ? detail.attributes.filter((item: any) => item.trait_type === 'projectName' || item.trait_type === 'name')[0].value
                        : '--'}
                    </div>
                  </div>
                  <div className="metaItem">
                    <div className="detailTitle">{t('project.nft.detail.proId')}</div>
                    <div className="detailValue">
                      {detail?.attributes
                        ? detail.attributes.filter((item: any) => item.trait_type === 'projectID' || item.trait_type === 'id')[0].value
                        : '--'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Details>
        )}
      </HomeContent>
      <ProjectFooter name={name1} />
    </SetWrapper>
  )
})
