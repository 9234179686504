import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import Web3 from 'web3'
import MON from '@/assets/svg/usdt.svg'
import centreusdc from '@/assets/centre-usdc_28.png'
import cc from '@/assets/cc.png'
const web3 = new Web3(Web3.givenProvider)

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
  NetWork = 'NetWork',
}

export interface ConnectorNamesType {
  src: 'Injected' | 'WalletConnect' | 'NetWork'
}

export const RPC_URLS: { [chainId: number]: string } = {
  1: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
}

// , 2152, 1088, 56
export const injected = new InjectedConnector({ supportedChainIds: [1] })

export const walletconnect = (rpc: any, chainId: number) => {
  return new WalletConnectConnector({
    rpc,
    chainId,
    qrcode: true,
    infuraId: '9aa3d95b3bc440fa88ea12eaa4456161',
  })
}

export const network = new NetworkConnector({
  urls: { 1: RPC_URLS[1] },
  defaultChainId: 1,
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.NetWork]: network,
}

export const defaultChainId = 1

export const useConstant = {
  1: {
    CHAIN_ID: 1,
    Blockchain: 'Ethereum',
    RPC_URL: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    Token_ADDRESS: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    SharedToken_ADDRESS: '0xa74741F131a2F280DA6828D0227Fc623D8A0db49',
    Shared1155Token_ADDRESS: '0x88c71f88F6aa75aE1eA5754190ea3C2F3E555Ea8',
    FundSplitter_ADDRESS: '0xb66c2D0A12B2e9C01CB7891B82808f68Bc617182',
    Member_ADDRESS: '0x6d1544E94AB86001b94FBE1d8134F4938e5f9A01',
    Categories_ADDRESS: '0x43f2377820D7653E5feEa81F4C12757C60e97B0d',
    Market_ADDRESS: '0x7F6a09cc4414f2D6Fb356B1DefC8fFa6Ffa6C511',

    payTokenOptions: [
      {
        value: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        label: 'USDT',
        ico: MON,
        type: 'mwei',
      },
      {
        value: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        label: 'USDC',
        ico: centreusdc,
        type: 'mwei',
      },
      {
        value: '0x5d768411e77E79E3B8C133A4616734Bbd400f47c',
        label: 'CC',
        ico: cc,
        type: 'ether',
      },
    ],
    apiUrl: '',
    apiKey: '',
    role: '0x5db53b6955dc773ef555b3cc1835ee528ea8da7adbe1cd523fbff73209b77e0b',
    reqUrl: 'https://carbon-exchange.com/CTC/',
    readUrl: 'https://eth-mainnet.g.alchemy.com/v2/SjT7XXoLEetrL59FKMg84ppb-lmRvHWx',
  },
}

export const netWorks = {
  1: {
    chainId: web3.utils.numberToHex(1),
    isSwitch: true,
  },
}
