import styled, { css } from 'styled-components'
import { proTopBarAdaptationMax, proTopBarAdaptation, h5proTopBarAdaptation } from '@/common/styled'

export const VaultWrapper = styled.div`
  ${proTopBarAdaptation}
  @media (min-width: 1920px) {
    ${proTopBarAdaptationMax}
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        ${h5proTopBarAdaptation}
      `,
    )}
  padding-top: 5rem;
  margin-bottom: 5rem !important;
  ${({ theme }) => theme.mediaWidth.screenXl`
      padding: 6rem 20px 0 20px;
  `}
`

export const VaultTitle = styled.div`
  width: 100%;
  height: 6.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #00477c;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
  `}
`

export const VaultContent = styled.div`
  width: 100%;
  position: relative;
  background-color: #f7f8fa;
  padding: 6.125rem 20.625rem 3.6875rem 20.625rem;
  ${({ theme }) => theme.mediaWidth.screenMd`
    padding:0;
  `}
  img {
    width: 100%;
  }
  /* .span {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    color: #5d6175;
    height: 100%;
    .int {
      font-size: 18px;
      color: #87868d;
      max-width: 468px;
      text-align: center;
      margin: 0 auto;
    }
    img {
      height:100%;
      ${({ theme }) => theme.mediaWidth.screenMd`
      width:234px
      
  `}
    }
  } */
`
