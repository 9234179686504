import { GetWeb3StorageJsonOne, GetWeb3StorageJsonOneNew } from '@/common'
import React, { memo, useState, useEffect } from 'react'
import { ListItem } from './styled'
import { Spin, Image } from 'antd'
import DEFAULT_IMG from '@/assets/default.png'
import DEFAULT_IMG1 from '@/assets/load_img.gif'
import default_fail from '@/assets/default_fail.png'
import { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import Web3 from 'web3'

interface ItemCardType {
  detail: any
  pageNum: Number
}

interface ItemType {
  imageFiles: string
  coverFiles: string
  name: string
  isDefault: string
  id: any
  tokenID: any
  num: any
}

export default memo(function NftCardPage(props: ItemCardType) {
  const { detail, pageNum } = props
  const nftData: ConstantInitTypes = useDataHooks()
  const { constant } = nftData
  const [itemDetail, setItemDeatil] = useState<ItemType>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (detail) {
      initData()
    }
  }, [detail])

  useEffect(() => {
    setItemDeatil({
      name: '',
      imageFiles: '',
      coverFiles: '',
      isDefault: '',
      id: null,
      tokenID: null,
      num: 0,
    })
  }, [pageNum])
  const initData = async () => {
    setLoading(true)
    setItemDeatil(await getNftCid(detail.tokenID, detail.type))
    setLoading(false)
  }

  const getNftCid = async (id: any, type: number) => {
    try {
      let uri =
        type === 2
          ? await constant.ContractShared1155TokenRead.methods.uri(id).call()
          : await constant.ContractMarketSharedTokenRead.methods.tokenURI(id).call()

      // let navThemeCidV1 = Web3.utils.hexToAscii(uri)

      // const data: any = await GetWeb3StorageJsonOne(navThemeCidV1, false, 'Nft')
      const data: any = await GetWeb3StorageJsonOneNew(uri)

      data.name = data.name || 'NFT-' + id
      data.num = detail.num
      return data
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  return (
    <Spin spinning={loading} tip="loading...">
      <ListItem>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Image
            src={itemDetail?.coverFiles || itemDetail?.imageFiles || DEFAULT_IMG}
            alt=""
            fallback={default_fail}
            preview={false}
            placeholder={<Image preview={false} src={DEFAULT_IMG1} />}
          />
        </div>
        <div className="name" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{itemDetail?.name ? itemDetail?.name : 'NFT-' + itemDetail?.id}</div>
          <div>x{itemDetail?.num || 0}</div>
        </div>
      </ListItem>
    </Spin>
  )
})
