import React, { memo, useEffect, useRef, useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { Row, Col, Image, Button, InputNumber, Pagination, Drawer, Spin, Input, Table, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { Token_ABI } from '@/contracts/constant'
import {
  TradingFloorWrapper,
  TradingFloorLeft,
  TradingFloorContent,
  TradingFloorTitle,
  TitlteRight,
  Title,
  RightContent,
  SelectionDiv,
  RightTitle,
  PriceDivCard,
  SelectionNumDiv,
  H5Bottom,
  PriceDiv,
  CurrentThemeDiv,
  DescribeDiv,
  ShopDiv,
  NftList,
  customStylesPay,
  ImageDiv,
  Details,
  RightTitle1,
} from './styled'
import { MenusList } from '@/components/SelectNetWork/styled'
import { AdaptFontSize, Adapth5, formatDate, proType, objArrayDuplicateRemovaltokenID, proLink } from '@/utils'
import Select, { components } from 'react-select'
import { message } from 'antd'
import { useMarketHooks1 } from '@/hooks/useMarketHooks1'
import Card from '@/components/Card'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import type { CardType } from '@/common/data.d'
import ConnectWallet from '@/components/ConnectWallet'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { formatStrAddress, fuzzyMatch } from '@/utils'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import NoData from '@/components/NoData'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import ICO from '@/assets/page_slices/ico.png'
import MON from '@/assets/svg/usdt.svg'
import FILTER_ICON from '@/assets/page_slices/FILTER_ICON.png'
import copy from '@/assets/copy.png'
import Current from '@/assets/page_slices/current.png'
import Current1 from '@/assets/page_slices/current1.png'
import features from '@/assets/page_slices/features.png'
import features1 from '@/assets/page_slices/features1.png'
import des from '@/assets/page_slices/des.png'
import des1 from '@/assets/page_slices/des1.png'
import detail from '@/assets/page_slices/detail.png'
import detail1 from '@/assets/page_slices/detail1.png'
import HIS from '@/assets/page_slices/his.png'
import HIS1 from '@/assets/page_slices/his1.png'
import back1 from '@/assets/page_slices/back1.png'
import back from '@/assets/page_slices/back.png'
import Status from '@/assets/page_slices/status.png'
import Price from '@/assets/page_slices/price.png'
import Project from '@/assets/page_slices/project.png'
import Projects from '@/assets/page_slices/projects.png'
import filter from '@/assets/phone_slices/filter.png'
import Sea from '@/assets/page_slices/search.png'
import PAGE from '@/assets/page_slices/close.png'
import type { NftListType } from '@/common/data.d'
import { nftListStatusInit } from '@/contracts/init'
import Modelviewer from '@/components/ModelViewer'
import { nftPriceIcon, nftPriceIconColor } from '@/contracts/init'
import { GetWeb3StorageJsonOne, GetWeb3StorageJsonOneNew } from '@/common'
import BigNumber from 'bignumber.js'
import { getData } from '@/api'
import DEFAULT_IMG from '@/assets/default.png'
import DEFAULT_IMG1 from '@/assets/load_img.gif'
import default_fail from '@/assets/default_fail.png'
import Web3 from 'web3'
import { DelSpining, SaveLoadingTips, SaveSpining } from '@/store/user/action'
import { log } from 'console'
const currentDetailsInit: CardType = {
  name: '',
  image: '',
  serialNumber: '',
  tokenId: '',
  tokenID: '',
  type: '',
  amount: '',
  unit: '',
}

export default memo(function TradingFloorPage(props: any) {
  const context = useWeb3React<Web3Provider>()
  const { active } = context
  const { t, i18n } = useTranslation()
  let history = useHistory()
  const marketH5Drawer = useRef<any>(null)
  const dispatch = useDispatch()
  const nftData: ConstantInitTypes = useDataHooks()
  const {
    marketPageSize,
    payTokenOptions,
    web3,
    readWeb3,
    constant,
    toWeiFromWei,
    Market_ADDRESS,
    SharedToken_ADDRESS,
    Blockchain,
    Shared1155Token_ADDRESS,
  } = nftData

  const [currentDetails, setCurrentDetails] = useState<CardType>(currentDetailsInit)
  const [nftListStatus, setNftListStatus] = useState<NftListType[]>(JSON.parse(JSON.stringify(nftListStatusInit)))

  const [isShow, setIsShow] = useState(true)
  const [priceNum, setPriceNum] = useState<{ min: number | undefined; max: number | undefined }>({ min: undefined, max: undefined })
  const [priceNumStatus, setPriceNumStatus] = useState(false)

  const [pirceH5Min, setPriceH5Min] = useState<number | undefined>(undefined)
  const [pirceH5Max, setPriceH5Max] = useState<number | undefined>(undefined)

  const [selectPayActive, setSelectPayActive] = useState<any>(() => {
    return payTokenOptions[0]
  })
  const [vagueSearch, setVagueSearch] = useState<any>(() => {
    return null
  })
  const [selectType, setSelectType] = useState<any>()
  const [selectTypeActive, setSelectTypeActive] = useState<any>(() => {
    return null
  })
  const [selectSetActive, setSelectSetActive] = useState<any>(() => {
    return null
  })

  const [isMaskOptions, setIsMaskOptions] = useState(false)
  const [isMaskOptions1, setIsMaskOptions1] = useState(false)
  const [isMaskOptions2, setIsMaskOptions2] = useState(false)

  const [current, setCurent] = useState(1)
  const [currentStatus, setCurrentStatus] = useState<'list' | 'details'>('list')

  const [isRefreshData, setIsRefreshData] = useState(false)

  const myAddress = useSelector((state: any) => state.userInfo.address)
  const { tradList, loading, listData } = useMarketHooks1({ isRefreshData, currentDetails, myAddress })
  const { windowSize } = useWindowSizeHooks()
  const [moveSwitch, setMoveSwitch] = useState(false)

  const [type, setType] = useState<string>('.png')
  const [searchValue, setSearchValue] = useState<any>(undefined)

  const [spinLoading, setSpinLoading] = useState(false)
  const [loadingText, setLoadingText] = useState<string>('Loading...')
  const [searchList, setSearchList] = useState<any[]>([])

  const [detailLoading, setDetailLoading] = useState(false)
  const [tokenID, setTokenID] = useState(false)
  const [contractType, setContractType] = useState('')
  const [openBoxList, setOpenBoxList] = useState<any[]>([])
  const [onShow1, setOnShow1] = useState(false)
  const [buyNum, setBuyNum] = useState<any>(null)

  useEffect(() => {
    const search = props.location.search

    if (props.location.state) {
      setSelectTypeActive(proType[props.location.state.index - 1])
    } else {
      setSelectTypeActive(null)
    }
    if (tradList && listData) {
      historySearchSwitch(search)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, listData, tradList])

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    if (typeof priceNum.min !== 'number' && typeof priceNum.max !== 'number') {
      setPriceNumStatus(false)
    }
    if (typeof priceNum.min === 'number' && typeof priceNum.max === 'number') {
      if (priceNum.min > priceNum.max) {
        setPriceNum({
          min: priceNum.min,
          max: priceNum.min,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceNum])

  useEffect(() => {
    if (currentStatus === 'details' && contractType) {
      getHis(contractType)
    }
  }, [currentStatus])

  const getHis = async (contractType: any) => {
    try {
      console.log(currentDetails)

      const params = `
      query MyQuery {
        maketBuyHistories(where: {tokenID: "${tokenID}", collection: "${
        contractType === 1 ? SharedToken_ADDRESS : Shared1155Token_ADDRESS
      }"}, first: 10) {
          seller
          time
          tokenID
          price
          metadata_cid
          id
          categories_cid
          buyer
          amount
          ERC20_address
          collection
        }
      }
      `
      const data: any = await getData(params)
      const result: any = data.data.maketBuyHistories

      const newArr: any = []
      if (result.length > 0) {
        for (var i = 0; i < result.length; i++) {
          let obj: any = {
            // eslint-disable-next-line no-loop-func
            unit: payTokenOptions.find((item: any) => item.value.toLowerCase() === result[i].ERC20_address.toLowerCase())?.label,

            price: web3.utils.fromWei(
              result[i].price ? result[i].price.toString() : '0',
              payTokenOptions.find(
                // eslint-disable-next-line no-loop-func
                (item: any) =>
                  item.label ===
                  payTokenOptions.find((item: any) => item.value.toLowerCase() === result[i].ERC20_address.toLowerCase())?.label,
              )?.type,
            ),
            from: result[i].seller,
            to: result[i].buyer,
            time: formatDate(result[i].time),
            amount: result[i].amount || '1',
          }
          newArr.push(obj)
        }
      }
      setOpenBoxList(newArr)
    } catch (error) {}
  }

  useEffect(() => {}, [tradList])
  useEffect(() => {}, [listData])

  // 详情数据
  const historySearchSwitch = async (search: any) => {
    setDetailLoading(true)
    try {
      if (search && tradList.length !== 0) {
        let listSearch = search.slice(1).split('&')
        let list: { lable: string; value: any }[] = []
        listSearch.forEach((element: any) => {
          let objs = element.split('=')
          list.push({
            lable: objs[0],
            value: objs[1],
          })
        })
        if (list[0].lable === 'prokey' && list[0].value) {
          let data2 = listData.filter((item) => item.id === list[0].value)
          if (data2[0]) {
            selectPayOnChange2(data2[0])
            setDetailLoading(false)
          }
        } else if (list[0].lable === 'key' && list[0].value) {
          let data = tradList.filter((item) => item.id === list[0].value)
          if (data.length === 0) {
            message.error({
              content: t('market.message.tips'),
              className: 'message-global',
            })
            setTimeout(() => {
              history.replace('/market')
            }, 500)
            setDetailLoading(false)
            return false
          }
          setTokenID(data[0].tokenID)
          setContractType(data[0].type)
          setCurrentStatus('details')

          let navThemeCidV3 = Web3.utils.hexToAscii(data[0].categoriesId)
          const data1: any = await GetWeb3StorageJsonOne(navThemeCidV3, true, 'Collection')

          const cid =
            data[0].type === 2
              ? await constant.ContractShared1155TokenRead.methods.uri(data[0].tokenID).call()
              : await constant.ContractMarketSharedTokenRead.methods.tokenURI(data[0].tokenID).call()

          const data2: any = await GetWeb3StorageJsonOneNew(cid)

          data2.imageFiles = data2.imageFiles || DEFAULT_IMG
          let s = data2.imageFiles.substr(data2.imageFiles.lastIndexOf('.'))
          setType(s)
          let royaltyCall: any = await constant.ContractMarketSharedTokenRead.methods
            .royaltyInfo(data[0].tokenID, web3.utils.toWei('1'))
            .call()
          let royaltyB = royaltyCall[1] === '0' ? '0' : new BigNumber(royaltyCall[1]).div(web3.utils.toWei('1')).toFixed(6)
          let royalty = royaltyB === '0' ? '0' : new BigNumber(royaltyB).multipliedBy(100).toFixed(4)

          let obj: any = {
            image: data2.imageFiles ? data2.imageFiles : DEFAULT_IMG,
            cover: data2.coverFiles ? data2.coverFiles : DEFAULT_IMG,
            description: data2.description || '',
            nameTheme: data1.name ? data1.name : data1.axiosData.name,
            name: data2.name ? data2.name : 'NFT-' + data[0].tokenID,
            isSell: data[0].isSell || false,
            isDefault: data[0].isDefault || false,
            tokenID: data[0].tokenID,
            royalty,
            price: data[0].price,
            unit: data[0].unit,
            collectibleHash: data[0].id,
            seller: data[0].seller,
            royaltyAddress: data2.myAddress,
            type: data[0].type,
            amount: data[0].amount || data[0].num,
            serialNumber: data2.projectOffcialLink || '',
            attributes: data2.attributes || '',
          }
          setCurrentDetails(obj)
          setDetailLoading(false)
        } else if (list[0].lable === 'type' && list[0].value) {
          let data1 = listData.filter((item) => item.id === list[0].value)
          if (data1.length === 0) {
            message.error({
              content: t('market.message.tips'),
              className: 'message-global',
            })
            setTimeout(() => {
              history.replace('/market')
            }, 500)
            setDetailLoading(false)
            return false
          } else {
            setDetailLoading(false)
            selectPayOnChange2(data1[0])
          }
          setDetailLoading(false)
        }
      } else {
        setDetailLoading(false)
        setCurrentStatus('list')
      }
      setDetailLoading(false)
    } catch (error) {
      console.log(error)

      setTimeout(() => {
        history.replace('/market')
      }, 500)
    }
  }

  // 页码
  const paginationChange = (page: any, pageSize: any) => setCurent(page)

  // 搜索清除事件
  const reset = () => {
    setPriceNum({ min: undefined, max: undefined })
    setNftListStatus(JSON.parse(JSON.stringify(nftListStatusInit)))
    setPriceNumStatus(false)
    setSearchValue(undefined)
    setVagueSearch(null)
    setSelectTypeActive(null)
    setSelectSetActive(null)
    setCurent(1)
    resetH5()
  }

  const resetH5 = () => {
    setPriceH5Max(undefined)
    setPriceH5Min(undefined)
    setNftListStatus(JSON.parse(JSON.stringify(nftListStatusInit)))
    setPriceNumStatus(false)
    setSearchValue(undefined)
    setVagueSearch(null)
    setSelectTypeActive(null)
    setSelectSetActive(null)
    setCurent(1)
  }

  // 模糊搜索NFT
  const selectPayOnChange3 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVagueSearch(e.target.value)
    setCurent(1)
  }

  // 价格
  const selectPayOnChange = (val: any) => {
    setSelectPayActive(val)
    setIsMaskOptions(false)
    setPriceNum({ min: undefined, max: undefined })
    // setPriceNumStatus(false)
  }

  // 项目类型选项搜索
  const selectPayOnChange1 = (val: any) => {
    setCurent(1)
    setSelectTypeActive(val)
    setIsMaskOptions1(false)
    // setPriceNumStatus(false)
  }

  // 项目集选项搜索
  const selectPayOnChange2 = (val: any) => {
    console.log(val)

    setCurent(1)
    setSelectSetActive(val)
    setIsMaskOptions2(false)
  }

  const selectPayOnChangeH5 = (val: any) => {
    setSelectPayActive(val)
    setIsMaskOptions(false)
  }

  const selectPayOnChangeH51 = (val: any) => {
    setCurent(1)
    setSelectTypeActive(val)
    setIsMaskOptions1(false)
  }

  const selectPayOnChangeH52 = (val: any) => {
    setCurent(1)
    setSelectSetActive(val)
    setIsMaskOptions2(false)
  }
  // 价格应用搜索
  const applyClickNum = () => {
    if (typeof priceNum.min !== 'number' && typeof priceNum.max !== 'number') {
      message.warning({
        content: t('market.message.tips1'),
        className: 'message-global',
      })
    } else if (typeof priceNum.min === 'number' && typeof priceNum.max === 'number') {
      if (priceNum.min > priceNum.max) {
        message.warning({
          content: t('market.message.tips2'),
          className: 'message-global',
        })
      } else {
        setCurent(1)
        setPriceNumStatus(true)
      }
    } else {
      setCurent(1)
      setPriceNumStatus(true)
    }
  }
  // H5价格应用搜索
  const applyClickNumH5 = (obj: any) => {
    let min = pirceH5Min ? Number(pirceH5Min) : undefined
    let max = pirceH5Max ? Number(pirceH5Max) : undefined
    setPriceNum({ min, max })
    if (typeof min !== 'number' && typeof max !== 'number') {
      message.warning({
        content: t('market.message.tips1'),
        className: 'message-global',
      })
    } else if (typeof min === 'number' && typeof max === 'number') {
      if (min > max) {
        message.warning({
          content: t('market.message.tips2'),
          className: 'message-global',
        })
      } else {
        setMoveSwitch(false)
        window.scrollTo(0, 0)
        setPriceNumStatus(true)
        setCurent(1)
      }
    } else {
      setMoveSwitch(false)
      window.scrollTo(0, 0)
      setPriceNumStatus(true)
      setCurent(1)
    }
  }

  const buyClcik = async (obj?: CardType, num?: any) => {
    let params = obj ? obj : currentDetails

    if (myAddress.toLowerCase() === (params.address || params.seller || params.myAddress).toLowerCase()) {
      message.warning({
        content: t('market.message.tips3'),
        className: 'message-global',
      })
      return false
    }

    if (params.unit === 'ETH') buyClickNative(params, num || '')
    else buyClickUsdt(params, num || '')
  }

  const buyClickNative = async (params: CardType, num: any) => {
    let balance = await web3.eth.getBalance(myAddress)
    let balanceToWei = toWeiFromWei(balance)
    let pricesToWei = toWeiFromWei(params.price)
    if (Number(pricesToWei) > Number(balanceToWei)) {
      message.warning({
        content: `${t('market.message.tips4', { msg: payTokenOptions[0].label })}`,
        className: 'message-global',
      })
      return false
    }
    dispatch(SaveLoadingTips(t('spin.buy')))
    dispatch(SaveSpining(true))
    buyNftNativeImplement(params, num)
  }

  const buyNftNativeImplement = async (obj: CardType, num?: any) => {
    try {
      constant.ContractMarket.methods
        .purchase(obj.collectibleHash, obj.type === 2 ? num : Number(obj.amount))
        .send({ from: myAddress, value: String(Number(obj.price) * (obj.type === 2 ? num : Number(obj.amount))) })
        .on('transactionHash', function (hash: any) {
          console.log(hash)
        })
        .on('receipt', async (receipt: any) => {
          message.success({
            content: t('market.buy.success'),
            className: 'message-global',
          })
          history.replace('/market')
          setIsRefreshData(!isRefreshData)
          setOnShow1(false)
          dispatch(DelSpining(false))
        })
        .on('error', function (error: any, receipt: any) {
          message.error({
            content: error.message,
            className: 'message-global',
          })
          console.log('购买error', error)
          dispatch(DelSpining(false))
        })
    } catch (error) {
      dispatch(DelSpining(false))
      console.log('error', error)
    }
  }

  const buyClickUsdt = async (params: CardType, num?: any) => {
    const ContractToken = new readWeb3.eth.Contract(
      Token_ABI,
      payTokenOptions.find((item) => {
        return item.label === params.unit
      })?.value,
    )
    let balanceUsdt = await ContractToken.methods.balanceOf(myAddress).call()
    let balanceToWei = web3.utils.fromWei(
      balanceUsdt,
      payTokenOptions.find((item) => {
        return item.label === params.unit
      })?.type,
    )
    let pricesToWei = web3.utils.fromWei(
      params.price || '',
      payTokenOptions.find((item) => {
        return item.label === params.unit
      })?.type,
    )
    if (Number(pricesToWei) * (params.type === 2 ? Number(num) : Number(params.amount)) > Number(balanceToWei)) {
      message.warning({
        content: `${t('market.message.tips4', {
          msg: payTokenOptions.find((item) => {
            return item.label === params.unit
          })?.label,
        })}`,
        className: 'message-global',
      })
      return false
    }

    dispatch(SaveSpining(true))
    buyNftUsdtisAuthorize(params, num || '')
  }
  // 购买查询余额及授权Token合约
  const buyNftUsdtisAuthorize = async (params: CardType, num?: any) => {
    try {
      const ContractToken = new web3.eth.Contract(
        Token_ABI,
        payTokenOptions.find((item) => {
          return item.label === params.unit
        })?.value,
      )
      const ContractTokenRead = new readWeb3.eth.Contract(
        Token_ABI,
        payTokenOptions.find((item) => {
          return item.label === params.unit
        })?.value,
      )
      let AuthorizedAmount = await ContractTokenRead.methods.allowance(myAddress, Market_ADDRESS).call()

      if (
        Number(
          web3.utils.fromWei(
            AuthorizedAmount,
            payTokenOptions.find((item) => {
              return item.label === params.unit
            })?.type,
          ),
        ) <
        Number(
          web3.utils.fromWei(
            params.price || '',
            payTokenOptions.find((item) => {
              return item.label === params.unit
            })?.type,
          ),
        ) *
          (params.type === 2 ? Number(num) : Number(params.amount))
      ) {
        dispatch(SaveLoadingTips(t('spin.approving')))
        ContractToken.methods
          .approve(
            Market_ADDRESS,
            web3.utils.toWei(
              (
                Number(
                  web3.utils.fromWei(
                    params.price || '',
                    payTokenOptions.find((item) => {
                      return item.label === params.unit
                    })?.type,
                  ),
                ) * (params.type === 2 ? Number(num) : Number(params.amount))
              ).toString(),
              payTokenOptions.find((item) => {
                return item.label === params.unit
              })?.type,
            ),
          )
          .send({
            from: myAddress,
          })
          .on('transactionHash', function (hash: any) {
            console.log(hash)
          })
          .on('receipt', async (receipt: any) => {
            dispatch(SaveLoadingTips(t('spin.buy')))
            buyNftUsdtImplement(params, num || '')
          })
          .on('error', function (error: any, receipt: any) {
            message.error({
              content: error.message,
              className: 'message-global',
            })
            dispatch(DelSpining(false))
          })
      } else buyNftUsdtImplement(params, num || '')
    } catch (error) {
      dispatch(DelSpining(false))
      console.log('error', error)
    }
  }

  const buyNftUsdtImplement = async (obj: CardType, num: any) => {
    try {
      constant.ContractMarket.methods
        .purchase(obj.collectibleHash, obj.type === 2 ? num : obj.amount)
        .send({ from: myAddress })
        .on('transactionHash', function (hash: any) {
          console.log(hash)
        })
        .on('receipt', async (receipt: any) => {
          message.success({
            content: t('market.buy.success'),
            className: 'message-global',
          })
          history.replace('/market')
          setIsRefreshData(!isRefreshData)
          setOnShow1(false)
          dispatch(DelSpining(false))
        })
        .on('error', function (error: any, receipt: any) {
          message.error({
            content: error.message,
            className: 'message-global',
          })
          console.log('购买error', error)
          dispatch(DelSpining(false))
        })
    } catch (error) {
      dispatch(DelSpining(false))
      console.log('error', error)
    }
  }

  const nftListClick = (item: NftListType, is?: boolean) => {
    setCurent(1)
    if (is) item.active = !item.active
    else if (!item.active) item.active = !item.active
    let list = Array.from(new Set([...[item], ...nftListStatus]))
    list.forEach((res) => {
      if (is && res.value === '') {
        res.active = true
      } else if (res.value !== item.value) {
        res.active = false
      }
    })
    setNftListStatus(() => {
      list = list.sort((n1: any, n2: any) => {
        return n1.key - n2.key
      })
      return JSON.parse(JSON.stringify(list))
    })
  }

  const DropdownIndicatorPrice = (props: any) => (
    <components.DropdownIndicator {...props}>
      <DownOutlined style={{ color: '#5746FE' }} />
    </components.DropdownIndicator>
  )
  // 价格下拉数据选择
  const MenuListH5 = (props: any) => {
    return (
      <components.MenuList {...props}>
        {payTokenOptions.map((item: any, i: number) => (
          <MenusList key={i} onClick={() => selectPayOnChangeH5(item)}>
            <div className="network-content">
              <img src={item.ico} style={{ width: '0.8125rem', height: '1.125rem' }} alt="" />
              <h3>{item.label}</h3>
            </div>
          </MenusList>
        ))}
      </components.MenuList>
    )
  }
  // 价格下拉数据选择
  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        {payTokenOptions.map((item: any, i: number) => (
          <MenusList key={i} onClick={() => selectPayOnChange(item)}>
            <div className="network-content">
              <h3>
                <img src={item.ico} style={{ width: '0.8125rem', height: '1.125rem' }} alt="" />
                {item.label}
              </h3>
            </div>
          </MenusList>
        ))}
      </components.MenuList>
    )
  }
  // 项目类型选择数据H5
  const MenuListH51 = (props: any) => {
    return (
      <components.MenuList {...props}>
        {proType.map((item: any, i: number) => (
          <MenusList key={i} onClick={() => selectPayOnChangeH51(item)}>
            <div className="network-content">
              <h3>{i18n.language === 'en' ? item.enLabel : item.label}</h3>
            </div>
          </MenusList>
        ))}
      </components.MenuList>
    )
  }
  // 项目类型选择数据
  const MenuList1 = (props: any) => {
    return (
      <components.MenuList {...props}>
        {proType.map((item: any, i: number) => (
          <MenusList key={i} onClick={() => selectPayOnChange1(item)}>
            <div className="network-content">
              <h3>{i18n.language === 'en' ? item.enLabel : item.label}</h3>
            </div>
          </MenusList>
        ))}
      </components.MenuList>
    )
  }

  const MenuListH52 = (props: any) => {
    return (
      <components.MenuList {...props}>
        {listData.map((item: any, i: number) => (
          <MenusList key={i} onClick={() => selectPayOnChangeH52(item)}>
            <div className="network-content">
              <h3>{item.label}</h3>
            </div>
          </MenusList>
        ))}
      </components.MenuList>
    )
  }
  // 项目集下拉选择数据
  const MenuList2 = (props: any) => {
    return (
      <components.MenuList {...props}>
        {listData.map((item: any, i: number) => (
          <MenusList key={i} onClick={() => selectPayOnChange2(item)}>
            <div className="network-content">
              <h3>{item.label}</h3>
            </div>
          </MenusList>
        ))}
      </components.MenuList>
    )
  }
  // 价格
  const Control1 = ({ children, ...props }: any) => {
    return (
      <div style={{ borderRadius: 10 }}>
        <components.Control {...props}>
          <img
            src={selectPayActive.label === 'USDT' ? MON : ICO}
            alt=""
            style={{ width: '0.8125rem', marginLeft: '0.625rem', height: '1.125rem' }}
          />
          {children}
        </components.Control>
      </div>
    )
  }
  // 项目类型
  const Control2 = ({ children, ...props }: any) => {
    return (
      <div style={{ borderRadius: 10 }}>
        <components.Control {...props}>{children}</components.Control>
      </div>
    )
  }
  // 项目集
  const Control3 = ({ children, ...props }: any) => {
    return (
      <div style={{ borderRadius: 10 }}>
        <components.Control {...props}>{children}</components.Control>
      </div>
    )
  }
  // H5页面

  // 项目详情数据列表
  const columns = [
    {
      title: t('market.list.th5'),
      dataIndex: 'unit',
      key: 'unit',
      render: (_: any, record: any) => [<> {Blockchain}</>],
    },
    {
      title: t('market.list.th1'),
      dataIndex: 'price',
      key: 'price',
      render: (_: any, record: any) => [
        <>
          <Image src={nftPriceIcon[record.unit || '']} width="0.75rem" preview={false}></Image>
          <span style={{ marginLeft: '0.63rem' }}>{record.price}</span>
        </>,
      ],
    },
    {
      title: t('project.nft.detail.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (_: any, record: any) => [<>{record.amount}</>],
    },
    {
      title: t('market.list.th2'),
      dataIndex: 'from',
      key: 'from',
      render: (_: any, record: any) => [<>{formatStrAddress(6, 4, record.from)}</>],
    },
    {
      title: t('market.list.th3'),
      dataIndex: 'to',
      key: 'to',
      render: (_: any, record: any) => [<>{formatStrAddress(6, 4, record.to)}</>],
    },
    { title: t('market.list.th4'), dataIndex: 'time', key: 'time' },
  ]

  const copyAddress = (val: any) => {
    let aux = document.createElement('input')
    aux.setAttribute('value', val)
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
    message.success({
      content: t('igonft.apply.copy.success'),
      className: 'message-global',
    })
  }

  return (
    <Spin spinning={spinLoading} tip={loadingText} className="antd-loadings">
      <TradingFloorWrapper ref={marketH5Drawer}>
        {currentStatus === 'list' && (
          <>
            {windowSize.innerWidth < Adapth5 && (
              <>
                <H5Bottom>
                  <Button className="su-btn" onClick={() => setMoveSwitch(true)}>
                    <img src={filter} alt="" />
                    {t('market.left.title')}
                  </Button>
                </H5Bottom>
              </>
            )}
            <Row>
              {windowSize.innerWidth >= Adapth5 && (
                <Col
                  span={isShow ? 8 : 2}
                  md={isShow ? 6 : 2}
                  xl={isShow ? 4 : 2}
                  className={isShow ? 'left' : ''}
                  style={{
                    borderRight: '1px solid #e5e5e5',
                  }}
                >
                  <TradingFloorLeft>
                    {!isShow && <img className="left-icon" src={back1} alt="" onClick={() => setIsShow(true)} />}
                    {isShow && (
                      <RightContent>
                        <SelectionDiv>
                          <div>
                            <Image src={FILTER_ICON} width="1rem" preview={false}></Image>
                            <span>{t('market.left.title')}</span>
                          </div>
                        </SelectionDiv>

                        <div style={{ padding: '10px' }}>
                          {/* 搜索 */}
                          <div className="search-input">
                            <img src={Sea} alt="" style={{ width: '1.25rem' }} />
                            <Input
                              value={vagueSearch}
                              className="myInput"
                              placeholder={t('market.search')}
                              allowClear
                              onChange={selectPayOnChange3}
                            />
                          </div>
                          {/* 状态 */}
                          <div className="status">
                            <RightTitle>
                              <img src={Status} width={20} alt="" /> {t('market.left.title.vice1')}
                            </RightTitle>
                            <div className="nft-list">
                              {nftListStatus.map((item, i) => (
                                <div className={item.active ? 'nft-btn active' : 'nft-btn'} key={i} onClick={() => nftListClick(item)}>
                                  {i18n.language === 'en' ? item.enName : item.name}
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* 价格 */}
                          <div className="status" style={{ paddingBottom: '10px' }}>
                            <RightTitle>
                              <img src={Price} width={20} alt="" /> {t('market.left.title.vice2')}
                            </RightTitle>
                            <PriceDivCard>
                              <Select
                                className="mySelect"
                                menuIsOpen={isMaskOptions}
                                onMenuOpen={() => setIsMaskOptions(true)}
                                onMenuClose={() => setIsMaskOptions(false)}
                                styles={customStylesPay}
                                isSearchable={false}
                                options={payTokenOptions}
                                placeholder={t('market.input.placeholder1')}
                                onChange={selectPayOnChange}
                                value={selectPayActive}
                                components={{ DropdownIndicator: DropdownIndicatorPrice, MenuList, Control: Control1 }}
                              />
                            </PriceDivCard>
                            <Row className="price-number">
                              <Col span={10}>
                                <InputNumber
                                  type="number"
                                  precision={6}
                                  placeholder="Min"
                                  min={0.000001}
                                  max={999999999.999999}
                                  value={priceNum.min}
                                  onChange={(s) => setPriceNum({ ...priceNum, min: s })}
                                />
                              </Col>
                              <Col span={4} className="price-num-to">
                                to
                              </Col>
                              <Col span={10}>
                                <InputNumber
                                  type="number"
                                  precision={6}
                                  placeholder="Max"
                                  min={priceNum.min}
                                  max={999999999.999999}
                                  onChange={(s) => setPriceNum({ ...priceNum, max: s })}
                                  value={priceNum.max}
                                />
                              </Col>
                            </Row>
                            <Button className="apply-btn" type="primary" onClick={applyClickNum}>
                              {t('market.left.title.vice2.btn')}
                            </Button>
                          </div>
                          {/* 项目类型 */}
                          <div className="status" style={{ paddingBottom: '10px' }}>
                            <RightTitle>
                              <img src={Project} width={20} alt="" /> {t('market.left.title.vice3')}
                            </RightTitle>
                            <PriceDivCard>
                              <Select
                                className="mySelect"
                                menuIsOpen={isMaskOptions1}
                                onMenuOpen={() => {
                                  setIsMaskOptions1(true)
                                  setIsMaskOptions2(false)
                                }}
                                onMenuClose={() => setIsMaskOptions1(false)}
                                styles={customStylesPay}
                                isSearchable={false}
                                options={proType}
                                placeholder={t('market.input.placeholder1')}
                                onChange={selectPayOnChange1}
                                value={selectTypeActive}
                                components={{ DropdownIndicator: DropdownIndicatorPrice, MenuList: MenuList1, Control: Control2 }}
                              />
                            </PriceDivCard>
                          </div>
                          {/* 项目集 */}
                          <div className="status" style={{ paddingBottom: '10px' }}>
                            <RightTitle>
                              <img src={Projects} width={20} alt="" /> {t('market.left.title.vice4')}
                            </RightTitle>
                            <PriceDivCard className="homeSpin">
                              <Spin spinning={loading} tip="">
                                <Select
                                  className="mySelect"
                                  menuIsOpen={isMaskOptions2}
                                  onMenuOpen={() => {
                                    setIsMaskOptions1(false)
                                    setIsMaskOptions2(true)
                                  }}
                                  onMenuClose={() => setIsMaskOptions2(false)}
                                  styles={customStylesPay}
                                  isSearchable={false}
                                  options={listData}
                                  placeholder={t('market.input.placeholder1')}
                                  onChange={selectPayOnChange2}
                                  value={selectSetActive}
                                  components={{ DropdownIndicator: DropdownIndicatorPrice, MenuList: MenuList2, Control: Control3 }}
                                />
                              </Spin>
                            </PriceDivCard>
                          </div>
                        </div>
                      </RightContent>
                    )}
                  </TradingFloorLeft>
                </Col>
              )}

              <Col span={24} md={isShow ? 17 : 22} xl={isShow ? 17 : 22}>
                {windowSize.innerWidth > Adapth5 ? (
                  <TradingFloorTitle className="list_title" active={isShow}>
                    <Title>
                      <Link style={{ fontWeight: '400' }} to={'/market'}>
                        {t('market.title')}
                      </Link>
                    </Title>
                  </TradingFloorTitle>
                ) : (
                  <div style={{ height: '2.625rem' }}></div>
                )}
                <TradingFloorContent active={isShow}>
                  <TitlteRight>
                    <div className="left">
                      {/* 模糊搜索 */}
                      {vagueSearch && (
                        <SelectionNumDiv>
                          <div
                            style={{
                              textAlign: 'center',
                              flex: '1',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              padding: '0 0.3125rem',
                            }}
                          >
                            {t('market.left.title.vice5')}:&nbsp;&nbsp;{vagueSearch}
                          </div>
                        </SelectionNumDiv>
                      )}
                      {priceNumStatus && (
                        <SelectionNumDiv>
                          <Image src={selectPayActive.label === 'USDT' ? MON : ICO} className="icons" preview={false}></Image>
                          <div style={{ flex: '1', textAlign: 'center' }}>
                            {selectPayActive.label}:&nbsp;&nbsp;
                            {typeof priceNum.min === 'number' && typeof priceNum.max === 'number' && (
                              <>
                                <span>{priceNum.min}</span>-<span>{priceNum.max}</span>
                              </>
                            )}{' '}
                            {typeof priceNum.min === 'number' && typeof priceNum.max !== 'number' && (
                              <>
                                {'>='}
                                <span>{priceNum.min}</span>
                              </>
                            )}{' '}
                            {typeof priceNum.min !== 'number' && typeof priceNum.max === 'number' && (
                              <>
                                {'<='}
                                <span>{priceNum.max}</span>
                              </>
                            )}
                          </div>
                        </SelectionNumDiv>
                      )}
                      {/* 项目类型 搜索 */}
                      {selectTypeActive && (
                        <SelectionNumDiv>
                          <div
                            style={{
                              textAlign: 'center',
                              flex: '1',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              padding: '0 0.3125rem',
                            }}
                          >
                            {t('market.left.title.vice3')}:&nbsp;&nbsp;{' '}
                            {i18n.language === 'en' ? selectTypeActive.enLabel : selectTypeActive.label}
                          </div>
                        </SelectionNumDiv>
                      )}
                      {/* 项目集 搜索 */}
                      {selectSetActive && (
                        <SelectionNumDiv>
                          <div
                            style={{
                              textAlign: 'center',
                              flex: '1',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              padding: '0 0.3125rem',
                            }}
                          >
                            {t('market.left.title.vice4')}:&nbsp;&nbsp; {selectSetActive.label}
                          </div>
                        </SelectionNumDiv>
                      )}
                      {/* 清除 */}
                      {(priceNumStatus || selectTypeActive || vagueSearch || selectSetActive) && (
                        <Button className="reset-btn" type="text" onClick={() => reset()}>
                          {t('market.left.reset')}
                        </Button>
                      )}
                    </div>
                  </TitlteRight>
                  {windowSize.innerWidth > Adapth5 ? (
                    <div
                      style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#DEDEDE',
                        margin: priceNumStatus || selectTypeActive || vagueSearch || selectSetActive ? '2.625rem 0' : '0 0 1.625rem 0',
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                  <div className="content-nft">
                    <NftList>
                      {!loading && (
                        <>
                          {tradList
                            .filter((item) => {
                              if (searchValue) return fuzzyMatch(item.name, searchValue)
                              else return true
                            })
                            .filter((item) => {
                              if (searchValue && item.nameTheme) return fuzzyMatch(item.name, searchValue)
                              else return true
                            })
                            .filter((item) => {
                              let obj = nftListStatus.find((item) => item.value === '1')
                              return obj?.active ? item.isSell === true : true
                            })
                            .filter((item) => {
                              if (priceNumStatus) {
                                return item.unit === selectPayActive.label
                              } else return true
                            })

                            .filter((item) => {
                              if (priceNumStatus) {
                                if (priceNum.min && priceNum.max)
                                  return (
                                    Number(
                                      web3.utils.fromWei(
                                        item.price || '',
                                        payTokenOptions.find((item1) => {
                                          return item1.label === item.unit
                                        })?.type,
                                      ),
                                    ) >= priceNum.min &&
                                    Number(
                                      web3.utils.fromWei(
                                        item.price || '',
                                        payTokenOptions.find((item1) => {
                                          return item1.label === item.unit
                                        })?.type,
                                      ),
                                    ) <= priceNum.max
                                  )
                                else if (priceNum.min)
                                  return (
                                    Number(
                                      web3.utils.fromWei(
                                        item.price || '',
                                        payTokenOptions.find((item1) => {
                                          return item1.label === item.unit
                                        })?.type,
                                      ),
                                    ) >= priceNum.min
                                  )
                                else if (priceNum.max)
                                  return (
                                    Number(
                                      web3.utils.fromWei(
                                        item.price || '',
                                        payTokenOptions.find((item1) => {
                                          return item1.label === item.unit
                                        })?.type,
                                      ),
                                    ) <= priceNum.max
                                  )
                                else return true
                              } else return true
                            })

                            .filter((item, i) => i < current * (marketPageSize || 16) && i >= (current - 1) * (marketPageSize || 16))
                            .filter((item) => {
                              if (selectTypeActive) {
                                if (selectTypeActive.value === item.carbonType + '') {
                                  return true
                                } else {
                                  return false
                                }
                              }
                              return true
                            })
                            .filter((item) => {
                              //菜单栏类型筛选
                              if (selectType) {
                                return item.status === selectType.key
                              } else return true
                            })
                            .filter((item) => {
                              if (selectSetActive) {
                                return item.categoriesId === selectSetActive.id
                              } else return true
                            })
                            .filter((item) => {
                              if (vagueSearch) {
                                let arrFilter = searchList.filter((ite) => ite.name.indexOf(vagueSearch) !== -1)
                                let obj = arrFilter.find((ite) => ite.tokenID === item.tokenID)
                                return obj?.tokenID === item.tokenID
                              }
                              return true
                            })
                            .map((item, i) => (
                              <div key={i} className="content-nft-info myOwn">
                                <Card
                                  details={item}
                                  keys="tradingFloor"
                                  returnRefreshData={() => {}}
                                  returnClick={(s) => {
                                    console.log(s)
                                    history.replace(`/market?key=${s.id}`)
                                  }}
                                  returnBuyClcik={(s, num) => buyClcik(s, num)}
                                  setLoading={(s) => setSpinLoading(s)}
                                  setLoadingText={(s) => setLoadingText(s)}
                                  returnLoad={(s) => {
                                    setSearchList((arr) => {
                                      return objArrayDuplicateRemovaltokenID(arr.concat([s]))
                                    })
                                  }}
                                />
                              </div>
                            ))}
                        </>
                      )}
                      {loading && (
                        <div className="loadings">
                          <Spin tip="Loading..." />
                        </div>
                      )}
                      {!loading &&
                        tradList
                          .filter((item) => {
                            if (searchValue) return fuzzyMatch(item.name, searchValue)
                            else return true
                          })
                          .filter((item) => {
                            if (searchValue && item.nameTheme) return fuzzyMatch(item.name, searchValue)
                            else return true
                          })
                          .filter((item) => {
                            let obj = nftListStatus.find((item) => item.value === '1')
                            return obj?.active ? item.isSell === true : true
                          })
                          .filter((item) => {
                            if (priceNumStatus) {
                              return item.unit === selectPayActive.label
                            } else return true
                          })
                          .filter((item) => {
                            // if (priceNumStatus && item.unit !== 'USDT') {
                            if (priceNumStatus) {
                              if (priceNum.min && priceNum.max)
                                return (
                                  Number(
                                    web3.utils.fromWei(
                                      item.price || '',
                                      payTokenOptions.find((item1) => {
                                        return item1.label === item.unit
                                      })?.type,
                                    ),
                                  ) >= priceNum.min &&
                                  Number(
                                    web3.utils.fromWei(
                                      item.price || '',
                                      payTokenOptions.find((item1) => {
                                        return item1.label === item.unit
                                      })?.type,
                                    ),
                                  ) <= priceNum.max
                                )
                              else if (priceNum.min)
                                return (
                                  Number(
                                    web3.utils.fromWei(
                                      item.price || '',
                                      payTokenOptions.find((item1) => {
                                        return item1.label === item.unit
                                      })?.type,
                                    ),
                                  ) >= priceNum.min
                                )
                              else if (priceNum.max)
                                return (
                                  Number(
                                    web3.utils.fromWei(
                                      item.price || '',
                                      payTokenOptions.find((item1) => {
                                        return item1.label === item.unit
                                      })?.type,
                                    ),
                                  ) <= priceNum.max
                                )
                              else return true
                            } else return true
                          })

                          .filter((item, i) => i < current * (marketPageSize || 16) && i >= (current - 1) * (marketPageSize || 16))
                          .filter((item) => {
                            if (selectTypeActive) {
                              if (selectTypeActive.value === item.carbonType + '') {
                                return true
                              } else {
                                return false
                              }
                            }
                            return true
                          })
                          .filter((item) => {
                            //菜单栏类型筛选
                            if (selectType) {
                              return item.status === selectType.key
                            } else return true
                          })
                          .filter((item) => {
                            if (selectSetActive) {
                              return item.categoriesId === selectSetActive.id
                            } else return true
                          })
                          .filter((item) => {
                            if (vagueSearch) {
                              let arrFilter = searchList.filter((ite) => ite.name.indexOf(vagueSearch) !== -1)
                              let obj = arrFilter.find((ite) => ite.tokenID === item.tokenID)
                              return obj?.tokenID === item.tokenID
                            }
                            return true
                          }).length === 0 && <NoData top={6} />}
                    </NftList>
                  </div>

                  <div className="pagination-nft">
                    {tradList
                      .filter((item) => {
                        if (searchValue) return fuzzyMatch(item.name, searchValue)
                        else return true
                      })
                      .filter((item) => {
                        if (searchValue && item.nameTheme) return fuzzyMatch(item.name, searchValue)
                        else return true
                      })
                      .filter((item) => {
                        let obj = nftListStatus.find((item) => item.value === '1')
                        return obj?.active ? item.isSell === true : true
                      })
                      .filter((item) => {
                        if (priceNumStatus) {
                          return item.unit === selectPayActive.label
                        } else return true
                      })
                      .filter((item) => {
                        // if (priceNumStatus && item.unit !== 'USDT') {
                        if (priceNumStatus) {
                          if (priceNum.min && priceNum.max)
                            return (
                              Number(
                                web3.utils.fromWei(
                                  item.price || '',
                                  payTokenOptions.find((item1) => {
                                    return item1.label === item.unit
                                  })?.type,
                                ),
                              ) >= priceNum.min &&
                              Number(
                                web3.utils.fromWei(
                                  item.price || '',
                                  payTokenOptions.find((item1) => {
                                    return item1.label === item.unit
                                  })?.type,
                                ),
                              ) <= priceNum.max
                            )
                          else if (priceNum.min)
                            return (
                              Number(
                                web3.utils.fromWei(
                                  item.price || '',
                                  payTokenOptions.find((item1) => {
                                    return item1.label === item.unit
                                  })?.type,
                                ),
                              ) >= priceNum.min
                            )
                          else if (priceNum.max)
                            return (
                              Number(
                                web3.utils.fromWei(
                                  item.price || '',
                                  payTokenOptions.find((item1) => {
                                    return item1.label === item.unit
                                  })?.type,
                                ),
                              ) <= priceNum.max
                            )
                          else return true
                        } else return true
                      })

                      .filter((item, i) => i < current * (marketPageSize || 16) && i >= (current - 1) * (marketPageSize || 16))
                      .filter((item) => {
                        if (selectTypeActive) {
                          if (selectTypeActive.value === item.carbonType + '') {
                            return true
                          } else {
                            return false
                          }
                        }
                        return true
                      })
                      .filter((item) => {
                        //菜单栏类型筛选
                        if (selectType) {
                          return item.status === selectType.key
                        } else return true
                      })
                      .filter((item) => {
                        if (selectSetActive) {
                          return item.categoriesId === selectSetActive.id
                        } else return true
                      })
                      .filter((item) => {
                        if (vagueSearch) {
                          let arrFilter = searchList.filter((ite) => ite.name.indexOf(vagueSearch) !== -1)
                          let obj = arrFilter.find((ite) => ite.tokenID === item.tokenID)
                          return obj?.tokenID === item.tokenID
                        }
                        return true
                      }).length > 0 &&
                      !loading && (
                        <Pagination
                          showTitle={false}
                          current={current}
                          defaultCurrent={current}
                          pageSize={marketPageSize || 16}
                          defaultPageSize={marketPageSize || 16}
                          total={
                            tradList
                              .filter((item) => {
                                if (searchValue) return fuzzyMatch(item.name, searchValue)
                                else return true
                              })
                              .filter((item) => {
                                if (searchValue && item.nameTheme) return fuzzyMatch(item.name, searchValue)
                                else return true
                              })
                              .filter((item) => {
                                let obj = nftListStatus.find((item) => item.value === '1')
                                return obj?.active ? item.isSell === true : true
                              })
                              .filter((item) => {
                                if (priceNumStatus) {
                                  return item.unit === selectPayActive.label
                                } else return true
                              })
                              .filter((item) => {
                                // if (priceNumStatus && item.unit !== 'USDT') {
                                if (priceNumStatus) {
                                  if (priceNum.min && priceNum.max)
                                    return (
                                      Number(
                                        web3.utils.fromWei(
                                          item.price || '',
                                          payTokenOptions.find((item1) => {
                                            return item1.label === item.unit
                                          })?.type,
                                        ),
                                      ) >= priceNum.min &&
                                      Number(
                                        web3.utils.fromWei(
                                          item.price || '',
                                          payTokenOptions.find((item1) => {
                                            return item1.label === item.unit
                                          })?.type,
                                        ),
                                      ) <= priceNum.max
                                    )
                                  else if (priceNum.min)
                                    return (
                                      Number(
                                        web3.utils.fromWei(
                                          item.price || '',
                                          payTokenOptions.find((item1) => {
                                            return item1.label === item.unit
                                          })?.type,
                                        ),
                                      ) >= priceNum.min
                                    )
                                  else if (priceNum.max)
                                    return (
                                      Number(
                                        web3.utils.fromWei(
                                          item.price || '',
                                          payTokenOptions.find((item1) => {
                                            return item1.label === item.unit
                                          })?.type,
                                        ),
                                      ) <= priceNum.max
                                    )
                                  else return true
                                } else return true
                              })

                              .filter((item) => {
                                if (selectTypeActive) {
                                  if (selectTypeActive.value === item.carbonType + '') {
                                    return true
                                  } else {
                                    return false
                                  }
                                }
                                return true
                              })
                              .filter((item) => {
                                //菜单栏类型筛选
                                if (selectType) {
                                  return item.status === selectType.key
                                } else return true
                              })
                              .filter((item) => {
                                if (selectSetActive) {
                                  return item.categoriesId === selectSetActive.id
                                } else return true
                              })
                              .filter((item) => {
                                if (vagueSearch) {
                                  let arrFilter = searchList.filter((ite) => ite.name.indexOf(vagueSearch) !== -1)
                                  let obj = arrFilter.find((ite) => ite.tokenID === item.tokenID)
                                  return obj?.tokenID === item.tokenID
                                }
                                return true
                              }).length
                          }
                          showSizeChanger={false}
                          onChange={paginationChange}
                        />
                      )}
                  </div>
                </TradingFloorContent>
              </Col>
            </Row>
          </>
        )}
        {/* NFT列表详情 */}
        {currentStatus === 'details' && (
          <Spin spinning={detailLoading} tip="detailLoading">
            <TradingFloorTitle active={false}>
              <Title>
                <div style={{ fontWeight: '400' }}>{t('market.title')}</div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCurrentStatus('list')
                    setCurrentDetails(currentDetailsInit)
                    setCurent(1)
                    history.replace('/market')
                  }}
                >
                  <img src={back} alt="" style={{ width: '2rem', height: '2rem' }} />
                </div>
              </Title>
            </TradingFloorTitle>
            {/*  */}
            <div className="meta-data" style={{ marginBottom: '1.75rem' }}>
              <div className="main-content">
                <div className="content-left">
                  <ImageDiv>
                    <ImageDiv>
                      {(type === '.jpg' || type === '.png' || type === '.jpeg' || type === '.gif' || type === '.svg' || '.soogif') && (
                        <Image
                          placeholder={
                            <Image
                              style={{ maxWidth: '100%', maxHeight: '31.25rem', objectFit: 'contain', borderRadius: '1rem' }}
                              preview={false}
                              src={DEFAULT_IMG1}
                            />
                          }
                          src={currentDetails.image || DEFAULT_IMG}
                          style={{ maxWidth: '100%', maxHeight: '31.25rem', objectFit: 'contain', borderRadius: '1rem' }}
                          preview={false}
                          fallback={default_fail}
                        />
                      )}
                      {(type === '.mp4' || type === '.webm') && (
                        <video
                          src={currentDetails.image}
                          style={{ maxWidth: '100%', maxHeight: '31.25rem', objectFit: 'contain', borderRadius: '1rem' }}
                          controls
                          autoPlay
                          loop
                        ></video>
                      )}
                      {(type === '.mp3' || type === '.wav' || type === '.ogg') && (
                        <audio controls>
                          <source src={currentDetails.image} />
                        </audio>
                      )}
                      {type === '.gltf' && <Modelviewer src={currentDetails.image} />}
                    </ImageDiv>
                  </ImageDiv>
                  {windowSize.innerWidth > Adapth5 && (
                    <DescribeDiv style={{ marginTop: '1.5rem' }}>
                      <h3>
                        <img src={features} alt="" />
                        {t('market.details.vice.title2')}
                      </h3>
                      <div className="info" style={{ marginTop: '1rem' }}>
                        <div className="span">{t('market.details.vice.title2.list1')}</div>
                        <div
                          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                          onClick={() =>
                            copyAddress(
                              currentDetails.type ? (currentDetails.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '--',
                            )
                          }
                        >
                          {formatStrAddress(
                            6,
                            4,
                            currentDetails.type ? (currentDetails.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '--',
                          )}
                          <img src={copy} alt="" style={{ height: '1.5rem', marginLeft: '0.5rem' }} />
                        </div>
                      </div>
                      <div className="info">
                        <div className="span">{t('Token.ID')}</div>
                        {currentDetails.tokenID}
                      </div>
                      <div className="info">
                        <div className="span">{t('market.details.vice.title2.list2')}</div>
                        {currentDetails.type === 2 ? 'ERC1155' : 'ERC721'}
                      </div>
                      <div className="info">
                        <div className="span">{t('market.details.vice.title2.list3')}</div>
                        {Blockchain}
                      </div>
                      <div className="info">
                        <div className="span">{t('market.details.vice.title2.list4')}</div>
                        {t('market.details.vice.title2.list.info3')}
                      </div>
                    </DescribeDiv>
                  )}
                </div>
                <div className="content-right">
                  {/* 版本 */}
                  <CurrentThemeDiv>
                    {t('market.details.theme.title')}
                    {currentDetails.nameTheme}
                  </CurrentThemeDiv>
                  {/* NFT名称 + token */}
                  <h2>{currentDetails.isDefault ? `${currentDetails.name}${currentDetails.tokenID}` : currentDetails.name}</h2>
                  {/* 交易价格 */}
                  <DescribeDiv style={{ marginBottom: '1.8125rem' }}>
                    <h3>
                      <img src={windowSize.innerWidth > Adapth5 ? Current : Current1} alt="" /> {t('market.details.price.title')}
                    </h3>
                    {currentDetails.isSell ? (
                      <div className="price-content">
                        <PriceDiv
                          style={{
                            color: nftPriceIconColor[currentDetails.unit || ''],
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <img src={nftPriceIcon[currentDetails.unit || '']} width={16} alt="" style={{ marginRight: '5px' }} />
                            {Number(
                              web3.utils.fromWei(
                                currentDetails.price ? currentDetails.price.toString() : '0',
                                payTokenOptions.find((item) => item.label === currentDetails.unit).type,
                              ),
                            ) || 0}
                            <span style={{ marginLeft: '5px' }}> {currentDetails.unit || ''}</span>
                          </div>
                          <div>x{currentDetails.amount || currentDetails.num}</div>
                        </PriceDiv>
                        {/* <h5>{t('market.details.price.title.vice')}</h5> */}
                        {!active && <ConnectWallet status="shop" isTop={true} close={() => {}} />}
                        {active && (
                          <Button
                            className="my-home-btn-1 details-btns-my"
                            onClick={() => {
                              if (currentDetails.type === 1) {
                                buyClcik()
                              } else {
                                if (
                                  myAddress.toLowerCase() ===
                                  (currentDetails.address || currentDetails.seller || currentDetails.myAddress).toLowerCase()
                                ) {
                                  message.warning({
                                    content: t('market.message.tips3'),
                                    className: 'message-global',
                                  })
                                  return false
                                }
                                setOnShow1(true)
                              }
                            }}
                          >
                            {t('market.details.price.btn')}
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div className="price-content">
                        <PriceDiv
                          style={{
                            color: nftPriceIconColor[currentDetails.unit || ''],
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <img src={nftPriceIcon[currentDetails.unit || '']} alt="" width={16} style={{ marginRight: '5px' }} />
                            {(Number(
                              web3.utils.fromWei(
                                currentDetails.price ? currentDetails.price.toString() : '0',
                                payTokenOptions.find((item) => item.label === currentDetails.unit)?.type,
                              ),
                            ) || 0) * Number(currentDetails.amount) || 0}
                            <span style={{ marginLeft: '5px' }}> {currentDetails.unit || ''}</span>
                          </div>
                          <div>x{currentDetails.amount || currentDetails.num}</div>
                        </PriceDiv>
                        {/* <h5>{t('market.details.price.title.vice')}</h5> */}
                      </div>
                    )}
                  </DescribeDiv>
                  {/* 版税率 */}
                  <DescribeDiv className="addDes" style={{ marginBottom: '1.8125rem' }}>
                    {currentDetails.type === 1 && (
                      <div className="info">
                        <div className="span">{t('market.details.vice.title3')}</div>
                        <span
                          style={{
                            width: windowSize.innerWidth > AdaptFontSize ? '100%' : 'auto',
                            textAlign: windowSize.innerWidth <= AdaptFontSize ? 'left' : 'end',
                          }}
                        >
                          {Number(currentDetails.royalty)}%
                        </span>
                      </div>
                    )}

                    {currentDetails.type === 1 && (
                      <div className="info">
                        <div className="span">{t('market.details.vice.title5')}</div>
                        <span
                          style={{
                            width: windowSize.innerWidth > AdaptFontSize ? '100%' : 'auto',
                            textAlign: windowSize.innerWidth <= AdaptFontSize ? 'left' : 'end',
                          }}
                        >
                          {currentDetails.royaltyAddress ? formatStrAddress(6, 4, currentDetails.royaltyAddress) : '--'}
                        </span>
                      </div>
                    )}
                    <div className="info">
                      <div className="span">{t('market.details.vice.title4')}</div>
                      <span
                        style={{
                          width: windowSize.innerWidth > AdaptFontSize ? '100%' : 'auto',
                          textAlign: windowSize.innerWidth <= AdaptFontSize ? 'left' : 'end',
                        }}
                      >
                        {openBoxList.length > 0 ? openBoxList[0].time : '--'}
                      </span>
                    </div>
                  </DescribeDiv>
                  {windowSize.innerWidth < Adapth5 && (
                    <DescribeDiv style={{ margin: '1.5rem 0' }}>
                      <h3>
                        <img src={features1} alt="" />
                        {t('market.details.vice.title2')}
                      </h3>
                      <div className="info" style={{ marginTop: '1rem' }}>
                        <div className="span">{t('market.details.vice.title2.list1')}</div>
                        <div
                          onClick={() =>
                            copyAddress(
                              currentDetails.type ? (currentDetails.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '--',
                            )
                          }
                        >
                          {formatStrAddress(
                            6,
                            4,
                            currentDetails.type ? (currentDetails.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '--',
                          )}
                          <img src={copy} alt="" style={{ height: '2rem', marginLeft: '0.5rem', cursor: 'pointer' }} />
                        </div>
                      </div>
                      <div className="info">
                        <div className="span">{t('market.details.vice.title2.list2')}</div>
                        {currentDetails.type === 2 ? 'ERC1155' : 'ERC721'}
                      </div>
                      <div className="info">
                        <div className="span">{t('market.details.vice.title2.list3')}</div>
                        {Blockchain}
                      </div>
                      <div className="info">
                        <div className="span">{t('market.details.vice.title2.list4')}</div>
                        {t('market.details.vice.title2.list.info3')}
                        {/* {currentDetails} */}
                      </div>
                    </DescribeDiv>
                  )}
                  {/* 描述 */}
                  <DescribeDiv>
                    <h3>
                      <img src={windowSize.innerWidth > Adapth5 ? des : des1} alt="" />
                      {t('market.details.vice.title1')}
                    </h3>
                    {currentDetails.description && (
                      <ul>
                        <li>{currentDetails.description}</li>
                      </ul>
                    )}
                    {!currentDetails.description && (
                      <div style={{ marginBottom: '2.5rem' }}>
                        <NoData top={0} />
                      </div>
                    )}
                  </DescribeDiv>
                </div>
              </div>
            </div>
            {/* 元数据详情 */}
            <div style={{ marginBottom: '2.5rem' }} className="meta-data">
              <Details>
                <div className="des">
                  <h3>
                    <img src={windowSize.innerWidth > Adapth5 ? detail : detail1} alt="" />
                    {t('project.nft.detail.dataDetail')}
                  </h3>
                  <div className="metaContent">
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.country')}</div>
                      <div className="detailValue">
                        {currentDetails.attributes
                          ? currentDetails.attributes.filter(
                              (item: any) => item.trait_type === 'country' || item.trait_type === 'nation',
                            )[0].value
                          : '--'}
                      </div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.num')}</div>
                      <div className="detailValue">
                        {currentDetails.attributes ? (
                          <a href={currentDetails.serialNumber || proLink} target="_blank" rel="noreferrer">
                            {currentDetails.attributes.filter((item: any) => item.trait_type === 'serialNum')[0].value || proLink}
                          </a>
                        ) : (
                          '--'
                        )}
                      </div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.stan')}</div>
                      <div className="detailValue">
                        {currentDetails.attributes
                          ? currentDetails.attributes.filter((item: any) => item.trait_type === 'standard')[0].value
                          : '--'}
                      </div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.amount')}</div>
                      <div className="detailValue">
                        {currentDetails.attributes
                          ? currentDetails.attributes.filter((item: any) => item.trait_type === 'amount' || item.trait_type === 'num')[0]
                              .value
                          : '--'}
                      </div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.type')}</div>
                      <div className="detailValue">
                        {currentDetails.attributes
                          ? currentDetails.attributes.filter((item: any) => item.trait_type === 'type')[0].value
                          : '--'}
                      </div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.time')}</div>
                      <div className="detailValue">
                        {currentDetails.attributes
                          ? currentDetails.attributes.filter((item: any) => item.trait_type === 'vintage' || item.trait_type === 'cycle')[0]
                              .value
                          : '--'}
                      </div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.proName')}</div>
                      <div className="detailValue">
                        {currentDetails.attributes
                          ? currentDetails.attributes.filter(
                              (item: any) => item.trait_type === 'projectName' || item.trait_type === 'name',
                            )[0].value
                          : '--'}
                      </div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.proId')}</div>
                      <div className="detailValue">
                        {currentDetails.attributes
                          ? currentDetails.attributes.filter((item: any) => item.trait_type === 'projectID' || item.trait_type === 'id')[0]
                              .value
                          : '--'}
                      </div>
                    </div>
                  </div>
                </div>
              </Details>
            </div>
            {/* NFT交易记录 */}
            <div className="meta-data" style={{ marginBottom: '5.625rem' }}>
              <ShopDiv>
                <h3>
                  <img src={windowSize.innerWidth > Adapth5 ? HIS1 : HIS} alt="" />
                  {t('market.list.title')}
                </h3>
                {windowSize.innerWidth > AdaptFontSize ? (
                  <Table
                    className="mine"
                    loading={loading}
                    columns={columns}
                    dataSource={openBoxList}
                    locale={{ emptyText: i18n.language === 'en' ? 'No Data' : '暂无相关数据' }}
                    rowKey={(record) => {
                      return record.time
                    }}
                    scroll={{ x: 750 }}
                    pagination={false}
                  />
                ) : (
                  <Table
                    className="mine"
                    loading={loading}
                    columns={columns}
                    dataSource={openBoxList}
                    locale={{ emptyText: i18n.language === 'en' ? 'No Data' : '暂无相关数据' }}
                    rowKey={(record) => {
                      return record.time
                    }}
                    scroll={{ x: 750 }}
                    pagination={false}
                  />
                )}
              </ShopDiv>
            </div>
          </Spin>
        )}
      </TradingFloorWrapper>
      <Modal
        visible={onShow1}
        style={{ borderRadius: '0.63rem' }}
        footer={null}
        onCancel={() => {
          setOnShow1(false)
          setBuyNum(null)
        }}
        width="36.88rem"
        wrapClassName="wallets"
        centered
        destroyOnClose
        maskClosable={false}
      >
        <div className="">{t('create.from8.label')}：</div>
        <InputNumber
          style={{ width: '100%', margin: '1.5rem 0' }}
          type="number"
          precision={0}
          min={1}
          max={Number(currentDetails.amount || currentDetails.num)}
          placeholder={t('create.from8.placeholder')}
          size="large"
          value={buyNum}
          onChange={(e) => {
            setBuyNum(e)
          }}
        ></InputNumber>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className="buy-now-btn"
            onClick={() => {
              if (buyNum) {
                buyClcik(currentDetails, buyNum)
              } else {
                message.warning(t('create.from8.placeholder'))
              }
            }}
          >
            {t('market.list.buy.now')}
          </Button>
        </div>
      </Modal>
      <Drawer
        height="100%"
        key="TradingFloorH5"
        className="drawer-trading myDrawer"
        placement="bottom"
        onClose={() => setMoveSwitch(false)}
        visible={moveSwitch}
        closable={false}
        getContainer={marketH5Drawer.current}
      >
        <RightContent style={{ paddingTop: '0' }}>
          <div className="close">
            <Image src={FILTER_ICON} width="2.375rem" preview={false}></Image>
            <span>{t('market.left.title')}</span>
            <img src={PAGE} alt="" style={{ width: '1.4375rem' }} onClick={() => setMoveSwitch(false)} />
          </div>
          <div style={{ padding: '1.875rem 1.75rem' }}>
            <div className="search-input1">
              <img src={Sea} alt="" style={{ width: '2.125rem' }} />
              <Input className="myInput" placeholder={t('market.search')} value={vagueSearch} allowClear onChange={selectPayOnChange3} />
            </div>
            <div className="my-status">
              <RightTitle1>
                <img src={Status} width={36} alt="" />
                {t('market.left.title.vice1')}
              </RightTitle1>
              <div className="nft-list1">
                {nftListStatus.map((item, i) => (
                  <div className={item.active ? 'nft-btn active' : 'nft-btn'} key={i} onClick={() => nftListClick(item)}>
                    {i18n.language === 'en' ? item.enName : item.name}
                  </div>
                ))}
              </div>
            </div>

            <div className="my-status">
              <RightTitle1>
                <img src={Price} width={36} alt="" />
                {t('market.left.title.vice2')}
              </RightTitle1>
              <PriceDivCard>
                <Col span={24}>
                  <Select
                    menuIsOpen={isMaskOptions}
                    onMenuOpen={() => setIsMaskOptions(true)}
                    onMenuClose={() => setIsMaskOptions(false)}
                    styles={customStylesPay}
                    isSearchable={false}
                    options={payTokenOptions}
                    placeholder={t('market.input.placeholder1')}
                    value={selectPayActive}
                    components={{ DropdownIndicator: DropdownIndicatorPrice, MenuList: MenuListH5, Control: Control1 }}
                  />
                </Col>
              </PriceDivCard>
              <Row className="price-number">
                <Col span={11}>
                  <InputNumber
                    type="number"
                    precision={6}
                    placeholder="Min"
                    min={0}
                    max={999999999.999999}
                    name="min_number"
                    defaultValue={pirceH5Min}
                    value={pirceH5Min}
                    onBlur={({ target }: any) => setPriceH5Min(target.value)}
                  />
                </Col>
                <Col span={2} className="price-num-to">
                  to
                </Col>
                <Col span={11}>
                  <InputNumber
                    type="number"
                    precision={6}
                    placeholder="Max"
                    min={0}
                    max={999999999.999999}
                    name="max_number"
                    defaultValue={pirceH5Max}
                    value={pirceH5Max}
                    onBlur={({ target }: any) => setPriceH5Max(target.value)}
                  />
                </Col>
              </Row>
              <Button className="apply-btn" type="primary" onClick={applyClickNumH5}>
                {t('market.left.title.vice2.btn')}
              </Button>
            </div>

            <div className="my-status">
              <RightTitle1>
                <img src={Project} width={36} alt="" />
                {t('market.left.title.vice3')}
              </RightTitle1>
              <PriceDivCard>
                <Col span={24}>
                  <Select
                    menuIsOpen={isMaskOptions1}
                    onMenuOpen={() => {
                      setIsMaskOptions1(true)
                      setIsMaskOptions2(false)
                    }}
                    onMenuClose={() => setIsMaskOptions1(false)}
                    styles={customStylesPay}
                    isSearchable={false}
                    options={proType}
                    placeholder={t('market.input.placeholder1')}
                    value={selectTypeActive}
                    components={{ DropdownIndicator: DropdownIndicatorPrice, MenuList: MenuListH51, Control: Control2 }}
                  />
                </Col>
              </PriceDivCard>
            </div>
            <div className="my-status">
              <RightTitle1>
                <img src={Projects} width={36} alt="" />
                {t('market.left.title.vice4')}
              </RightTitle1>
              <PriceDivCard>
                <Col span={24}>
                  <Select
                    menuIsOpen={isMaskOptions2}
                    onMenuOpen={() => {
                      setIsMaskOptions1(false)
                      setIsMaskOptions2(true)
                    }}
                    onMenuClose={() => setIsMaskOptions2(false)}
                    styles={customStylesPay}
                    isSearchable={false}
                    options={listData}
                    placeholder={t('market.input.placeholder1')}
                    value={selectSetActive}
                    components={{ DropdownIndicator: DropdownIndicatorPrice, MenuList: MenuListH52, Control: Control3 }}
                  />
                </Col>
              </PriceDivCard>
            </div>
            <div className="content_bottom">
              <div
                className="btn_item"
                onClick={() => {
                  resetH5()
                }}
              >
                {t('market.left.reset')}
              </div>
              <div
                className="btn_item"
                onClick={() => {
                  setMoveSwitch(false)
                }}
              >
                {t('market.left.sure')}
              </div>
            </div>
          </div>
        </RightContent>
      </Drawer>
    </Spin>
  )
})
