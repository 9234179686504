const myproject = {
  'myproject.message.tips': '数据误差',
  'myproject.create.title1': '创建新项目集',
  'myproject.create.title2': '创建新的项目',
  'myproject.title': '我的项目集',
  'myproject.right.btn1': '导入现有智能合约',
  'myproject.right.btn2': '创建新项目集',
  'myproject.right.btn3': '创建新的项目',
  'myproject.list.uint': '碳资产',
  'myproject.list.title1': '项目名称:',
  'myproject.list.title2': '所有 NFT:',
  'myproject.import.title': '导入现有智能合约',
  'myproject.import.f1': '你的项目处于哪个阶段?',
  'myproject.import.list1.span': '主网上',
  'myproject.import.list1.p': `我们有一些东西他已经准备好了`,
  'myproject.import.list2.span': '测试网上',
  'myproject.import.list2.p': '它位于测试网上并准备迁移到主网上',
  'myproject.import.modal.title': '输入你的合约地址',
  'myproject.import.modal.f1': '你的ERC721合约地址是什么?',
  'myproject.import.modal.placeholder': '请输入您的ERC721合同地址',
  'myproject.import.modal.rules': '不能为空',
  'myproject.import.modal.btn': '提交',
  'myproject.import.verify': '验证',
  'myproject.import.h3': '为导入的NFT创建一个项目集',
  'myproject.theme.title': '主题:',
  'myproject.import.msg1': '合约地址错误',
  'myporject.message.tips2': '不要重复导入',
  'myproject.own': '当前拥有{{msg}}个项目集',
  'myproject.ver': '无效的合约地址',
  'myproject.create': '铸造',
}
export default myproject
