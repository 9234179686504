const home = {
  'home.nft.title1': 'More',
  'home.nft.title2': 'Resources Introduction',
  'home.nft.vice.title': 'Every NFT resource is unique and enjoys universal access rights and special interests',
  'home.nft.check': 'Check Now',
  'home.about.title': 'About',
  'home.about.list.1':
    'The carbon exchange platform is a multi-chain NFT market, creating project incubation operation management, NFT design, casting, and sale of the NFT industry, providing secondary circulation of NFT works and artist IP incubation and operation services.',
  'home.about.list.2': `Introduce NFT-related projects to settle in, empower designers, celebrities and artists, and increase creators' economic income through digital works. At the same time, use incubation operation strategies to promote the circulation of NFT works, improve economic benefits, and promote the rapid development of the platform and partners.`,
  'home.about.list.3': 'Advantages',
  'home.about.list.4': '1. Ensure the sustainability of NFTs',
  'home.about.list.5':
    'There is currently an oversupply of low-value NFTs, and with a forecast that 90% of NFTs will lose value in the next few years, carbon exchange will focus on the most valuable 10%, focusing on the rarest and most exclusive NFT works.',
  'home.about.list.6': '2. Work innovation',
  'home.about.list.7':
    'At present, NFT has only scratched the surface in adding value to holders. How to empower requires more innovation. carbon exchange will integrate AR elements in the near future to enhance the relationship between physical assets and works, and realize value and interactivity.',
  'home.about.list.8': '3. Improve the ecological development of the platform',
  'home.about.list.9':
    'We are building a complete NFT industry ecosystem to provide security and convenience for participants in the NFT field.',
  'home.about.list.10': 'In the future, the ecosystem will be improved: GameFi, digital property management, open interfaces, etc.',
  'home.open.tips': 'version updating... please wait on',
  'home.exper.title': 'Explore the carbon trading center',
  'home.pro': 'Project set',
  'home.igo.tips': 'You are part of the carbon trading program and you can submit an application',
  'home.pro.tips': 'You are carbon trading project defense, can create project set, create NFT',
  'home.igo.btn': 'Apply',
  'home.pro.btn': 'Create',
  'home.show.title': 'Explore Collections',
  'home.dao.title': 'Carbon Governance',
  'home.dao.tips':
    'IEO, carbon NFT experience, selected carbon emission projects, custodians, carbon governance, and carbon neutrality vision are all in DAO',
  'home.dao.btn': 'Explore',
  'home.dao.tag1': 'Carbon emission project',
  'home.dao.tag2': 'Custodians',
  'home.dao.tag3': 'Carbon management',
  'home.dao.tag4': 'Carbon-Neutral',
  'home.more.title': 'Learn more',
  'home.more.tips': 'Want to know what digital carbon assets are used for and how they are traded? Check out our help articles and guides',
  'home.more.btn': 'Check',
  'home.banner.name1': 'Your authentic carbon asset',
  'home.banner.name2': 'trading platform',
  'home.banner.tips': 'Carbon asset trading, retirement, contributing to environmental protection.',
  'home.banner.tips1': 'Please connect the wallet first',
}

export default home
