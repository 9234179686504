const help = {
  'help.title': '帮助中心',
  'help.type': '问题类型',
  'help.type1': '功能异常',
  'help.type2': '产品建议',
  'help.type3': '其它问题',
  'help.title1': '反馈内容',
  'help.title1.placeholder': '请描述出现的问题',
  'help.title2': '联系方式',
  'help.title2.placeholder': '请输入联系方式',
  'about.title': '关于我们',
  'about.content': 'Carbon-exchange是全球首个真实碳资产交易平台',
  'about.content1':
    '平台致力于推动全球碳中和计划的可持续发展，为实现「巴黎协定」的气候目标将升温控制在1.5摄氏度之内，广泛使用碳定价来引导全球用户走上低碳道路，减少因全球变暖导致的各种威胁人类生存的灾难发生！',
  'about.content2':
    '与此同时，用全新的NFT价值体系来真实锚定碳资产，打造一个可持续发展与良性循环保护生态。让人们能在了解环境污染而导致全球变暖对人类生存环境的可怕影响，同时倡导低碳生活，唤醒环保意识。',
  'att.title': '注意事项',
  'att.topic1': '1、个人账户安全信息要保密',
  'att.topic1.info1': '• 不要向任何人泄露您的钱包密码、私钥、助记词等文件。',
  'att.topic1.info2': '• 站点授权时一定要查看是否是正规网页，还可以定期检查钱包是否授权过陌生站点，及时取消授权。',
  'att.topic1.info3': '• 官方人员不会索要用户安全信息，不要将账户密码、资金密码、验证码等安全信息泄露给任何人。',
  'att.topic1.info4': '• 涉及到任何账户资产安全信息时，切勿“共享屏幕”、“拍照截图发送”，请务必提高警惕。',
  'att.topic2': '2、请注意甄别登录平台',
  'att.topic2.info1':
    '• 碳交易中心活动及业务变更请以官网公告为准，请您注意辨别，谨防受骗。在登录官网时，不要通过搜索引擎搜索网址登录，以免进入虚假站点，可以选择手动输入网址登录，碳交易中心官方网址：',
  'att.topic2.info1.link': 'carbon-exchange.com',
  'att.topic2.info2':
    '• 碳交易中心不会向您发送含有链接的短信，非官方网址的不明链接／二维码，以及未知文件都存在风险，切勿轻易点击，更不要输入您的账号、密码、验证码等账户安全信息，以免被钓鱼网站或网络木马等窃取。',
  'att.topic2.info3': '• DNS域名系统确保了域名的唯一性，在域名为真的情况下，用户几乎不可能打开虚假网站。',
  'att.topic3': '3、防范钓鱼攻击',
  'att.topic3.info1':
    '钓鱼攻击是一种常见的网络欺诈方式，指不法分子利用各种手段，仿冒真实网站的URL地址以及页面内容，以此来骗取用户银行账号、信用卡账号、密码等私人资料，进而骗取用户资产。',
  'att.topic3.info2':
    '在加密领域常见的网络钓鱼攻击中，骗子可能会假冒平台工作人员，创建钓鱼网站并发布虚假信息，通过短信、邮件等方式，谎称“账户升级”、“迁移”、“清退”、“触发风控”、“资金存在风险”、“成为国际用户”等，诱导用户点击钓鱼网站链接或扫描二维码。而一旦账号密码或资金密码等信息泄露，用户账户内的数字资产将被快速窃取。',
}

export default help
