import styled, { css } from 'styled-components'

export const ListItem = styled.div`
  width: 100%;
  /* border: 1px solid #dedede;
      border-radius: 1rem;
      margin-bottom: 2rem;
      cursor: pointer;
      &:hover {
        box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
      }
      ${({ theme }) => theme.mediaWidth.screenMd`
    width: calc((100% - 2rem) / 2);
  `} */

  .ant-image,
  .ant-image-img {
    width: 100%;
    height: 17.5rem;
    border-radius: 1rem 1rem 0 0;
    object-fit: cover;
  }
  .name {
    padding: 1.8rem 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #5d6175;
  }
`
