import React, { memo, useState } from 'react'
import styled, { css } from 'styled-components'
import { Image } from 'antd'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import FOOTER_1 from '@/assets/page_slices/FOOTER_1.png'
import FOOTER_2 from '@/assets/page_slices/FOOTER_2.png'
import FOOTER_4 from '@/assets/page_slices/FOOTER_4.png'
import FOOTER_5 from '@/assets/page_slices/FOOTER_5.png'
import FOOTER_6 from '@/assets/page_slices/FOOTER_6.png'
import Medium from '@/assets/page_slices/Medium.png'
import DOWN from '@/assets/phone_slices/xiala.png'
import UP from '@/assets/phone_slices/up.png'
import { setListInfoSwitch2 } from '@/common/init'
import type { MenuListType as ListType } from '@/common/data.d'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import { AdaptFontSize } from '@/utils'
import { webLayoutAdaptationMax, webLayoutAdaptation, h5LayoutAdaptation } from '@/common/styled'

const FooterWrapper = styled.div`
  ${webLayoutAdaptation}
  height: 22.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .footerTop {
    border-right: 1px solid #ffffff;
    margin: 4.5625rem 0 5.25rem 0;
    width: 34.5%;
    .footerTitle {
      font-size: 1.625rem;
      font-weight: bold;
      color: #ffffff;
      /* text-align: center; */
      margin-bottom: 9.3125rem;
    }
  }
  .footerBottom {
    display: flex;
    justify-content: space-between;
    padding-left: 5.83%;
    padding-right: 3.12%;
    width: calc(100% - 34.5%);
    margin: 4.5625rem 0 5.25rem 0;
    .top {
      font-size: 1.5rem;
      font-weight: 400;
      color: #00c28e;
      margin-bottom: 2.4375rem;
    }
    .item {
      a {
        font-size: 1rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.5rem;
      }
    }
  }
  @media (min-width: 1920px) {
    ${webLayoutAdaptationMax}
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        ${h5LayoutAdaptation}
        flex-direction: column;
        min-height: 40.625rem;
        height: auto;
        .footerTop {
          border-right: none;
          border-bottom: 0.0625rem solid #ffffff;
          margin: 0;
          padding-bottom: 3.125rem;
          width: 100%;
          .footerTitle {
            font-size: 3rem;
            font-weight: bold;
            color: #ffffff;
            text-align: start;
            margin-bottom: 5rem;
          }
        }
        .footerBottom {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding-left: 0;
          padding-right: 0;
          margin: 0;
          padding-top: 3.4375rem;
          .footerItem {
            width: 100%;
            .top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 2.25rem;
              font-size: 1.5rem;
            }
            .item {
              margin-bottom: 2rem;
              div:not(:first-child) {
                margin-top: 0.875rem;
              }
              a {
                font-size: 1.5rem;
              }
            }
          }
        }
      `,
    )}
`

const FooterIcon = styled.div`
  img:hover {
    transform: scale(1.1, 1.1);
  }
  width: 100%;
  /* text-align: center; */
  .ant-image {
    margin-right: 1rem;
  }
  a:last-child {
    .ant-image {
      margin-right: 0rem;
    }
  }
  .ant-image-img,
  .ant-image {
    width: auto;
    height: 1.75rem;
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        text-align: start;
        .ant-image-img,
        .ant-image {
          width: auto;
          height: 3.1875rem;
        }
      `,
    )}
`

const activeClassName = 'ACTIVE'
export const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #eeeeee;
  &:hover {
    content: none;
    font-weight: 600;
    color: #fff;
  }
`

interface Types {
  className?: any | string
}

export default memo(function FooterPages(props: Types) {
  const { className } = props
  const { windowSize } = useWindowSizeHooks()
  const { t, i18n } = useTranslation()
  let history = useHistory()
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [{ list }] = useState<{ list: ListType[] }>(() => {
    let { list } = setListInfoSwitch2()
    return { list }
  })

  return (
    <FooterWrapper className={className}>
      <div className="footerTop">
        <div className="footerTitle">{t('footer_title')}</div>
        <FooterIcon>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="https://twitter.com/carbonexchange1" target="_blank" rel="noreferrer">
            <Image preview={false} src={FOOTER_1} />
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="https://www.facebook.com/Carbon-Exchange-107635675367463" target="_blank" rel="noreferrer">
            <Image preview={false} src={FOOTER_2} />
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="https://www.youtube.com/channel/UCFkfl63b82zCJZMQ0B5A7iA" target="_blank" rel="noreferrer">
            <Image preview={false} src={FOOTER_4} />
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="https://t.me/carbonexchangeofficial" target="_blank" rel="noreferrer">
            <Image preview={false} src={FOOTER_5} />
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="https://www.linkedin.com/company/carbon-exchange-official/" target="_blank" rel="noreferrer">
            <Image preview={false} src={FOOTER_6} />
          </a>
          <a href="https://medium.com/@Carbon-Exchange" target="_blank" rel="noreferrer">
            <Image preview={false} src={Medium} />
          </a>
        </FooterIcon>
      </div>
      <div className="footerBottom">
        <div className="footerItem">
          <div
            className="top"
            onClick={() => {
              if (windowSize.innerWidth <= AdaptFontSize) {
                setShow((n) => {
                  return !n
                })
              }
            }}
          >
            {t('footer.top1')}
            {windowSize.innerWidth <= AdaptFontSize && <img src={show ? UP : DOWN} alt="" />}
          </div>
          {windowSize.innerWidth <= AdaptFontSize ? (
            <>
              {show && (
                <div className="item">
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault()
                        history.replace('/server')
                      }}
                    >
                      {t('footer.top1.name1')}
                    </a>
                  </div>
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault()
                        history.replace('/privacy')
                      }}
                    >
                      {t('footer.top1.name2')}
                    </a>
                  </div>
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault()
                        history.replace('/statement')
                      }}
                    >
                      {t('footer.top1.name3')}
                    </a>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="item">
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    history.replace('/server')
                  }}
                >
                  {t('footer.top1.name1')}
                </a>
              </div>
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    history.replace('/privacy')
                  }}
                >
                  {t('footer.top1.name2')}
                </a>
              </div>
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    history.replace('/statement')
                  }}
                >
                  {t('footer.top1.name3')}
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="footerItem">
          <div
            className="top"
            onClick={() => {
              if (windowSize.innerWidth <= AdaptFontSize) {
                setShow1((n) => {
                  return !n
                })
              }
            }}
          >
            {t('footer.top2')}
            {windowSize.innerWidth <= AdaptFontSize && <img src={show1 ? UP : DOWN} alt="" />}
          </div>
          {windowSize.innerWidth <= AdaptFontSize ? (
            <>
              {show1 && (
                <div className="item">
                  {list.map((item) => {
                    return (
                      <StyledNavLink to={item.url === '' ? {} : item.url} key={item.key}>
                        {i18n.language === 'en' ? item.enName : item.name}
                      </StyledNavLink>
                    )
                  })}
                </div>
              )}
            </>
          ) : (
            <div className="item">
              {list.map((item) => {
                return (
                  <StyledNavLink to={item.url === '' ? {} : item.url} key={item.key}>
                    {i18n.language === 'en' ? item.enName : item.name}
                  </StyledNavLink>
                )
              })}
            </div>
          )}
        </div>
        <div className="footerItem">
          <div
            className="top"
            onClick={() => {
              if (windowSize.innerWidth <= AdaptFontSize) {
                setShow2((n) => {
                  return !n
                })
              }
            }}
          >
            {t('footer.top3')}
            {windowSize.innerWidth <= AdaptFontSize && <img src={show2 ? UP : DOWN} alt="" />}
          </div>
          {windowSize.innerWidth <= AdaptFontSize ? (
            <>
              {show2 && (
                <div className="item">
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault()
                        history.replace('/help')
                      }}
                    >
                      {t('footer.top3.name1')}
                    </a>
                  </div>
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault()
                        history.replace('/validation')
                      }}
                    >
                      {t('footer.top3.name2')}
                    </a>
                  </div>
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault()
                        history.replace('/precautions')
                      }}
                    >
                      {t('footer.top3.name3')}
                    </a>
                  </div>
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault()
                        history.replace('/about')
                      }}
                    >
                      {t('footer.top3.name4')}
                    </a>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="item">
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    history.replace('/help')
                  }}
                >
                  {t('footer.top3.name1')}
                </a>
              </div>
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    history.replace('/validation')
                  }}
                >
                  {t('footer.top3.name2')}
                </a>
              </div>
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    history.replace('/precautions')
                  }}
                >
                  {t('footer.top3.name3')}
                </a>
              </div>
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    history.replace('/about')
                  }}
                >
                  {t('footer.top3.name4')}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </FooterWrapper>
  )
})
