const myproject = {
  'myproject.message.tips': 'Data Error',
  'myproject.create.title1': 'Create program',
  'myproject.create.title2': 'Create new project',
  'myproject.title': 'My item collection',
  'myproject.right.btn1': 'Import contracts',
  'myproject.right.btn2': 'Create program',
  'myproject.right.btn3': 'Create new project',
  'myproject.list.uint': 'indivual',
  'myproject.list.title1': 'Program name:',
  'myproject.list.title2': 'All NFT:',
  'myproject.import.title': 'Import contracts',
  'myproject.import.f1': 'What stage is your project at?',
  'myproject.import.list1.span': 'Mainnet',
  'myproject.import.list1.p': `we have something, it's ready`,
  'myproject.import.list2.span': 'Testnet',
  'myproject.import.list2.p': 'It is on testnet and ready to migrate to mainnet',
  'myproject.import.modal.title': 'Enter your contract address',
  'myproject.import.modal.f1': 'What is your ERC721 contract address?',
  'myproject.import.modal.placeholder': 'Please enter your ERC721 contract address',
  'myproject.import.modal.rules': 'Can not be empty',
  'myproject.import.modal.btn': 'Submit',
  'myproject.import.verify': 'Verify',
  'myproject.import.h3': 'Create an project set for the imported NFT',
  'myproject.theme.title': 'Theme:',
  'myproject.import.msg1': 'Contract address error',
  'myporject.message.tips2': 'Do not import repeatedly',
  'myproject.own': 'There are currently {{msg}} itemsets',
  'myproject.ver': 'Invalid contract address',
  'myproject.create': 'Casting',
}
export default myproject
