const info = {
  'myhome.title': '个人信息',
  'myhome.user.title': '您的账户',
  'myhome.vice.title1.link': 'Wallet Connect:',
  'myhome.vice.title2': 'NFT交易所权益',
  'myhome.vice.title2.list1': '1. 发行NFT福利',
  'myhome.vice.title2.list1.1': 'a. NFT白名单用户支持创建自己的项目集铸造自己的NFT项目',
  'myhome.vice.title2.list1.2': 'b. 获得治理权的白名单用户满足条件下可发起社区治理的任何提案',
  'myhome.vice.title2.list1.3': 'c. 同时也可治理金库合约的资金管理',
  'myhome.vice.title2.list2': '2. NFT白名 单来自社区不同级别的成员',
  'myhome.vice.title2.list3': '3. 欢迎各个顶级高科技品牌的最新多链技术合作伙伴加入',
}

export default info
