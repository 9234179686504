import styled, { css } from 'styled-components'
import { ComMyLayout } from '@/common/styled'
import { proTopBarAdaptationMax, proTopBarAdaptation, h5proTopBarAdaptation } from '@/common/styled'

export const MyProjectWrapper = styled(ComMyLayout)`
  /* margin-top: 5rem !important; */
  .myown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          flex-wrap: wrap;
          .ownLeft {
            width: 100%;
            border-radius: 0.4375rem;
            background-color: #f5f6f8;
            padding: 1rem 1.5625rem;
          }
          .title-right {
            margin-top: 1.875rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .create-btn {
              margin-left: 0 !important;
            }
            .import-btn,
            .create-btn {
              width: calc((100% - 33px) / 2);
            }
          }
        `,
      )}
    .ownLeft {
      color: #5d6175;
      font-size: 1.25rem;
    }
    .title-right {
      .create-btn {
        margin-left: 1.5625rem;
      }
    }
  }
  max-width: 1200px;
  margin: 0 auto;
  @media (min-width: 1920px) {
    /* ${proTopBarAdaptationMax} */
    max-width: 1200px;
    margin: 0 auto;
  }
  ${({ theme }) =>
    theme.mediaWidth.screenMd(
      () => css`
        ${h5proTopBarAdaptation}
      `,
    )}
  .content-nft-info {
    width: calc((100% - 4.875rem) / 4);
    display: flex;
    justify-content: center;
    .ant-spin-nested-loading {
      width: 100%;
    }
    ${({ theme }) => theme.mediaWidth.screebXll`
      width: calc((100% - 4.875rem) / 4);
    `}
    ${({ theme }) => theme.mediaWidth.screenMd`
       width: calc((100% - 2.125rem) / 2);
    `}
    ${({ theme }) => theme.mediaWidth.screenXl`
     width: calc((100% - 3.75rem) / 3);
    `}
    ${({ theme }) => theme.mediaWidth.screenSm`
      width: calc((100% - 2.125rem) / 2);
    `}
  }
`

export const MyProjectTitle = styled.div`
  margin-top: 5rem;
  height: 6.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
`

export const Titles = styled.div`
  margin-top: 2.5625rem;
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  margin-bottom: 1rem;
`

export const MyProjectTitle1 = styled.div`
  margin-top: 5rem;
  height: 6.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  .title-right {
    .create-btn {
      margin-left: 1.25rem;
    }
  }
  .title {
    line-height: 4rem;
  }
  ${(props) =>
    props.theme.mediaWidth.screenLg(
      () => css`
        flex-direction: column;
        align-items: flex-start;
        margin-top: 6.25rem;
        height: auto;
        .title-right {
          margin-top: 1.25rem;
          .create-btn {
            margin-left: 0;
          }
          .import-btn {
            margin-right: 1.25rem;
          }
          .ant-btn {
            margin-bottom: 0.63rem;
          }
        }
      `,
    )}
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #00477c;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
  `}
`

export const MyProjectList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2.4375rem;
`

export const ImageDiv = styled.div`
  width: 100%;
  border-radius: 1.4375rem 1.4375rem 0 0;
  height: 8.75rem;
  .ant-image,
  .ant-image-img {
    width: 100%;
    height: 8.75rem;
    border-radius: 1.4375rem 1.4375rem 0 0;
    object-fit: cover;
  }
`

export const ListInfo = styled.div`
  width: 100%;
  background: #ffffff;
  border: 0.06rem solid #e8e9ee;
  border-radius: 1.4375rem;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    box-shadow: 0rem 0rem 0.63rem 0rem rgba(67, 68, 82, 0.28);
  }
  span {
    font-size: 1rem;
    font-weight: 400;
    color: #363639;
    line-height: 1.56rem;
    margin-top: 0.88rem;
  }
  h5 {
    font-size: 1rem;
    font-weight: Bold;
    color: #37383d;
    line-height: 1.56rem;
    margin: 0.81rem 0 0 0;
  }

  .mainContent {
    padding: 0.9375rem;
    width: 100%;
    border-radius: 0 0 1rem 1rem;
    min-height: 5rem;
  }

  .listName {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #5d6175;
    font-size: 1rem;
  }
`

export const TitleMin = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
  .left {
    display: flex;
    align-items: center;
    span {
      font-size: 1.25rem;
      font-weight: 400;
      color: #5d6175;
      line-height: 1.56rem;
    }
    p {
      font-size: 1.25rem;
      font-weight: 400;
      color: #363639;
      margin-left: 0.38rem;
      margin-right: 1.25rem;
      margin-bottom: 0;
      line-height: 1.56rem;
    }
    .theme {
      color: #5d6175;
    }
  }
  ${(props) =>
    props.theme.mediaWidth.screenLg(
      () => css`
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        .left {
          margin-bottom: 1.25rem;
        }
      `,
    )}
`

export const MyProjectCurrent = styled.div`
  padding: 3.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImporMethod = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.theme.mediaWidth.screenLg(
      () => css`
        flex-direction: column;
      `,
    )}
  .methodItem {
    width: calc((100% - 2.5rem) / 2);
    background-color: #f7f8fa;
    padding: 1.625rem 1.875rem;
    display: flex;
    justify-content: space-between;
    border-radius: 0.9375rem;
    cursor: pointer;
    .itemName {
      color: #37383d;
      font-size: 1.75rem;
    }
    .itemTips {
      color: #5d6175;
      font-size: 1.1875rem;
    }
    .itemRight {
      display: flex;
      align-items: center;
    }

    .itemBtn {
      width: 7.5rem;
      height: 2.5rem;
      text-align: center;
      line-height: 2.5rem;
      font-size: 1rem;
      color: #00c28e;
      border: 1px solid #00c28e;
      border-radius: 1.25rem;
      margin-top: 5.3125rem;
    }
    ${(props) =>
      props.theme.mediaWidth.screenLg(
        () => css`
          width: 100%;
        `,
      )}
  }
`
export const UploadCusDiv = styled.div`
  position: absolute;
  z-index: 1;
  height: calc(16.25rem - 6.5rem);
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-btn {
    width: 100%;
    color: ${(props) => props.theme.gray};
    font-size: 1rem;
    font-weight: 600;
  }

  video {
    width: 100%;
    height: 100%;
  }
  ${(props) =>
    props.theme.mediaWidth.screenLg(
      () => css`
        width: 100%;
      `,
    )}
`
