import { CardType } from '@/common/data'
import { useEffect, useState } from 'react'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import { getData } from '@/api'

export interface ListType {
  tokenID: any
  key?: string | number
  serialNumber: string
  img: string
  name: string
  number: string | number
  list: CardType[]
  blockNumber: any
  value: string
  isImport: boolean
  carbonType: string
  id: string
}

export interface PortfolioImportType {
  key?: string | number
  label: string
  value: string
  blockNumber: number
  cover: string
  coverFiles: string
  isImport: boolean
  contracts: any
  carbonType: string
}

export interface ImportListType {
  contracts: string
  key?: string | number
}

interface Type {
  myAddress: string
  isRefreshData: boolean
}

export const useMyProjectHooks1 = (props: Type) => {
  const { myAddress, isRefreshData } = props
  const nftData: ConstantInitTypes = useDataHooks()
  const { apiKey, apiUrl, SharedToken_ADDRESS } = nftData

  const [loading, setLoading] = useState<boolean>(true)
  const [myProjectList, setMyProjectList] = useState<ListType[]>([])
  const [myImportProjectList, setImportMyProjectList] = useState<ImportListType[]>([])

  useEffect(() => {
    setMyProjectList([])
    setImportMyProjectList([])
    getPlist()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, apiUrl, myAddress, isRefreshData])

  const getPlist = async () => {
    getProjectList()
  }

  const getProjectList = async () => {
    setLoading(true)
    try {
      const params = `
            query MyQuery {
                categoriesInfos {
                  id
                  create_time
                  contractAddress
                  carbonType
                  account
                  NFTs {
                    tokenID
                    metadata_cid
                    id
                    categoriesOwener
                    categoriesId
                    carbonType
                    minter
                    type
                    num
                  }
                }
              }
            `

      const data: any = await getData(params)

      const newArr: any = data.data.categoriesInfos.filter((item: any) => item.account.toLowerCase() === myAddress.toLowerCase())
      const newArr2: any = []
      const sellData: any = await getSelleList()

      const resData: any = sellData.sort(function (a: any, b: any) {
        return b.time - a.time
      })

      for (let i = 0; i < newArr.length; i++) {
        if (resData.length > 0) {
          var newArr1: any = resData.filter(
            (item: any) =>
              item.categoriesId === newArr[i].id &&
              item.seller.toLowerCase() === myAddress.toLowerCase() &&
              item.contractAddress.toLowerCase() !== SharedToken_ADDRESS.toLowerCase(),
          )
        }

        let obj: any = {
          account: newArr[i].account,
          carbonType: newArr[i].carbonType,
          contractAddress: newArr[i].contractAddress,
          create_time: newArr[i].create_time,
          id: newArr[i].id,
          list: newArr1 ? [...newArr[i].NFTs, ...newArr1] : [...newArr[i].NFTs],
        }
        newArr2.push(obj)
      }

      setMyProjectList(newArr2)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getSelleList = async () => {
    try {
      const params = `
        query MyQuery {
            marketItemInfos {
              tokenID
              seller
              price
              metadata_cid
              id
              contractAddress
              categoriesOwener
              categoriesId
              carbonType
              amount
              ERC20_address
              time
            }
          }
        `
      const data: any = await getData(params)

      return data.data.marketItemInfos
    } catch (error) {}
  }

  return { myProjectList, loading, myImportProjectList }
}
