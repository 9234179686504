import React, { memo, useState, useRef, useEffect } from 'react'
import { CardWrapper, Span, MyNftContent, CardModalWrapper, SpanStatus3, CardBuilt, ImageDiv, CardModalImage } from './styled'
import type { CardType } from '@/common/data.d'
import { Image, Button, Row, Col, Modal, message, Select, Form, InputNumber, Spin } from 'antd'
import { AdaptFontSize, Adapth5, formatStrAddress, validateValue } from '@/utils'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import ConnectWallet from '@/components/ConnectWallet'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ImageError } from '@/common/init'
import Succ from '@/assets/img_slices/success.png'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import default_fail from '@/assets/default_fail.png'
import DEFAULT_IMG from '@/assets/default.png'
import DEFAULT_IMG1 from '@/assets/load_img.gif'
import { nftPriceIcon } from '@/contracts/init'
import { MarketSharedToken_ABI } from '@/contracts/constant'
import { CastModal } from '@/pages/ProjectCreate/styled'
import { useHistory } from 'react-router-dom'
import { GetWeb3StorageJsonOne, GetWeb3StorageJsonOneNew } from '@/common'
import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import { DelSpining, SaveLoadingTips, SaveSpining } from '@/store/user/action'
import { getData } from '@/api'

const { Option } = Select

interface Type {
  details: CardType
  keys: string
  returnClick: (s: CardType) => void
  returnRefreshData: () => void
  returnBuyClcik: (s: CardType, num?: any) => void
  serviceCharge?: string
  setLoading: (s: boolean) => void
  setLoadingText: (s: string) => void
  returnLoad?: (s: any) => void
}

export default memo(function CardPage(pages: Type) {
  const context = useWeb3React<Web3Provider>()
  const dispatch = useDispatch()
  const { active } = context
  const myAddress = useSelector((state: any) => state.userInfo.address)
  const modalRef = useRef<any>(null)
  let history = useHistory()
  const nftData: ConstantInitTypes = useDataHooks()
  const { minimumSaleAmount, constant, web3, SharedToken_ADDRESS, Market_ADDRESS, payTokenOptions, Shared1155Token_ADDRESS, readWeb3 } =
    nftData
  const [onShow, setOnShow] = useState(false)
  const [onShow1, setOnShow1] = useState(false)
  const { t } = useTranslation()
  const { details, returnClick, keys, returnRefreshData, returnBuyClcik, serviceCharge = '2.5', returnLoad } = pages
  const [onMynftShow, setOnMynftShow] = useState(false)
  const [uintSelect, setUintSelect] = useState<string>(() => {
    return payTokenOptions[0].label
  })
  const [itemDetail, setItemDetail] = useState<any>({})

  const { windowSize } = useWindowSizeHooks()
  const [loading, setIsLoading] = useState(false)
  const [buyNum, setBuyNum] = useState<any>()

  useEffect(() => {
    if (details) {
      initData(details)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details])

  const initData = async (item: any) => {
    setIsLoading(true)
    const newObj = await initDetails(item)
    setItemDetail(newObj)
    if (returnLoad) {
      returnLoad(newObj)
    }
    setIsLoading(false)
  }

  const initDetails = async (item: any) => {
    try {
      let navThemeCidV3 = Web3.utils.hexToAscii(item.categoriesId || item.categories_cid)
      const data1: any = await GetWeb3StorageJsonOne(navThemeCidV3, true, 'Collection')

      const cid =
        item.type === 1
          ? await constant.ContractMarketSharedTokenRead.methods.tokenURI(item.tokenID).call()
          : await constant.ContractShared1155TokenRead.methods.uri(item.tokenID).call()

      const data: any = await GetWeb3StorageJsonOneNew(cid)

      var royalty: any = ''
      if (item.price) {
        let royaltyCall: any = await constant.ContractMarketSharedTokenRead.methods.royaltyInfo(item.tokenID, item.price).call()
        let royaltyB = royaltyCall[1] === '0' ? '0' : new BigNumber(royaltyCall[1]).div(item.price).toFixed(6)
        royalty = royaltyB === '0' ? '0' : new BigNumber(royaltyB).multipliedBy(100).toFixed(4)
      } else {
        let royaltyCall: any = await constant.ContractMarketSharedTokenRead.methods.royaltyInfo(item.tokenID, web3.utils.toWei('1')).call()
        let royaltyB = royaltyCall[1] === '0' ? '0' : new BigNumber(royaltyCall[1]).div(web3.utils.toWei('1')).toFixed(6)
        royalty = royaltyB === '0' ? '0' : new BigNumber(royaltyB).multipliedBy(100).toFixed(4)
      }

      var ownerAddress = ''

      if (item.type === 1 && keys === 'myproject') {
        const params = `
            query MyQuery {
                maketBuyHistories {
                  tokenID
                  ERC20_address
                  amount
                  buyer
                  categories_cid
                  id
                  metadata_cid
                  price
                  seller
                  time
                }
              }
            `

        const buyHis: any = await getData(params)
        const buyHisData: any = buyHis.data.maketBuyHistories.filter((item1: any) => item1.tokenID === item.tokenID)
        if (buyHisData.length > 0) {
          ownerAddress = buyHisData[0].buyer || ''
        }
      }

      data.categoriesName = item.categoriesId || item.categories_cid
      data.id = item.categoriesId || item.categories_cid
      data.nameTheme = data1.name ? data1.name : data1.axiosData.name ? data1.axiosData.name : ''
      data.price = item.price
      data.isSell = item.isSell || false
      data.tokenID = item.tokenID
      data.amount = item.amount
      data.unit =
        item.unit ||
        (item.ERC20_address ? payTokenOptions.find((item1) => item1.value.toLowerCase() === item.ERC20_address.toLowerCase()).label : '') ||
        ''
      data.carbonType = item.carbonType
      data.collectibleHash = item.id
      data.self = ownerAddress || item.ownner || item.minter || item.myAddress || item.seller
      data.status = item.status || ''
      data.royalty = royalty
      data.isSelfBuilt = data.myAddress === myAddress
      data.name = data.name ? data.name : 'NFT-' + item.tokenID
      data.address = item.seller
      data.num = item.num || 1
      data.type = item.type

      return data
    } catch (error) {
      console.log('111111error', error, item)
      setIsLoading(false)
      return {}
    }
  }

  const switchClick = () => {
    if (keys === 'nft') {
      returnClick(details)
      return false
    }
  }

  const switchClickTwo = () => {
    if (keys === 'tradingFloor' || keys === 'myproject' || keys === 'mynft') {
      returnClick(details)
      return false
    }
  }

  /** my nft sell click */
  const sellClick = async (values: any) => {
    try {
      dispatch(SaveSpining(true))
      let isAuthorize =
        itemDetail.type === 2
          ? await constant.ContractShared1155TokenRead.methods.isApprovedForAll(myAddress, Market_ADDRESS).call()
          : await constant.ContractMarketSharedTokenRead.methods.isApprovedForAll(myAddress, Market_ADDRESS).call()
      if (!isAuthorize) {
        dispatch(SaveLoadingTips(t('spin.approving')))
        const contarct: any = itemDetail.type === 1 ? constant.ContractMarketSharedToken : constant.ContractShared1155Token
        contarct.methods
          .setApprovalForAll(Market_ADDRESS, true)
          .send({
            from: myAddress,
          })
          .on('transactionHash', function (hash: any) {
            console.log(hash)
          })
          .on('receipt', async (receipt: any) => {
            if (itemDetail.isImport) sellImportIsAuthorize(values)
            else sellImplement(values)
          })
          .on('error', function (error: any, receipt: any) {
            message.error({
              content: error.message,
              className: 'message-global',
            })
            // setSellLoading(false)
            dispatch(DelSpining(false))
          })
      } else {
        if (itemDetail.isImport) sellImportIsAuthorize(values)
        else sellImplement(values)
      }
    } catch (error) {
      console.log('error', error)
      dispatch(DelSpining(false))
    }
  }

  const sellImportIsAuthorize = async (values: any) => {
    try {
      let constantWeb3721 = new web3.eth.Contract(MarketSharedToken_ABI, itemDetail.contracts)
      let constantWeb3721Read = new readWeb3.eth.Contract(MarketSharedToken_ABI, itemDetail.contracts)
      let data721 = await constantWeb3721Read.methods.supportsInterface('0x80ac58cd').call()
      if (data721) {
        let isAuthorize = await constantWeb3721Read.methods.isApprovedForAll(myAddress, Market_ADDRESS).call()
        if (!isAuthorize) {
          constantWeb3721.methods
            .setApprovalForAll(Market_ADDRESS, true)
            .send({
              from: myAddress,
            })
            .on('transactionHash', function (hash: any) {
              console.log(hash)
            })
            .on('receipt', async (receipt: any) => {
              sellImplement(values)
            })
            .on('error', function (error: any, receipt: any) {
              message.error({
                content: error.message,
                className: 'message-global',
              })
              dispatch(DelSpining(false))
            })
        } else {
          sellImplement(values)
        }
      } else dispatch(DelSpining(false))
    } catch (error) {
      console.log('error', error)
      dispatch(DelSpining(false))
    }
  }

  const sellImplement = async (values: any) => {
    try {
      dispatch(SaveLoadingTips(t('spin.sell')))
      let currency = payTokenOptions.find((item) => item.label === values.uint).value
      let price = web3.utils.toWei(values.pledge.toString(), payTokenOptions.find((item) => item.label === values.uint).type)

      constant.ContractMarket.methods
        .addCollectible(
          itemDetail.categoriesName,
          itemDetail.isImport ? itemDetail.contracts : itemDetail.type === 1 ? SharedToken_ADDRESS : Shared1155Token_ADDRESS,
          itemDetail.tokenID,
          itemDetail.type === 2 ? values.amount : '1',
          currency,
          price,
        )
        .send({
          from: myAddress,
        })
        .on('transactionHash', function (hash: any) {
          console.log(hash)
        })
        .on('receipt', async (receipt: any) => {
          setTimeout(() => {
            setOnMynftShow(false)
            dispatch(DelSpining(false))
            setOnShow(true)
          }, 2000)
        })
        .on('error', function (error: any, receipt: any) {
          message.error({
            content: error.message,
            className: 'message-global',
          })
          dispatch(DelSpining(false))
        })
    } catch (error) {
      console.log('error', error)
      dispatch(DelSpining(false))
    }
  }

  /** transaction building buy click */
  const buyClcik = (num?: any) => returnBuyClcik(itemDetail, num)

  /** my nft cancel click */
  const cancelClick = async () => {
    try {
      dispatch(SaveSpining(true))
      let isAuthorize =
        itemDetail.type === 2
          ? await constant.ContractShared1155TokenRead.methods.isApprovedForAll(myAddress, Market_ADDRESS).call()
          : await constant.ContractMarketSharedTokenRead.methods.isApprovedForAll(myAddress, Market_ADDRESS).call()
      if (!isAuthorize) {
        dispatch(SaveLoadingTips(t('spin.approving')))
        const contarct: any = itemDetail.type === 1 ? constant.ContractMarketSharedToken : constant.ContractShared1155Token
        contarct.methods
          .setApprovalForAll(Market_ADDRESS, true)
          .send({
            from: myAddress,
          })
          .on('transactionHash', function (hash: any) {
            console.log(hash)
          })
          .on('receipt', async (receipt: any) => {
            cancelImplement()
          })
          .on('error', function (error: any, receipt: any) {
            message.error({
              content: error.message,
              className: 'message-global',
            })
            dispatch(DelSpining(false))
          })
      } else {
        cancelImplement()
      }
    } catch (error) {
      console.log('error', error)
      dispatch(DelSpining(false))
    }
  }

  const cancelImplement = () => {
    try {
      dispatch(SaveLoadingTips(t('spin.canel')))
      constant.ContractMarket.methods
        .removeCollectible(itemDetail.collectibleHash)
        .send({ from: myAddress })
        .on('transactionHash', function (hash: any) {
          console.log(hash)
        })
        .on('receipt', async (receipt: any) => {
          setTimeout(() => {
            message.success({
              content: t('mynft.message.tips3'),
              className: 'message-global',
            })
            dispatch(DelSpining(false))
            returnRefreshData()
          }, 5000)
        })
        .on('error', function (error: any, receipt: any) {
          message.error({
            content: error.message,
            className: 'message-global',
          })
          dispatch(DelSpining(false))
        })
    } catch (error) {
      console.log('error', error)
      dispatch(DelSpining(false))
    }
  }

  const onFinish = (values: any) => {
    let isTrueNumber = validateValue(values.pledge)
    if (!isTrueNumber) {
      message.warning({
        content: t('mynft.message.tips1', { msg: minimumSaleAmount }),
        className: 'message-global',
      })
      return false
    }
    if (Number(values.pledge) < minimumSaleAmount) {
      message.warning({
        content: t('mynft.message.tips1', { msg: minimumSaleAmount }),
        className: 'message-global',
      })
      return false
    }
    sellClick(values)
  }

  const uintOnChange = (e: any) => setUintSelect(e)

  return (
    <Spin className="myOwnSpin" spinning={loading} tip="loading...">
      <CardWrapper onClick={switchClick} ref={modalRef}>
        <ImageDiv className={keys === 'mynft' || keys === 'myproject' ? 'my-card' : ''}>
          <Image
            placeholder={<Image className="card-img" preview={false} src={DEFAULT_IMG1} />}
            src={itemDetail?.coverFiles || itemDetail?.imageFiles || DEFAULT_IMG}
            className="card-img"
            preview={false}
            onClick={switchClickTwo}
            fallback={default_fail}
          />
        </ImageDiv>
        {keys === 'nft' && <Span>{itemDetail.name}</Span>}
        {keys === 'mynft' && (
          <>
            <SpanStatus3>
              <Row>
                <Col span={24} className="three-span1">
                  {t('myproject.theme.title')}&nbsp;&nbsp;{itemDetail.nameTheme || 'Untitled Collection'}
                </Col>
                <Col span={24} className="three-span" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    {itemDetail.name ? (itemDetail.isDefault ? `${itemDetail.name}${itemDetail.tokenID}` : itemDetail.name) : 'NFT-'}
                  </div>
                  <div>x {itemDetail.amount || itemDetail.num || 0}</div>
                </Col>
              </Row>
            </SpanStatus3>
            {itemDetail.isSelfBuilt && <CardBuilt>{t('mynft.sefl.title')}</CardBuilt>}
          </>
        )}
        {keys === 'myproject' && (
          <SpanStatus3>
            <Row>
              <Col span={24} className="three-span1" onClick={switchClickTwo}>
                {t('myproject.theme.title')}&nbsp;&nbsp;{itemDetail.nameTheme || 'Untitled Collection'}
              </Col>
              <Col span={24} className="three-span" onClick={switchClickTwo} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{itemDetail.name ? (itemDetail.isDefault ? `${itemDetail.name}${itemDetail.tokenID}` : itemDetail.name) : 'NFT-'}</div>
                <div>x{itemDetail.amount || itemDetail.num || 0}</div>
              </Col>
            </Row>
          </SpanStatus3>
        )}
        {keys === 'tradingFloor' && (
          <>
            <div className={Adapth5 < windowSize.innerWidth ? 'divss' : 'divss dirce'} onClick={switchClickTwo}>
              <h5 title={itemDetail.nameTheme || 'Untitled Collection'}> {itemDetail.nameTheme || 'Untitled Collection'} </h5>
              <h5 style={{ color: '#9596A5' }}>
                {formatStrAddress(6, 4, itemDetail.address || itemDetail.self || itemDetail.myAddress || '')}
              </h5>
            </div>
            <div className="divss dirce" onClick={switchClickTwo}>
              <h4
                title={itemDetail.isDefault ? `${itemDetail.name}${itemDetail.tokenID}` : itemDetail.name}
                style={{ color: '#37383D', display: 'flex', justifyContent: 'space-between', flex: '1' }}
              >
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {itemDetail.name ? (itemDetail.isDefault ? `${itemDetail.name}${itemDetail.tokenID}` : itemDetail.name) : 'NFT-'}
                </div>
                <div style={{ marginLeft: '1rem' }}>x{itemDetail.amount || itemDetail.num || 0}</div>
              </h4>
              {itemDetail.isSell && (
                <h4 className="price-h4" style={{ flex: '1', marginLeft: '1rem' }}>
                  <Image src={nftPriceIcon[itemDetail.unit || '']} className="icosns" preview={false}></Image>
                  <span>
                    {Number(
                      web3.utils.fromWei(itemDetail.price.toString(), payTokenOptions.find((item) => item.label === itemDetail.unit).type),
                    )}
                  </span>
                  &nbsp;{itemDetail.unit}
                </h4>
              )}
            </div>
            <div
              className={Adapth5 < windowSize.innerWidth ? 'divss' : 'divss ssss-h5'}
              style={{ marginTop: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {!active && <ConnectWallet status="buynow" isTop={true} close={() => {}} />}
              {active && itemDetail.isSell && (
                <Button
                  className="buy-now-btn"
                  onClick={() => {
                    if (itemDetail.type === 1) {
                      buyClcik()
                    } else {
                      if (myAddress.toLowerCase() === (itemDetail.address || itemDetail.seller || itemDetail.myAddress).toLowerCase()) {
                        message.warning({
                          content: t('market.message.tips3'),
                          className: 'message-global',
                        })
                        return false
                      }
                      setOnShow1(true)
                    }
                  }}
                >
                  {t('market.list.buy.now')}
                </Button>
              )}
              {active && !itemDetail.isSell && (
                <Button className="buy-now-btn1" onClick={switchClickTwo}>
                  {t('market.list.details.tips')}
                </Button>
              )}
            </div>
          </>
        )}
        {(keys === 'mynft' || keys === 'myproject') && (
          <>
            <MyNftContent>
              {(itemDetail.status === '' || itemDetail.status === '1') && itemDetail.self.toLowerCase() === myAddress.toLowerCase() && (
                <Row>
                  <Col span={24}>
                    <Button
                      className="mynft-btn1-1 sells"
                      onClick={() => {
                        setOnMynftShow(true)
                      }}
                    >
                      {t('mynft.sell.btn')}
                    </Button>
                  </Col>
                </Row>
              )}
              {itemDetail.status === '2' && (
                <Row>
                  <Col span={12}>
                    <div className="eth-span">
                      <Image
                        src={nftPriceIcon[itemDetail.unit || '']}
                        width="1rem"
                        style={{ display: 'flex', alignItems: 'center' }}
                        preview={false}
                      ></Image>

                      <div style={{ marginLeft: '0.25rem', display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
                        <span className="myPrice">
                          {Number(
                            web3.utils.fromWei(
                              itemDetail.price.toString(),
                              payTokenOptions.find((item) => item.label === itemDetail.unit).type,
                            ),
                          ) || ''}
                        </span>{' '}
                        {itemDetail.unit}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    {(itemDetail.address || itemDetail.self || itemDetail.myAddress || itemDetail.seller.toLowerCase()).toLowerCase() ===
                      myAddress.toLowerCase() && (
                      <Button
                        className="mynft-btn-1"
                        style={{
                          width: windowSize.innerWidth > AdaptFontSize ? '6.25rem' : '100%',
                          borderRadius: windowSize.innerWidth > AdaptFontSize ? '1.25rem' : '1.5625rem',
                        }}
                        onClick={() => cancelClick()}
                      >
                        {t('mynft.sell.cancel')}
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
            </MyNftContent>
          </>
        )}
        <Modal
          visible={onMynftShow}
          className="modal-mask"
          footer={null}
          onCancel={() => setOnMynftShow(false)}
          width="62.5rem"
          centered
          getContainer={modalRef.current}
          bodyStyle={{ padding: '5rem 3rem' }}
          maskClosable={false}
          destroyOnClose
        >
          <Row gutter={[16, 32]} className="sell-row">
            <Col span={24} lg={14}>
              <Form onFinish={onFinish} initialValues={{ uint: payTokenOptions[0].label }}>
                <h2>{t('mynft.sell.modal.title')}</h2>
                <div className="pledge-content">
                  <div className="input-titles">
                    <span>{t('mynft.sell.modal.input.title')}</span>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Form.Item name="uint" rules={[{ required: true, message: t('mynft.form.item.rules1') }]}>
                      <Select className="select-before mySelect" size="large" onChange={uintOnChange}>
                        {payTokenOptions.map((item, index) => (
                          <Option value={item.label} key={index}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="pledge"
                      rules={[{ required: true, message: t('mynft.form.item.rules2') }]}
                      style={{ marginLeft: '0.63rem', flex: 1 }}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        type="number"
                        precision={6}
                        min={0.000001}
                        max={999999999.999999}
                        placeholder={t('mynft.sell.modal.input.placeholder')}
                        addonAfter={uintSelect}
                        size="large"
                      ></InputNumber>
                    </Form.Item>
                  </div>
                  {itemDetail.type === 2 && (
                    <div style={{ display: 'flex' }}>
                      <Form.Item
                        name="amount"
                        rules={[{ required: true, message: t('mynft.sell.modal.input.placeholder1') }]}
                        style={{ width: '100%' }}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          type="number"
                          precision={0}
                          min={1}
                          max={itemDetail.num}
                          placeholder={t('mynft.sell.modal.input.placeholder1')}
                          size="large"
                        ></InputNumber>
                      </Form.Item>
                    </div>
                  )}
                  {itemDetail.type === 1 && (
                    <>
                      <div className="input-titless" style={{ marginTop: '0' }}>
                        <span>{t('mynft.sell.modal.input.title1')}</span>
                        <span>{Number(itemDetail.royalty)}%</span>
                      </div>
                      <div className="input-min-title">{t('mynft.sell.modal.input.titles')}</div>
                    </>
                  )}

                  <div className="input-titless">
                    <span>{t('mynft.sell.modal.input.title3')}</span>
                    <span>{serviceCharge}%</span>
                  </div>
                  <Button className="my-home-btn-3" style={{ borderRadius: '1.875rem' }} htmlType="submit" type="primary">
                    {t('mynft.sell.btn')}
                  </Button>
                </div>
              </Form>
            </Col>
            {windowSize.innerWidth > AdaptFontSize && (
              <Col span={24} lg={10}>
                <CardModalWrapper>
                  <CardModalImage>
                    <Image
                      src={itemDetail.coverFiles || itemDetail.imageFiles || DEFAULT_IMG}
                      className="card-modal-img"
                      preview={false}
                      fallback={ImageError}
                    />
                  </CardModalImage>
                  <div className="title">{itemDetail.isDefault ? `${itemDetail.name}${itemDetail.tokenId}` : itemDetail.name}</div>
                </CardModalWrapper>
              </Col>
            )}
          </Row>
        </Modal>

        <Modal
          visible={onShow}
          style={{ borderRadius: '0.63rem' }}
          footer={null}
          onCancel={() => {
            returnRefreshData()
            setOnShow(false)
          }}
          width="36.88rem"
          wrapClassName="wallets"
          centered
          afterClose={() => {}}
        >
          <CastModal>
            <img src={Succ} alt="" />
            <div className="topic">{t('mynft.message.tips2')}</div>
            <div className="tips">{t('mynft.message.sell')}</div>
            <div
              className="castBtn"
              onClick={() => {
                history.replace('./market')
              }}
            >
              {t('admin.amount.btn')}
            </div>
          </CastModal>
        </Modal>
        <Modal
          visible={onShow1}
          style={{ borderRadius: '0.63rem' }}
          footer={null}
          onCancel={() => {
            setOnShow1(false)
            setBuyNum(null)
          }}
          width="36.88rem"
          wrapClassName="wallets"
          centered
          destroyOnClose
          maskClosable={false}
        >
          <div className="">{t('create.from8.label')}：</div>
          <InputNumber
            style={{ width: '100%', margin: '1.5rem 0' }}
            type="number"
            precision={0}
            min={1}
            max={Number(itemDetail.amount || itemDetail.num)}
            placeholder={t('create.from8.placeholder')}
            size="large"
            value={buyNum}
            onChange={(e) => {
              setBuyNum(e)
            }}
          ></InputNumber>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className="buy-now-btn"
              onClick={() => {
                if (buyNum) {
                  buyClcik(buyNum)
                } else {
                  message.warning(t('create.from8.placeholder'))
                }
              }}
            >
              {t('market.list.buy.now')}
            </Button>
          </div>
        </Modal>
      </CardWrapper>
    </Spin>
  )
})
