import styled from 'styled-components'

export const HomeDiv = styled.div`
  width: 100%;
  /* .homecard {
    height: 7.5rem;
    @media only screen and (max-width: 1440px) {
      height: 11rem;
    }
  }
  .ant-image {
    width: 100%;
    height: 7.5rem;
    @media only screen and (max-width: 1440px) {
      height: 11rem;
    }
    .ant-image-img {
      height: 100%;
    }
  }
  .img {
    height: 7.5rem;
    width: 100%;
    object-fit: cover;
    @media only screen and (max-width: 768px) {
      height: 178px;
    }
  } */
  .ant-image-img,
  .ant-image,
  .ant-image-img {
    width: 100%;
    height: 150px;
    border-radius: 1rem 1rem 0 0;
    object-fit: cover;
  }
  .name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9596a5;
    font-size: 1rem;
    padding-top: 1.3125rem;
    padding-bottom: 1.3125rem;
    .left {
      flex: 0.6;
      padding: 0 0.875rem;
    }
    .right {
      flex: 0.4;
      padding: 0 0.875rem;
      text-align: right;
    }
  }

  .price {
    color: #37383d;
    font-size: 1.25rem;
    font-weight: 600;
    font-family: DINPro-Bold;
    padding: 0.75rem 0.875rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      margin-right: 5px;
    }
  }
`
