import React, { memo, useEffect, useState } from 'react'
import { Spin, Image } from 'antd'
import { HomeDiv } from './styled'
import DEFAULT_IMG from '@/assets/default.png'
import DEFAULT_IMG1 from '@/assets/load_img.gif'
import default_fail from '@/assets/default_fail.png'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import { Adapth5 } from '@/utils'
import { GetWeb3StorageJsonOne } from '@/common'
import Web3 from 'web3'

interface HomeCardType {
  detail: any
  index: number
}

export default memo(function HomeCard(props: HomeCardType) {
  const { detail, index } = props
  const { windowSize } = useWindowSizeHooks()
  const [loading, setLoading] = useState(false)
  const [itemDetail, setItemDetail] = useState<any>({})

  useEffect(() => {
    initDetail(detail)
  }, [detail])

  const initDetail = async (record: any) => {
    try {
      setLoading(true)
      let navThemeCidV3 = Web3.utils.hexToAscii(record.id)
      const data: any = await GetWeb3StorageJsonOne(navThemeCidV3, true, 'Collection')
      var num: any = 0
      // if (record.CtgNFTs && record.CtgNFTs.length > 0) {
      //   let navThemeCidV1 = Web3.utils.hexToAscii(record.CtgNFTs[0].metadata_cid)
      //   const data1: any = await GetWeb3StorageJsonOne(navThemeCidV1, true, 'Nft')
      //   num = data1.num ? data1.num.split(' ')[0] : '0'
      // }
      let obj: any = {
        cover: data.coverFiles ? data.coverFiles : data.axiosData.coverFiles,
        name: data.name ? data.name : data.axiosData.name,
        index: index,
        child: record.CtgNFTs ? record.CtgNFTs.length : 0,
        num,
      }
      setItemDetail(obj)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('error', error)
    }
  }

  return (
    <Spin spinning={loading} tip="loading">
      <HomeDiv>
        {windowSize.innerWidth > Adapth5 ? (
          <div style={{ textAlign: 'center' }}>
            <Image
              className="img"
              src={itemDetail.cover || DEFAULT_IMG}
              alt=""
              fallback={default_fail}
              preview={false}
              placeholder={<Image preview={false} src={DEFAULT_IMG1} />}
            />
          </div>
        ) : (
          <img className="img" src={itemDetail.cover || DEFAULT_IMG} alt="" style={{ width: '100%', objectFit: 'cover' }} />
        )}
        <div className="name">
          <div className="left">
            {itemDetail.name ? (itemDetail.isDefault ? `${itemDetail.name}${itemDetail.index}` : itemDetail.name) : 'Untitled Collection'}
          </div>
          <div className="right">{itemDetail.child || 0}</div>
        </div>
        {/* <div className="price">{itemDetail.num * itemDetail.child || 0} T</div> */}
      </HomeDiv>
    </Spin>
  )
})
