import Layout from '@/layout'
import { Redirect } from 'react-router-dom'
import { RouteConfig } from 'react-router-config'
import Home from '@/pages/Home'
import No404 from '@/pages/404'
import Market from '@/pages/Market'
import Vault from '@/pages/Vault'
import Governance from '@/pages/Governance'
import Information from '@/pages/User/Information'
import MyNft from '@/pages/User/MyNft'
import MyProject from '@/pages/User/MyProject'
import Create from '@/pages/Create'
import IGOnft from '@/pages/IGOnft'
import ProjectHome from '@/pages/ProjectHome'
import ProjectNft from '@/pages/ProjectNft'
import ProjectCreate from '@/pages/ProjectCreate'
import ProjectAdmin from '@/pages/ProjectAdmin'
import Privacy from '@/pages/Source/Privacy'
import Server from '@/pages/Source/Server'
import Statement from '@/pages/Source/Statement'
import Validation from '@/pages/Source/Validation'
import Echarts from '@/pages/Echarts'
import Help from '@/pages/UserSupport/Help'
import About from '@/pages/UserSupport/About'
import Precautions from '@/pages/UserSupport/Precautions'

const Router: RouteConfig[] = [
  {
    path: '/',
    component: Layout,
    routes: [
      {
        path: '/',
        exact: true,
        render: () => <Redirect to="/home" />,
      },
      {
        path: '/home',
        exact: true,
        component: Home,
      },
      {
        path: '/market',
        exact: true,
        component: Market,
      },
      {
        path: '/vault',
        exact: true,
        component: Vault,
      },
      {
        path: '/governance',
        exact: true,
        component: Governance,
      },
      {
        path: '/information',
        exact: true,
        component: Information,
      },
      {
        path: '/mynft',
        exact: true,
        component: MyNft,
      },

      {
        path: '/projectHome',
        exact: true,
        component: ProjectHome,
      },
      {
        path: '/projectNft',
        exact: true,
        component: ProjectNft,
      },
      {
        path: '/projectCreate',
        exact: true,
        component: ProjectCreate,
      },
      {
        path: '/projectAdmin',
        exact: true,
        component: ProjectAdmin,
      },
      {
        path: '/myproject',
        exact: true,
        component: MyProject,
      },
      {
        path: '/create',
        exact: true,
        component: Create,
      },
      {
        path: '/igonft',
        exact: true,
        component: IGOnft,
      },
      {
        path: '/echarts',
        exact: true,
        component: Echarts,
      },
      {
        path: '/privacy',
        exact: true,
        component: Privacy,
      },
      {
        path: '/Server',
        exact: true,
        component: Server,
      },
      {
        path: '/statement',
        exact: true,
        component: Statement,
      },
      {
        path: '/validation',
        exact: true,
        component: Validation,
      },
      {
        path: '/help',
        exact: true,
        component: Help,
      },
      {
        path: '/about',
        exact: true,
        component: About,
      },
      {
        path: '/precautions',
        exact: true,
        component: Precautions,
      },
      {
        path: '/404',
        component: No404,
      },
      {
        path: '*',
        component: No404,
      },
    ],
  },
  {
    path: '/404',
    component: No404,
  },
  {
    path: '*',
    component: No404,
  },
]

export default Router
