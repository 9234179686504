import styled, { css } from 'styled-components'

export const ListItem = styled.div`
  border: 1px solid #dedede;
  &:hover {
    box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
  }
  border-radius: 1rem;
  cursor: pointer;
  width: 100%;
  .ant-image,
  .ant-image-img {
    width: 100%;
    height: 8.75rem;
    border-radius: 1rem 1rem 0 0;
    object-fit: cover;
  }
  .name {
    color: #5d6175;
    font-size: 1.25rem;
    padding: 1rem 5px 0 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .type {
    color: #9596a5;
    font-size: 0.875rem;
    padding: 0 0.3125rem 0.3125rem 0.3125rem;
    text-align: center;
    /* border-radius: 0 0 1rem 1rem; */
  }
`
