import React, { memo, useState, useEffect, useRef } from 'react'
import {
  MyProjectWrapper,
  MyProjectTitle,
  Title,
  MyProjectList,
  TitleMin,
  MyProjectTitle1,
  Titles,
  MyProjectCurrent,
  ImporMethod,
  UploadCusDiv,
} from './MyProjectStyled'
import { InboxOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { getBase64 } from '@/utils'
import { Button, Image, message, Row, Col, Divider, Modal, Form, Input, Spin, Pagination, Upload, Select } from 'antd'
import { useMyProjectHooks1 } from '@/hooks/useMyProjectHooks1'
import type { ListType } from '@/hooks/useMyProjectHooks1'
import type { CardType } from '@/common/data'
import { LeftCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TitleTrue } from '@/components/CreateModal/styled'
import Card from '@/components/Card'
import CreateDetailsModal from '@/components/CreateDetailsModal'
import { useSelector } from 'react-redux'
import NoData from '@/components/NoData'
import { MarketSharedToken_ABI } from '@/contracts/constant'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import { useFeeUpdatedHooks } from '@/hooks/useFeeUpdatedHooks'
import { GetWeb3StorageJsonOne } from '@/common'
import useWeb3StoreHooks from '@/hooks/useWeb3StoreHooks'
import type { StorageClientTypes } from '@/contracts/web3StorageInit'
import { FormInstance } from 'antd/es/form'
import MAIN from '@/assets/page_slices/main.png'
import TEST from '@/assets/page_slices/test.png'
import BACK from '@/assets/page_slices/back.png'
import ProCard from '@/components/ProCard'
import { getData } from '@/api'
import Web3 from 'web3'

const { Option } = Select

const currentDetailsInit: ListType = {
  tokenID: '',
  serialNumber: '',
  img: '',
  name: '',
  number: 0,
  list: [],
  blockNumber: undefined,
  value: '',
  isImport: false,
  carbonType: '0',
  id: '',
}

const currentDetailsInit1: CardType = {
  tokenId: '',
  name: '',
  image: '',
  serialNumber: 0,
  unit: '',
  type: '',
  contractAddress: '',
}

const acceptTypeCover = 'image/jpeg,image/png,image/gif'

export default memo(function MyProjectPage(props: any) {
  const { REACT_APP_ENV = 'prd' } = process.env
  let formRef = useRef<FormInstance>()

  const modalRef = useRef<any>(null)
  let history = useHistory()
  const { t, i18n } = useTranslation()
  const nftData: ConstantInitTypes = useDataHooks()
  const { payTokenOptions, constant, web3, readWeb3 } = nftData

  const web3Store: StorageClientTypes = useWeb3StoreHooks()
  const { client, storeFilesPapers, makeFileObjects } = web3Store

  const [onShow, setOnShow] = useState(false)
  const [currentDetails, setCurrentDetails] = useState<ListType>(currentDetailsInit)
  const [currentDetails1, setCurrentDetails1] = useState<CardType>(currentDetailsInit1)
  const [currentStatus, setCurrentStatus] = useState<'projectList' | 'detailsList' | 'import' | 'details'>('projectList')

  const [isRefreshData, setIsRefreshData] = useState(false)
  const myAddress = useSelector((state: any) => state.userInfo.address)

  const { myProjectList, loading, myImportProjectList } = useMyProjectHooks1({ myAddress, isRefreshData })
  const { serviceCharge } = useFeeUpdatedHooks()

  const [current, setCurent] = useState(1)
  const [spinLoading, setSpinLoading] = useState(false)
  const [loadingText, setLoadingText] = useState<string>('Loading...')

  const [imageUrlCover, setImageUrlCover] = useState<string>('')
  const [imageCidCover, setImageCidCover] = useState<any>(undefined)
  const [imageUrlCoverSource, setImageUrlCoverSource] = useState<string>('')
  const [importLoading, setLoading] = useState(false)
  const [isVerify, setIsVerify] = useState(false)
  const [isVerifAddress, setIsVerifyAddress] = useState<any>(undefined)
  const getLength = (str: string) => {
    var realLength = 0,
      len = str.length,
      charCode = -1
    for (var i = 0; i < len; i++) {
      charCode = str.charCodeAt(i)
      if (charCode >= 0 && charCode <= 128) realLength += 1
      else realLength += 2
    }
    return realLength
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    const search = props.location.search
    historySearchSwitch(search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, myProjectList])

  const historySearchSwitch = async (search: any) => {
    if (search) {
      let listSearch = search.slice(1).split('&')
      let list: { lable: string; value: any }[] = []
      listSearch.forEach((element: any) => {
        let objs = element.split('=')
        list.push({
          lable: objs[0],
          value: objs[1],
        })
      })
      if (list[0].lable === 'project' && list[0].value && list[1].value && list[1].lable === 'r' && myProjectList.length !== 0) {
        let listArr: CardType[] = []
        myProjectList.forEach((item) => {
          listArr.push(...item.list)
        })
        let data = listArr.filter((item) => item.id === list[0].value)
        let dataOne = myProjectList.filter((item) => item.id === list[1].value)
        if (data.length === 0 || dataOne.length === 0) {
          message.error({
            content: t('myproject.message.tips'),
            className: 'message-global',
          })
          setTimeout(() => {
            history.replace('/myproject')
          }, 500)
          return false
        }
        setCurrentStatus('details')
        const sellData: any = await getSelleList()
        if (sellData.length > 0) {
          const newArr: any = sellData.filter((item: any) => item.id === data[0].id)
          if (newArr.length > 0) {
            data[0].price = newArr[0].price
            data[0].status = '2'
            data[0].unit = newArr[0].ERC20_address
              ? payTokenOptions.find((item1) => item1.value.toLowerCase() === newArr[0].ERC20_address.toLowerCase()).label
              : ''
            data[0].id = newArr[0].id
          } else {
            data[0].status = ''
          }
        }

        setCurrentDetails1(data[0])
        setCurrentDetails(dataOne[0])
      } else if (
        list[0].lable === 'key' &&
        list[0].value &&
        list[0].value !== 'import' &&
        list[0].value !== 'create' &&
        myProjectList.length !== 0
      ) {
        let data = myProjectList.filter((item) => item.id === list[0].value)
        if (data.length === 0) {
          message.error({
            content: t('myproject.message.tips'),
            className: 'message-global',
          })
          setTimeout(() => {
            history.replace('/myproject')
          }, 500)
          return false
        }
        setCurrentStatus('detailsList')

        let navThemeCidV1 = Web3.utils.hexToAscii(data[0].id)
        const data1: any = await GetWeb3StorageJsonOne(navThemeCidV1, false, 'Nft')

        data[0].name = data1.name ? data1.name : data1.axiosData.name

        const sellData: any = await getSelleList()
        const resData: any = sellData.sort(function (a: any, b: any) {
          return b.time - a.time
        })

        if (data[0].list && data[0].list.length > 0) {
          for (let i = 0; i < data[0].list.length; i++) {
            let newArr: any =
              data[0].list[i].type === 1
                ? resData.filter((item: any) => item.tokenID === data[0].list[i].tokenID)
                : resData.filter((item: any) => item.id === data[0].list[i].id)
            if (newArr.length > 0) {
              data[0].list[i].status = '2'
              data[0].list[i].price = newArr[0].price || '0'
              data[0].list[i].unit = newArr[0].ERC20_address
                ? payTokenOptions.find((item1) => item1.value.toLowerCase() === newArr[0].ERC20_address.toLowerCase()).label
                : ''
              data[0].list[i].id = newArr[0].id
            } else {
              data[0].list[i].status = ''
            }
          }
        }

        setCurrentDetails(data[0])
      } else if (list[0].lable === 'key' && list[0].value && list[0].value === 'import') {
        setCurrentStatus('import')
      } else {
        setCurrentStatus('projectList')
      }
    } else {
      setCurrentStatus('projectList')
    }
  }

  const getSelleList = async () => {
    try {
      const params = `
        query MyQuery {
            marketItemInfos {
              tokenID
              seller
              price
              metadata_cid
              id
              contractAddress
              categoriesOwener
              categoriesId
              carbonType
              amount
              ERC20_address
              time
            }
          }
        `
      const data: any = await getData(params)

      return data.data.marketItemInfos
    } catch (error) {}
  }

  const paginationChange = (page: any, pageSize: any) => setCurent(page)

  const onFinishImport = (values: any) => {
    try {
      if (!imageCidCover) {
        message.warning({
          content: `Cover ${t('create.from2.rules')}`,
          className: 'message-global',
        })
        return false
      }
      setLoading(true)
      let obj = {
        name: values.name,
        cover: imageCidCover,
        coverFiles: imageUrlCoverSource,
        contract: isVerifAddress,
      }
      portfolioClick(obj)
    } catch (error) {
      console.log('err', error)
      setLoading(false)
    }
  }

  const portfolioClick = async (obj: any) => {
    try {
      let params = {
        ...obj,
        myAddress,
      }
      let files = makeFileObjects(params, '')
      let data: any = await storeFilesPapers(files, client, 'application/json')
      // let namebyte32 = getBytes32FromIpfsHash(data.cid)
      let namebyte32 = Web3.utils.asciiToHex(data.cid)
      constant.ContractCategories.methods
        .add(namebyte32, '0', isVerifAddress)
        .send({
          from: myAddress,
        })
        .on('transactionHash', function (hash: any) {
          console.log(hash)
        })
        .on('receipt', async (receipt: any) => {
          message.success({
            content: t('create.message.tip.success'),
            className: 'message-global',
          })
          setTimeout(() => {
            formRef.current!.resetFields()
            setImageUrlCover('')
            setImageCidCover(undefined)
            setIsVerify(false)
            setIsVerifyAddress(undefined)
            setOnShow(false)
            setLoading(false)
            setIsRefreshData(!isRefreshData)
            history.replace('/myproject')
          }, 500)
        })
        .on('error', function (error: any, receipt: any) {
          message.error({
            content: error.message,
            className: 'message-global',
          })
          setLoading(false)
        })
    } catch (error) {
      console.log('err', error)
      setLoading(false)
    }
  }

  const imageOrBase64 = async (info: any) =>
    getBase64(info, (img: any) => {
      setImageUrlCover(img)
    })

  const beforeUpload = (file: any) => {
    let isType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'

    if (!isType) {
      message.error({
        content: t('create.message.tips3'),
        className: 'message-global',
      })
    }
    const isLt100M = file.size / 1024 / 1024 < 100
    if (!isLt100M) {
      message.error({
        content: t('create.message.tips2'),
        className: 'message-global',
      })
    }
    if (isType && isLt100M) {
      let isType2 = file.type
      setLoadingText('Uploading...')
      setLoading(true)
      if (client) manualUpload(file, isType2)
    }
    return false
  }

  const manualUpload = async (s: any, type: string) => {
    try {
      let data: any = await storeFilesPapers(s, client, type)
      setImageCidCover(data.cid)
      setImageUrlCoverSource(data.url)
      if (type === 'image/jpeg' || type === 'image/png' || type === 'image/gif' || type === 'image/svg+xml') {
        imageOrBase64(s)
      } else {
        setImageUrlCover(data.url)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const verifyClick = async () => {
    formRef.current?.validateFields(['address']).then(async (res) => {
      let arrObj = myImportProjectList.filter((item) => item.contracts.toLowerCase() === res.address.toLowerCase())
      if (arrObj instanceof Array && arrObj.length > 0) {
        message.warning({
          content: t('myporject.message.tips2'),
          className: 'message-global',
        })
        return false
      }
      let isTrueAddress = await web3.utils.isAddress(res.address)
      if (!isTrueAddress) {
        message.error({
          content: t('myproject.import.msg1'),
          className: 'message-global',
        })
        formRef.current?.resetFields()
        return false
      }
      setLoading(true)
      await verifyContract(res.address)
    })
  }

  const verifyContract = async (address: string) => {
    let constantWeb3721 = new readWeb3.eth.Contract(MarketSharedToken_ABI, address)
    constantWeb3721.methods
      .supportsInterface('0x80ac58cd')
      .call()
      .then(async (res: any) => {
        if (res) {
          await verifyImplement(address)
        } else {
          setLoading(false)
          message.error({
            content: t('myproject.ver'),
            className: 'message-global',
          })
        }
      })
      .catch((error: any) => {
        setLoading(false)
        message.error({
          content: error.message,
          className: 'message-global',
        })
        console.log('error', error.message)
      })
  }

  const verifyImplement = async (address: string) => {
    setIsVerifyAddress(address)
    setIsVerify(true)
    setLoading(false)
  }

  const uploadButton = (
    <div className="upload-btn-hover">
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t('create.upload.btn')}</p>
    </div>
  )

  const uploadExhibit = (
    <UploadCusDiv>
      <Image src={imageUrlCover} preview={false} height="8.25rem" width="auto"></Image>
    </UploadCusDiv>
  )

  return (
    <Spin spinning={loading} tip={loadingText} className="antd-loadings">
      <Spin spinning={loading} tip="Loading..." className="antd-loadings">
        <MyProjectWrapper ref={modalRef}>
          {currentStatus === 'projectList' && (
            <>
              <MyProjectTitle1>
                <Title className="title">
                  <Link to={'/myproject'}>{t('myproject.title')}</Link>
                </Title>
              </MyProjectTitle1>
              <div className="myown">
                <div className="ownLeft">{t('myproject.own', { msg: myProjectList.length })}</div>

                <div className="title-right">
                  <Button type="primary" className="create-btn" onClick={() => history.replace(`/create`)}>
                    {t('myproject.right.btn2')}
                  </Button>
                </div>
              </div>
              <MyProjectList>
                {myProjectList.length > 0 && (
                  <>
                    {myProjectList.map((item, index) => (
                      <div key={index} className="content-nft-info" onClick={() => history.replace(`/myproject?key=${item.id}`)}>
                        <ProCard detail={item} pageNum={1} />
                      </div>
                    ))}
                    <div className="content-nft-info" />
                    <div className="content-nft-info" />
                    <div className="content-nft-info" />
                    <div className="content-nft-info" />
                  </>
                )}
                {!loading && myProjectList.length === 0 && <NoData top={0} />}
              </MyProjectList>
            </>
          )}
          {currentStatus === 'detailsList' && (
            <>
              <MyProjectTitle>
                <Title className="title">
                  <Link to={'/myproject'}>{t('myproject.title')} - NFT</Link>
                </Title>
                <Title>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setCurrentStatus('projectList')
                      setCurrentDetails(currentDetailsInit)
                      history.replace('/myproject')
                    }}
                  >
                    <img src={BACK} alt="" />
                  </div>
                </Title>
              </MyProjectTitle>
              <TitleMin>
                <div className="left">
                  <span style={{ marginRight: '0.63rem' }}>{t('myproject.list.title1')}</span>
                  <p>{currentDetails.name}</p>
                  <span style={{ marginRight: '0.63rem' }}>{t('myproject.list.title2')}</span>
                  <p className="theme">{currentDetails.list.length}</p>
                </div>
                <div className="right">
                  <Button
                    type="primary"
                    disabled={currentDetails.isImport}
                    className="create-btn"
                    onClick={() => history.replace('/create?key=1')}
                  >
                    {t('myproject.right.btn3')}
                  </Button>
                </div>
              </TitleMin>
              <MyProjectList>
                {currentDetails.list
                  .filter((item, i) => i < current * 16 && i >= (current - 1) * 16)
                  .map((item, index) => (
                    <div key={index} className="content-nft-info mycard">
                      <Card
                        details={item}
                        keys="myproject"
                        returnBuyClcik={(s) => {}}
                        returnClick={(s) => {
                          history.replace(`/myproject?project=${s.id}&r=${currentDetails.id}`)
                        }}
                        returnRefreshData={() => {
                          setIsRefreshData(!isRefreshData)
                        }}
                        serviceCharge={serviceCharge}
                        setLoading={(s) => setSpinLoading(s)}
                        setLoadingText={(s) => setLoadingText(s)}
                      />
                    </div>
                  ))}
                <div className="content-nft-info" />
                <div className="content-nft-info" />
                <div className="content-nft-info" />
                <div className="content-nft-info" />
                {currentDetails.list.length === 0 && <NoData top={0} />}
              </MyProjectList>
              <MyProjectCurrent>
                {currentDetails.list.length > 0 && (
                  <Pagination
                    showTitle={false}
                    defaultCurrent={current}
                    current={current}
                    defaultPageSize={16}
                    total={currentDetails.list.length}
                    showSizeChanger={false}
                    onChange={paginationChange}
                  />
                )}
              </MyProjectCurrent>
            </>
          )}
          {currentStatus === 'import' && (
            <>
              <MyProjectTitle>
                <Title>{t('myproject.import.title')}</Title>
                <img
                  src={BACK}
                  alt=""
                  style={{ cursor: 'pointer', width: '2rem', height: '2rem' }}
                  onClick={() => {
                    setCurrentStatus('projectList')
                    history.replace('/myproject')
                  }}
                />
              </MyProjectTitle>
              <>
                <Titles />
                <TitleTrue style={{ marginBottom: '2.5rem' }}>{t('myproject.import.f1')}</TitleTrue>
                <ImporMethod>
                  {REACT_APP_ENV === 'prd' && (
                    <div className="methodItem" onClick={() => setOnShow(true)}>
                      <div className="itemLeft">
                        <div className="itemName">{t('myproject.import.list1.span')}</div>
                        <div className="itemTips">{t('myproject.import.list1.p')}</div>
                        <div className="itemBtn">{i18n.language === 'en' ? 'enter' : '进入'}</div>
                      </div>
                      <div className="itemRight">
                        <img src={MAIN} alt="" />
                      </div>
                    </div>
                  )}
                  {REACT_APP_ENV !== 'prd' && (
                    <div className="methodItem" onClick={() => setOnShow(true)}>
                      <div className="itemLeft">
                        <div className="itemName">{t('myproject.import.list2.span')}</div>
                        <div className="itemTips">{t('myproject.import.list2.p')}</div>
                        <div className="itemBtn">{i18n.language === 'en' ? 'enter' : '进入'}</div>
                      </div>
                      <div className="itemRight">
                        <img src={TEST} alt="" />
                      </div>
                    </div>
                  )}
                </ImporMethod>
              </>
            </>
          )}
          {currentStatus === 'details' && (
            <>
              <MyProjectTitle>
                <Title>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setCurrentStatus('projectList')
                      history.replace(`/myproject?key=${currentDetails.id}`)
                    }}
                  >
                    <LeftCircleOutlined style={{ color: '#00C28E' }} />
                  </div>
                </Title>
              </MyProjectTitle>
              <CreateDetailsModal
                details={currentDetails1}
                returnRefreshData={() => {
                  setIsRefreshData(!isRefreshData)
                  history.replace(`/myproject?key=${currentDetails.id}`)
                }}
                serviceCharge={serviceCharge}
              />
            </>
          )}
          <Modal
            visible={onShow}
            style={{ borderRadius: '0.63rem' }}
            footer={null}
            onCancel={() => setOnShow(false)}
            width="36.88rem"
            wrapClassName="wallets"
            centered
            afterClose={() => {
              formRef.current!.resetFields()
              setImageUrlCover('')
              setImageCidCover(undefined)
              setIsVerify(false)
              setIsVerifyAddress(undefined)
            }}
            getContainer={modalRef.current}
          >
            <Spin spinning={importLoading} tip="Loading...">
              <Titles>{t('myproject.import.modal.title')}</Titles>
              <TitleTrue>{t('myproject.import.modal.f1')}</TitleTrue>
              <Form
                className="myForm"
                preserve={false}
                ref={formRef as any}
                onFinish={onFinishImport}
                initialValues={{ uint: payTokenOptions[0].label }}
                layout="vertical"
              >
                {!isVerify ? (
                  <Row>
                    <Col span={18}>
                      <Form.Item
                        name="address"
                        rules={[{ required: true, message: t('myproject.import.modal.rules') }]}
                        style={{ width: '100%' }}
                      >
                        <Input placeholder={t('myproject.import.modal.placeholder')} autoComplete="off" size="large"></Input>
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Form.Item>
                        <Button type="primary" className="my-home-btn-5" onClick={() => verifyClick()}>
                          {t('myproject.import.verify')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Col span={18}>
                      <Input placeholder={t('myproject.import.modal.placeholder')} disabled value={isVerifAddress} size="large"></Input>
                    </Col>
                    <Col span={6}>
                      <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '1.13rem', marginLeft: '0.63rem' }} />
                    </Col>
                  </Row>
                )}
                <Divider plain dashed className="gray"></Divider>
                <div style={{ opacity: !isVerify ? 0.5 : 1 }}>
                  <h3>{t('myproject.import.h3')}</h3>
                  <Form.Item
                    label={
                      <span>
                        <span style={{ color: '#ff4d4f', marginRight: '4px' }}>*</span>
                        {t('create.project.title1')}
                      </span>
                    }
                    name="cover"
                    valuePropName="fileList"
                    style={{ position: 'relative' }}
                    // rules={[{ required: true, message: t('create.from2.rules') }]}
                  >
                    {imageCidCover && uploadExhibit}
                    <Upload.Dragger
                      className="uploader-files1"
                      name="files"
                      beforeUpload={beforeUpload}
                      maxCount={1}
                      accept={acceptTypeCover}
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      disabled={!isVerify}
                    >
                      {imageCidCover ? (
                        <Button type="text" size="large" className="modal-btns">
                          {t('create.upload.btn1')}
                        </Button>
                      ) : (
                        uploadButton
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                  <TitleTrue>{t('create.from2.f2')}</TitleTrue>
                  <Form.Item
                    label={t('create.from3.label')}
                    name="name"
                    rules={[
                      { required: true, message: t('create.from3.rules') },
                      {
                        required: true,
                        validator: (index, value) => {
                          return getLength(value) > 20 ? Promise.reject(t('igo-input-tips36')) : Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <Input placeholder={t('create.from3.placeholder')} autoComplete="off" disabled={!isVerify} />
                  </Form.Item>
                  <Form.Item
                    name="carbonType"
                    label={t('create.from5.label1')}
                    rules={[{ required: true, message: t('create.from5.rules') }]}
                  >
                    <Select placeholder={t('create.from5.placeholder')} className="mySele" style={{ borderRadius: '0.4375rem' }}>
                      <Option value="0">碳资产</Option>
                    </Select>
                  </Form.Item>
                  <Button className="my-home-btn-4" htmlType="submit" type="primary" disabled={!isVerify}>
                    {t('myproject.import.modal.btn')}
                  </Button>
                </div>
              </Form>
            </Spin>
          </Modal>
        </MyProjectWrapper>
      </Spin>
    </Spin>
  )
})
