import styled from 'styled-components'

export const GovernanceWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  /* height: 71.4vh; */
  padding-top: 5rem;
  margin-bottom: 5rem !important;
  ${({ theme }) => theme.mediaWidth.screenXl`
      padding: 5rem 20px 0 20px;
  `}
  ${({ theme }) => theme.mediaWidth.screenMd`
      padding: 5rem 20px 0 20px;
  `}
`

export const GovernanceTitle = styled.div`
  width: 100%;
  height: 6.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #00477c;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
  `}
`

export const GovernanceContent = styled.div`
  width: 100%;
  position: relative;
  background-color: #f7f8fa;
  padding: 2.5rem 0;
  .span {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #5d6175;
    .title {
      line-height: 3.5rem;
    }
    .int {
      font-size: 1.125rem;
      color: #87868d;
      max-width: 35rem;
      text-align: center;
      margin: 2.875rem auto 0 auto;
      padding: 0 0.625rem;
      line-height: 2.5rem;
    }
    img {
      ${({ theme }) => theme.mediaWidth.screenMd`
      width:234px
  `}
    }
  }
`
