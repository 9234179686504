import { useEffect, useState } from 'react'
import { CardType } from '@/common/data.d'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import { getData } from '@/api'

export interface ListType {
  key?: string | number
  serialNumber: string
  img: string
  name: string
  number: string | number
  list: CardType[]
  blockNumber: any
  value: string
  isImport: boolean
  carbonType: string
  account: string
  cid: string
  collectionHash: string
  contracts: string
  label: string
  cover: string
  coverFiles: string
}

export interface PortfolioImportType {
  key?: string | number
  label: string
  value: string
  blockNumber: number
  cover: string
  coverFiles: string
  isImport: boolean
  contracts: any
  carbonType: string
  account: string
}

export const useHomeHooks1 = () => {
  const nftData: ConstantInitTypes = useDataHooks()
  const { apiKey, apiUrl } = nftData
  const [spinLoading, setLoading] = useState(true)
  const [homeList, setHomeList] = useState<ListType[]>([])

  useEffect(() => {
    if (apiKey === '' && apiUrl === '') getList()
    return () => setHomeList([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, apiUrl])

  const getList = async () => {
    try {
      setLoading(true)
      const params = `
            query MyQuery {
                excellentsIndexes {
                  id
                  categorieIds
                }
              }
            `

      const removedData: any = await getData(params)
      var excellentsIndexes: any = []
      if (removedData.data && removedData.data.excellentsIndexes.length > 0) {
        excellentsIndexes = removedData.data.excellentsIndexes[0].categorieIds
      }
      const params1: any = `
            query MyQuery {
                categoriesInfos(where: {collectionHash_in: [${excellentsIndexes.map((ele: any) => `"${ele}"`)}]}) {
                  id
                  create_time
                  contractAddress
                  carbonType
                  collectionHash
                  account
                  CtgNFTs {
                    carbonType
                    id
                    metadata_cid
                  }
                }
              }
            `
      const excellentsData: any = await getData(params1)

      if (excellentsData.data) {
        setHomeList(excellentsData.data.categoriesInfos.sort((a: any, b: any) => b.create_time - a.create_time))
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('err', error)
    }
  }

  return { homeList, spinLoading }
}
