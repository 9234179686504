import React, { memo, useState } from 'react'
import { TopBarWrapper } from './styled'
import { Image, Input } from 'antd'
import { useHistory } from 'react-router-dom'
import LOGO from '@/assets/page_slices/logo.png'
import SideMenu from '@/components/SideMenu'
import ConnectWallet from '@/components/ConnectWallet'
import Sea from '@/assets/page_slices/search.png'

import { AdaptFontSize } from '@/utils'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import SideMenuH5 from '@/components/SideMenuH5'

import SwitchLanguage from '../SwitchLanguage'
import { useTranslation } from 'react-i18next'

export default memo(function TopBarPages() {
  const { t } = useTranslation()
  const { windowSize } = useWindowSizeHooks()
  const [search, setSearch] = useState<any>()
  let history = useHistory()
  let pathname = history.location.pathname

  const keyDown = async (e: any) => {
    if (e.keyCode === 13 && search) {
      history.replace(`/market?searchTop=${search}`)
    }
  }

  return (
    <TopBarWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Image
          src={LOGO}
          className="logo"
          preview={false}
          onClick={() => {
            history.replace('./home')
          }}
        />
      </div>
      <div className={pathname === '/home' ? 'search-input' : 'search-input whi'}>
        <img
          src={Sea}
          alt=""
          style={{ width: '1.375rem' }}
          onClick={() => {
            if (search) {
              const val: any = String(search)
              history.replace(`/market?searchTop=${val}`)
            }
          }}
        />
        <Input
          onPressEnter={(e) => keyDown(e)}
          autoComplete="off"
          value={search}
          className="myInput"
          allowClear
          placeholder={t('app.header.search')}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
        />
      </div>
      {windowSize.innerWidth > AdaptFontSize && <SideMenu />}
      {windowSize.innerWidth <= AdaptFontSize && <SideMenuH5 />}
      {windowSize.innerWidth > AdaptFontSize && (
        <>
          <SwitchLanguage />
          <ConnectWallet isTop={true} close={() => {}} />
        </>
      )}
    </TopBarWrapper>
  )
})
