import { GetWeb3StorageJsonOne } from '@/common'
import React, { memo, useState, useEffect } from 'react'
import { ListInfo, ListItem, ImageDiv } from './styled'
import { Spin, Image } from 'antd'
import i18n from '@/utils/i18n'
import { showMenu } from '@/utils'
import DEFAULT_IMG from '@/assets/default.png'
import DEFAULT_IMG1 from '@/assets/load_img.gif'
import default_fail from '@/assets/default_fail.png'
import { useHistory } from 'react-router-dom'
import Web3 from 'web3'

interface ItemCardType {
  detail: any
  pageNum: Number
}

interface ItemType {
  contract: string
  name: string
  cover: string
  coverFiles: string
  myAddress: string
  carbonType: string
  id: string
  list: any
}

export default memo(function ProCardPage(props: ItemCardType) {
  const { detail } = props
  let history = useHistory()
  const [itemDetail, setItemDeatil] = useState<ItemType>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (detail) {
      initData()
    }
  }, [detail])

  const init = () => {
    setItemDeatil({
      contract: '',
      name: '',
      cover: '',
      coverFiles: '',
      myAddress: '',
      carbonType: '',
      id: '',
      list: [],
    })
  }
  const initData = async () => {
    setLoading(true)
    init()
    setItemDeatil(await initDetails(detail))
    setLoading(false)
  }

  const initDetails = async (item: any) => {
    let navThemeCidV1 = Web3.utils.hexToAscii(item.id)
    const data: any = await GetWeb3StorageJsonOne(navThemeCidV1, false, 'Collection')
    data.id = item.id
    data.list = item.list
    return data
  }
  return (
    <Spin className="mySpin" spinning={loading} tip="loading...">
      <ListItem>
        <ListInfo onClick={() => history.replace(`/myproject?key=${itemDetail?.id}`)}>
          <ImageDiv>
            <Image
              src={itemDetail?.coverFiles || DEFAULT_IMG}
              className="card-img"
              preview={false}
              fallback={default_fail}
              placeholder={<Image preview={false} src={DEFAULT_IMG1} />}
            />
          </ImageDiv>
          <div className="mainContent">
            <div className="listName">
              <div>{itemDetail?.name || 'Untitled Collection'}</div>
              <div>{itemDetail?.list ? itemDetail?.list.length : 0}</div>
            </div>
            <h5>
              {i18n.language === 'en'
                ? showMenu[Number(itemDetail?.carbonType || 0)].enName
                : showMenu[Number(itemDetail?.carbonType || 0)].name}
            </h5>
          </div>
        </ListInfo>
      </ListItem>
    </Spin>
  )
})
