const components = {
  'create.message.tips1': '你只能上传 JPG/PNG/GIF/SVG/MP4/WEBM/MP3/WAV/OGG/GITF file',
  'create.message.tips2': '图像必须小于100MB',
  'create.message.tips3': '你只能上传 JPG/PNG/GIF file',
  'create.upload.btn': '上传',
  'create.upload.btn1': '上传',
  'create.title1': '创建一个新项目集',
  'create.title': '创建一个新NFT',
  'create.title.f1': '必填字段',
  'create.from1.label': '图像、视频、音频或3D模型',
  'create.from1.rules': '不能为空',
  'create.from1.f1': '支持的文件类型: JPG、 PNG、GIF、 MP4、 WEBM、MP3、 WAV、OGG、 GITF。',
  'create.from2.label': '图像封面',
  'create.from2.rules': '不能为空',
  'create.from2.f1': '因为上传文件已经包含了多媒体图像，所以您需要为此提供一个图像封面。建议使用600*300',
  'create.from2.f2': '此图像将用于项目集主页，类别页面或其他宣传区域展示您的收藏。建议使用600*300',
  'create.from3.label': '名称',
  'create.from3.placeholder': '请输入',
  'create.from3.rules': '不能为空',
  'create.from4.label': '描述',
  'create.from4.placeholder': '提供您的NFT的详细描述',
  'create.from4.f1': '描述将包含在其图像下方的详细信息页面上。',
  'create.from5.label': '项目集',
  'create.from5.label1': '项目类型',
  'create.from5.rules': '不能为空',
  'create.from5.placeholder': '选择一个项目类型',
  'create.from5.placeholder1': '选择一个项目集',

  'create.from6.f1': '当用户转售您最初创建的项目时收取费用。从最终售价中扣除。',
  'create.from6.label': `创建者收益`,
  'create.from6.rules': '不能为空',
  'create.from6.por': '分比费用不能为空',
  'create.from6.placeholder': '分比费用，例如2.5',
  'create.message.tip.success': '创建成功',
  'create.from7.label': '创建者收益地址',
  'create.from7.rules': '不能为空',
  'create.from7.placeholder': '请输入',
  'create.from8.placeholder': '请输入数量',
  'create.from8.label': '数量',
  'create.from9.label': '代币标准',

  'create.btn': '创建',
  'create.btn1': '保存',
  'create.btn2': '删除',
  'create.modal.title': '成功创建',
  'create.modal.title1': '恭喜你!你刚刚创建',
  'create.modal.btn1': '查看项目集',
  'create.modal.btn3': '查看NFT',
  'create.modal.btn2': '继续创建',
  'create.message.success': '创建成功',
  'create.set': '这是您的NFT将出现的集合。',

  'create.apply': '申请成为IEO项目方',
  'create.apply.tips': '你还不是IEO项目方哦，赶紧来申请吧！',

  'create.hot': '热门',
  'create.title.1': '链接你的钱包',
  'create.title.2': '与我们的一个可用钱包提供商联系或创建一个新的钱包提供商。',
  'create.menu1': '创建项目集',
  'create.menu2': '创建NFT',
  'create.nft.title1': 'NFT封面',
  'create.nft.title2': '此图像将用于显示您的NFT封面。建议800*800。',
  'create.project.title1': '项目集封面',
  'create.project.title2': '此图像将用于项目集主页，类别页面或其他宣传区域展示您的收藏。建议使用 600 * 300。',
}

export default components
