import React, { memo, useEffect, useState } from 'react'
import { CreateWrapper, CreateContent, CreateTitle, Title } from './styled'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import ConnectWallet from '@/components/ConnectWallet'
import CreateOrEdit from '@/components/CreateModal'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default memo(function CreatePage(props: any) {
  const { t } = useTranslation()
  const context = useWeb3React<Web3Provider>()
  const { active } = context
  const [search, setSearch] = useState('')

  useEffect(() => {
    const search = props.location.search
    if (search) {
      let listSearch = search.slice(1).split('&')
      setSearch(listSearch[0].split('=')[1])
    }
    // setSearch
  }, [props])

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CreateWrapper>
      {active && <CreateOrEdit cur={search} />}
      {!active && (
        <>
          <CreateTitle>
            <Title>
              <Link to={'/Create'}>{t('create.btn')}</Link>
            </Title>
          </CreateTitle>
          <CreateContent>
            <div className="span">
              {t('create.title.1')}
              <div className="int">{t('governance.title.2')}</div>
            </div>
            <ConnectWallet status="create" isTop={true} close={() => {}} />
          </CreateContent>
        </>
      )}
    </CreateWrapper>
  )
})
