import React, { memo, useEffect } from 'react'
import { Back, TopBarWrapper, ProTop } from './styled'
import { Col } from 'antd'
import { useHistory } from 'react-router-dom'

import { AdaptFontSize } from '@/utils'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'

import ProjectSideMenu from '../ProjectSideMenu'
import ProjectSideMenuH5 from '../ProjectSideMenuH5'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { SaveAddress } from '@/store/user/action'
import SwitchLanguage from '../SwitchLanguage'

interface Type {
  background?: string
  borderBottom?: string
  name: string
}

export default memo(function ProjectTopBarPages(props: Type) {
  const { background = '#fff', borderBottom = '1px solid #E5E5E5', name } = props
  const { windowSize } = useWindowSizeHooks()
  const { t } = useTranslation()
  let history = useHistory()
  const context = useWeb3React<Web3Provider>()
  const { account } = context
  const dispatch = useDispatch()

  useEffect(() => {
    if (account) dispatch(SaveAddress(account))
  }, [account])

  return (
    <ProTop>
      <TopBarWrapper style={{ background }}>
        <div className="proName">{name}</div>
        {windowSize.innerWidth <= AdaptFontSize && (
          <Col span={13} lg={{ span: 20 }} xl={{ span: 20 }} className="tabbar-right">
            <ProjectSideMenuH5 />
            <Back
              onClick={() => {
                history.replace('/market')
              }}
            >
              {t('project.back')}
            </Back>
          </Col>
        )}
        {windowSize.innerWidth > AdaptFontSize && (
          <>
            <div className="tabbar-center">
              <ProjectSideMenu />
              <SwitchLanguage />
              <Back
                onClick={() => {
                  history.replace('/market')
                }}
              >
                {t('project.back')}
              </Back>
            </div>
          </>
        )}
      </TopBarWrapper>
    </ProTop>
  )
})
