import React, { memo } from 'react'
import ReactEcharts from 'echarts-for-react'

interface EchartsPage {
  options: object,
  height: number
}

export default memo(function EchartsDome(this: any, props: EchartsPage) {
  const { options, height } = props
  const click = (param: any, echarts: any) => {
  }

  let onEvents = {
    'click': click.bind(this)
  }

  return <ReactEcharts option={options} onEvents={onEvents} style={{ height: (height / 16) + 'rem', width: '100%' }} />
})
