import request from '@/utils/request'

// 登录
export const getData = (data: any) =>
  request({
    url: '',
    method: 'post',
    data: {
      operationName: 'MyQuery',
      variables: null,
      query: data,
    },
  })
