import ProjectTopBar from '@/components/ProjectTopBar'
import React, { memo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import MORE from '@/assets/img_slices/more.png'
import { HomeContent, SetWrapper } from './styled'
import { Image, message } from 'antd'
import NoData from '@/components/NoData'
import ProjectFooter from '@/components/ProjectFooter'
import { getData } from '@/api'
import NftCard from '@/components/NftCard'
import Web3 from 'web3'
import Banner from '@/assets/img_slices/projectBanner.png'
import copy from '@/assets/copy.png'
import { GetWeb3StorageJsonOne } from '@/common'
import { DelSpining, SaveSpining } from '@/store/user/action'
import { useDispatch } from 'react-redux'

export default memo(function ProjectHome(props: any) {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  let history = useHistory()
  const [name, setName] = useState('')
  const [dataSource, setDataSource] = useState<any>([])
  const [hash, setHash] = useState('')
  const [url, setUrl] = useState('')
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    const local = localStorage.getItem('itemDetail_id')
    const search = props.location.search
    initData(search ? search : '?key=' + local)
  }, [props])

  const initData = async (search: any) => {
    dispatch(SaveSpining(true))
    try {
      if (search) {
        let listSearch = search.slice(1).split('&')
        let list: { lable: string; value: any }[] = []
        listSearch.forEach((element: any) => {
          let objs = element.split('=')
          list.push({
            lable: objs[0],
            value: objs[1],
          })
        })
        if (list[0].lable === 'key' && list[0].value) {
          let navThemeCidV1 = Web3.utils.hexToAscii(list[0].value)
          const params: any = `
            query MyQuery {
              categoriesInfo(id: "${list[0].value}") {
                account
                carbonType
                contractAddress
                id
                collectionHash
                CtgNFTs(first: 6) {
                  carbonType
                  categoriesId
                  categoriesOwener
                  id
                  metadata_cid
                  minter
                  num
                  type
                  tokenID
                }
              }
            }
        `
          const itemData: any = await GetWeb3StorageJsonOne(navThemeCidV1, true, 'Collection')

          if (itemData) {
            setName(itemData.name)
            setUrl(itemData.coverFiles)
          }

          const data: any = await getData(params)
          setHash(data.data.categoriesInfo.collectionHash || '')

          if (data) {
            setDataSource(data.data.categoriesInfo.CtgNFTs)
          }
        }
        dispatch(DelSpining(false))
      }
    } catch (error) {
      dispatch(DelSpining(false))
    }
  }

  const copyAddress = (val: any) => {
    let aux = document.createElement('input')
    aux.setAttribute('value', val)
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
    message.success({
      content: t('igonft.apply.copy.success'),
      className: 'message-global',
    })
  }
  return (
    <SetWrapper>
      <ProjectTopBar name={name} background={`rgba(255,255,255,1)`} borderBottom={`1px solid rgba(229, 229, 229,1)`} />
      <div className="banner">
        <img src={url || Banner} alt="" />
      </div>
      <HomeContent>
        <div
          style={{ display: 'flex', alignItems: 'center', fontSize: '1.125rem', marginBottom: '1rem' }}
          onClick={() => copyAddress(hash)}
        >
          <div style={{ minWidth: '70px' }}>{t('pro.Id')}：</div>
          <div className="hash">{hash || '--'}</div>
          <img src={copy} alt="" style={{ height: '1.25rem', marginLeft: '0.5rem', cursor: 'pointer' }} />
        </div>
        <div className="title">{t('project.home.title')}</div>
        <div className="tips">{t('project.home.tips')}</div>

        <div className="homeList">
          {dataSource.length > 0 ? (
            <>
              {dataSource.map((item: any, index: number) => {
                return (
                  <div
                    className="listItem"
                    key={item.id + Math.random()}
                    onClick={() => {
                      history.replace('./projectNft?key=' + item.id)
                    }}
                  >
                    <NftCard detail={item} pageNum={1} />
                  </div>
                )
              })}
              <div
                className="listItem"
                onClick={() => {
                  history.replace('./projectNft')
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Image src={MORE} alt="" preview={false} placeholder />
                </div>
                <div className="name">{i18n.language === 'en' ? 'More' : '更多'}</div>
              </div>
              <div className="bank" />
              <div className="bank" />
              <div className="bank" />
              <div className="bank" />
            </>
          ) : (
            <div style={{ marginBottom: '6rem', flex: '1' }}>
              <NoData top={4.7} />
            </div>
          )}
        </div>
      </HomeContent>
      <ProjectFooter name={name} />
    </SetWrapper>
  )
})
