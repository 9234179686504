/* eslint-disable */
import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MainWrapper } from '../Privacy/styled'

export default memo(function ServerPage(props: any) {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])
  return (
    <MainWrapper>
      <div className="title">
        <h1> {t('server.title')}</h1>
      </div>
      <div className="main">{t('server.content2')}</div>
      <br />
      <div className="main">{t('server.content3')}</div>
      <br />
      <div className="main">{t('server.content4')}</div>
      <br />
      <div className="main">{t('server.content5')}</div>
      <br />
      <div className="main">{t('server.content6')}</div>
      <div className="con">{t('server.content7')}</div>
      <div className="con">{t('server.content8')}</div>
      <div className="con">{t('server.content9')}</div>
      <div className="con">{t('server.content10')}</div>
      <div className="con">{t('server.content11')}</div>
      <div className="con">{t('server.content12')}</div>
      <div className="con">{t('server.content13')}</div>
      <div className="con">{t('server.content14')}</div>
      <div className="con">{t('server.content15')}</div>
      <div className="con">{t('server.content16')}</div>
      <div className="con">{t('server.content17')}</div>
      <br />
      <div className="main">{t('server.content18')}</div>
      <br />
      <div>{t('server.content19')}</div>
      <br />
      <div className="main">{t('server.content20')}</div>
      <br />
      <div>{t('server.content21')}</div>
      <br />
      <div className="main">{t('server.content22')}</div>
      <br />
      <div className="main">{t('server.content23')}</div>
    </MainWrapper>
  )
})
/* eslint-disable */
