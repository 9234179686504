import { notification } from 'antd'
import axios from 'axios'
/**
 * Create Axios instance
 * axios中请求配置有baseURL选项，表示请求URL公共部分
 * timeout  超时时间 默认5秒
 */

const service = axios.create({
  // baseURL: 'https://api.thegraph.com/subgraphs/name/battleman0526/carbonexchangekovan',
  baseURL: 'https://api.studio.thegraph.com/query/29466/carbonexchange-eth/1.1.4',
  // baseURL: 'https://api.studio.thegraph.com/query/29466/carbonexchange-goerli/1.1.18',
  timeout: 150000,
})

/** request Interceptor */
service.interceptors.request.use(
  (config) => {
    axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
    return config
  },
  (error: any) => {
    notification.error({
      message: 'Error',
      description: `${error.message}.Please refresh the page and try again`,
    })
    Promise.reject(error)
  },
)

/** Response interceptor */
service.interceptors.response.use(
  (res) => {
    return res.data
  },
  (error) => {
    console.log(error)
    notification.error({
      message: 'Error',
      description: `${error.message}.Please refresh the page and try again`,
    })
    return Promise.reject(error)
  },
)

export default service
