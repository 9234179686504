import React, { memo, useState, useEffect } from 'react'
import { Pagination, Spin, message } from 'antd'
import { UserMyNftWrapper, TitleVice, StateList, NftList } from './MyNftStyled'
import { MyProjectTitle, MyProjectWrapper } from './MyProjectStyled'
import { Title } from './InformationStyled'
import { LeftCircleOutlined } from '@ant-design/icons'
import { statusOptions } from '@/contracts/init'
import { useMyNftHooks1 } from '@/hooks/useMyNftHooks1'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Card from '@/components/Card'
import NoData from '@/components/NoData'
import useDataHooks from '@/hooks/useDataHooks'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import { ComLayoutTwo1 } from '@/common/styled'
import CreateDetailsModal from '@/components/CreateDetailsModal'
import type { CardType } from '@/common/data'
import { useHistory } from 'react-router-dom'
import { useFeeUpdatedHooks } from '@/hooks/useFeeUpdatedHooks'
import { SaveLoading } from '@/store/wallet/action'
import { AdaptFontSize } from '@/utils'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import i18n from '@/utils/i18n'

const currentDetailsInit1: CardType = {
  tokenId: '',
  name: '',
  image: '',
  serialNumber: 0,
  type: '',
  unit: '',
}

export default memo(function UserMyNftPage(props: any) {
  const { t } = useTranslation()
  let history = useHistory()

  const dispatch = useDispatch()
  const isRefreshDataRedux = useSelector((state: any) => state.walletInfo.loading)
  const BallKingData: ConstantInitTypes = useDataHooks()
  const { myNftPageSize } = BallKingData
  const { windowSize } = useWindowSizeHooks()

  const [activeStatus, setActiveStatus] = useState<string>('')
  const [current, setCurent] = useState(1)
  const [isRefreshData, setIsRefreshData] = useState(false)

  const myAddress = useSelector((state: any) => state.userInfo.address)
  const { myNftList, loading } = useMyNftHooks1({ myAddress, isRefreshData })
  const [spinLoading, setSpinLoading] = useState(false)
  const [loadingText, setLoadingText] = useState<string>('Loading...')

  const { serviceCharge } = useFeeUpdatedHooks()

  const [currentStatus, setCurrentStatus] = useState<'list' | 'details'>('list')
  const [currentDetails1, setCurrentDetails1] = useState<CardType>(currentDetailsInit1)
  const [detailLoading, setDetailLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    const search = props.location.search
    historySearchSwitch(search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, myNftList])

  const historySearchSwitch = (search: any) => {
    if (search) {
      setDetailLoading(true)
      let listSearch = search.slice(1).split('&')
      let list: { lable: string; value: any }[] = []
      listSearch.forEach((element: any) => {
        let objs = element.split('=')
        list.push({
          lable: objs[0],
          value: objs[1],
        })
      })
      if (list[0].lable === 'project' && list[0].value && myNftList.length !== 0) {
        let data = myNftList.filter((item) => item.id === list[0].value)
        if (data.length === 0) {
          message.error({
            content: t('myproject.message.tips'),
            className: 'message-global',
          })
          setTimeout(() => {
            history.replace('/mynft')
          }, 500)
          setDetailLoading(false)
          return false
        }
        setDetailLoading(false)
        setCurrentStatus('details')
        setCurrentDetails1(data[0])
      } else if (list[0].lable === 'key' && list[0].value === 'create') {
        setDetailLoading(false)
        dispatch(SaveLoading(!isRefreshDataRedux))
        setTimeout(() => {
          history.replace('/mynft')
        }, 100)
      } else {
        setDetailLoading(false)
        setCurrentStatus('list')
      }
    } else {
      setCurrentStatus('list')
    }
  }

  const paginationChange = (page: any, pageSize: any) => setCurent(page)

  return (
    <Spin spinning={spinLoading} tip={loadingText} className="antd-loadings">
      <UserMyNftWrapper>
        {myAddress && (
          <>
            {currentStatus === 'list' && (
              <ComLayoutTwo1>
                <div className="content-nft">
                  <div className="nft-left">
                    <StateList>
                      {statusOptions.map((item, i) => (
                        <div
                          className={item.value === activeStatus ? 'span active' : 'span'}
                          key={i}
                          onClick={() => {
                            setActiveStatus(item.value)
                            setCurent(1)
                          }}
                        >
                          <div className="spanItem">
                            {windowSize.innerWidth > AdaptFontSize && (
                              <img src={item.ico} alt="" style={{ width: '1.125rem', margin: '0 1.875rem' }} />
                            )}
                            {i18n.language === 'en' ? item.enLabel : item.lable}
                          </div>
                          <div className={item.value === activeStatus ? 'under ac' : 'under '} />
                        </div>
                      ))}
                    </StateList>
                  </div>
                  <div className="nft-right">
                    <TitleVice>
                      <div className="titlesssssss">
                        {activeStatus === '' && <>{t('mynft.title.vice')}&nbsp;&nbsp;</>}
                        {activeStatus === '1' && <>{t('mynft.title.vice2')}&nbsp;&nbsp;</>}
                        {activeStatus === '2' && <>{t('mynft.title.vice3')}&nbsp;&nbsp;</>}
                        <span>
                          {
                            myNftList.filter((item) => {
                              if (activeStatus === '') {
                                return item.isSell !== true
                              } else {
                                return item.status === activeStatus
                              }
                            }).length
                          }
                        </span>
                        {activeStatus === '' && (
                          <>
                            &nbsp;&nbsp;{t('mynft.title.vice.unit')}&nbsp;&nbsp;NFT&nbsp;&nbsp;{t('mynft.title.vice.s')}
                          </>
                        )}
                        {activeStatus === '1' && <>&nbsp;&nbsp;{t('mynft.title.vice2.unit')}</>}
                        {activeStatus === '2' && <>&nbsp;&nbsp;{t('mynft.title.vice3.unit')}</>}
                      </div>
                    </TitleVice>
                    <NftList>
                      {!loading && (
                        <>
                          {myNftList
                            .filter((item) => {
                              if (activeStatus === '') {
                                return item.isSell !== true
                              } else {
                                return item.status === activeStatus
                              }
                            })
                            .filter((item, i) => i < current * (myNftPageSize || 8) && i >= (current - 1) * (myNftPageSize || 8))
                            .map((item, i) => (
                              <div key={i} className="content-nft-info mycard">
                                <Card
                                  details={item}
                                  keys="mynft"
                                  serviceCharge={serviceCharge}
                                  returnBuyClcik={(s) => {}}
                                  returnClick={(s) => history.replace(`/mynft?project=${s.id}`)}
                                  returnRefreshData={() => setIsRefreshData(!isRefreshData)}
                                  setLoading={(s) => setSpinLoading(s)}
                                  setLoadingText={(s) => setLoadingText(s)}
                                />
                              </div>
                            ))}
                        </>
                      )}
                      {loading && (
                        <div className="loadings">
                          <Spin tip="Loading..." />
                        </div>
                      )}
                      {!loading &&
                        myNftList.filter((item) => {
                          if (activeStatus === '') {
                            return item.isSell !== true
                          } else {
                            return item.status === activeStatus
                          }
                        }).length === 0 && <NoData />}
                    </NftList>
                    <div className="pagination-nft">
                      {myNftList.filter((item) => {
                        if (activeStatus === '') {
                          return item.isSell !== true
                        } else {
                          return item.status === activeStatus
                        }
                      }).length > 0 &&
                        !loading && (
                          <Pagination
                            showTitle={false}
                            defaultCurrent={current}
                            current={current}
                            defaultPageSize={myNftPageSize || 8}
                            total={
                              myNftList.filter((item) => {
                                if (activeStatus === '') {
                                  return item.isSell !== true
                                } else {
                                  return item.status === activeStatus
                                }
                              }).length
                            }
                            showSizeChanger={false}
                            onChange={paginationChange}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </ComLayoutTwo1>
            )}
            {currentStatus === 'details' && (
              <Spin spinning={detailLoading} tip="loading">
                <MyProjectWrapper className="mynft-details">
                  <MyProjectTitle style={{ alignItems: 'center' }}>
                    <Title>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setCurrentStatus('list')
                          history.replace('/mynft')
                        }}
                      >
                        <LeftCircleOutlined style={{ color: '#9596A5', width: '2rem', height: '2rem' }} />
                      </div>
                    </Title>
                  </MyProjectTitle>
                  <CreateDetailsModal
                    details={currentDetails1}
                    returnRefreshData={() => {
                      setIsRefreshData(!isRefreshData)
                      history.replace(`/mynft`)
                    }}
                    serviceCharge={serviceCharge}
                  />
                </MyProjectWrapper>
              </Spin>
            )}
          </>
        )}
      </UserMyNftWrapper>
    </Spin>
  )
})
