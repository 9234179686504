import { GetWeb3StorageJsonOne } from '@/common'
import React, { memo, useState, useEffect } from 'react'
import { ListItem } from './styled'
import { Spin, Image } from 'antd'
import i18n from '@/utils/i18n'
import { showMenu } from '@/utils'
import DEFAULT_IMG from '@/assets/default.png'
import DEFAULT_IMG1 from '@/assets/load_img.gif'
import default_fail from '@/assets/default_fail.png'
import { useHistory } from 'react-router-dom'
import Web3 from 'web3'

interface ItemCardType {
  detail: any
  pageNum: Number
}

interface ItemType {
  contract: string
  name: string
  cover: string
  coverFiles: string
  myAddress: string
  carbonType: string
  id: string
}

export default memo(function ItemCardPage(props: ItemCardType) {
  const { detail } = props
  let history = useHistory()
  const [itemDetail, setItemDeatil] = useState<ItemType>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (detail) {
      initData()
    }
  }, [detail])

  const init = () => {
    setItemDeatil({
      contract: '',
      name: '',
      cover: '',
      coverFiles: '',
      myAddress: '',
      carbonType: '',
      id: '',
    })
  }
  const initData = async () => {
    setLoading(true)
    init()
    setItemDeatil(await initDetails(detail.id))
    setLoading(false)
  }

  const initDetails = async (cid: string) => {
    let navThemeCidV1 = Web3.utils.hexToAscii(cid)
    const data: any = await GetWeb3StorageJsonOne(navThemeCidV1, false, 'Collection')

    data.id = cid
    return data
  }
  return (
    <Spin className="mySpin" spinning={loading} tip="loading...">
      <ListItem
        onClick={() => {
          if (itemDetail?.myAddress) {
            localStorage.setItem('account', itemDetail?.myAddress)
          }

          if (itemDetail?.id) {
            localStorage.setItem('itemDetail_id', itemDetail?.id)
            if (localStorage.getItem('itemDetail_id')) {
              history.push('/projectNft')
            }
          }
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '1rem 1rem 0 0 ' }}>
          <Image
            src={itemDetail?.coverFiles || DEFAULT_IMG}
            alt=""
            fallback={default_fail}
            preview={false}
            placeholder={<Image preview={false} src={DEFAULT_IMG1} />}
          />
        </div>
        <div className="name">{itemDetail?.name || 'Untitled Collection'}</div>
        <div className="type">
          {i18n.language === 'en'
            ? showMenu[Number(itemDetail?.carbonType) || 0].enName
            : showMenu[Number(itemDetail?.carbonType) || 0].name}
        </div>
      </ListItem>
    </Spin>
  )
})
