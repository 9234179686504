import styled, { css } from 'styled-components'
import { proTopBarAdaptationMax, proTopBarAdaptation, h5proTopBarAdaptation } from '@/common/styled'

export const VaultWrapper = styled.div`
  ${proTopBarAdaptation}
  @media (min-width: 1920px) {
    ${proTopBarAdaptationMax}
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        ${h5proTopBarAdaptation}
      `,
    )}
  padding-top: 5rem;
  margin-bottom: 5rem !important;
  ${({ theme }) => theme.mediaWidth.screenXl`
      padding: 6rem 0.625rem 0 0.625rem;
  `}
`

export const VaultTitle = styled.div`
  width: 100%;
  height: 6.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #00477c;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
  `}
`

export const VaultContent = styled.div`
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.screenMd`
    padding:0;
  `}
  .title {
    font-size: 1.25rem;
    color: #87868d;
    ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 1.875rem;
    `}
  }
  .type {
    margin-bottom: 1.125rem;
  }
  .type1 {
    margin: 1.5625rem 0 1.1875rem 0;
  }
  .typeList {
    display: flex;

    ${({ theme }) => theme.mediaWidth.screenMd`
    justify-content: space-between;
    `}
    .listItem {
      margin-bottom: 1.875rem;
      width: 10.6875rem;
      height: 3.125rem;
      background: #f7f7f7;
      border-radius: 1.5625rem;
      line-height: 3.125rem;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.125rem;
      color: #87868d;
      &.active {
        background: #00c28e;
        color: white;
      }
      &:not(:first-child) {
        margin-left: 3.125rem;
      }
      ${({ theme }) => theme.mediaWidth.screenMd`
       font-size: 1.625rem;
       width: 11.25rem;
    padding: 0 0.625rem;
    &:not(:first-child) {
      margin-left: 20px;
    }
  `}
    }
  }
  .line {
    width: 53rem;
    height: 0.0625rem;
    background: #dedede;
    ${({ theme }) => theme.mediaWidth.screenMd`
    width:100%;
  `}
  }
  .content {
    margin-bottom: 1.875rem;
  }
  textarea {
    width: 53rem;
    height: 8.625rem;
    background: #f7f7f7;
    border-radius: 0.4375rem;
    border: none;
    padding: 0.3125rem 0.625rem;
    resize: none;
    outline: none;
    margin-bottom: 1.875rem;
    color: #87868d;
    font-size: 1rem;
    &::placeholder {
      color: #87868d;
    }
    ${({ theme }) => theme.mediaWidth.screenMd`
    width:100%;
    height:155px;
    font-size: 1.625rem;
  `}
  }
  .input {
    width: 53rem;
    height: 2.5rem;
    background: #f7f7f7;
    border-radius: 0.4375rem;
    outline: none;
    border: none;
    padding: 0.3125rem 0.625rem;
    color: #87868d;
    font-size: 1rem;
    &::placeholder {
      color: #87868d;
    }
    ${({ theme }) => theme.mediaWidth.screenMd`
    width:100%;
    height:4.375rem;
    font-size: 1.625rem;
  `}
  }
  .btn {
    .submit {
      width: 12.5rem;
      height: 3.75rem;
      background: #00c28e;
      border-radius: 1.875rem;
      cursor: pointer;
      line-height: 3.75rem;
      text-align: center;
      margin-top: 1.875rem;
      font-size: 1.5rem;
      color: white;
    }
  }
`
