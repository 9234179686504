import styled from 'styled-components'

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .topbar-col {
    padding-left: 0;
    padding-right: 0;
  }
  .layout-footer {
    width: 100%;
    background: #00477c;
  }
  .layout-topbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background: #fff;
    border-bottom: 0.0625rem solid #e5e5e5;
  }
`

export const LayoutContent = styled.div`
  min-height: calc(100vh - 6.5rem);
  flex: 1;
`
