import React, { memo, useEffect, useState } from 'react'
import { SideMenuWrapper, StyledNavLink } from './styled'
import { Drawer, Row, Col, Image } from 'antd'
import LOGO from '@/assets/page_slices/logo.png'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { setListInfoSwitch1 } from '@/common/init'
import type { MenuListType as ListType } from '@/common/data.d'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SwitchLanguage from '../SwitchLanguage'
import PAGE from '@/assets/page_slices/close.png'

export default memo(function ProjectSideMenuH5Page() {
  const { i18n } = useTranslation()
  const [isMenuShow, setIsMenuShow] = useState(false)
  const myAddress = useSelector((state: any) => state.userInfo.address)
  const [igoAccount, setIgoAccount] = useState<any>()
  const [{ list }] = useState<{ list: ListType[] }>(() => {
    let { list } = setListInfoSwitch1()
    return { list }
  })

  useEffect(() => {
    if (localStorage.getItem('account')) {
      setIgoAccount(localStorage.getItem('account'))
    }
  }, [igoAccount])

  const oddEvent = (match: any, location: any, item: any) => {
    if (!match || item.url === '') {
      return false
    }
    const hash = location.hash
    const itemHash = `#${item.url.substring(item.url.lastIndexOf('#') + 1, item.url.length)}`
    if (hash === itemHash) return true
    else return false
  }

  return (
    <SideMenuWrapper>
      {!isMenuShow && <MenuOutlined className="iconss" onClick={() => setIsMenuShow(true)} />}
      {isMenuShow && <CloseOutlined className="iconss" onClick={() => setIsMenuShow(false)} />}
      <Drawer
        className="h5-drawer"
        closable={false}
        placement="top"
        height={'65%'}
        onClose={() => setIsMenuShow(false)}
        visible={isMenuShow}
      >
        <Row style={{ width: '100%', height: '5.5rem', display: 'flex', justifyContent: 'center', alignContent: 'center' }} gutter={20}>
          <Col span={12} lg={{ span: 6 }} className="tabbar-left">
            <Link to="/home">
              <Image src={LOGO} className="logo" preview={false} width="10.06rem" />
            </Link>
          </Col>
          <Col span={12} lg={{ span: 6 }} className="tabbar-right">
            <img src={PAGE} alt="" style={{ width: '1.4375rem' }} onClick={() => setIsMenuShow(false)} />
          </Col>
        </Row>
        <div className="h5-menu-drawer-navlink">
          {list.map((item) => {
            if (item.key === '4') {
              return (
                <>
                  {igoAccount && igoAccount === myAddress && (
                    <StyledNavLink to={item.url === '' ? {} : item.url} key={item.key} onClick={() => setIsMenuShow(false)}>
                      <div className="navlink-child-title">{i18n.language === 'en' ? item.enName : item.name}</div>
                    </StyledNavLink>
                  )}
                </>
              )
            } else
              return (
                <StyledNavLink to={item.url === '' ? {} : item.url} key={item.key} onClick={() => setIsMenuShow(false)}>
                  <div className="navlink-child-title">{i18n.language === 'en' ? item.enName : item.name}</div>
                </StyledNavLink>
              )
          })}
        </div>
        <div style={{ padding: '0 2rem' }}>
          <SwitchLanguage />
        </div>
      </Drawer>
    </SideMenuWrapper>
  )
})
