import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { readGetPastEvents } from '@/common'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
export interface ListType {
  key?: string | number
  img: string
  icon: string
  name: string
  content: string[]
  title: string
  serialNumber: string
}

export const useIGOnftHooks = () => {
  const [list, setList] = useState<ListType[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const nftData: ConstantInitTypes = useDataHooks()
  const { constant, web3 } = nftData
  const { igoList } = useSelector((state: any) => state.infoInfo)

  useEffect(() => {
    if (igoList.length > 0) {
      setLoading(true)
      getList()
    }
    return () => {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getList = async () => {
    try {
      let addedSource: any = await readGetPastEvents(constant.ContractMember, 'AddedBase')
      let data: ListType[] = []
      addedSource.data.forEach((element: any, index: number) => {
        data.push({
          img: '',
          icon: '',
          name: web3.utils.hexToAscii(element.returnValues.name),
          content: [],
          title: '',
          serialNumber: element.blockNumber,
        })
      })
      data.sort((a, b) => {
        return Number(b.serialNumber) - Number(a.serialNumber)
      })

      setList(data)
      setLoading(false)
    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  return { loading, listData: list, getList }
}
