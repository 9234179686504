import styled, { css } from 'styled-components'

export const ListItem = styled.div`
  width: 100%;
`

export const ListInfo = styled.div`
  width: 100%;
  background: #ffffff;
  border: 0.06rem solid #e8e9ee;
  border-radius: 1.4375rem;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    box-shadow: 0rem 0rem 0.63rem 0rem rgba(67, 68, 82, 0.28);
  }
  span {
    font-size: 1rem;
    font-weight: 400;
    color: #363639;
    line-height: 1.56rem;
    margin-top: 0.88rem;
  }
  h5 {
    font-size: 1rem;
    font-weight: Bold;
    color: #37383d;
    line-height: 1.56rem;
    margin: 0.81rem 0 0 0;
  }

  .mainContent {
    padding: 0.9375rem;
    width: 100%;
    border-radius: 0 0 1rem 1rem;
    min-height: 5rem;
  }

  .listName {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #5d6175;
    font-size: 1rem;
  }
`

export const ImageDiv = styled.div`
  width: 100%;
  border-radius: 1.4375rem 1.4375rem 0 0;
  /* height: 8.75rem; */
  height: 160px;
  ${({ theme }) => theme.mediaWidth.screenMd`
    height: 10rem;
  `}
  .ant-image,
  .ant-image-img {
    width: 100%;
    /* height: 8.75rem; */
    height: 160px;
    border-radius: 1.4375rem 1.4375rem 0 0;
    object-fit: cover;
    ${({ theme }) => theme.mediaWidth.screenMd`
    height: 10rem;
  `}
  }
`
