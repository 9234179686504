const server = {
  'server.title': 'The terms of service',
  'server.content1':
    'You are welcome to trade in the Carbon-Exchange. Please read the following terms carefully and consider whether to accept all the terms of this agreement. Unless you get all the terms of this agreement, you cannot become a registered user of the platform or enjoy the services the medium provides through any channel. You are advised to stop using our services and other operations immediately. If you accept this agreement, you will be deemed to know, acknowledge and agree to the contents of this document.',
  'server.content2': 'rules of use',
  'server.content3':
    "To use Carbon-Exchange's services, users must use a third-party wallet that allows them to transact on the blockchain. The user's blockchain address acts as their identity on Carbon-Exchange. Therefore, users need a blockchain address and a third-party wallet to access the service. The User's account on the Service ('Account') will be associated with the User's blockchain address.",
  'server.content4':
    "The user's account on Carbon-Exchange will be associated with the user's linked blockchain address. By using your wallet for the service, you agree to use the wallet by the terms and conditions of the applicable wallet provider. Carbon-Exchange is not a provider, agent, financial institution, or creditor of the wallet. Carbon-Exchange assumes no responsibility or liability for users' use of the wallet and makes no representations or warranties regarding how it will function with any particular wallet. Users will be solely responsible for keeping their wallets safe and should not share their wallet's Recovery Phrase or Private Key with anyone. Likewise, users are solely responsible for their accounts and any associated wallets. Carbon-Exchange is not responsible for any actions or omissions in connection with charges or any losses caused by the theft of user accounts or wallets.",
  'server.content5':
    'Users promise not to use the Carbon-Exchange to directly or indirectly engage in acts that violate the law and social morality. The Carbon-Exchange has the right to delete the content that violates the above commitments.',
  'server.content6':
    'Users are not allowed to make, upload, copy, publish, disseminate or reproduce the following content using the Carbon-Exchange service:',
  'server.content7': 'Opposes the basic principles established by the Constitution;',
  'server.content8': 'Endangering national security, divulging state secrets, subverting state power, and undermining national unity;',
  'server.content9': 'Damage to national honor and interests;',
  'server.content10': 'Inciting ethnic hatred, ethnic discrimination, or undermining ethnic unity;',
  'server.content11':
    'Insulting and abusing the image of heroic martyrs, denying heroic deeds, beautifying and whitewashing acts of war of aggression;',
  'server.content12': "Undermining the state's religious policy and promoting cults and feudal superstitions;",
  'server.content13': 'Spreading rumors, disrupting social order, and undermining social stability;',
  'server.content14': 'Spread obscenity, pornography, gambling, violence, murder, terror, or instigate crime;',
  'server.content15': 'Insulting or slandering others, infringing on the legitimate rights and interests of others;',
  'server.content16': 'Laws and administrative regulations prohibit information containing other content.',
  'server.content17': 'All users agree to abide by the Carbon-Exchange Service Agreement.',
  'server.content18':
    "The Carbon-Exchange has the right to review and supervise the user's use of the Carbon-Exchange. Suppose the user violates the above regulations when using the Carbon-Exchange. In that case, the Carbon-Exchange or its authorized person has the right to require the user to correct or directly take all necessary measures (including but not limited to changing or deleting content posted by users, suspending or terminating users' rights to use the Carbon-Exchange) to mitigate the effects of user misconduct.",
  'server.content19': 'personal privacy',
  'server.content20':
    "Respecting the privacy of users' personal privacy information is the consistent principle of the Carbon-Exchange. The Carbon-Exchange will fully protect the personal privacy information of users through technical means, strengthening internal management and other methods, unless required by law or government departments with legal authority or obtained from users in advance. Except for explicit authorization and other reasons, the Carbon-Exchange guarantees that it will not disclose or disclose the user's personal privacy information to the third party or the non-public content stored by the user when using the service. At the same time, to operate and improve the technology and benefits of the Carbon-Exchange, the Carbon-Exchange may collect, use or provide users' non-personal privacy information to third parties, which will help the Carbon-Exchange provide users with a better user experience and quality of services.",
  'server.content21': 'Agreement modification',
  'server.content22':
    'According to the development of the Internet and changes in relevant laws, regulations, and normative documents, or due to business development needs, Carbon-Exchange has the right to modify or change the terms of this agreement. Once the content of this agreement changes, the Carbon-Exchange will directly announce the modification. After the range of the agreement, the publication behavior is deemed that the Carbon-Exchange has notified the user to modify the content. The Carbon-Exchange may also use e-mail or private message transmission to inform users of amendments to the terms of the agreement, service changes, or other vital matters.',
  'server.content23':
    'If you disagree with the changes made by the Carbon-Exchange to the relevant terms of this agreement, the user has the right and should stop using the Carbon-Exchange. If the user continues to use the Carbon-Exchange, it is deemed that the user accepts the changes made by the Carbon-Exchange to the relevant terms of this agreement.',
}

export default server
