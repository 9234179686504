const market = {
  'market.title': '碳交易中心',
  'market.left.title': '筛选',
  'market.left.reset': '清除',
  'market.left.sure': '确定',
  'market.left.title.vice1': 'NFT 状态',
  'market.left.title.vice2': '价格',
  'market.left.title.vice2.btn': '应用',
  'market.left.title.vice3': '项目类型',
  'market.left.title.vice4': '项目集',
  'market.left.title.vice5': '模糊搜索NFT',
  'market.list.buy.now': '立即购买',
  'market.input.placeholder': 'Price Rank',
  'market.input.placeholder1': '请选择',
  'market.details.theme.title': '当前项目集: ',
  'market.details.theme.hold': '持有者',
  'market.details.vice.title1': '描述',
  'market.details.vice.title2': '特性',
  'market.details.vice.title3': '版税比例',
  'market.details.vice.title4': '最新时间',
  'market.details.vice.title5': '版税收益地址',
  'market.details.vice.title2.list1': '合约地址',
  'market.details.vice.title2.list2': '认证标准',
  'market.details.vice.title2.list3': '区块链',
  'market.details.vice.title2.list4': '元数据',
  'market.details.vice.title2.list.info1': 'ERC721',
  'market.details.vice.title2.list.info3': '碳资产',
  'market.details.price.title': '交易价格',
  'market.details.price.title.vice': '注:购买NFT资源盲盒时，需要缴纳GAS费、交易税',
  'market.details.price.btn': '立即购买',
  'market.message.tips': '暂无数据',
  'market.message.tips1': '价格范围不能为空',
  'market.message.tips2': '价格范围有错误，请重新输入',
  'market.list.theme': 'NFT 资源',
  'market.list.title': 'NFT 交易记录',
  'market.list.th1': '价格',
  'market.list.th2': '出售地址',
  'market.list.th3': '购买地址',
  'market.list.th4': '交易时间',
  'market.list.th5': '区块链',
  'market.message.tips3': '不支持购买自己销售的NFT',
  'market.message.tips4': '帐号中{{msg}}不足',
  'market.buy.success': '购买成功',
  'market.list.details.tips': '查看详情',
  'market.search': '搜索',
}

export default market
