import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

export const ConnectWalletWrapper = styled.div`
  .linkBtn {
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          width: 100%;
          height: 5.3125rem;
          line-height: 5.3125rem;
          font-size: 2.5rem;
        `,
      )}
    width: 18rem;
    height: 3.2rem;
    border-radius: 2rem;
    text-align: center;
    color: white;
    font-size: 1.6rem;
    line-height: 3.2rem;
    background-color: #00c28e;
    margin: 1.5rem 0 0 0;
    cursor: pointer;
  }
  .loginout-icon {
    cursor: pointer;
  }
  .details-btns {
    width: 18.125rem !important;
    height: 3.125rem !important;
    border-radius: 2.6875rem;
    font-size: 1.5rem !important;
    font-weight: 400!important;
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          width: 100% !important;
          height: 5rem !important;
          /* line-height: 5rem; */
          font-size: 1.5rem !important;
        `,
      )}
  }
  .shop-btns {
    width: 100%;
  }
  .phoneWallet {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .drawer-content {
    font-size: 1.5rem;
    color: #5d6175;
    line-height: 5rem;
    font-weight: 400;
    padding: 3rem 0 0 4.1875rem;
  }
`

export const DivTest = styled.div`
  margin-top: 1.25rem;
  font-size: 0.88rem;
  line-height: 1.85rem;
  font-weight: 400;
  color: #80808b;
  span {
    color: #ff4d4f;
    margin-right: 0.25rem;
  }
`

export const ModalTitle = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
  position: relative;
  text-indent: 2.5em;
  &.h5Wall {
    .choose-info:first-child {
      margin-top: 2.75rem;
    }
  }
  .span {
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    text-indent: 0;
    text-align: center;
    border-radius: 100%;
    color: ${({ theme }) => theme.gray};
    background: ${({ theme }) => theme['gary-4']};
  }
  .choose-info {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40.625rem;
    height: 3.75rem;
    background-color: #fff;
    border: 1px solid #cdd8e2;
    border-radius: 5px;
    padding: 10px 15px;
    text-indent: 0;
    ${({ theme }) => theme.mediaWidth.screenMd`
   width:100%;
   height:5rem;
    `}
    &:first-child {
      margin-bottom: 20px;
    }
    :hover {
      background-color: #fff;
    }
    .choose-span {
      font-size: 1.125rem;
      line-height: 2.5rem;
      font-weight: 400;
      color: #37383d;
      ${({ theme }) => theme.mediaWidth.screenMd`
     font-size: 0.88rem;
    `}
    }
    .choose-icon {
      background: ${({ theme }) => theme.white};
    }
    .hot {
      width: 6.0625rem;
      padding: 0.3rem 0;
      background: #1471fb;
      border-radius: 0.4375rem;
      text-align: center;
      color: white;
      font-weight: 400;
    }
  }

  ${({ theme }) => theme.mediaWidth.screenMd`
    text-indent: 3.5em;
    font-size: 1rem;
    line-height: 2.5rem;
      .span{
        width: 2.5rem;
        height: 2.5rem;
      }
    `}
`

export const NoChainIdTips = styled.div`
  position: fixed;
  top: 5.5rem;
  left: 0;
  width: 100%;
  z-index: 2;
  line-height: 3.38rem;
  height: 3.38rem;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  align-items: center;
  background: ${({ theme }) => `${theme.themeColor}`};
  color: ${({ theme }) => theme.white};
  ${({ theme }) => theme.mediaWidth.screenMd`
    top: 5.5rem;
    height: 4.38rem;
  `}
`

export const WalletTitleAddress = styled.div`
  font-size: 1.125rem;
  font-weight: 400;
  color: #1471fb;
  margin-right: 1.88rem;
  min-width: 8.13rem;
  height: 2.38rem;
  /* background: ${(props) => props.theme['gary-4']}; */
  border-radius: 1.25rem;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  .anticon-wallet {
    font-size: 2.13rem;
    color: ${(props) => props.theme.themeColor};
    margin-right: 0.63rem;
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        span {
          font-size: 1.5rem;
          font-weight: 400;
        }
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.screenLg(
      () => css`
        margin-right: 0.88rem;
        height: 3.38rem;
        border-radius: 0.31rem;
        .anticon-wallet {
          font-size: 2.38rem;
          margin-right: 0;
        }
      `,
    )}
`

const activeClassName = 'ACTIVE'
export const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  font-size: 0.88rem;
  font-weight: 400;
  color: ${(props) => props.theme.gray};
  text-align: center;
  &.${activeClassName} {
    font-weight: 600;
    color: #363639 !important;
  }
  &:hover {
    content: none;
    color: #363639;
  }
  &.myMenu {
    padding: 10px;
  }
  &.myMenu:hover div {
    border-left: 2px solid #00477c;
  }
`

export const AccountMoveWrapper = styled.div`
  position: absolute;
  top: 4.3rem;
  width: 100%;
  height: auto;
  z-index: 100;
`

export const AccountContent = styled.div`
  width: 8.94rem;
  background: #ffffff;
  border: 0.06rem solid #e5e5e5;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 500;
  /* line-height: 3rem; */
  a {
    color: ${(props) => props.theme.gray};
    width: 100%;
    /* border-bottom: 1px solid #e5e5e5; */
    &:hover {
      color: #363639;
    }
  }
`

export const WalletDivCreate = styled.div`
  padding: 2.5rem 1.25rem;
  margin: 0 auto;
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        width: 100%;
        padding: 2.5rem 1.6875rem;
      `,
    )}
`

export const MyWallet = styled.div`
  padding: 25px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #37383d;
  border-bottom: 1px solid #dedfe1;
`

export const WalletContent = styled.div`
  padding: 25px;
  .tips {
    font-size: 18px;
    color: #87868d;
  }
`

export const WalletContent1 = styled.div`
  padding: 25px 0;
  .tips {
    font-size: 1.875rem;
    color: #87868d;
  }
`
