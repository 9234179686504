const igonft = {
  'igonft.title': 'IEO-NFT',

  'igonft.title.h3': 'IEO Carbon NFT Project',
  'igonft.title.h5':
    'The IEO Carbon Trading NFT project, initiated by the Council and the Carbon community, helps users more widely and easily supplement their carbon emissions to achieve the ultimate goal of carbon neutrality',
  'igonft.message.tips': 'Data Error',
  'igonft.details.introduce.title': 'Introduce',
  'igonft.details.btn': 'Participate Project',
  'igonft.apply.btn': 'Apply for the IEO Program',
  'igonft.apply.add': 'Add for the IEO Program',
  'igonft.apply.btnAdd': 'add',
  'igonft.apply.title': 'Apply for IEO-NFT project',
  'igonft.add.title': 'Add for IEO-NFT project',
  'igonft.apply.p1': 'Apply for the IEO-NFT project, please contact us by email',
  'igonft.apply.p2':
    'The documents required in the application will be sent to your account by email, please pay attention to check them! ',
  'igonft.apply.email': 'email address',
  'igonft.apply.copy.btn': 'Copy email address',
  'igonft.apply.copy.success': 'Copy Success',
  'igo-basic-title1': 'Basic information of the project party',
  'igo-basic-title1-0': 'name',
  'igo-basic-title1-1': 'Wallet address',
  'igo-basic-title1-2': 'Email',
  'igo-basic-title1-3': 'Phone',
  'igo-basic-title2': 'IEO Asset type application',
  'igo-type1': 'Carbon assets',
  'igo-type2': 'Carbon stock',
  'igo-type3': 'Carbon futures',
  'igo-type4': 'Carbon paper',
  'igo-btn': 'Submit',
  'igo-opened': 'Temporarily not opened',
  'igo-input-tips1': 'Please enter wallet address',
  'igo-input-tips2': 'please input your email',
  'igo-input-tips3': 'Please enter phone',
  'igo-input-tips4': 'Please enter country',
  'igo-input-tips5': 'Please enter an image',
  'igo-input-tips6': 'Please enter serial number',
  'igo-input-tips7': 'Please enter additional credentials',
  'igo-input-tips8': 'Please enter certification criteria',
  'igo-input-tips9': 'Please enter quantity',
  'igo-input-tips10': 'Please enter item type',
  'igo-input-tips11': 'Please enter the Vintage',
  'igo-input-tips12': 'Please enter a project name',
  'igo-input-tips13': 'Please enter project ID',
  'igo-input-tips14': 'Please enter methodology',
  'igo-input-tips15': 'Please enter status',
  'igo-input-tips16': 'Submitted successfully',
  'igo-input-tips17': 'Add successfully',
  'igo-input-tips18': 'Please enter a name',
  'igo-input-tips19': 'Add failed',
  'igo-input-tips20': 'Wrong wallet address',
  'igo-input-tips21': 'Wrong phone number',
  'igo-input-tips22': 'Email address is wrong',
  'igo-input-tips23':
    'The name of Ieo is too long, the maximum number of Chinese characters is 10, and the maximum number of English is 20',
  'igo-input-tipsNum': 'The IEO name cannot be a pure number',
  'igo-input-tipsApp': 'The wallet address is submitted repeatedly',
  'igo-input-tips24': 'The country character cannot exceed 100',
  'igo-input-tips25': 'The image contains a maximum of 100 characters',
  'igo-input-tips26': 'The serial number cannot exceed 100',
  'igo-input-tips27': 'The number of additional authentication characters cannot exceed 100',
  'igo-input-tips28': 'The authentication standard contains a maximum of 100 characters',
  'igo-input-tips29': 'Quantity The value contains a maximum of 100 characters',
  'igo-input-tips30': 'The item type contains a maximum of 100 characters',
  'igo-input-tips31': 'The year character of carbon asset cannot exceed 100',
  'igo-input-tips32': 'The project name contains a maximum of 100 characters',
  'igo-input-tips33': 'The project ID cannot exceed 100 characters',
  'igo-input-tips34': 'The maximum number of methodology characters cannot exceed 100',
  'igo-input-tips35': 'The status character cannot exceed 100',
  'igo-input-tips36': 'The item set name is too long, the longest Chinese character is 10, and the English maximum is 20',
  'igo-input-tips37': 'The NFT name is too long, the longest Chinese character is 10, and the English is up to 20',
  'igo-input-tips38': 'Please enter the Project offcial link',

  'project.back': 'To Market',
  'project.home.title': 'More about NFT resources',
  'project.home.tips': 'Each NFT resource is unique and enjoys universal access and special benefits.',
  'project.home.about': 'About',
  'project.home.about.list.1': 'MetaDao from unknown to unknown.',
  'project.home.about.list.2':
    'The future has arrived, NFT will become the most powerful IP carrier in this era, the Sky Valley Trading planet will bring an extraordinary FEAST of NFT for mankind. Imagination has no boundaries, exploration has no limits!',
  'project.home.about.list.3': 'Discover, collect, and create new realities of extraordinary NFT together.',
  'project.nft.bottom': "The world's leading carbon credit spot trading platform",
  'peoject.nft.title1': 'NFT ',
  'peoject.nft.title2': ' > NFT details',
  'project.nft.features': 'Features',
  'project.nft.contentItem1': 'Contract address',
  'project.nft.contentItem2': 'Tokens standard',
  'project.nft.contentItem3': 'Block chain',
  'project.nft.contentItem4': 'Metadata',
  'project.nft.detail.header': 'Current project set: ',
  'project.nft.detail.name': 'Carbon NFT resources — ',
  'project.nft.detail.owner': 'Owner',
  'project.nft.detail.price': 'Current price',
  'project.nft.detail.nocan': 'Unable to cast currently',
  'project.nft.detail.attend': 'Note: You will have to pay GAS fee, {{msg}}% transaction tax',
  'project.nft.detail.buy': 'Go to market',
  'project.nft.detail.des': 'Describe',
  'project.nft.detail.description': 'Create a transparent decentralized carbon market for a sustainable future.',
  'project.nft.detail.dataDetail': 'Metadata Details',
  'project.nft.detail.country': 'Country',
  'project.nft.detail.img': 'Image',
  'project.nft.detail.num': 'Serial-number',
  'project.nft.detail.offcial': 'Project offcial link',
  'project.nft.detail.renzhen': 'Additional authentication',
  'project.nft.detail.stan': 'Certification standards',
  'project.nft.detail.amount': 'Amount',
  'project.nft.detail.type': 'Project type',
  'project.nft.detail.time': 'Vintage',
  'project.nft.detail.pro': 'Project details',
  'project.nft.detail.proId': 'Project ID',
  'project.nft.detail.proName': 'Project name',
  'project.nft.detail.method': 'Methodology',
  'project.nft.detail.status': 'Status',
  Singapore: 'Singapore',
  'admin.title': 'Admin',
  'admin.isopen': 'Whether the casting:',
  'admin.btn': 'Yes',
  'admin.btn1': 'No',
  'admin.success': 'Casting chamber opened',
  'admin.fail': 'Casting chamber opening failed',
  'admin.success1': 'The casting chamber has been closed',
  'admin.fail1': 'Casting chamber closure failed',
  'admin.set': 'Set',
  'admin.price': 'Price',
  'admin.amount': 'Amount',
  'admin.plo': 'Please enter',
  'admin.tips': 'Successfully set',
  'admin.tips1': 'Setup failed',
  'admin.btn2': 'Complete',
  'admin.config.tips': 'Enter a valid value',
  'admin.amount.warning': 'Insufficient casting quantity',
  'admin.amount.success': 'Casting success',
  'admin.amount.tips': 'Check it out at my NFT',
  'admin.amount.btn': 'To view',
  'admin.cast': 'Castable quantity:',
}

export default igonft
