import styled, { css } from 'styled-components'
import nftAbout from '@/assets/img_slices/nftAbout.png'

export const SetWrapper = styled.div`
  padding-top: 5rem;
  ${({ theme }) => theme.mediaWidth.screenMd`
     padding-top:6rem;
  `}
`

export const ImageDiv = styled.div`
  width: 100%;
  height: 31.25rem;
  border-radius: 0.31rem;
  box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
    height: 100%;
  }
`

export const HomeContent = styled.div`
  max-width: 1200px;
  margin: 2rem auto 4rem auto;
  min-height: calc(100vh - 20rem);
  ${({ theme }) => theme.mediaWidth.screenMd`
    padding:1rem;
  `}
  .title {
    color: #5d6175;
    font-size: 1.625rem;
    margin-bottom: 2rem;
    .line {
      cursor: pointer;
      &:hover {
        color: #00c28e;
      }
    }
  }
  .homeList {
    margin: 1.3rem 0 0 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .listItem {
      width: calc((100% - 3rem) / 4);
      border: 1px solid #dedede;
      border-radius: 1rem;
      margin-bottom: 2rem;
      cursor: pointer;
      &:hover {
        box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
      }
      ${({ theme }) => theme.mediaWidth.screenMd`
    width: calc((100% - 2rem) / 2);
  `}
      .ant-image,.ant-image-img {
        width: 100%;
        height: 280px;
        /* height: 140px; */
        border-radius: 1rem 1rem 0 0;
        object-fit: cover;
        ${({ theme }) => theme.mediaWidth.screenMd`
    height: 17.5rem;
  `}
      }
      .name {
        padding: 1.8rem 10px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #5d6175;
      }
    }
    .bank {
      width: calc((100% - 3rem) / 4);
    }
  }
  .about {
    margin-top: 2rem;
    border: 1px solid #dedede;
    border-radius: 1rem;
    height: 8.5625rem;
    line-height: 8.5625rem;
    background-image: url(${nftAbout});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: #00477c;
    font-size: 1.8rem;
  }
`

export const Details = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${({ theme }) =>
    theme.mediaWidth.screenMd(
      () => css`
        display: block;
        flex-wrap: wrap;
      `,
    )}
  .detailLeft {
    flex: 0.425;
    .topic {
      color: #00c28e;
      font-size: 1rem;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 1.875rem;
            margin: 1rem 0;
          `,
        )}
    }
    .rightName {
      color: #37383d;
      font-size: 1.375rem;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 3rem;
            line-height: 3rem;
          `,
        )}
    }
    .buyBtn {
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            font-size: 2.5rem;
          `,
        )}
      width: 18rem;
      height: 3.8rem;
      border-radius: 2rem;
      text-align: center;
      color: white;
      font-size: 1.6rem;
      line-height: 3.8rem;
      background-color: #00c28e;
      margin: 1.5rem 0 0 0;
      cursor: pointer;
    }
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          flex: 1;
        `,
      )}
    .mainImg {
      max-width: 100%;
      max-height: 31.25rem;
    }
    .leftBottom {
      margin-top: 1.6rem;
      border: 1px solid #dedede;
      border-radius: 1rem;
      .header {
        padding: 1.4rem 2rem;
        background: #f5f6f8;
        color: #37383d;
        font-size: 1.25rem;
        border-radius: 1rem 1rem 0 0;
        display: flex;
        align-items: center;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 1.875rem;
              padding: 1rem 2rem;
            `,
          )}
      }
      .content {
        padding: 1.4rem 2rem;
        .contentItem {
          &:not(:first-child) {
            margin-top: 1rem;
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.125rem;
          ${({ theme }) =>
            theme.mediaWidth.screenMd(
              () => css`
                font-size: 1.75rem;
              `,
            )}
          .name {
            flex: 1;
            color: #9596a5;
          }
          .value {
            flex: 1;
            color: #5d6175;
          }
        }
      }
    }
  }
  .detailRight {
    margin-left: 3rem;
    flex: 0.575;
    .leftBottom {
      margin-top: 1.6rem;
      border: 1px solid #dedede;
      border-radius: 1rem;
      .header {
        padding: 1.4rem 2rem;
        background: #f5f6f8;
        color: #37383d;
        font-size: 1.25rem;
        border-radius: 1rem 1rem 0 0;
        display: flex;
        align-items: center;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 1.875rem;
              padding: 1rem 2rem;
            `,
          )}
      }
      .content {
        padding: 1.4rem 2rem;
        .contentItem {
          &:not(:first-child) {
            margin-top: 1rem;
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.125rem;
          ${({ theme }) =>
            theme.mediaWidth.screenMd(
              () => css`
                font-size: 1.75rem;
              `,
            )}
          .name {
            flex: 1;
            color: #9596a5;
          }
          .value {
            flex: 1;
            color: #5d6175;
          }
        }
      }
    }
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          flex: 1;
          margin: 1.6rem 0 0;
        `,
      )}
    .topic {
      color: #00c28e;
      font-size: 1rem;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 1.875rem;
          `,
        )}
    }
    .rightName {
      color: #37383d;
      font-size: 1.375rem;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 3rem;
          `,
        )}
    }
    .buyBtn {
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            font-size: 2.5rem;
          `,
        )}
      width: 18rem;
      height: 3.8rem;
      border-radius: 2rem;
      text-align: center;
      color: white;
      font-size: 1.6rem;
      line-height: 3.8rem;
      background-color: #00c28e;
      margin: 1.5rem 0 0 0;
      cursor: pointer;
    }
    .des {
      margin-top: 1.5rem;
      border: 1px solid #dedede;
      border-radius: 1rem;
      .heder {
        display: flex;
        padding: 1rem 2rem;
        color: #37383d;
        font-size: 1.25rem;
        background-color: #f5f6f8;
        border-radius: 1rem 1rem 0 0;
        align-items: center;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 1.875rem;
            `,
          )}
        img {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 10px;
          ${({ theme }) =>
            theme.mediaWidth.screenMd(
              () => css`
                width: 2.25rem;
                height: 2.25rem;
              `,
            )}
        }
      }
      .desTips {
        width: 100%;
        color: #5d6175;
        font-size: 1.125rem;
        padding: 1.4rem 2rem;
        word-break: break-all;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 2rem;
            `,
          )}
        .nodes {
          text-align: center;
          color: #9596a5;
          font-size: 1.125rem;
        }
      }
      .metaContent {
        padding: 1.4rem 2rem;
        .metaItem {
          width: 100%;
          display: flex;
          font-size: 1.125rem;
          ${({ theme }) =>
            theme.mediaWidth.screenMd(
              () => css`
                font-size: 1.75rem;
              `,
            )}
          &:not(:first-child) {
            margin-top: 0.8rem;
          }
          .detailTitle {
            color: #9596a5;
            flex: 0.4;
          }
          .detailValue {
            color: #5d6175;
            flex: 0.6;
            /* white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; */
            padding: 0 1rem 0 0;
          }
        }
      }
    }
  }
`
