import styled, { css } from 'styled-components'
import { ComMyLayout } from '@/common/styled'
import { Row } from 'antd'
import ShowSet from '@/assets/home/showSet.png'

export const ComLayoutTwo = styled.div`
  .ant-cols {
    background-image: url(${ShowSet});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .pageCenter {
    text-align: center;
  }
`

export const IGOnftWrapper = styled(ComMyLayout)`
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 6.5rem);
  ${({ theme }) => theme.mediaWidth.screenMd`
      padding: 3rem 20px 0 20px;
      // .igo-btn {
      // {
      //   &:last-child{
      //     margin-left:2.35rem !important;
      //   }
      // }
    }
  `}
  .igo-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
    .ant-btn {
      width: auto !important;
    }
    .my-home-btn {
      color: #00c28e;
      border-radius: 2rem;
      width: 25rem !important;
      &.last-btn {
        margin-left: 5.69rem;
      }
      &:hover {
        background-color: #00c28e;
        color: #ffffff;
      }
    }
  }
  .details-igonft {
    .details-igonft-left {
      display: flex;
      align-items: center;
      padding-top: 1.25rem;
      flex-direction: column;
      margin-bottom: 2.5rem;
      .ant-image {
        width: 100%;
        border-radius: 1.25rem;
        height: auto;
        box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
      }
      .ant-image-img {
        border-radius: 1.25rem;
      }
    }
    .details-igonft-right {
      display: flex;
      flex-direction: column;
      padding-top: 1.25rem;
      h2 {
        font-size: 1.88rem;
        margin: 1.69rem 0;
        font-weight: 700;
      }
      h4 {
        font-size: 1rem;
        font-weight: 400;
        color: #363639;
        margin-bottom: 1.88rem;
      }
    }
  }
  .igonft-two {
    ${(props) =>
      props.theme.mediaWidth.screenXl(
        () => css`
          margin: 0 2.5rem;
        `,
      )}
    ${(props) =>
      props.theme.mediaWidth.screenLg(
        () => css`
          margin: 0 1.13rem;
        `,
      )}
  }
`

export const IGOnftTitle = styled.div`
  height: 6.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: end;
  padding-top: 0;
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`

export const Title = styled.div`
  font-size: 3.5rem;
  font-weight: 600;
  color: #363639;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #363639;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
  `}
`

export const IGOnftContent = styled.div`
  ${({ theme }) => theme.mediaWidth.screenMd`
     margin-top: 0;
  `}
  .h3 {
    font-size: 3rem;
    font-weight: 400;
    color: #00477c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    img {
      cursor: pointer;
      &:hover {
        transform: scale(1.05, 1.05);
      }
    }
    ${({ theme }) => theme.mediaWidth.screenMd`
     padding-top: 0rem;
  `}
  }
  .h5 {
    margin-top: 1rem;
    font-size: 1.125rem;
    font-weight: 400;
    color: #9596a5;
  }
`

export const ContentList = styled(Row)`
  margin-top: 2.5rem;
  .ant-col {
    margin-bottom: 4.38rem;
  }
  .ant-cols {
    flex-direction: row;
    display: flex;
    height: 20rem;
    border: none;
    border-radius: 0.63rem;
    align-items: center;
    justify-content: center;
    /* cursor: pointer; */
    .ant-image {
      width: 95%;
      margin: 0 2.5%;
      max-width: 12.5rem;
      height: auto;
      max-height: 12.5rem;
      border-radius: 0.63rem;
      .ant-image-img {
        border-radius: 0.63rem;
      }
    }
    .right {
      width: 95%;
      margin: 0 2.5%;
      text-align: center;
      span {
        font-size: 2.5rem;
        font-weight: 400;
        color: #5d6175;
        line-height: 1.56rem;
      }
      p {
        font-size: 0.88rem;
        font-weight: 400;
        color: #363639;
        line-height: 1.56rem;
      }
    }
    .ant-col {
      margin-bottom: 0;
    }
  }
`
