import styled, { css } from 'styled-components'
import { proTopBarAdaptationMax, proTopBarAdaptation, h5proTopBarAdaptation } from '@/common/styled'

export const VaultWrapper = styled.div`
  ${proTopBarAdaptation}
  @media (min-width: 1920px) {
    ${proTopBarAdaptationMax}
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        ${h5proTopBarAdaptation}
      `,
    )}
  padding-top: 5rem;
  margin-bottom: 5rem !important;
  ${({ theme }) => theme.mediaWidth.screenXl`
      padding: 6rem 0.625rem 0 0.625rem;
  `}
`

export const VaultTitle = styled.div`
  width: 100%;
  height: 6.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #00477c;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
  `}
`

export const VaultContent = styled.div`
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.screenMd`
    padding:0;
  `}
  .title {
    font-size: 1.625rem;
    color: #37383d;
    margin-bottom: 0.625rem;
    &:not(:first-child) {
      margin-top: 4.0625rem;
    }
    ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 1.875rem;
    margin-bottom: 1.5rem;
  `}
  }
  .item {
    color: #87868d;
    font-size: 1rem;
    span {
      color: #00477c;
      cursor: pointer;
      text-decoration: underline;
    }
    ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 1.375rem;
  `}
  }
`
