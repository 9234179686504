import styled from 'styled-components'
import Banner from '@/assets/img_slices/projectBanner.png'

export const SetWrapper = styled.div`
  padding-top: 5rem;
  /* margin: 0 auto; */
  .hash {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .banner {
    /* width: 100%; */
    max-width: 1200px;
    margin: 0 auto;
    height: 600px;
    /* background: url(${Banner}) no-repeat center;
    background-size: 100% 100%; */
    /* padding: 5rem 0; */
    ${({ theme }) => theme.mediaWidth.screenMd`
    width: 100%;
    height:auto;
  `}
    img {
      width: 100%;
      height: 100%;
      /* object-fit: cover; */
    }
    .bannerTopic {
      color: white;
      font-size: 2.25rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
`

export const HomeContent = styled.div`
  max-width: 1200px;
  margin: 1rem auto 2rem auto;
  ${({ theme }) => theme.mediaWidth.screenMd`
    padding:1rem;
  `}
  .title {
    color: #37383d;
    font-size: 1.625rem;
    ${({ theme }) => theme.mediaWidth.screenMd`
     font-size: 2rem;
  `}
  }
  .tips {
    color: #5d6175;
    font-size: 1.125rem;
    margin: 1rem 0 2.5rem 0;
    ${({ theme }) => theme.mediaWidth.screenMd`
     font-size: 1.5rem;
  `}
  }
  .homeList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .listItem {
      width: calc((100% - 3rem) / 4);
      border: 1px solid #dedede;
      border-radius: 1rem;
      margin-bottom: 2rem;
      cursor: pointer;
      &:hover {
        box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
      }
      ${({ theme }) => theme.mediaWidth.screenMd`
    width: calc((100% - 2rem) / 2);
  `}

      .ant-image,.ant-image-img {
        width: 100%;
        height: 280px;
        /* height: 140px; */
        border-radius: 1rem 1rem 0 0;
        object-fit: cover;
        ${({ theme }) => theme.mediaWidth.screenMd`
    height: 17.5rem;
  `}
      }
      .name {
        padding: 1.8rem 10px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #5d6175;
      }
    }
    .bank {
      width: calc((100% - 3rem) / 4);
    }
  }
  .about {
    border: 1px solid #dedede;
    border-radius: 1rem;
    padding: 2.1875rem 8.25rem 2.875rem 2.9375rem;
    ${({ theme }) => theme.mediaWidth.screenMd`
            padding: 2.1875rem 1.5625rem 2.1875rem 2.9375rem;
        `}
    .aboutTitle {
      color: #37383d;
      font-size: 1.625rem;
      ${({ theme }) => theme.mediaWidth.screenMd`
            font-size: 2rem;
        `}
    }

    ul {
      padding: 0;
      li {
        list-style-type: none;
        color: #5d6175;
        font-size: 1.125rem;
        ${({ theme }) => theme.mediaWidth.screenMd`
           font-size: 1.5rem;
        `}
      }
    }
  }
`
