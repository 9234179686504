const validation = {
  'validation.title': 'Official Verification',
  'validation.content1': 'Carbon-Exchange',
  'validation.content2': 'Official verification channel',
  'validation.placeholder': 'Enter the query entry',
  'validation.btn': 'Query',
  'validation.content3': 'Please use Carbon-Exchange Verify to check whether the source is officially from Carbon-Exchange.  ',
  'validation.content6': 'The following sources may be verified:',
  'validation.content4': ' email address, phone number, WeChat ID, Twitter account or Telegram ID.',
  'validation.content5': 'Please do not contact any unofficial/non-verified sources or reveal your account details to them. ',
}

export default validation
