const footer = {
  'footer.title': 'Carbon trading center',
  'footer.top1': 'Law and Privacy',
  'footer.top2': 'Service',
  'footer.top3': 'Customer Support',
  'footer.top1.name1': 'The terms of service',
  'footer.top1.name2': 'Privacy protection',
  'footer.top1.name3': 'Disclaimer',
  'footer.top1.name4': 'Enforcement requests',
  'footer.top2.name1': 'Apply for IEO',
  'footer.top2.name2': 'Create item collection',
  'footer.top2.name3': 'Carbon bank',
  'footer.top2.name4': 'Carbon management',
  'footer.top3.name1': 'Help center',
  'footer.top3.name2': 'Official verification',
  'footer.top3.name3': 'Precautions',
  'footer.top3.name4': 'About us',
  footer_title: 'Carbon Exchange',
}

export default footer
