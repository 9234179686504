import React, { memo, useState, useEffect } from 'react'
import { SetWrapper, HomeContent, Details, MyLink, CastModal } from './styled'
// import ProjectTopBar from '@/components/ProjectTopBar'
// import { AdaptFontSize, Adapth5, bucket, formatStrAddress, params } from '@/utils'
// import { message, Modal, Spin } from 'antd'
// import { useTranslation } from 'react-i18next'
// import { useHistory } from 'react-router-dom'
// import features from '@/assets/page_slices/features.png'
// import des from '@/assets/page_slices/des.png'
// import des1 from '@/assets/page_slices/des1.png'
// import detail from '@/assets/page_slices/detail.png'
// import detail1 from '@/assets/page_slices/detail1.png'
// import CRE from '@/assets/img_slices/cre.png'
// import price from '@/assets/page_slices/current.png'
// import ICO from '@/assets/usdt_icon.png'
// import NO from '@/assets/img_slices/no.png'
// import Succ from '@/assets/img_slices/success.png'

// import i18n from '@/utils/i18n'
// import { useWeb3React } from '@web3-react/core'
// import { Web3Provider } from '@ethersproject/providers'
// import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
// import ConnectWallet from '@/components/ConnectWallet'
// import { ConstantInitTypes } from '@/contracts/constantInit'
// import useDataHooks from '@/hooks/useDataHooks'
// import ProjectFooter from '@/components/ProjectFooter'
// import noDes from '@/assets/home/noDes.png'
// import { retrieveFiles } from '@/common'
// import { useSelector } from 'react-redux'
// import useWeb3StoreHooks from '@/hooks/useWeb3StoreHooks'
// import { StorageClientTypes } from '@/contracts/web3StorageInit'
// import { getData } from '@/api'
// import copy from '@/assets/copy.png'
// import Web3 from 'web3'
// import { S3 } from '@aws-sdk/client-s3'

export default memo(function ProjectCreate(props: any) {
  // const { t } = useTranslation()
  // let history = useHistory()
  // const web3Store: StorageClientTypes = useWeb3StoreHooks()
  // const { client, storeFilesPapers, makeFileObjects } = web3Store
  // const [name, setName] = useState('')
  // const [dataSource, setDataSource] = useState<any>([])
  // const myAddress = useSelector((state: any) => state.userInfo.address)
  // const [loadingText, setLoadingText] = useState<string>('Loading...')
  // const context = useWeb3React<Web3Provider>()
  // const { active, account } = context
  // const nftData: ConstantInitTypes = useDataHooks()
  // const { Blockchain, SharedToken_ADDRESS, constant, Store_ADDRESS } = nftData
  // const [can, setCan] = useState(false)
  // const { windowSize } = useWindowSizeHooks()
  // const [priceValue, setPriceValue] = useState(0)
  // const [rateValue, setRateValue] = useState(0)
  // const [amount, setAmount] = useState(0)
  // const [spinLoading, setSpinLoading] = useState(false)
  // const [metaLoading, setMetaLoading] = useState(false)
  // const [cid, setCid] = useState('')
  // const [orgData, setOrgData] = useState<any>({})
  // const [onShow, setOnShow] = useState(false)
  // const [proCid, setProCid] = useState<any>()
  // const [metaCid, setMetaCid] = useState('')

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  //   return () => window.scrollTo(0, 0)

  // }, [props])

  // useEffect(() => {
  //   const local = localStorage.getItem('itemDetail_id')
  //   const search = props.location.search

  //   historySearchSwitch(search ? search : '?key=' + local)
  // }, [props])

  // const historySearchSwitch = async (search: any) => {
  //   setSpinLoading(true)
  //   if (search) {
  //     let listSearch = search.slice(1).split('&')
  //     let list: { lable: string; value: any }[] = []
  //     listSearch.forEach((element: any) => {
  //       let objs = element.split('=')
  //       list.push({
  //         lable: objs[0],
  //         value: objs[1],
  //       })
  //     })
  //     if (list[0].lable === 'key' && list[0].value) {
  //       let navThemeCidV1 = Web3.utils.hexToAscii(list[0].value)
  //       const params: any = `
  //         query MyQuery {
  //           categoriesInfo(id: "${list[0].value}") {
  //             account
  //             carbonType
  //             contractAddress
  //             id
  //             NFTs(first: 6) {
  //               carbonType
  //               categoriesId
  //               categoriesOwener
  //               id
  //               metadata_cid
  //               minter
  //               type
  //               num
  //             }
  //           }
  //         }
  //     `
  //       const itemData: any = sessionStorage.getItem(navThemeCidV1)
  //       if (itemData) {
  //         setName(JSON.parse(itemData).name)
  //       }

  //       const data: any = await getData(params)
  //       const result: any = await getIeo(data.data.categoriesInfo.account)

  //       setCid(list[0].value)
  //       getPrice(list[0].value)
  //       setCan(await isCast(list[0].value))
  //       setProCid(list[0].value)
  //       setDataSource(JSON.parse(itemData))
  //       setSpinLoading(false)

  //       if (result.length > 0) {
  //         setMetaLoading(true)
  //         setMetaCid(result[0].ipfs_cid)
  //         let navThemeCidV3 = Web3.utils.hexToAscii(result[0].ipfs_cid)
  //         const a1: any = await retrieveFiles(navThemeCidV3)
  //         setOrgData(a1 ? a1 : {})
  //         setMetaLoading(false)
  //       }
  //     }
  //   }
  //   setSpinLoading(false)
  // }

  // const getIeo = async (account: any) => {
  //   try {
  //     const params = `
  //     query MyQuery {
  //       metadataInfos {
  //         ipfs_cid
  //         id
  //         carbonType
  //         account
  //       }
  //     }
  //     `
  //     const data: any = await getData(params)
  //     return data.data.metadataInfos.filter((item: any) => item.account.toLowerCase() === account.toLowerCase())
  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // }

  // const getCidData = async () => {
  //   setSpinLoading(true)
  //   let cidV1 = Web3.utils.hexToAscii(metaCid)
  //   const a1: any = await retrieveFiles(cidV1)
  //   setOrgData(a1 ? a1 : {})

  //   setSpinLoading(false)
  // }

  // const getPrice = async (value: any) => {
  //   let data: any = await constant.ContractStore.methods.categorieCasts(value).call()
  //   if (data) {
  //     setPriceValue(data.price)
  //     setAmount(data.amount)
  //   }
  //   let data1: any = await constant.ContractStore.methods.fee().call()
  //   if (data1) {
  //     setRateValue(Number(data1) / 100)
  //   }
  // }

  // const isCast = async (value: any) => {
  //   let data: any = await constant.ContractStore.methods.isCasts(value).call()
  //   return data
  // }

  // const openBox = async () => {
  //   let obj = {
  //     categoriesName: '',
  //     myAddress,
  //   }

  //   let files: any = await makeFileObjects(obj, '')

  //   const s3 = new S3({
  //     endpoint: params.endpoint,
  //     credentials: {
  //       accessKeyId: params.accessKey,
  //       secretAccessKey: params.secretKey,
  //     },
  //     region: 'us-west-2',
  //   })
  //   const reader = new FileReader()
  //   reader.readAsArrayBuffer(files)
  //   reader.onload = async () => {
  //     //文件数据
  //     const fileBody: any = reader.result
  //     const putObject = await s3.putObject({
  //       Bucket: bucket,
  //       Key: 'Nft/' + files.name,
  //       Body: fileBody,
  //     })
  //     console.log(putObject)
  //     s3.headObject(
  //       {
  //         Bucket: bucket,
  //         Key: 'Nft/' + files.name,
  //       },
  //       async (err, data: any) => {
  //         if (!err) {
  //           try {
  //             constant.ContractStore.methods
  //               .purchase(cid, Web3.utils.asciiToHex(files.name))
  //               .send({ from: myAddress })
  //               .on('transactionHash', function (hash: any) {
  //                 console.log(hash)
  //               })
  //               .on('receipt', async (receipt: any) => {
  //                 console.log('receipt', receipt)
  //                 setOnShow(true)
  //                 getCidData()
  //                 setSpinLoading(false)
  //               })
  //               .on('error', function (error: any, receipt: any) {
  //                 message.error({
  //                   content: error.message,
  //                   className: 'message-global',
  //                 })
  //                 setSpinLoading(false)
  //               })
  //           } catch (error) {
  //             setSpinLoading(false)
  //           }
  //         }
  //       },
  //     )
  //   }
  // }

  // const getBalance = async () => {
  //   const data: any = await constant.ContractToken.methods.balanceOf(myAddress).call()
  //   return data
  // }

  // const cast = async () => {
  //   setSpinLoading(true)
  //   setLoadingText('CastLoading')
  //   if (await isCast(proCid)) {
  //     if (amount < 1) {
  //       message.warning(t('admin.amount.warning'))
  //       setSpinLoading(false)
  //       return
  //     }
  //     if (Number(await getBalance()) < Number(priceValue)) {
  //       message.warning(t('market.message.tips4', { msg: 'USDT' }))
  //       setSpinLoading(false)
  //       return
  //     }

  //     const data: any = await constant.ContractToken.methods.allowance(myAddress, Store_ADDRESS).call()

  //     if (Number(data) >= Number(priceValue)) {
  //       openBox()
  //     } else {
  //       constant.ContractToken.methods
  //         .approve(Store_ADDRESS, priceValue)
  //         .send({
  //           from: myAddress,
  //         })
  //         .on('transactionHash', function (hash: any) {
  //           console.log(hash)
  //         })
  //         .on('receipt', async (receipt: any) => {
  //           openBox()
  //         })
  //         .on('error', function (error: any, receipt: any) {
  //           setSpinLoading(false)
  //         })
  //     }
  //   }
  // }

  // const copyAddress = (val: any) => {
  //   let aux = document.createElement('input')
  //   aux.setAttribute('value', val)
  //   document.body.appendChild(aux)
  //   aux.select()
  //   document.execCommand('copy')
  //   document.body.removeChild(aux)
  //   message.success({
  //     content: t('igonft.apply.copy.success'),
  //     className: 'message-global',
  //   })
  // }

  return (
    // <Spin className="mySpin" spinning={spinLoading} tip={loadingText}>
    <SetWrapper>
      {/* <ProjectTopBar name={name} background={`rgba(255,255,255,1)`} borderBottom={`1px solid rgba(229, 229, 229,1)`}></ProjectTopBar>
        <HomeContent>
          <Details>
            <div className="detailLeft">
              <div style={{ textAlign: 'center' }}>
                <img src={CRE} alt="" className="mainImg" />
              </div>
              {windowSize.innerWidth <= Adapth5 && (
                <MyLink>
                  <div className="topic">
                    {t('project.nft.detail.header')} {name}
                  </div>
                  <div className="name">
                    {t('project.nft.detail.name')} {name}
                  </div>
                  <div className="owner">
                    {t('project.nft.detail.owner')} {dataSource.myAddress && formatStrAddress(6, 4, dataSource.myAddress)}
                  </div>
                  <div className="des">
                    {can ? (
                      <>
                        <div className="heder" style={{ fontWeight: 'bold' }}>
                          <img src={price} alt="" />
                          {t('project.nft.detail.price')}
                        </div>
                        <div className="desTips">
                          <div className="price">
                            <img src={ICO} alt="" />{' '}
                            <span>{priceValue ? Number(nftData.toWeiFromWei(priceValue)).toFixed(6) : '0.000'} USDT</span>
                          </div>
                          <div className="tips">{t('project.nft.detail.attend', { msg: rateValue || 0 })}</div>
                          {active && account ? (
                            <div
                              className="linkBtn"
                              onClick={() => {
                                cast()
                              }}
                            >
                              {t('myproject.create')}
                            </div>
                          ) : (
                            <ConnectWallet status="proCreate" isTop={true} close={() => {}} />
                          )}
                          <div className="canCast">
                            {t('admin.cast')} {amount}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="nocan">
                          <img src={NO} alt="" />
                        </div>
                        <div className="nocanTips">{t('project.nft.detail.nocan')}</div>
                      </>
                    )}
                  </div>
                </MyLink>
              )}
              <div className="leftBottom">
                <div className="header">
                  <img src={features} alt="" style={{ marginRight: '10px' }} />
                  {t('project.nft.features')}
                </div>
                <div className="content">
                  <div className="contentItem">
                    <div className="name">{t('project.nft.contentItem1')}</div>
                    <div
                      className="value"
                      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                      onClick={() => copyAddress(SharedToken_ADDRESS)}
                    >
                      {formatStrAddress(6, 4, SharedToken_ADDRESS)}
                      <img
                        src={copy}
                        alt=""
                        style={{ height: windowSize.innerWidth > AdaptFontSize ? '1.5rem' : '2rem', marginLeft: '0.5rem' }}
                      />
                    </div>
                  </div>
                  <div className="contentItem">
                    <div className="name">{t('project.nft.contentItem2')}</div>
                    <div className="value">ERC721</div>
                  </div>
                  <div className="contentItem">
                    <div className="name">{t('project.nft.contentItem3')}</div>
                    <div className="value">{Blockchain}</div>
                  </div>
                  <div className="contentItem">
                    <div className="name">{t('project.nft.contentItem4')}</div>
                    <div className="value">{i18n.language === 'en' ? 'Carbon assets' : '碳资产'}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailRight">
              {windowSize.innerWidth > Adapth5 && (
                <MyLink>
                  <div className="topic">
                    {t('project.nft.detail.header')}
                    {name}
                  </div>
                  <div className="name">
                    {t('project.nft.detail.name')} {name}
                  </div>
                  <div className="owner">
                    {t('project.nft.detail.owner')} {dataSource.myAddress && formatStrAddress(6, 4, dataSource.myAddress)}
                  </div>
                  <div className="des">
                    {can ? (
                      <>
                        <div className="heder" style={{ fontWeight: 'bold' }}>
                          <img src={price} alt="" />
                          {t('project.nft.detail.price')}
                        </div>
                        <div className="desTips">
                          <div className="price">
                            <img src={ICO} alt="" />{' '}
                            <span>{priceValue ? Number(nftData.toWeiFromWei(priceValue)).toFixed(6) : '0.000'} USDT</span>
                          </div>
                          <div className="tips">{t('project.nft.detail.attend', { msg: rateValue || 0 })}</div>
                          {active && account ? (
                            <div
                              className="linkBtn"
                              onClick={() => {
                                cast()
                              }}
                            >
                              {t('myproject.create')}
                            </div>
                          ) : (
                            <ConnectWallet status="proCreate" isTop={true} close={() => {}} />
                          )}
                          <div className="canCast">
                            {t('admin.cast')} {amount}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="nocan">
                          <img src={NO} alt="" />
                        </div>
                        <div className="nocanTips">{t('project.nft.detail.nocan')}</div>
                      </>
                    )}
                  </div>
                </MyLink>
              )}
              <div className="des">
                <div className="heder">
                  <img src={windowSize.innerWidth > Adapth5 ? des : des1} alt="" />
                  {t('project.nft.detail.des')}
                </div>
                <div className="desTips">
                  {dataSource.description ? (
                    dataSource.description
                  ) : (
                    <div className="nodes">
                      <img src={noDes} alt="" />
                      <div>{i18n.language === 'en' ? 'No Data' : '暂无信息'}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="des">
                <div className="heder">
                  <img src={windowSize.innerWidth > Adapth5 ? detail : detail1} alt="" />
                  {t('project.nft.detail.dataDetail')}
                </div>
                <Spin spinning={metaLoading} tip={'loading'}>
                  <div className="metaContent">
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.country')}</div>
                      <div className="detailValue">{orgData?.nation || '--'}</div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.num')}</div>
                      <div className="detailValue">{orgData?.serialNum || '--'}</div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.stan')}</div>
                      <div className="detailValue">{orgData?.standard || '--'}</div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.amount')}</div>
                      <div className="detailValue">{orgData?.num || '--'}</div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.type')}</div>
                      <div className="detailValue">{orgData?.type || '--'}</div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.time')}</div>
                      <div className="detailValue">{orgData?.cycle || '--'}</div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.proName')}</div>
                      <div className="detailValue">{orgData?.name || '--'}</div>
                    </div>
                    <div className="metaItem">
                      <div className="detailTitle">{t('project.nft.detail.proId')}</div>
                      <div className="detailValue">{orgData?.id || '--'}</div>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </Details>
        </HomeContent>
        <Modal
          visible={onShow}
          style={{ borderRadius: '0.63rem' }}
          footer={null}
          onCancel={() => {
            getPrice(proCid)
            setOnShow(false)
          }}
          width="36.88rem"
          wrapClassName="wallets"
          centered
          afterClose={() => {}}
        >
          <CastModal>
            <img src={Succ} alt="" />
            <div className="topic">{t('admin.amount.success')}</div>
            <div className="tips">{t('admin.amount.tips')}</div>
            <div
              className="castBtn"
              onClick={() => {
                history.replace('./mynft')
              }}
            >
              {t('admin.amount.btn')}
            </div>
          </CastModal>
        </Modal>
        <ProjectFooter name={name} /> */}
    </SetWrapper>
    // </Spin>
  )
})
