const privacy = {
  'privacy.title': 'Privacy policy',
  'privacy.content1':
    "Carbon-Exchange protects users' privacy and personal information very seriously. When you use our products/services, we may collect and use your relevant information. Our Carbon-Exchange Privacy Policy, referred to as the 'Privacy Policy' , explains how we collect, use, save, disclose and share the products and services you provide using our website and mobile devices.",
  'privacy.content2':
    'We collect and use your personal information to relevant laws, regulations, and technical specifications to help us provide you with better products and services. Please read and fully understand this guide before using the Carbon-Exchange. Please note that we will only begin collecting, processing, and storing your personal information after confirming your consent.',
  'privacy.content3':
    "Suppose, in a particular scenario, you use the Carbon-Exchange in the way of 'no connect wallet' In that case, we will try our best to reduce the information collection and provide you with browsing functions, but this will prevent you from entirely using the other parts of the Carbon-Exchange: feature or service. If you do not agree to the collection of any of your personal information by the Carbon-Exchange, please stop using it immediately and exit the Carbon-Exchange.",
  'privacy.content4':
    'Except as otherwise provided in this Privacy Policy, this application will not disclose or provide this information to third parties without your prior permission. This app will update the privacy policy from time to time. You agree to the entire content of this application policy. This privacy policy is an integral part of this application service use agreement.',
  'privacy.content5': '1. Scope of application',
  'privacy.content6':
    '(1) When you use this application service, you link your blockchain address according to the requirements of this application.',
  'privacy.content7':
    '(2) This application automatically receives and records your browsing and information on your computer, including but not limited to your IP address, browser type, language used, date and time of access, software and hardware feature information, and the webpage you need Records and other data when you use the web services of this application or visit the web pages of this application platform;',
  'privacy.content8': '(3) This application obtains personal user data from business partners through legal channels.',
  'privacy.content9': ' 2. Information use',
  'privacy.content10':
    '(1) This application will not provide, sell, rent, share or trade your personal information to any unrelated third party unless you have obtained your permission in advance or the third party and this application (including this application affiliates) have jointly acted independently. You provide the service, and after the service ends, it will be prohibited from accessing all such materials, including those it previously could access.',
  'privacy.content11':
    '(2) This application does not allow any third party to collect, edit, sell or disseminate your personal information. If any user of this application platform engages in the above activities, once discovered, this application has the right to terminate the service agreement with the user immediately.',
  'privacy.content12':
    '(3) to serve users, this application may use your personal information to provide you with information that is of interest to you, including but not limited to sending you product and service information or sharing information with the application partners for their convenience. To send you information about its products and services (the latter requires your prior consent).',
  'privacy.content13': '3. Information disclosure',
  'privacy.content14':
    'In the following cases, this application will disclose your personal information in whole or in part according to your reasons or the provisions of the law:',
  'privacy.content15': '(1) Disclosure to third parties with your prior consent;',
  'privacy.content16':
    '(2) To provide the products and services you have requested, you must share your personal information with the company;',
  'privacy.content17':
    '(3) According to the relevant provisions of laws and regulations or the requirements of administrative or judicial institutions, disclose to third parties or administrative or judicial institutions;',
  'privacy.content18':
    '(4) If you violate relevant laws, regulations, or this application service agreement or relevant rules, you need to disclose it to a third party;',
  'privacy.content19':
    '(5) If you are a qualified complainant of instructing property rights and have filed a lawsuit, you should disclose it to the respondent at the request of the respondent so that both parties can deal with possible rights disputes;',
  'privacy.content20':
    '(6) In a transaction created on this application platform, if any party to the transaction fulfills the transaction obligations and requests information disclosure, this application has the right to decide to submit the contact information of the counterparty to the user if necessary. Information to facilitate the completion of a transaction or dispute resolution.',
  'privacy.content21': '(7) Other disclosures this application deems appropriate by-laws, regulations, or website policies.',
  'privacy.content22': ' 4. Information storage and exchange',
  'privacy.content23':
    'The information and data about you collected by this application will be saved on the servers of this application and its affiliates, and this information and data may be transmitted to your country, region, or outside the location where this application collects information and data and in Accessed, stored and displayed abroad.',
  'privacy.content24': '5. Information Security',
  'privacy.content25':
    "(1) This app account has security protection functions; please keep your private key and recovery phrase properly. This application uses physical, administrative, and technical protection measures to ensure that your information is not lost, misused, or altered. Despite the security above criteria, please note that there are no 'perfect security measures' on the information network.",
  'privacy.content26':
    '(2) You are responsible for the security of your third-party wallet, and we urge you to take steps to keep it safe. If you find a problem with your wallet, please contact your wallet provider.',
  'privacy.content27': '6. Changes to this Privacy Policy',
  'privacy.content28':
    '(1) If we decide to change the privacy policy, we will post these changes in this policy on our website and where we deem appropriate so that you can understand how we collect and use your personal information and who can access this information, and under what circumstances we disclose this information.',
  'privacy.content29':
    '(2) The company reserves the right to revise the policy at any time, so check it frequently. If there are significant changes to this policy, the company will notify you through the website notice',
}

export default privacy
