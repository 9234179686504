import styled from 'styled-components'
import { ComMyLayout } from '@/common/styled'

export const CreateWrapper = styled(ComMyLayout)`
  max-width: 1200px;
  margin: 0 auto;
  min-height: 80vh;
  padding-top: 5rem;
  ${({ theme }) => theme.mediaWidth.screenMd`
      padding: 3rem 20px 0 20px;
  `}
`

export const CreateWalletWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
`

export const CreateTitle = styled.div`
  /* margin-top: 5rem; */
  ${({ theme }) => theme.mediaWidth.screenMd`
      margin-top: 2rem;
  `}
  width: 100%;
  height: 6.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #00477c;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
  `}
`

export const CreateContent = styled.div`
  width: 100%;
  position: relative;
  background-color: #f7f8fa;
  padding: 40px 0;
  .span {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #5d6175;
    ${({ theme }) => theme.mediaWidth.screenMd`
     font-size: 3rem;
  `}
    .int {
      font-size: 1.125rem;
      color: #87868d;
      text-align: center;
      margin: 0 auto;
      line-height: 2.5rem;
      ${({ theme }) => theme.mediaWidth.screenMd`
      padding:0 0.625rem;
      font-size: 1.875rem;
  `}
    }
  }
`
