const validation = {
  'validation.title': '官方验证',
  'validation.content1': 'Carbon-Exchange',
  'validation.content2': '官方验证通道',
  'validation.placeholder': '输入查询条目',
  'validation.btn': '查询',
  'validation.content3': '请通过Carbon-Exchange官方验证渠道检查账号来源是否来自Carbon-Exchange官方。 ',
  'validation.content6': '支持通过以下方式验证：',
  'validation.content4': '电子邮件地址、电话号码、微信 ID、Twitter 帐户或 Telegram ID。',
  'validation.content5': '请不要与任何非官方渠道联系或泄漏您的账号信息，远离诈骗。',
}

export default validation
