import { Web3Storage } from 'web3.storage/dist/bundle.esm.min.js'
import CID from 'cids'
import Mock from 'mockjs'

export interface IIeoData {
  walletAddress: string
  email: string
  phone: string
  country: string
  serialNum: string
  projectOffcialLink: string
  standard: string
  amount: string
  type: string
  vintage: string
  projectName: string
  projectID: string
  ieoName: string
}
export interface StorageClientTypes {
  client: typeof Web3Storage
  storeFilesPapers: (file: any, client: any, type: string) => void
  makeFileObjects: (obj: object, name: string) => void
  retrieveFiles: (cid: any, client: any) => boolean
}

class StorageClient {
  client: typeof Web3Storage
  constructor(token: string) {
    this.client = new Web3Storage({ token })
  }

  async storeFilesPapers(file: any, client: any, type: string) {
    try {
      const ext = file.name.split('.').pop()
      const fileName = `${Mock.mock('@natural')}.${ext}`
      const newFile = new File([file], fileName, { type })
      const s = await client.put([newFile], { name: fileName })
      let cid = new CID(s).toV0().toString('base58btc')
      return { cid, url: `https://ipfs.io/ipfs/${s}/${fileName}`, s }
    } catch (error) {
      console.log('error', error)
    }
  }

  makeFileObjects(obj: object, name: string) {
    const blob = new Blob([JSON.stringify(obj)], { type: 'application/json' })
    const files = new File([blob], name + '_' + Date.now() + '_' + Math.ceil(Math.random() * 1000000000))
    return files
  }

  getImage() {}

  async retrieveFiles(cid: any, client: any) {
    const res = await client.get(cid)
    return res
  }
}
export default StorageClient
