import React, { memo, useEffect } from 'react'
import { VaultWrapper, VaultTitle, Title, VaultContent } from './styled'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default memo(function ValidationPage() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VaultWrapper>
      <VaultTitle>
        <Title>
          <Link to={'/validation'}>{t('validation.title')}</Link>
        </Title>
      </VaultTitle>
      <VaultContent>
        <div className="topic">
          <span>{t('validation.content1')}</span> {t('validation.content2')}
        </div>
        <div className="input">
          <input placeholder={t('validation.placeholder')} />
          <div className="query">{t('validation.btn')}</div>
        </div>
        <div className="des">
          {t('validation.content3')} <br />
          {t('validation.content6')}
          <span>{t('validation.content4')}</span> <br />
          {t('validation.content5')}
        </div>
      </VaultContent>
    </VaultWrapper>
  )
})
