import React, { memo, useEffect, useState } from 'react'
import { Carousel, message, Select, Spin } from 'antd'
import { HomeWrapper, Experience, StyledNavLink, Project, Dao, More } from './styled'
import { AdaptFontSize, scrollToAnchor, showMenu } from '@/utils'
import TopBar from '@/components/TopBar'
import { useTranslation } from 'react-i18next'
import { useHomeHooks1 } from '@/hooks/useHomeHooks1'
import IEO from '@/assets/home/an_igo.gif'
import Pro from '@/assets/home/an_pro.gif'
import DAO from '@/assets/home/dao.png'
import MORE from '@/assets/home/more.png'
import { useHistory } from 'react-router-dom'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import ConnectWallet from '@/components/ConnectWallet'
import IgoApply from '@/components/IgoApply'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import NoData from '@/components/NoData'
import HomeCard from '@/components/HomeCard'
const { Option } = Select

export default memo(function HomePages(pages: any) {
  let history = useHistory()
  const context = useWeb3React<Web3Provider>()
  const { active, account } = context
  const { t, i18n } = useTranslation()
  const { windowSize } = useWindowSizeHooks()
  const [type, setType] = useState('0')
  const [isApply, setIsApply] = useState(false)
  const [loadingText, setLoadingText] = useState<string>('Loading...')
  const { homeList, spinLoading } = useHomeHooks1()

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const search = pages.location.search
    if (search) {
      let listSearch = search.slice(1).split('&')
      let key = listSearch[0].split('=')[1]

      if (key === 'create') {
        setIsApply(true)
      } else {
        setIsApply(false)
      }
    }
    let hash = pages.location.hash
    if (hash.length > 0) {
      let str = hash.substr(1)
      scrollToAnchor(str)
      // window.scrollTo(0, document.body.scrollHeight)
    } else {
      window.scrollTo(0, 0)
    }
    return () => {
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages])

  const handleChange = (e: any) => {
    setType(e)
  }

  return (
    <HomeWrapper>
      <div className="homeTopBar">
        <TopBar />
      </div>
      {!isApply ? (
        <>
          {/* banner */}
          <div className="banner">
            <div className="bannerContent">
              <div className="name">
                {t('home.banner.name1')}
                <br />
                {t('home.banner.name2')}
              </div>
              <div className="bannerTips">{t('home.banner.tips')}</div>
              {!active && (
                <div className="bannerBtn">
                  <ConnectWallet isTop={false} status="homeBar" close={() => {}} />
                </div>
              )}
              {active && (
                <div className="bannerBtn">
                  <div style={{ height: '3.75rem' }} />
                </div>
              )}
            </div>
          </div>
          {/* experience */}
          <div
            style={{
              backgroundColor: '#f7f8fa',
            }}
          >
            <Experience>
              <div className="experTitle">{t('home.exper.title')}</div>
              <div className="experContent">
                <div className="item">
                  <img src={IEO} alt="" />
                  <div className="itemName">IEO</div>
                  <div className="itemTips">{t('home.igo.tips')}</div>
                  <div
                    className="itemBtn"
                    onClick={() => {
                      if (account) {
                        window.scrollTo(0, 0)
                        setIsApply(true)
                      } else {
                        message.warning(t('home.banner.tips1'))
                      }
                    }}
                  >
                    {t('home.igo.btn')}
                  </div>
                </div>
                <div className="item project">
                  <img src={Pro} alt="" />
                  <div className="itemName">{t('home.pro')}</div>
                  <div className="itemTips">{t('home.pro.tips')}</div>
                  <div className="itemBtn">
                    <StyledNavLink to={'/create'} className="alink">
                      {t('home.pro.btn')}
                    </StyledNavLink>
                  </div>
                </div>
              </div>
            </Experience>
          </div>
          {/* project */}
          <Project>
            <div className="proTitle">{t('home.show.title')}</div>
            <div className="listMenu">
              {windowSize.innerWidth > AdaptFontSize ? (
                <>
                  {showMenu.map((item) => {
                    return (
                      <div
                        className={type === item.index - 1 + '' ? 'menuItem active' : 'menuItem'}
                        onClick={() => {
                          setType(String(item.index - 1))
                        }}
                      >
                        {i18n.language === 'en' ? item.enName : item.name}
                      </div>
                    )
                  })}
                  <div
                    className="menuItem"
                    onClick={() => {
                      history.replace('./igonft')
                    }}
                  >
                    {i18n.language === 'en' ? 'More' : '更多 >'}
                  </div>
                </>
              ) : (
                <>
                  <Select
                    id="homeMySele"
                    className="mySelect homeMySele"
                    value={type}
                    style={{ width: '21.125rem', textAlign: 'center' }}
                    onChange={handleChange}
                  >
                    <Option value="0">{i18n.language === 'en' ? showMenu[0].enName : showMenu[0].name}</Option>
                    {/* <Option value="1">{i18n.language === 'en' ? showMenu[1].enName : showMenu[1].name}</Option>
                    <Option value="2">{i18n.language === 'en' ? showMenu[2].enName : showMenu[2].name}</Option>
                    <Option value="3">{i18n.language === 'en' ? showMenu[3].enName : showMenu[3].name}</Option> */}
                  </Select>
                </>
              )}
            </div>
            {windowSize.innerWidth > AdaptFontSize && (
              <Spin spinning={spinLoading} tip={loadingText} className="antd-loadings">
                <div className="proList">
                  {homeList.filter((itm: any) => {
                    if (itm.carbonType + '' === type) {
                      return itm
                    }
                  }).length > 0 ? (
                    homeList
                      .filter((itm: any, index: number) => {
                        if (itm.carbonType + '' === type) {
                          return itm && index < 4
                        }
                      })
                      .map((item: any, index: number) => {
                        return (
                          <div
                            key={item.create_time}
                            className="proItem"
                            onClick={() => {
                              if (item?.account) {
                                localStorage.setItem('account', item?.account)
                              }

                              if (item?.id) {
                                localStorage.setItem('itemDetail_id', item?.id)
                                history.replace('/projectHome?key=' + item?.id)
                              }
                            }}
                          >
                            <HomeCard detail={item} index={index} />
                          </div>
                        )
                      })
                  ) : (
                    <NoData top={1.5} />
                  )}
                </div>
              </Spin>
            )}
            {windowSize.innerWidth <= AdaptFontSize && (
              <Spin spinning={spinLoading} tip={loadingText} className="antd-loadings">
                <div style={{ padding: '0 2rem' }}>
                  <Carousel dotPosition="bottom">
                    {homeList.filter((itm: any) => {
                      if (itm.carbonType + '' === type) {
                        return itm
                      }
                    }).length > 0 ? (
                      homeList
                        .filter((itm: any, index: any) => {
                          if (itm.carbonType + '' === type) {
                            return itm && index < 4
                          }
                        })
                        .map((item: any, index: number) => {
                          return (
                            <div
                              key={item.create_time}
                              className="proItem"
                              onClick={() => {
                                if (item?.account) {
                                  localStorage.setItem('account', item?.account)
                                }

                                if (item?.id) {
                                  localStorage.setItem('itemDetail_id', item?.id)
                                  history.replace('/projectHome?key=' + item?.id)
                                }
                              }}
                            >
                              <HomeCard detail={item} index={index} />
                            </div>
                          )
                        })
                    ) : (
                      <NoData top={windowSize.innerWidth <= AdaptFontSize ? 0 : 6} />
                    )}
                  </Carousel>
                </div>
              </Spin>
            )}
          </Project>
          {/* DAO */}
          <Dao>
            <div className="daoTitle">{t('home.dao.title')}</div>
            {/* <div className="daoTips">{t('home.dao.tips')}</div> */}
            {/* <div className="daoBtn">
              <StyledNavLink to={'/governance'} className="alink" style={{ fontSize: '1.5rem' }}>
                {t('home.dao.btn')}
              </StyledNavLink>
            </div> */}
            <div className="daoContent">
              {windowSize.innerWidth > AdaptFontSize && (
                <div className="left">
                  <div className="tag">{t('home.dao.tag1')}</div>
                  <div className="tag">{t('home.dao.tag2')}</div>
                </div>
              )}
              <img src={DAO} alt="" />
              {windowSize.innerWidth > AdaptFontSize && (
                <div className="right">
                  <div className="tag">{t('home.dao.tag3')}</div>
                  <div className="tag">{t('home.dao.tag4')}</div>
                </div>
              )}
            </div>
          </Dao>
          {/* MORE */}
          <More>
            <div className="daoTitle">{t('home.more.title')}</div>
            <div className="daoTips">{t('home.more.tips')}</div>
            {/* <div className="daoBtn">
              <StyledNavLink to={'/governance'} className="alink" style={{ fontSize: '1.5rem' }}>
                {t('home.more.btn')}
              </StyledNavLink>
            </div> */}
            <div className="daoContent">
              <img src={windowSize.innerWidth > AdaptFontSize ? MORE : DAO} alt="" />
            </div>
          </More>
        </>
      ) : (
        <>
          <IgoApply
            changeData={() => {
              setIsApply(false)
            }}
          />
        </>
      )}
    </HomeWrapper>
  )
})
