import React, { memo, useEffect } from 'react'
import { VaultWrapper, VaultTitle, Title, VaultContent } from './styled'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default memo(function AboutPage() {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VaultWrapper>
      <VaultTitle>
        <Title>
          <Link to={'/about'}>{t('about.title')}</Link>
        </Title>
      </VaultTitle>
      <VaultContent>
        <div className="mainContent">
          <div>{t('about.content')}</div>
          <div style={{ margin: '1rem 0' }}>{t('about.content1')}</div>
          <div>{t('about.content2')}</div>
        </div>
      </VaultContent>
    </VaultWrapper>
  )
})
