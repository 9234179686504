import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const SideMenuWrapper = styled.div`
  .tabbar-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`

const activeClassName = 'ACTIVE'
export const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.gray};
  text-align: center;
  line-height: 6rem;
  .navlink-content {
    font-size: 1.75rem;
    line-height: 1.5rem;
    padding: 0 1.63rem;
    color: ${(props) => props.theme.gray};
  }
  .navlink-title {
    font-size: 1.75rem;
    /* font-weight: bold; */
    color: ${(props) => props.theme.gray};
    line-height: 1.5rem;
    padding: 0 1.63rem;
  }
  .navlink-child-title {
    font-size: 1.5rem;
    color: #00477c;
    /* padding: 0 1.63rem; */
    &:hover {
      color: #00477c !important;
      font-weight: 400;
      content: none;
    }
  }
  &.${activeClassName} {
    color: #00477c;
    font-weight: 600;
    .navlink-child-title {
      color: #00477c;
      font-weight: 600;
    }
  }
  &:hover {
    color: #00477c !important;
    font-weight: 400;
    content: none;
  }
`

export const MyDrawer = styled.div`
  padding: 2.0625rem 2.75rem;
  .close {
    display: flex;
    justify-content: end;
  }
  .myMarket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00477c;
    font-size: 1.5rem;
    line-height: 6rem;
  }
  .marketItem {
    color: #5d6175;
    font-size: 1.125rem;
    line-height: 5rem;
  }
`
