import styled, { css } from 'styled-components'
import { ComMyLayout } from '@/common/styled'
import { Row } from 'antd'

export const IGOnftWrapper = styled(ComMyLayout)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 0 20px 0;
  ${({ theme }) => theme.mediaWidth.screenXl`
      padding: 6rem 20px 20px;
  `}

  .igo-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
    .ant-btn {
      width: auto !important;
    }
  }
  .details-igonft {
    .details-igonft-left {
      display: flex;
      align-items: center;
      padding-top: 1.25rem;
      flex-direction: column;
      margin-bottom: 2.5rem;
      .ant-image {
        width: 100%;
        border-radius: 1.25rem;
        height: auto;
        box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
      }
      .ant-image-img {
        border-radius: 1.25rem;
      }
    }
    .details-igonft-right {
      display: flex;
      flex-direction: column;
      padding-top: 1.25rem;
      h2 {
        font-size: 1.88rem;
        margin: 1.69rem 0;
        font-weight: 700;
      }
      h4 {
        font-size: 1rem;
        font-weight: 400;
        color: #00477c;
        margin-bottom: 1.88rem;
      }
    }
  }
  .igonft-two {
    ${(props) =>
      props.theme.mediaWidth.screenXl(
        () => css`
          margin: 0 2.5rem;
        `,
      )}
    ${(props) =>
      props.theme.mediaWidth.screenLg(
        () => css`
          margin: 0 1.13rem;
        `,
      )}
  }
`

export const IGOnftTitle = styled.div`
  height: 6.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 1.88rem; */
  a {
    color: #00477c;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
  `}
`

export const IGOnftContent = styled.div`
  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .listItem {
      border: 1px solid #dedede;
      &:hover {
        box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
      }
      border-radius: 1rem;
      cursor: pointer;
      width: calc((100% - 5rem) / 4);
      margin-bottom: 2.375rem;
      ${({ theme }) => theme.mediaWidth.screenMd`
    width: calc((100% - 2rem) / 2);
  `}
      .ant-image,.ant-image-img {
        width: 100%;
        height: 150px;
        border-radius: 1rem 1rem 0 0;
        object-fit: cover;
        ${({ theme }) => theme.mediaWidth.screenMd`
     height: 9.375rem;
  `}
      }
      .name {
        color: #5d6175;
        font-size: 1.25rem;
        padding: 1rem 5px 0 5px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .type {
        color: #9596a5;
        font-size: 0.875rem;
        padding: 0 0.3125rem 0.3125rem 0.3125rem;
        text-align: center;
        /* border-radius: 0 0 1rem 1rem; */
      }
    }
    .blank {
      width: calc((100% - 5rem) / 4);
    }
  }
`

export const ContentList = styled(Row)`
  margin-top: 2.5rem;
  .ant-col {
    margin-bottom: 4.38rem;
  }
  .ant-cols {
    flex-direction: row;
    display: flex;
    height: 17.5rem;
    border: 0.06rem solid #5746fe;
    border-radius: 0.63rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .ant-image {
      width: 95%;
      margin: 0 2.5%;
      max-width: 12.5rem;
      height: auto;
      max-height: 12.5rem;
      border-radius: 0.63rem;
      .ant-image-img {
        border-radius: 0.63rem;
      }
    }
    .right {
      width: 95%;
      margin: 0 2.5%;
      span {
        font-size: 1.25rem;
        font-weight: 400;
        color: #00477c;
        line-height: 1.56rem;
      }
      p {
        font-size: 0.88rem;
        font-weight: 400;
        color: #00477c;
        line-height: 1.56rem;
      }
    }
    .ant-col {
      margin-bottom: 0;
    }
  }
`
