import React, { memo, useEffect, useState, useRef } from 'react'
import {
  ConnectWalletWrapper,
  ModalTitle,
  WalletTitleAddress,
  StyledNavLink,
  AccountMoveWrapper,
  AccountContent,
  WalletDivCreate,
  MyWallet,
  WalletContent,
  WalletContent1,
} from './styled'
import { Button, Image, Row, Col, message, Drawer } from 'antd'
import HEAD from '@/assets/page_slices/head.png'
import WALLET from '@/assets/page_slices/wallet.png'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useDispatch, useSelector } from 'react-redux'
import { SetActivaing } from '@/store/connector/action'
import { connectorsByName, defaultChainId, getActiveChainId, RPC_URLS } from '@/contracts/constant'
import { useTranslation } from 'react-i18next'
import { formatStrAddress, AdaptFontSize } from '@/utils'
import { netWorks } from '@/contracts/constant'
import { SaveIsLogin, SaveWallet, SaveNetwork } from '@/store/wallet/action'
import { walletInit } from '@/contracts/init'
import { getErrorMessage } from '@/hooks/useErrorHooks'
import { SaveAddress } from '@/store/user/action'
import { CloseOutlined } from '@ant-design/icons'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import { useHistory } from 'react-router-dom'
import DOWN from '@/assets/phone_slices/down.png'
import AVA from '@/assets/phone_slices/ava.png'

interface Type {
  status?: string
  isTop: boolean
  close: Function
}

export default memo(function ConnectWalletPage(props: Type) {
  const { close } = props
  const { REACT_APP_ENV = 'prd' } = process.env
  // @ts-ignore
  const { ethereum } = window
  let history = useHistory()
  const modalRef = useRef<any>(null)

  const { status = '', isTop } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [onShow, setOnShow] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showInfo, setShowInfo] = useState(false)

  const context = useWeb3React<Web3Provider>()
  const { activate, active, error, library, deactivate, account } = context

  const walletInfo = useSelector((state: any) => state.walletInfo)
  const myAddress = useSelector((state: any) => state.userInfo.address)

  const [moveSwitch, setMoveSwitch] = useState(false)
  const [isNetWork, setIsNetWork] = useState<boolean>(() => getActiveChainId(RPC_URLS, walletInfo.network))
  const { windowSize } = useWindowSizeHooks()

  useEffect(() => {
    setIsNetWork(getActiveChainId(RPC_URLS, walletInfo.network))
    return setIsNetWork(getActiveChainId(RPC_URLS, walletInfo.network))
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletInfo.network])

  useEffect(() => {
    setMoveSwitch(false)
    return setMoveSwitch(false)
  }, [active])

  useEffect(() => {
    setLoading(false)
    if (active) setOnShow(false)
    if (!active) {
      dispatch(SaveIsLogin(false))
      dispatch(SaveWallet('NetWork'))
      dispatch(SaveAddress(''))
      localStorage.removeItem('isLogin')
      localStorage.removeItem('wallet')
      // let pathname = history.location.pathname
      // if (pathname === '/information' || pathname === '/mynft' || pathname === '/myproject') {
      //   history.replace('/home')
      // }
    }
    return () => {
      setLoading(false)
      setOnShow(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, error, library])

  useEffect(() => {
    if (account) dispatch(SaveAddress(account))
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const onConnect = async ({ src }: any) => {
    const currentConnector = connectorsByName[src]
    if (src === 'Injected') {
      dispatch(SetActivaing(currentConnector))
      setInjectedNetWorks(walletInfo.network)
      return false
    } else if (src === 'WalletConnect') {
      activate(
        connectorsByName['WalletConnect']({ [walletInfo.network]: RPC_URLS[walletInfo.network] }, walletInfo.network),
        undefined,
        true,
      )
        .then(() => {
          setLoading(false)
          dispatch(SaveIsLogin(true))
          dispatch(SaveWallet(src))
          localStorage.setItem('wallet', src)
          localStorage.setItem('isLogin', 'true')
          message.success({
            content: t('app.link.suceess'),
            className: 'message-global',
          })
        })
        .catch(async (error) => {
          let msg = getErrorMessage(error)
          await deactivate()
          message.error({
            content: msg,
            className: 'message-global',
          })
          setLoading(false)
          connectorsByName[src].walletConnectProvider = undefined
        })
    } else {
      activate(connectorsByName[src], undefined, true)
        .then(() => {
          setLoading(false)
          dispatch(SaveIsLogin(true))
          dispatch(SaveWallet(src))
          localStorage.setItem('wallet', src)
          localStorage.setItem('isLogin', 'true')
          message.success({
            content: t('app.link.suceess'),
            className: 'message-global',
          })
        })
        .catch(async (error) => {
          let msg = getErrorMessage(error)
          await deactivate()
          message.error({
            content: msg,
            className: 'message-global',
          })
          setLoading(false)
          if (src === 'WalletConnect') connectorsByName[src].walletConnectProvider = undefined
        })
    }
  }

  const setInjectedNetWorks = (objChainId: string | number) => {
    return changeInjectedNetwork(objChainId)
      .then(async () => await activeInjectedChange())
      .catch((error) => {
        console.log('err', error.message)
        message.error({
          content: error.message,
          className: 'message-global',
        })
        setLoading(false)
      })
  }

  const changeInjectedNetwork = (objChainId: any) => {
    return new Promise(async (resolve: any, reject) => {
      // @ts-ignore
      const { ethereum } = window
      let obj: any = isNetWork ? netWorks[objChainId] : netWorks[defaultChainId]

      if (ethereum && ethereum.isMetaMask && obj) {
        if (obj.isSwitch)
          ethereum
            .request({ method: 'wallet_switchEthereumChain', params: [{ chainId: obj.chainId }] })
            .then(() => setTimeout(resolve, 500))
            .catch((err: any) => reject(err))
        else
          ethereum
            .request({ method: 'wallet_switchEthereumChain', params: [{ chainId: obj.chainId }] })
            .then(() => setTimeout(resolve, 500))
            .catch((switchError: any) => {
              if (switchError.code === 4902)
                ethereum
                  .request({ method: 'wallet_addEthereumChain', params: [netWorks[objChainId]] })
                  .then(() => setTimeout(resolve, 500))
                  .catch((err: any) => reject(err))
              else reject(switchError)
            })
      } else resolve()
    })
  }

  const activeInjectedChange = async () => {
    activate(connectorsByName['Injected'], undefined, true)
      .then(() => {
        dispatch(SaveIsLogin(true))
        dispatch(SaveWallet('Injected'))
        setLoading(false)
        localStorage.setItem('wallet', 'Injected')
        localStorage.setItem('isLogin', 'true')
        message.success({
          content: t('app.link.suceess'),
          className: 'message-global',
        })
      })
      .catch(async (error) => {
        let msg = getErrorMessage(error)
        await deactivate()
        setLoading(false)
        message.error({
          content: msg,
          className: 'message-global',
        })
      })
  }

  /** choose Wallet */
  const switchWalletConnect = async (src: any) => {
    onConnect({ src })
    setLoading(true)
  }

  const switchNetWork = (str: any) => {
    localStorage.setItem('chainId', str)
    dispatch(SaveNetwork(str))
  }

  const loginOut = async () => {
    await deactivate()
    dispatch(SaveIsLogin(false))
    dispatch(SaveAddress(''))
    dispatch(SaveWallet('NetWork'))
    localStorage.removeItem('isLogin')
    localStorage.removeItem('wallet')
    localStorage.removeItem('currentConnector')
    message.info({
      content: t('app.link.disconnect'),
      className: 'message-global',
    })
    let pathname = history.location.pathname
    if (pathname === '/information' || pathname === '/mynft' || pathname === '/myproject') {
      history.replace('/home')
    }
  }

  const OnMouseOverDome = () => {
    return (
      <AccountMoveWrapper>
        <div style={{ width: '100%', height: '0.75rem' }}></div>
        <AccountContent>
          {/* <StyledNavLink className="myMenu" to={'/information'}>
            <div>{t('app.my.title1')}</div>
          </StyledNavLink> */}
          <StyledNavLink className="myMenu" to={'/mynft'}>
            <div>{t('app.my.title2')}</div>
          </StyledNavLink>
          <StyledNavLink className="myMenu" to={'/myproject'}>
            <div>{t('app.my.title4')}</div>
          </StyledNavLink>
          <StyledNavLink
            className="myMenu"
            to={{}}
            onClick={() => {
              loginOut()
            }}
          >
            <div>{t('app.my.title3')}</div>
          </StyledNavLink>
        </AccountContent>
      </AccountMoveWrapper>
    )
  }

  const OnMouseOverH5Dome = () => {
    return (
      <Drawer
        key="h5"
        className="drawer-account"
        placement="bottom"
        onClose={() => setMoveSwitch(false)}
        visible={moveSwitch}
        height="50%"
        closeIcon={<CloseOutlined style={{ color: 'black' }} />}
      >
        <div className="drawer-content">
          {/* <StyledNavLink to={'/information'} onClick={() => setMoveSwitch(false)}>
            {t('app.my.title1')}
          </StyledNavLink> */}
          <StyledNavLink to={'/mynft'} onClick={() => setMoveSwitch(false)}>
            {t('app.my.title2')}
          </StyledNavLink>
          <StyledNavLink to={'/myproject'} onClick={() => setMoveSwitch(false)}>
            {t('app.my.title4')}
          </StyledNavLink>
          <StyledNavLink to={{}} onClick={() => loginOut()}>
            {t('app.my.title3')}
          </StyledNavLink>
        </div>
      </Drawer>
    )
  }

  const WalletDiv = () => (
    <>
      <ModalTitle>
        {walletInit
          .filter((item) => {
            if (!ethereum) return item.name !== 'Metamask'
            return true
          })
          .map((item, index) => (
            <div className="choose-info" key={index} onClick={() => switchWalletConnect(item.link)}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Image width={30} src={item.icon} preview={false} />
                <span className="choose-span" style={{ marginLeft: '10px' }}>
                  {item.name}
                </span>
              </div>
              {item.name === 'Metamask' && <div className="hot">{t('create.hot')}</div>}
            </div>
          ))}
      </ModalTitle>
    </>
  )

  const WalletDiv1 = () => (
    <>
      <Row gutter={[16, 32]}>
        <Col span={24}>
          <ModalTitle>
            <Row style={{ padding: '20px 0 0 0' }}>
              {walletInit
                .filter((item) => {
                  if (!ethereum) return item.name !== 'Metamask'
                  return true
                })
                .map((item, index) => (
                  <Col span={12} className="choose-info" md={{ span: 24 }} key={index} onClick={() => switchWalletConnect(item.link)}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Image width={30} src={item.icon} preview={false} />
                      <span className="choose-span" style={{ marginLeft: '10px' }}>
                        {item.name}
                      </span>
                    </div>
                    {item.name === 'Metamask' && <div className="hot">{t('create.hot')}</div>}
                  </Col>
                ))}
            </Row>
          </ModalTitle>
        </Col>
      </Row>
    </>
  )

  const WalletDiv2 = () => (
    <>
      <ModalTitle className="h5Wall">
        {walletInit
          .filter((item) => {
            if (!ethereum) return item.name !== 'Metamask'
            return true
          })
          .map((item, index) => (
            <div className="choose-info" key={index} onClick={() => switchWalletConnect(item.link)}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Image width={30} src={item.icon} preview={false} />
                <span className="choose-span" style={{ marginLeft: '10px' }}>
                  {item.name}
                </span>
              </div>
              {item.name === 'Metamask' && <div className="hot">{t('create.hot')}</div>}
            </div>
          ))}
      </ModalTitle>
    </>
  )

  return (
    <ConnectWalletWrapper className="connect-wallet" ref={modalRef}>
      {!active && status === 'create' && (
        <WalletDivCreate>
          <WalletDiv />
        </WalletDivCreate>
      )}
      {!active && status === 'shop' && (
        <Button className="my-home-btn-1 details-btns" title={t('app.link.title')} onClick={() => setOnShow(true)}>
          {t('app.link.title')}
        </Button>
      )}
      {!active && status === 'buynow' && (
        <Button className="buy-now-btn" title={t('app.link.title')} onClick={() => setOnShow(true)}>
          {t('app.link.title')}
        </Button>
      )}
      {!active && status === 'proCreate' && (
        <div className="linkBtn" onClick={() => setOnShow(true)}>
          {t('app.link.title')}
        </div>
      )}
      {active && myAddress && windowSize.innerWidth >= AdaptFontSize && (
        <WalletTitleAddress onMouseEnter={() => setMoveSwitch(true)} onMouseLeave={() => setMoveSwitch(false)}>
          <span style={{ marginLeft: '1.05rem', marginRight: '0.63rem', lineHeight: '4.875rem' }}>{formatStrAddress(6, 4, myAddress)}</span>
          {/* <WalletOutlined /> */}
          {moveSwitch && <OnMouseOverDome />}
        </WalletTitleAddress>
      )}
      {active && myAddress && window.innerWidth < AdaptFontSize && (
        <div style={{ flex: '1' }}>
          <div
            className="phoneWallet"
            onClick={() => {
              setShowInfo((n) => {
                return !n
              })
            }}
          >
            <WalletTitleAddress style={{ padding: '0' }}>
              <img src={AVA} alt="" style={{ width: '6rem', marginRight: '3rem' }} />
              <span>{formatStrAddress(6, 4, myAddress)}</span>
              {/* <WalletOutlined /> */}
            </WalletTitleAddress>
            <div>
              <img src={DOWN} alt="" />
            </div>
            {/* <OnMouseOverH5Dome /> */}
          </div>
          {/* 个人中心菜单模块 */}
          {showInfo && (
            <div>
              <div className="drawer-content">
                {/* <div
                  onClick={() => {
                    close()
                    history.replace('/information')
                  }}
                >
                  {t('app.my.title1')}
                </div> */}
                <div
                  onClick={() => {
                    close()
                    history.replace('/mynft')
                  }}
                >
                  {t('app.my.title2')}
                </div>
                <div
                  onClick={() => {
                    close()
                    history.replace('/myproject')
                  }}
                >
                  {t('app.my.title4')}
                </div>
                <div
                  onClick={() => {
                    close()
                    loginOut()
                  }}
                >
                  {t('app.my.title3')}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!active && status === 'homeBar' && (
        <Button size="large" className="wallet-login-btn myConnect" type="text" onClick={() => setOnShow(true)}>
          {t('app.link.title')}
        </Button>
      )}
      {!active && status === '' && window.innerWidth >= AdaptFontSize && (
        <Button
          size="large"
          icon={<img src={isTop ? WALLET : ''} style={{ marginRight: '0.375rem', width: '1.625rem' }} alt="" />}
          className={isTop ? 'wallet-login-btn defaultConnect' : 'wallet-login-btn myConnect'}
          type="text"
          onClick={() => setOnShow(true)}
        >
          {t('app.link.title')}
        </Button>
      )}
      {!active && status === '' && window.innerWidth < AdaptFontSize && (
        <Button
          style={{ width: '100%', padding: '1.5rem 0', lineHeight: '1.75rem', marginRight: '0', height: '5.3125rem', fontSize: '1.5rem' }}
          size="large"
          icon={<img src={isTop ? WALLET : ''} style={{ marginRight: '1rem', width: '2rem' }} alt="" />}
          className={isTop ? 'wallet-login-btn defaultConnect' : 'wallet-login-btn myConnect'}
          type="text"
          onClick={() => setOnShow(true)}
        >
          {t('app.link.title')}
        </Button>
      )}
      {windowSize.innerWidth > AdaptFontSize && (
        <Drawer
          className="wallet-drawer mineDra"
          title=""
          placement="right"
          closable={false}
          onClose={() => setOnShow(false)}
          visible={onShow}
          key="wallet"
        >
          <MyWallet>
            <img src={HEAD} alt="" width={52} style={{ marginRight: '10px' }} />
            <div>{t('app.mywallet')}</div>
          </MyWallet>
          <WalletContent>
            <div className="tips">{t('app.mywallet.tips')}</div>
            <WalletDiv1 />
          </WalletContent>
        </Drawer>
      )}
      {windowSize.innerWidth <= AdaptFontSize && (
        <Drawer
          key="wallet-h5"
          placement="bottom"
          title=""
          onClose={() => setOnShow(false)}
          visible={onShow}
          className="drawer-mask drawer-mask-wallet"
          height="60%"
          closeIcon={<CloseOutlined style={{ color: 'black' }} />}
        >
          <MyWallet style={{ padding: '0 0 1.5625rem 0 ' }}>
            <img src={HEAD} alt="" width={60} style={{ marginRight: '10px' }} />
            <div>{t('app.mywallet')}</div>
          </MyWallet>
          <WalletContent1>
            <div className="tips">{t('app.mywallet.tips')}</div>
            <WalletDiv2 />
          </WalletContent1>
        </Drawer>
      )}
    </ConnectWalletWrapper>
  )
})
