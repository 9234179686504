import styled, { css } from 'styled-components'
import { webTopBarAdaptationMax, h5TopBarAdaptation, webTopBarAdaptation } from '@/common/styled'

export const TopBarWrapper = styled.div`
  ${webTopBarAdaptation}
  @media (min-width: 1920px) {
    ${webTopBarAdaptationMax}
  }
  height: 5rem;
  display: flex;
  align-items: center;
  color: #5f6469;
  .logo {
    height: 2.5rem;
    width: auto;
  }
  .search-input {
    width: 27.5rem;
    padding: 0 0.625rem;
    display: flex;
    align-items: center;
    margin-left: 2.625rem;
    border: 0.0625rem solid #cdd8e2;
    border-radius: 0.3125rem;
    background-color: #e4f0fc;
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          max-width: 15rem;
          margin-left: 0.5rem;
          background-color: white;
        `,
      )}
    &.whi {
      background-color: white;
      .myInput {
        background-color: white;
        .ant-input {
          background-color: white;
        }
      }
    }
    .myInput {
      width: 27.5rem;
      margin-left: 0.625rem;
      border: transparent;
      background-color: #e4f0fc;
      box-shadow: none;
      font-size: 1rem;
      color: #b2c0cd;
      font-weight: 400;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            background-color: white;
          `,
        )}
      .ant-input {
        background-color: #e4f0fc;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              background-color: white;
            `,
          )}
      }
    }
  }
  ${({ theme }) =>
    theme.mediaWidth.screenMd(
      () => css`
        ${h5TopBarAdaptation}
        justify-content: space-between;
        height: 6.25rem;
        .logo {
          width: 12.3125rem;
          height: auto;
        }
      `,
    )}
`
