import React, { memo, useEffect, useState } from 'react'
import { StyledNavLink } from './styled'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { setListInfoSwitch1 } from '@/common/init'
import type { MenuListType as ListType } from '@/common/data.d'
import { useSelector } from 'react-redux'

export default memo(function ProjectSideMenuPages(props: any) {
  const { i18n } = useTranslation()
  const myAddress = useSelector((state: any) => state.userInfo.address)
  const [igoAccount, setIgoAccount] = useState<any>()
  const [{ list }] = useState<{ list: ListType[] }>(() => {
    let { list } = setListInfoSwitch1()
    return { list }
  })

  useEffect(() => {
    if (localStorage.getItem('account')) {
      setIgoAccount(localStorage.getItem('account'))
      const hash: any = window.location.hash
      if (localStorage.getItem('account') !== myAddress && hash.indexOf('projectAdmin') !== -1) {
        window.location.hash = '/projectHome'
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, igoAccount])

  return (
    <Menu mode="horizontal" style={styleMenu}>
      {list.map((item) => (
        <Menu.Item key={item.key} className="menuItemCus">
          {item.key === '4' ? (
            <>
              {igoAccount && igoAccount === myAddress && (
                <StyledNavLink to={item.url === '' ? {} : item.url}>
                  <div className="navlink-child-title">{i18n.language === 'en' ? item.enName : item.name}</div>
                </StyledNavLink>
              )}
            </>
          ) : (
            <StyledNavLink to={item.url === '' ? {} : item.url}>
              <div className="navlink-child-title">{i18n.language === 'en' ? item.enName : item.name}</div>
            </StyledNavLink>
          )}
        </Menu.Item>
      ))}
    </Menu>
  )
})

const styleMenu = {
  width: 'calc(100% - 20rem)',
  background: 'transparent',
  borderBottom: 'none',
  display: 'flex',
  justifyContent: 'flex-end',
}
