import * as actionTypes from './constants'

const initState = {
  user: {},
  address: '',
  spining: false,
  tips: 'Loading',
}

export default function reducer(state = initState, action: any) {
  const { type } = action
  switch (type) {
    case actionTypes.SAVE_USER:
      return { ...state, user: action.user }
    case actionTypes.SAVE_ADDRESS:
      return { ...state, address: action.address }
    case actionTypes.SAVE_SPINING:
      return { ...state, spining: action.spining }
    case actionTypes.DEL_SPINING:
      return { ...state, spining: action.spining }
    case actionTypes.SAVE_SPING_TIPS:
      return { ...state, tips: action.tips }
    default:
      return state
  }
}
