import footer from './zh-CN/footer'
import home from './zh-CN/home'
import governance from './zh-CN/governance'
import vault from './zh-CN/vault'
import info from './zh-CN/info'
import mynft from './zh-CN/mynft'
import market from './zh-CN/market'
import igonft from './zh-CN/igonft'
import myproject from './zh-CN/myproject'
import components from './zh-CN/components'
import server from './zh-CN/server'
import privacy from './zh-CN/privacy'
import statement from './zh-CN/statement'
import validation from './zh-CN/validation'
import help from './zh-CN/help'

const zh = {
  'app.link.btn': '链接钱包',
  'app.link.suceess': '登录成功',
  'app.link.disconnect': '退出成功',
  'app.link.modal.title': 'Choose a connection method',
  'app.link.modal.ftitle1': 'Choose Network',
  'app.link.title': '链接钱包',
  'app.link.modal.ftitle2': 'Choose Wallet',
  'app.link.test.tips': '如果切换到【测试】，请在Metamask或Wallet APP中手动切换到http://47.108.77.85:8545，点击链接即可生效',
  'app.footer.copyright': '© 2022 carbon exchange',
  'app.switch.language.tips': '切换 {{msg}} 成功',
  'app.no.chainid.tips': '请将您的钱包切换到支持的网络',
  'app.no.chainid.btn': '切换网络',
  'app.no.data.title': '目前没有相关内容，敬请期待',
  'app.chainid.drawer.title': '选择网络',
  'app.404.title': '找不到请求的URL',
  'app.404.btn': '回到首页',
  'app.my.title1': '个人中心',
  'app.my.title2': '我的NFT',
  'app.my.title3': '退出链接',
  'app.my.title4': '我的项目集',
  'app.mywallet': '我的钱包',
  'app.mywallet.tips': '与我们的一个可用钱包提供商联系或创建一个新的钱包提供商。',
  'app.mywallet.hot': '热门',
  'app.header.search': '请输入NFT名称',
  'echarts.title': '交易量数据统计 ',
  'echarts.all': '总交易人数：',
  'echarts.allT': '总交易吨数：',
  'email.success': '提交成功！',
  'spin.approving': '授权中...',
  'spin.sell': '出售中...',
  'spin.canel': '正在取消...',
  'spin.buy': '正在购买...',
  'spin.loading': '加载中...',
  'spin.create': '创建中...',
  'spin.upload': '上传中...',
  'pro.Id': '项目集ID',
  'Token.ID': '代币ID',
  ...footer,
  ...home,
  ...vault,
  ...governance,
  ...info,
  ...mynft,
  ...market,
  ...igonft,
  ...myproject,
  ...components,
  ...server,
  ...privacy,
  ...statement,
  ...validation,
  ...help,
}

export default zh
