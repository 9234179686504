import styled, { css } from 'styled-components'
import { proTopBarAdaptationMax, proTopBarAdaptation, h5proTopBarAdaptation } from '@/common/styled'

export const VaultWrapper = styled.div`
  ${proTopBarAdaptation}
  @media (min-width: 1920px) {
    ${proTopBarAdaptationMax}
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        ${h5proTopBarAdaptation}
      `,
    )}
  padding-top: 5rem;
  margin-bottom: 5rem !important;
  ${({ theme }) => theme.mediaWidth.screenXl`
      padding: 6rem 0.625rem 0 0.625rem;
  `}
`

export const VaultTitle = styled.div`
  width: 100%;
  height: 6.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #00477c;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
  `}
`

export const VaultContent = styled.div`
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.screenMd`
    padding:0;
  `}
  .topic {
    font-size: 1.875rem;
    font-weight: bold;
    color: #37383d;
    span {
      color: #00477c;
    }
  }
  .input {
    margin: 1.6875rem 0 3.25rem 0;
    display: flex;
    input {
      width: 45.8125rem;
      height: 3.25rem;
      background: #ffffff;
      border: 0.0625rem solid #dedede;
      border-radius: 0.4375rem;
      margin-right: 1.25rem;
      outline: none;
      padding: 0 0.625rem;
      font-size: 1.125rem;
      &::placeholder {
        color: #d0d0d0;
      }
      ${({ theme }) => theme.mediaWidth.screenMd`
    height: 3.75rem;
    font-size: 1.375rem;
  `}
    }
    .query {
      width: 9rem;
      height: 3.125rem;
      background: #00c28e;
      border-radius: 0.4375rem;
      line-height: 3.125rem;
      text-align: center;
      color: white;
      cursor: pointer;
      font-size: 1.125rem;
      ${({ theme }) => theme.mediaWidth.screenMd`
    height: 3.75rem;
    line-height: 3.75rem;
    font-size: 1.375rem;
  `}
    }
  }
  .des {
    font-size: 1rem;
    color: #87868d;
    span {
      color: #37383d;
    }
    ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 1.5rem;
  `}
  }
`
