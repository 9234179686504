import React, { memo, useEffect, useState } from 'react'
import { VaultWrapper, VaultTitle, Title, VaultContent } from './styled'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import email_send from '@emailjs/browser'
import { message, Spin } from 'antd'
const publicKey = 'vJiS-G8dQYskAgm_J'
const templateID = 'template_bss0wsp'
const serviceID = 'service_3j1no7q'
export default memo(function HelpPage() {
  const [current, setCurrent] = useState(1)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState<{
    content: string
    methods: string
  }>({
    content: '',
    methods: '',
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = () => {
    if (!form.content) {
      message.warning({
        content: t('help.title1.placeholder'),
        className: 'message-global',
      })
      return
    }
    if (!form.methods) {
      message.warning({
        content: t('help.title2.placeholder'),
        className: 'message-global',
      })
      return
    }
    setLoading(true)
    try {
      email_send.send(
        serviceID,
        templateID,
        {
          to_email: 'test@co2e.store',
          // to_email: '1021537393@qq.com',
          from_name: '帮助中心',
          type: t('help.type' + current),
          content: form.content,
          phone: form.methods,
        },
        publicKey,
      )
      setForm({
        ...form,
        content: '',
        methods: '',
      })
      setCurrent(1)
      setLoading(false)
      message.success(t('email.success'))
    } catch (error) {
      setLoading(false)
      message.warning({
        content: error,
        className: 'message-global',
      })
    }
  }

  return (
    <Spin spinning={loading}>
      <VaultWrapper>
        <VaultTitle>
          <Title>
            <Link to={'/help'}>{t('help.title')}</Link>
          </Title>
        </VaultTitle>
        <VaultContent>
          <div className="title type">{t('help.type')}</div>
          <div className="typeList">
            <div
              className={current === 1 ? 'listItem active' : 'listItem'}
              onClick={() => {
                setCurrent(1)
              }}
            >
              {t('help.type1')}
            </div>
            <div
              className={current === 2 ? 'listItem active' : 'listItem'}
              onClick={() => {
                setCurrent(2)
              }}
            >
              {t('help.type2')}
            </div>
            <div
              className={current === 3 ? 'listItem active' : 'listItem'}
              onClick={() => {
                setCurrent(3)
              }}
            >
              {t('help.type3')}
            </div>
          </div>
          <div className="line" />
          <div className="title type1">{t('help.title1')}</div>
          <textarea
            name=""
            id=""
            placeholder={t('help.title1.placeholder')}
            value={form.content}
            onChange={(e: any) => {
              setForm({ ...form, content: e.target.value })
            }}
          />
          <div className="line" />
          <div className="title type1">{t('help.title2')}</div>
          <input
            type="text"
            placeholder={t('help.title2.placeholder')}
            className="input"
            value={form.methods}
            onChange={(e: any) => {
              setForm({ ...form, methods: e.target.value })
            }}
          />
          <div className="btn">
            <div className="submit" onClick={submit}>
              {t('igo-btn')}
            </div>
          </div>
        </VaultContent>
      </VaultWrapper>
    </Spin>
  )
})
