import styled, { css } from 'styled-components'

export const MainWrapper = styled.div`
  padding: 5rem 0;
  max-width: 75rem;
  margin: 0 auto;
  .title {
    padding: 1rem 0;
    text-align: center;
  }
  .main {
    text-indent: 2rem;
  }
  .con {
    text-indent: 2rem;
  }
  .vali_link {
    text-indent: 3rem;
  }
  ${(props) =>
    props.theme.mediaWidth.screenXl(
      () => css`
        margin: 0 2.5rem;
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        padding: 6.25rem 0 5rem 0;
      `,
    )}
`
