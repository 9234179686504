import type { CardType } from '@/common/data.d'
import { useEffect, useState } from 'react'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import { GetWeb3StorageJsonOne } from '@/common'
import { getData } from '@/api'
import Web3 from 'web3'

export interface ImportListType {
  contracts: string
  key?: string | number
}

interface ListType1 {
  key?: string | number
  label: string
  value: string
  id: string
}

interface Type {
  isRefreshData: boolean
  currentDetails: CardType
  myAddress: string
}

interface OpenBoxType {
  key?: string
  price: any
  to: string
  from: string
  blockNumber: string
  index?: number
  time: string
  unit: string
}

export interface PortfolioImportType {
  key?: string | number
  label: string
  value: string
  blockNumber: number
  cover: string
  coverFiles: string
  isImport: boolean
  contracts: any
  carbonType: any
  account?: any
}

export const useMarketHooks1 = (props: Type) => {
  const { isRefreshData, currentDetails, myAddress } = props

  const nftData: ConstantInitTypes = useDataHooks()
  const { payTokenOptions, apiKey, apiUrl, Shared1155Token_ADDRESS } = nftData

  const [tradList, setTradList] = useState<CardType[]>([])

  const [openBoxList, setOpenBoxList] = useState<OpenBoxType[]>([])
  const [loading, setLoading] = useState(true)
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [listData, setMyProjectList] = useState<ListType1[]>([])

  useEffect(() => {
    setLoading(true)
    setTradList([])
    getAllData()
    // getPlist()
    // getTradMarketList()
    setMyProjectList([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshData, apiKey, apiUrl, myAddress])

  useEffect(() => {
    setDetailsLoading(true)
    setOpenBoxList([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDetails, apiKey, apiUrl, myAddress])

  const getAllData = async () => {
    try {
      const params: any = `
    query MyQuery {
      categoriesInfos(orderBy: create_time, orderDirection: asc) {
        id
        contractAddress
        carbonType
        account
      }
      marketItemInfos {
        ERC20_address
        amount
        carbonType
        categoriesId
        id
        contractAddress
        metadata_cid
        price
        seller
        tokenID
        categoriesOwener
        time
      }
      nftinfos {
        carbonType
        categoriesId
        categoriesOwener
        tokenID
        id
        metadata_cid
        minter
        ownner
        type
        num
      }
    }
    `
      const data: any = await getData(params)
      var newList: any = []
      if (data.data) {
        newList = data.data.categoriesInfos
        const list = []
        if (newList.length > 0) {
          for (let i = 0; i < newList.length; i++) {
            let navThemeCidV1 = Web3.utils.hexToAscii(newList[i].id)
            const data: any = await GetWeb3StorageJsonOne(navThemeCidV1, false, 'Collection')
            let obj = {
              key: i,
              label: data.axiosData ? data.axiosData.name + i : data.name,
              value: data.axiosData ? data.axiosData.name + i : data.name,
              id: newList[i].id,
            }
            list.push(obj)
          }
        }

        setMyProjectList(list)
      }

      const result: any = data.data.marketItemInfos.sort(function (a: any, b: any) {
        return b.time - a.time
      })

      for (let i = 0; i < result.length; i++) {
        result[i].isSell = true
        result[i].unit = payTokenOptions.find((pi) => pi.value.toLowerCase() === result[i].ERC20_address.toLowerCase()).label
        result[i].type = result[i].contractAddress.toLowerCase() === Shared1155Token_ADDRESS.toLowerCase() ? 2 : 1
      }

      const result_data: any = data.data.nftinfos.filter((item: any) => !result.some((ele: any) => ele.tokenID === item.id))
      setTradList([...result, ...result_data])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return { listData, tradList, loading, openBoxList, detailsLoading }
}
