import React, { memo, useEffect, useState } from 'react'
import { proTopBarAdaptation, proTopBarAdaptationMax, h5proTopBarAdaptation } from '@/common/styled'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NavLink, Link } from 'react-router-dom'
import { setListInfoSwitch } from '@/common/init'
import type { MenuListType as ListType } from '@/common/data.d'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import { AdaptFontSize, footerMenu } from '@/utils'

const FooterWrapper = styled.div`
  ${proTopBarAdaptation}
  @media (min-width: 1920px) {
    ${proTopBarAdaptationMax}
  }
  .pc {
    height: 6.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 6.5rem;
  }
  .phone {
    height: 8.5rem;
    .name {
      width: 100%;
    }
    .menu {
      width: 100%;
    }
  }
  .name {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 1.875rem;
    ${({ theme }) => theme.mediaWidth.screenMd`
    padding:1rem;
  `}
  }
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    .item {
      color: #00b2ff;
      font-size: 1.25rem;
      margin: 0 1rem;
    }
  }
`

const activeClassName = 'ACTIVE'
export const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #eeeeee;
  &:hover {
    content: none;
    /* font-weight: 600; */
    color: #fff;
  }
`

export const ProTop = styled.div`
  background: #00477c;
`

interface Types {
  className?: any | string
  name: string
}

export default memo(function ProjectFooterPages(props: Types) {
  const { className, name } = props
  const { windowSize } = useWindowSizeHooks()
  const { t, i18n } = useTranslation()
  const [{ list }] = useState<{ list: ListType[] }>(() => {
    let { list } = setListInfoSwitch()
    return { list }
  })

  return (
    <ProTop>
      <FooterWrapper className={className}>
        <div className={windowSize.innerWidth > AdaptFontSize ? 'pc' : 'phone'}>
          <div className="name">{name}</div>
          <div className="menu">
            {footerMenu.map((item) => {
              return (
                <StyledNavLink to={item.url === '' ? {} : item.url} key={item.index} className="item">
                  {i18n.language === 'en' ? item.enName : item.name}
                </StyledNavLink>
              )
            })}
          </div>
        </div>
      </FooterWrapper>
    </ProTop>
  )
})
