import { SetWrapper, HomeContent, Details, MyModal } from './styled'
import React, { memo, useState, useEffect } from 'react'
// import { InputNumber, message, Modal, Spin } from 'antd'
// import ProjectTopBar from '@/components/ProjectTopBar'
// import { useTranslation } from 'react-i18next'
// import { ConstantInitTypes } from '@/contracts/constantInit'
// import useDataHooks from '@/hooks/useDataHooks'
// import ProjectFooter from '@/components/ProjectFooter'
// import { useSelector } from 'react-redux'
// import { retrieveFiles } from '@/common'
// import details from '@/assets/page_slices/detail.png'
// import Set from '@/assets/page_slices/set.png'
// import { getData } from '@/api'
// import Web3 from 'web3'

export default memo(function ProjectAdmin(props: any) {
  // const { t } = useTranslation()
  // const [name, setName] = useState('')
  // const [dataSource, setDataSource] = useState<any>([])
  // const nftData: ConstantInitTypes = useDataHooks()
  // const [spinLoading, setSpinLoading] = useState(false)
  // const [loadingText, setLoadingText] = useState<string>('Loading...')
  // const myAddress = useSelector((state: any) => state.userInfo.address)
  // const { constant, web3 } = nftData
  // const [can, setCan] = useState(false)
  // const [orgData, setOrgData] = useState<any>({})
  // const [visible, setVisible] = useState(false)
  // const [sureLoading, setSureLoading] = useState(false)
  // const [cid, setCid] = useState('')
  // const [form, setForm] = useState<{
  //   price: any
  //   amount: any
  // }>({
  //   price: null,
  //   amount: null,
  // })

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  //   return () => window.scrollTo(0, 0)

  // }, [props])

  // useEffect(() => {
  //   const local = localStorage.getItem('itemDetail_id')
  //   const search = props.location.search

  //   historySearchSwitch(search ? search : '?key=' + local)
  // }, [props])

  // const historySearchSwitch = async (search: any) => {
  //   setSpinLoading(true)
  //   if (search) {
  //     let listSearch = search.slice(1).split('&')
  //     let list: { lable: string; value: any }[] = []
  //     listSearch.forEach((element: any) => {
  //       let objs = element.split('=')
  //       list.push({
  //         lable: objs[0],
  //         value: objs[1],
  //       })
  //     })
  //     if (list[0].lable === 'key' && list[0].value) {
  //       let obj: any = {
  //         value: list[0].value,
  //       }
  //       setDataSource(obj)
  //       let navThemeCidV1 = Web3.utils.hexToAscii(list[0].value)
  //       const itemData: any = sessionStorage.getItem(navThemeCidV1)
  //       if (itemData) {
  //         setName(JSON.parse(itemData).name)
  //       }

  //       const isCasting: any = await isCast(list[0].value)
  //       setCan(isCasting)
  //       const params: any = `
  //                   query MyQuery {
  //                     categoriesInfo(id: "${list[0].value}") {
  //                       account
  //                       carbonType
  //                       contractAddress
  //                       id
  //                       NFTs {
  //                         carbonType
  //                         categoriesId
  //                         categoriesOwener
  //                         id
  //                         metadata_cid
  //                         minter
  //                         type
  //                         num
  //                       }
  //                     }
  //                   }
  //               `
  //       const data: any = await getData(params)

  //       const newMeta: any = await getIeo(data.data.categoriesInfo.account)
  //       setCid(newMeta[0].ipfs_cid)
  //       let cidV1 = Web3.utils.hexToAscii(newMeta[0].ipfs_cid)
  //       const a1: any = await retrieveFiles(cidV1)
  //       setOrgData(a1 ? a1 : {})
  //     }
  //   }
  //   setSpinLoading(false)
  // }

  // const getIeo = async (account: any) => {
  //   try {
  //     const params = `
  //     query MyQuery {
  //       metadataInfos {
  //         ipfs_cid
  //         id
  //         carbonType
  //         account
  //       }
  //     }
  //     `
  //     const data: any = await getData(params)
  //     return data.data.metadataInfos.filter((item: any) => item.account.toLowerCase() === account.toLowerCase())
  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // }

  // const isCast = async (value: any) => {
  //   let data: any = await constant.ContractStore.methods.isCasts(value).call()
  //   return data
  // }

  // const changeCast = async (type: string) => {
  //   if (!myAddress) {
  //     message.warning(t('home.banner.tips1'))
  //     return
  //   }
  //   if (type === '1') {
  //     if (can) {
  //       return
  //     }
  //     setSpinLoading(true)
  //     constant.ContractStore.methods
  //       .startCast(dataSource.value, true)
  //       .send({
  //         from: myAddress,
  //       })
  //       .on('transactionHash', function (hash: any) {
  //         console.log(hash)
  //       })
  //       .on('receipt', async (receipt: any) => {
  //         message.success({
  //           content: t('admin.success'),
  //           className: 'message-global',
  //         })
  //         const isCasting: any = await isCast(dataSource.value)
  //         setCan(isCasting)
  //         setSpinLoading(false)
  //       })
  //       .on('error', function (error: any, receipt: any) {
  //         message.error({
  //           content: error.message,
  //           className: 'message-global',
  //         })
  //         setSpinLoading(false)
  //       })
  //   } else {
  //     if (!can) {
  //       return
  //     }
  //     setSpinLoading(true)
  //     constant.ContractStore.methods
  //       .startCast(dataSource.value, false)
  //       .send({
  //         from: myAddress,
  //       })
  //       .on('transactionHash', function (hash: any) {
  //         console.log(hash)
  //       })
  //       .on('receipt', async (receipt: any) => {
  //         message.success({
  //           content: t('admin.success1'),
  //           className: 'message-global',
  //         })
  //         const isCasting: any = await isCast(dataSource.value)
  //         setCan(isCasting)
  //         setSpinLoading(false)
  //       })
  //       .on('error', function (error: any, receipt: any) {
  //         message.error({
  //           content: error.message,
  //           className: 'message-global',
  //         })
  //         setSpinLoading(false)
  //       })
  //   }
  // }

  // const setConfig = async () => {
  //   if (!myAddress) {
  //     message.warning(t('home.banner.tips1'))
  //     return
  //   }
  //   if (!form.price || !form.amount) {
  //     message.warning(t('admin.config.tips'))
  //     return
  //   }
  //   setSureLoading(true)
  //   let price = web3.utils.toWei(form.price.toString(), 'ether')
  //   constant.ContractStore.methods
  //     .castConfig(dataSource.value, price, form.amount, cid)
  //     .send({
  //       from: myAddress,
  //     })
  //     .on('transactionHash', function (hash: any) {
  //       console.log(hash)
  //     })
  //     .on('receipt', async (receipt: any) => {
  //       message.success({
  //         content: t('admin.tips'),
  //         className: 'message-global',
  //       })
  //       setVisible(false)
  //       setForm({
  //         ...form,
  //         price: null,
  //         amount: null,
  //       })
  //       setSureLoading(false)
  //     })
  //     .on('error', function (error: any, receipt: any) {
  //       console.log(error.message)
  //       message.error({
  //         content: error.message,
  //         className: 'message-global',
  //       })
  //       setSureLoading(false)
  //     })
  // }

  return (
    // <Spin className="mySpin" spinning={spinLoading} tip={loadingText}>
    <SetWrapper>
      {/* <ProjectTopBar name={name} background={`rgba(255,255,255,1)`} borderBottom={`1px solid rgba(229, 229, 229,1)`}></ProjectTopBar>
        <HomeContent>
          <div className="title">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{t('admin.title')}</div>
              <div style={{ display: 'flex' }}>
                <div>{t('admin.isopen')}</div>
                <div
                  className={can ? 'castBtn active' : 'castBtn'}
                  onClick={() => {
                    changeCast('1')
                  }}
                >
                  {t('admin.btn')}
                </div>
                <div
                  className={can ? 'castBtn' : 'castBtn active'}
                  onClick={() => {
                    changeCast('2')
                  }}
                >
                  {t('admin.btn1')}
                </div>
              </div>
            </div>
          </div>
          <Details>
            <div className="des">
              <div className="heder">
                <div>
                  <img src={details} alt="" />
                  {t('project.nft.detail.dataDetail')}
                </div>
                <div
                  className="set"
                  onClick={() => {
                    setVisible(true)
                  }}
                >
                  <img src={Set} alt="" />
                </div>
              </div>
              <div className="metaContent">
                <div className="metaItem">
                  <div className="detailTitle">{t('project.nft.detail.country')}</div>
                  <div className="detailValue">{orgData?.nation || '--'}</div>
                </div>
                <div className="metaItem">
                  <div className="detailTitle">{t('project.nft.detail.num')}</div>
                  <div className="detailValue">{orgData?.serialNum || '--'}</div>
                </div>
                <div className="metaItem">
                  <div className="detailTitle">{t('project.nft.detail.stan')}</div>
                  <div className="detailValue">{orgData?.standard || '--'}</div>
                </div>
                <div className="metaItem">
                  <div className="detailTitle">{t('project.nft.detail.amount')}</div>
                  <div className="detailValue">{orgData?.num || '--'}</div>
                </div>
                <div className="metaItem">
                  <div className="detailTitle">{t('project.nft.detail.type')}</div>
                  <div className="detailValue">{orgData?.type || '--'}</div>
                </div>
                <div className="metaItem">
                  <div className="detailTitle">{t('project.nft.detail.time')}</div>
                  <div className="detailValue">{orgData?.cycle || '--'}</div>
                </div>
                <div className="metaItem">
                  <div className="detailTitle">{t('project.nft.detail.proName')}</div>
                  <div className="detailValue">{orgData?.name || '--'}</div>
                </div>
                <div className="metaItem">
                  <div className="detailTitle">{t('project.nft.detail.proId')}</div>
                  <div className="detailValue">{orgData?.id || '--'}</div>
                </div>
              </div>
            </div>
          </Details>
        </HomeContent>
        <Modal
          visible={visible}
          className="modal-mask"
          footer={null}
          onCancel={() => {
            setVisible(false)
            setForm({
              ...form,
              price: undefined,
              amount: undefined,
            })
          }}
          centered
          bodyStyle={{ padding: '2.5rem' }}
          maskClosable={false}
        >
          <Spin spinning={sureLoading} tip="SetLoading...">
            <MyModal>
              <div className="modalHeader">
                <div>{t('admin.set')}</div>
              </div>
              <div className="price">
                <div>{t('admin.price')}</div>
                <InputNumber
                  precision={6}
                  min={0.000001}
                  max={999999999.999999}
                  value={form.price}
                  placeholder={t('admin.plo')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      price: e,
                    })
                  }}
                />
              </div>
              <div className="amount">
                <div>{t('admin.amount')}</div>
                <InputNumber
                  value={form.amount}
                  min={0}
                  max={999999}
                  placeholder={t('admin.plo')}
                  style={{ width: '100%' }}
                  onChange={(e: any) => {
                    setForm({
                      ...form,
                      amount: parseInt(e),
                    })
                  }}
                />
              </div>
              <div
                className="btn"
                onClick={() => {
                  setConfig()
                }}
              >
                {t('admin.btn2')}
              </div>
            </MyModal>
          </Spin>
        </Modal>
        <ProjectFooter name={name} /> */}
    </SetWrapper>
    // </Spin>
  )
})
