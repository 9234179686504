import styled, { css } from 'styled-components'
import { Row } from 'antd'
// import { h5LayoutAdaptation, webLayoutAdaptationMax, webLayoutAdaptation } from '@/common/styled'
import { proTopBarAdaptationMax, proTopBarAdaptation, h5proTopBarAdaptation } from '@/common/styled'

export const TradingFloorWrapper = styled.div`
  margin-top: 5rem;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.screenMd`
     margin-top: 6rem;
    `}
  .meta-data {
    ${proTopBarAdaptation}
    @media (min-width: 1920px) {
      ${proTopBarAdaptationMax}
    }
    ${(props) =>
      props.theme.mediaWidth.screenMd(
        () => css`
          ${h5proTopBarAdaptation}

          .main-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .content-left {
              width: 100% !important;
              .ant-image {
                width: 100%;
              }
            }
            .content-right {
              width: 100% !important;
              margin-top: 2rem;
            }
          }
        `,
      )}

    .main-content {
      display: flex;
      justify-content: space-between;
      .content-left {
        width: 31.25rem;
      }
      .content-right {
        width: calc(100% - 34.25rem);
        h2 {
          font-size: 1.375rem;
          ${({ theme }) => theme.mediaWidth.screenMd`
     font-size: 3rem;
    `}
        }
      }
    }
  }

  .content-nft {
    padding-top: 1rem;
    ${({ theme }) => theme.mediaWidth.screenMd`
     padding-top: 0rem;
    `}
  }
  .pagination-nft {
    height: 8.31rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-pagination-item,
    .ant-pagination-item-link {
      border-radius: 0.25rem;
    }
  }
  .content-nft-info {
    width: 20%;
    display: flex;
    justify-content: center;
    ${({ theme }) => theme.mediaWidth.screebXll`
      width: 25%;
    `}
    ${({ theme }) => theme.mediaWidth.screenMd`
      width: 50%;
    `}
    ${({ theme }) => theme.mediaWidth.screenXl`
      width: 33%;
    `}
    ${({ theme }) => theme.mediaWidth.screenSm`
      width: 50%;
    `}
  }
  .details-shop {
    .details-shop-left {
      display: flex;
      align-items: center;
      padding-top: 1.25rem;
      flex-direction: column;
      .ant-image {
        max-width: 95%;
        border-radius: 0.31rem;
        max-height: 37.5rem;
        box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
      }
      .ant-image-img {
        border-radius: 0.31rem;
        max-height: 37.5rem;
      }
    }
    .details-shop-right {
      display: flex;
      flex-direction: column;
      padding-top: 1.25rem;
      h2 {
        font-size: 1.375rem;
        margin: 1rem 0 1.5rem 0;
        font-weight: 400;
      }
      h4 {
        font-size: 1rem;
        font-weight: 400;
        color: #363639;
        margin-bottom: 1.88rem;
      }
    }
  }
  ${({ theme }) => theme.mediaWidth.screenSm`
    .content-shop {
      padding: 1.25rem 1rem 0 1rem;
    }
  `}
`

export const TradingFloorLeft = styled.div`
  min-height: 85vh;
  background: #f5f6f8;
  position: relative;
  .left-icon {
    position: absolute;
    top: 2.38rem;
    font-size: 1.48rem;
    left: calc(50% - 0.94rem);
    cursor: pointer;
    transform: rotate(180deg);
  }
  .left-active-icon {
    font-size: 1.48rem;
    color: ${({ theme }) => theme.themeColor};
    cursor: pointer;
  }
`

export const TradingFloorContent = styled.div<{ active: boolean }>`
  ${(props) =>
    props.active
      ? css`
          padding: 0 0 0 1.56rem;
          ${(props) =>
            props.theme.mediaWidth.screenMd(
              () => css`
                padding: 0 1.13rem;
              `,
            )}
        `
      : css`
          padding: 0 12.5rem;
          @media (max-width: 1700px) {
            padding: 0 11.5rem;
          }
          @media (max-width: 1500px) {
            padding: 0 10.5rem;
          }
          @media (max-width: 1400px) {
            padding: 0 9.5rem;
          }
          @media (max-width: 1300px) {
            padding: 0 8.5rem;
          }
          ${(props) =>
            props.theme.mediaWidth.screenLg(
              () => css`
                padding: 0 2.5rem;
              `,
            )}
          ${(props) =>
            props.theme.mediaWidth.screenMd(
              () => css`
                padding: 0 1.13rem;
              `,
            )}
        `}
`

export const TradingFloorTitle = styled.div<{ active: boolean }>`
  min-height: 6.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.list_title {
    margin: 0 !important;
  }
  ${proTopBarAdaptation}
  @media (min-width: 1920px) {
    ${proTopBarAdaptationMax}
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        ${h5proTopBarAdaptation}
      `,
    )}
  ${(props) =>
    props.active
      ? css`
          padding: 0 0 0 1.56rem;
          ${(props) =>
            props.theme.mediaWidth.screenMd(
              () => css`
                padding: 0 1.13rem;
              `,
            )}
        `
      : css`
          ${(props) =>
            props.theme.mediaWidth.screenLg(
              () => css`
                padding: 0 2.5rem;
              `,
            )}
          ${(props) =>
            props.theme.mediaWidth.screenMd(
              () => css`
                padding: 0 1.13rem;
              `,
            )}
        `}
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    flex-direction: column;
    align-items: start;
    justify-content: end;
  `}
`

export const Title = styled.div`
  width: 100%;
  font-size: 2rem;
  font-weight: 400;
  color: #00477c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.88rem;
  a {
    color: #00477c;
  }
  span {
    font-size: 3rem;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 2.31rem;
    line-height: 4.75rem;
    span {
      line-height: 4.75rem;
      font-size:  2.31rem;
    }
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    font-size: 1.88rem;
    span {
      font-size: 1.88rem;
    }
  `}
`

export const TitlteRight = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .left {
    width: 100%;
    display: flex;
    align-items: center;
  }
  ${({ theme }) =>
    theme.mediaWidth.screenMd(
      () => css`
        flex-direction: column;
        .left {
          width: 100%;
          margin-bottom: 1.25rem;
          display: flex;
          flex-wrap: wrap;
        }
      `,
    )}
`

export const RightContent = styled.div`
  .content_bottom {
    display: flex;
    justify-content: space-between;
    .btn_item {
      width: calc((100% - 2.5rem) / 2);
      height: 5rem;
      border-radius: 1rem;
      border: 1px solid #16cc9f;
      color: #00c28e;
      text-align: center;
      line-height: 5rem;
      font-size: 1.875rem;
      &:last-child {
        background-color: #00c28e;
        color: white;
      }
    }
  }
  .close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.875rem;
    background-color: white;
    span {
      font-size: 1.875rem;
      color: #5d6175;
    }
  }
  .search-input1 {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    border: 0.0625rem solid #dedede;
    border-radius: 1rem;
    background-color: #fff;
    padding: 0 1rem;
    margin-bottom: 1.875rem;
    .myInput {
      margin-left: 0.625rem;
      border: transparent;
      background-color: #fff;
      box-shadow: none;
      font-size: 1.875rem;
      color: #9596a5;
      font-weight: 400;
    }
  }
  .my-status {
    background-color: white;
    margin-bottom: 1.875rem;
    border-radius: 1rem;
    padding: 1.5rem 1.3125rem;
  }
  .search-input {
    width: 100%;
    padding: 0.2rem 0.625rem;
    display: flex;
    align-items: center;
    border: 0.0625rem solid #dedede;
    border-radius: 0.4375rem;
    background-color: #fff;
    .myInput {
      margin-left: 0.625rem;
      border: transparent;
      background-color: #fff;
      box-shadow: none;
      font-size: 1rem;
      color: #b2c0cd;
      font-weight: 400;
    }
  }
  .status {
    background-color: white;
    margin-top: 1.25rem;
    border-radius: 0.4375rem;
  }
  .price-number {
    margin: 0.9375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-input-number {
      width: 100%;
      height: 2.875rem;
      background: #ffffff;
      border: 0.06rem solid #e8e9ee;
      border-radius: 0.4375rem;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            border-radius: 1rem;
          `,
        )}
    }
    .ant-input-number-input {
      height: 2.875rem;
      border-radius: 0.4375rem;
      text-align: center;
    }
    ${(props) =>
      props.theme.mediaWidth.screenLg(
        () => css`
          .ant-input-number {
            height: 5rem;
          }
          .ant-input-number-input {
            height: 5rem;
            border-radius: 0.4375rem;
          }
        `,
      )}
    ${(props) =>
      props.theme.mediaWidth.screenMd(
        () => css`
          margin: 0.9375rem 0;
        `,
      )}
  }
  .price-num-to {
    font-size: 1rem;
    font-weight: 500;
    color: #363639;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nft-list {
    margin: 15px;
    display: flex;
    flex-wrap: wrap;
    .nft-btn {
      width: calc(50% - 0.5rem);
      height: 2.875rem;
      ${(props) => props.theme.mediaWidth.screenLg`
        height: 3.38rem;
      `}
      line-height: 2.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 0.06rem solid #e8e9ee;
      border-radius: 0.4375rem;
      margin-bottom: 0.88rem;
      font-size: 16px;
      font-weight: 500;
      color: #9596a5;
      &:nth-child(2n-1) {
        margin-right: 1rem;
      }
      &:hover {
        border-color: #16cc9f;
        color: #16cc9f;
      }
    }
    .nft-btn.active {
      border-color: #16cc9f;
      color: #16cc9f;
    }
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    .price-num-to {
      font-size: 1.25rem;
    }
    .nft-list {
      .nft-btn {
        font-size: 1.25rem;
      }
    }
  `}
  .nft-list1 {
    margin: 1.625rem 0;
    display: flex;
    flex-wrap: wrap;
    .nft-btn {
      width: calc(50% - 0.5rem);
      height: 2.875rem;
      ${(props) => props.theme.mediaWidth.screenLg`
        height: 5rem;
      `}
      line-height: 2.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 0.06rem solid #e8e9ee;
      border-radius: 1rem;
      font-size: 1.875rem;
      font-weight: 500;
      color: #9596a5;
      &:nth-child(2n-1) {
        margin-right: 1rem;
      }
      &:hover {
        border-color: #16cc9f;
        color: #16cc9f;
      }
    }
    .nft-btn.active {
      border-color: #16cc9f;
      color: #16cc9f;
    }
  }
`

export const RightTitle1 = styled.div`
  background: white;
  font-size: 2.125rem;
  font-weight: 400;
  color: #5d6175;
  display: flex;
  align-items: center;
  img {
    margin-right: 1.375rem;
    ${({ theme }) => theme.mediaWidth.screenMd`
    width:2.5rem;
  `}
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
   font-size: 1.5rem;
  `}
`

export const RightTitle = styled.div`
  padding: 0.625rem 0 0 0.9375rem;
  background: white;
  font-size: 18px;
  font-weight: 400;
  color: #5d6175;
  border-radius: 0.4375rem;
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 1.25rem;
  `}
`

export const SelectionDiv = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  span {
    font-size: 0.95rem;
    font-weight: 600;
    margin-left: 0.94rem;
    color: #5d6175;
  }
`

export const ThemeList = styled.div`
  width: 14rem;
  height: 2.38rem;
  background: #fff;
  border: 0.06rem solid #5746fe;
  border-radius: 0.63rem;
  font-size: 1rem;
  font-weight: 400;
  color: #5746fe;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0 2.5rem 1.38rem;
  ${({ theme }) => theme.mediaWidth.screenMd`
    font-size: 1.25rem;
    width: 15rem;
    height: 3.38rem;
  `}
`

export const PriceDivCard = styled(Row)`
  width: 100%;
  margin-top: 1.625rem;
  justify-content: center;
  align-items: center;
  .mySelect {
    flex: 1;
    margin: 0 0.9375rem;
    height: 2.875rem;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`

export const customStylesPay = {
  placeholder: (provided: any) => ({
    ...provided,
    color: '#00C28E',
    fontWeight: 400,
    fontSize: '1rem',
    '@media screen and (max-width: 992px)': {
      fontSize: '1.875rem',
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minWidth: '11.88rem',
    minHeight: 'auto',
    height: '2.875rem',
    color: '#ffffff',
    border: '0.9px solid #DEDEDE',
    borderRadius: '0.4375rem',
    display: 'flex',
    background: 'transparent',
    boxShadow: '1px solid transparent',
    '&:hover': {
      borderColor: '#DEDEDE',
    },
    '@media screen and (max-width: 992px)': {
      height: '5rem',
      fontSize: '1.875rem',
      borderRadius: '1rem',
    },
    '@media screen and (max-width: 1200px)': {
      minWidth: '8.88rem',
    },
    '@media screen and (max-width: 1400px)': {
      minWidth: '9.88rem',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? '#5746FE' : '#ffffff',
    background: '#ffffff',
    textAlign: 'center',
    fontSize: '14px',
    margin: 0,
    ':active': {
      backgroundColor: '#EFEEFD',
    },
    ':hover': {
      color: '#5746FE',
      backgroundColor: '#EFEEFD',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    boxShadow: 'none',
    borderRadius: '0.31rem',
    // marginTop: '1.5rem',
    backgroundColor: '#ffffff',
  }),
  menuList: (provided: any) => ({
    ...provided,
    borderRadius: '0.31rem',
    padding: '0.5rem 0',
    boxShadow: '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
  }),
  singleValue: (provided: any, state: any) => {
    const transition = 'opacity 300ms'
    return { ...provided, transition, color: '#00C28E', fontSize: '16px' }
  },
}

export const SelectionNumDiv = styled.div`
  min-width: 17.5rem;
  height: 3.125rem;
  border: 0.06rem solid #16cc9f;
  border-radius: 0.63rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  margin-right: 1.25rem;
  color: #5d6175;
  .icons {
    width: 0.8125rem;
    height: auto;
    margin-left: 1.81rem;
    margin-right: 0.75rem;
  }
  .leftsss {
    margin-left: 1.81rem;
  }
  .right-icons {
    font-size: 1.13rem;
    color: #a3b7c3;
    margin-right: 1.25rem;
    cursor: pointer;
  }
  /* span {
    color: #5746fe;
  } */
  ${({ theme }) => theme.mediaWidth.screenMd`
   width: 10.75rem;
   height: 3.25rem;
   font-size: 1.25rem;
   margin: 1rem 0;
   margin-right: 1rem;
  `}
`

export const H5Bottom = styled.div`
  height: 6rem;
  /* background-color: rgba(0, 0, 0, 0.3); */
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  .su-btn {
    background: #ffffff;
    width: 15.625rem;
    height: 5rem;
    font-size: 1.75rem;
    color: #5d6175;
    box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.25);
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 1.6875rem;
      ${({ theme }) => theme.mediaWidth.screenMd`
      width:2.375rem;
  `}
    }
  }
`

export const PriceDiv = styled.div`
  font-size: 1.88rem;
  font-weight: 500;
  color: #00c28e;
  display: flex;
  align-items: center;
  font-family: DINPro-Medium;
  .ant-image {
    margin-right: 0.75rem;
  }
`

export const CurrentThemeDiv = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #00c28e;
  ${({ theme }) => theme.mediaWidth.screenMd`
   
   font-size:1.875rem;
  `}
`

export const DescribeDiv = styled.div`
  width: 100%;
  min-height: auto;
  border-radius: 0.63rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #dedede;

  .price-content {
    display: flex;
    flex-direction: column;
    padding: 1.4rem 2rem;
    h5 {
      font-size: 1rem;
      font-weight: 400;
      color: #9596a5;
      margin-top: 1rem;
      margin-bottom: 0;
      ${({ theme }) => theme.mediaWidth.screenMd`
   font-size:1.625rem;
  `}
    }
  }
  h3 {
    font-size: 1.125rem;
    font-weight: 400;
    color: #37383d;
    margin-bottom: 0rem;
    padding: 1rem 2rem;
    background-color: #f5f6f8;
    border-radius: 0.63rem 0.63rem 0 0;
    img {
      width: 1.25rem;
      margin-right: 5px;
    }
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          font-size: 1.875rem;
          img {
            width: 2.5rem;
          }
        `,
      )}
  }
  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }
  li {
    list-style-type: none;
    font-size: 1.125rem;
    font-weight: 400;
    color: #5d6175;
    /* margin-bottom: 2.19rem; */
    padding: 1.8125rem;
    word-break: break-all;
    &::marker {
      content: '';
    }
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          font-size: 1.75rem;
        `,
      )}
  }
  .info {
    margin: 0 0.88rem 2rem 2rem;
    font-size: 1.125rem;
    font-weight: 400;
    color: #5d6175;
    display: flex;
    align-items: center;
    .span {
      font-weight: 400;
      width: 44%;
      color: #9596a5;
    }
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          font-size: 1.75rem;
        `,
      )}
  }

  &.addDes {
    display: flex;
    justify-content: center;
    padding: 1.8125rem;
    .info {
      margin: 0;
    }
  }
`

export const NftList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ShopDiv = styled.div`
  border: 1px solid #dedede;
  border-radius: 1rem;
  h3 {
    background: ${(props) => props.theme['gary-4']};
    border-radius: 1rem 1rem 0 0;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    font-weight: 400;
    color: #363639;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    ${({ theme }) => theme.mediaWidth.screenMd`
     font-size: 1.875rem;
  `}
    img {
      width: 1.25rem;
      margin-right: 5px;
      ${({ theme }) => theme.mediaWidth.screenMd`
     width: 2.5rem;
  `}
    }
  }
  /* ${({ theme }) => theme.mediaWidth.screenMd`
    padding: 1.5rem 1.13rem;
  `} */
`
export const TableList = styled.table`
  background: #ffffff;
  border-radius: 1rem;
  width: 100%;
  overflow-x: scroll;
  border-spacing: 0;
  border-collapse: collapse;
  thead {
    tr {
      height: 4.1875rem;
      line-height: 4.1875rem;
      th {
        font-size: 1.125rem;
        color: #9596a5;
        text-align: left;
      }
    }
  }
  tr {
    width: 100%;
    ${({ theme }) => theme.mediaWidth.screenMd`
    width: auto;
  `}
    th {
      width: 20%;
      font-size: 1rem;
      font-weight: 400;
      color: #5d6175;
      line-height: 1.56rem;
      height: 2.44rem;
      border-bottom: 0.06rem solid #e5e5e5;
      &:nth-child(1) {
        text-indent: 1.25rem;
      }
      ${({ theme }) => theme.mediaWidth.screenMd`
    width: auto;
    min-width:5rem;
  `}
    }
    td {
      width: 20%;
      font-size: 1.125rem;
      font-weight: 400;
      color: #5d6175;
      line-height: 1.56rem;
      height: 2.44rem;
      text-align: left;
      line-height: 3.5rem;
      &:nth-child(1) {
        text-indent: 1.25rem;
      }
      ${({ theme }) => theme.mediaWidth.screenMd`
    width: auto;
  `}
    }
  }
`

export const ImageDiv = styled.div`
  width: 100%;
  height: 31.25rem;
  ${(props) => props.theme.mediaWidth.screenMd`
    img{
      width:100%;
      max-height:100%;
    }
  `}
  border-radius: 0.31rem;
  box-shadow: 0.13rem 0.13rem 0.63rem 0rem rgba(18, 18, 27, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
    height: 100%;
  }
`

export const SearchInput = styled.div`
  width: 15.75rem;
  ${(props) => props.theme.mediaWidth.screenMd`
    width: 100%;
  `}
`
export const Details = styled.div`
  .des {
    background-color: #f5f5f5;
    border: 1px solid #dedede;
    border-radius: 1rem;
    h3 {
      padding: 1rem 2rem;
      font-size: 1.25rem;
      font-weight: 400;
      color: #363639;
      margin-bottom: 0rem;
      display: flex;
      align-items: center;
      ${({ theme }) => theme.mediaWidth.screenMd`
     font-size: 1.875rem;
  `}
      img {
        width: 1.25rem;
        margin-right: 5px;
        ${({ theme }) => theme.mediaWidth.screenMd`
      width: 2.5rem;
  `}
      }
    }
    .metaContent {
      display: flex;
      padding: 1rem 2rem;
      flex-wrap: wrap;
      background-color: white;
      .metaItem {
        ${(props) => props.theme.mediaWidth.screenMd`
    width: 100%;
    font-size:1.75rem;
  `}
        width: 50%;
        display: flex;
        font-size: 1.125rem;
        line-height: 3rem;
        /* &:not(:first-child) {
          margin-top: 0.8rem;
        } */
        .detailTitle {
          color: #9596a5;
          flex: 0.4;
        }
        .detailValue {
          color: #5d6175;
          flex: 0.6;
          /* white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; */
          padding: 0 1rem 0 0;
        }
      }
    }
  }
`
