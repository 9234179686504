const components = {
  'create.message.tips1': 'You can only upload JPG/PNG/GIF/SVG/MP4/WEBM/MP3/WAV/OGG/GITF file',
  'create.message.tips2': 'Image must smaller than 100MB',
  'create.message.tips3': 'You can only upload JPG/PNG/GIF file',
  'create.upload.btn': 'Upload',
  'create.upload.btn1': 'Change',
  'create.title1': 'Create new project',
  'create.title': 'Create new project',
  'create.title.f1': 'Required fields',
  'create.from1.label': 'Image, video, audio or 3D model',
  'create.from1.rules': 'Can not be empty',
  'create.from1.f1': 'Supported file types: JPG, PNG, GIF, MP4, WEBM, MP3, WAV, OGG, GITF. Minimum size: 100MB',
  'create.from2.label': 'Image Cover',
  'create.from2.rules': 'Can not be empty',
  'create.from2.f1':
    'Since the upload file already contains multimedia images, you will need to provide an image cover for this. You are advised to use 600 x 300',
  'create.from2.f2':
    'This image will be used to display your collections on the project home page, category page or other promotional area. You are advised to use 600 x 300',
  'create.from3.label': 'Name',
  'create.from3.placeholder': 'please enter',
  'create.from3.rules': 'Can not be empty',
  'create.from4.label': 'Describe',
  'create.from4.placeholder': 'Provide a detailed description of your NFT',
  'create.from4.f1': 'The description will be included on the details page below its image',
  'create.from5.label': 'Item collection',
  'create.from5.label1': 'Carbon Type',
  'create.from5.rules': 'Can not be empty',
  'create.from5.placeholder': 'select a program Carbon',
  'create.from5.placeholder1': 'select a item collection',

  'create.from6.f1': 'Charge when a user resells your originally created item, deducted from the final sale price',
  'create.from6.label': `Creator's Benefit`,
  'create.from6.rules': 'Can not be empty',
  'create.from6.por': 'The subdivision fee cannot be empty',
  'create.from6.placeholder': 'Percentage fee, e.g. 2.5',
  'create.message.tip.success': 'Create Success',
  'create.from7.label': 'Creator income address',
  'create.from7.rules': 'Can not be empty',
  'create.from7.placeholder': 'please enter',
  'create.from8.placeholder': 'please enter amount',
  'create.from8.label': 'Amount',
  'create.from9.label': 'Standard of token',

  'create.btn': 'Create',
  'create.btn1': 'Save',
  'create.btn2': 'Delete',
  'create.modal.title': 'Created successfully',
  'create.modal.title1': 'Congratulations! You just created',
  'create.modal.btn1': 'View list',
  'create.modal.btn3': 'Check nft',
  'create.modal.btn2': 'Continue to create',
  'create.message.success': 'Create Success',
  'create.set': 'This is the collection where your NFT will appear.',

  'create.apply': 'Apply to be an IEO project ',
  'create.apply.tips': 'You are not an IEO project partner yet, so apply now!',

  'create.hot': 'HOT',
  'create.title.1': 'Link your wallet',
  'create.title.2': 'Contact one of our available wallet providers or create a new wallet provider.',
  'create.menu1': 'Create collection',
  'create.menu2': 'Create NFT',
  'create.nft.title1': 'NFT cover',
  'create.nft.title2': 'This image will be used to display your NFT cover. Recommendation 800 * 800.',
  'create.project.title1': 'Project cover',
  'create.project.title2':
    'This image will be used to display your collections on the project home page, category page or other promotional area. You are advised to use 600 x 300.',
}

export default components
