import styled, { css } from 'styled-components'
import { ComLayoutTwo } from '@/common/styled'
import { NavLink } from 'react-router-dom'
import ShowSet from '@/assets/home/showSet.png'
import BANNER from '@/assets/home/banner.png'
import BANNER1 from '@/assets/home/phoneBanner.png'
import { h5LayoutAdaptation, webLayoutAdaptationMax, webLayoutAdaptation } from '@/common/styled'

export const HomeWrapper = styled.div`
  margin-top: 5rem;
  ${({ theme }) => theme.mediaWidth.screenMd`
      margin-top: 6.25rem;
    `}
  .homeTopBar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background: #e4f0fc;
    border-bottom: 0.0625rem solid rgba(229, 229, 229, 1);
    ${({ theme }) => theme.mediaWidth.screenMd`
      background: #fff;
    `}
  }
  .h5-anim {
    ${({ theme }) => theme.mediaWidth.screenMd`
      width: 80%;
    `}
    ${({ theme }) => theme.mediaWidth.screenSm`
      width: 100%;
    `}
  }
  .banner {
    width: 100%;
    background: url(${BANNER}) no-repeat center;
    background-size: 100% auto;
    background-color: #e4f0fc;
    padding: 10rem 0 0 0;
    min-height: 49.75rem;
    ${({ theme }) => theme.mediaWidth.screenMd`
       background:  url(${BANNER1}) no-repeat center;
      background-size: auto 100%;
       padding: 0;
      //  min-height:32.8125rem;
    `}
    .bannerContent {
      ${webLayoutAdaptation}
      .name {
        /* font-size: 4.5rem; */
        font-size: 3.2rem;
        color: #00477c;
        line-height: 5.5rem;
      }
      .bannerTips {
        color: #9596a5;
        font-size: 1.5rem;
        width: 26rem;
        margin: 1.5rem 0 4.25rem 0;
      }
      ${(props) =>
        props.theme.mediaWidth.screenMd(
          () => css`
            ${h5LayoutAdaptation}
            .name {
              padding-top: 4.8125rem;
              text-align: center;
              font-size: 4.875rem;
            }
            .bannerTips {
              width: 26rem;
              text-align: center;
              margin: 1.5rem auto;
              font-size: 1.5625rem;
            }
            .bannerBtn {
              display: flex;
              justify-content: center;
              padding: 0 0 35rem 0;
            }
          `,
        )}
      @media (min-width: 1920px) {
        ${webLayoutAdaptationMax}
      }
    }
  }
  .IntroduceA {
    width: 15rem;
    height: 3.75rem;
    border: 0.06rem solid #5746fe;
    border-radius: 0.63rem;
    font-size: 1.88rem;
    font-weight: bold;
    color: #5746fe;
    line-height: 1.56rem;
    margin-bottom: 5rem;
    border-color: #5746fe;
    :hover {
      color: #5746fe;
      border-color: #5746fe;
    }
    ${({ theme }) => theme.mediaWidth.screenMd`
      font-size: 1.5rem;
    `}
  }
  .HomeBannerTitle {
    width: 22.5rem;
    height: 4.38rem;
    background: #5746fe;
    border-radius: 0.63rem;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    font-size: 2.5rem;
    cursor: pointer;
    border-color: transparent;
  }
`

export const Banner = styled.div`
  position: relative;
  margin-top: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Experience = styled.div`
  ${webLayoutAdaptation}
  padding: 5.1875rem 0 4.375rem 0;
  .experTitle {
    font-size: 3rem;
    color: #00477c;
    text-align: center;
    margin-bottom: 3rem;
    /* ${({ theme }) => theme.mediaWidth.screenMd`
       font-size:3rem;
    `} */
  }
  .experContent {
    display: flex;
    justify-content: space-between;

    ${({ theme }) => theme.mediaWidth.screenMd`
       flex-wrap: wrap;
    `}
    .item {
      width: 33.75rem;
      padding: 2.5rem 4rem;
      font-weight: 400;
      background-color: white;
      border-radius: 0.9375rem;
      text-align: center;
      cursor: pointer;
      &:hover {
        box-shadow: 0rem 1.125rem 2rem 0rem rgba(180, 180, 180, 0.42);
      }
      ${({ theme }) => theme.mediaWidth.screenMd`
        width:100%;
        padding:2.5rem 0;
      `}
      img {
        width: 14.375rem;
        height: 18.5625rem;
      }
      &.project {
        ${({ theme }) => theme.mediaWidth.screenMd`
          margin-top: 3rem;
        `}
      }
      .itemName {
        font-size: 2.625rem;
        color: #1471fb;
        text-align: center;
        margin: 1.625rem 0;
        ${({ theme }) => theme.mediaWidth.screenMd`
       font-size:3rem;
    `}
      }
      .itemTips {
        min-width: 11rem;
        max-width: 16.375rem;
        /* width: 100%; */
        color: #9596a5;
        font-size: 1.375rem;
        margin: 0 auto;
        ${({ theme }) => theme.mediaWidth.screenMd`
         min-width: 30.25rem;
         max-width: 30.25rem;
        // width: 30.25rem;
       font-size:1.75rem;
      padding:0 1rem;
    `}
      }
      .itemBtn {
        width: 9.4375rem;
        height: 2.8125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #00c28e;
        margin: 2.375rem auto 0 auto;
        color: #9596a5;
        cursor: pointer;
        border-radius: 1.375rem;
        .alink {
          color: #9596a5;
          ${({ theme }) => theme.mediaWidth.screenMd`
       color:#00C28E;
       font-size:1.625rem;
    `}
        }
        &:hover {
          background-color: #00c28e;
          color: white;
          .alink {
            color: white !important;
          }
        }
        ${({ theme }) => theme.mediaWidth.screenMd`
       font-size:1.625rem;
       width: 12.5rem;
       height: 3.8125rem;
       color:#00C28E;
       border-radius: 3.75rem;
    `}
      }
    }
  }
  @media (min-width: 1920px) {
    ${webLayoutAdaptationMax}
  }
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        ${h5LayoutAdaptation}
      `,
    )}
`

export const StyledNavLink = styled(NavLink)`
  display: block;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    content: none;
    font-weight: 600;
    color: #fff;
  }
`

export const Project = styled.div`
  width: 100%;
  padding: 6.4375rem 0 10.9375rem 0;
  background-image: url(${ShowSet});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  ${(props) =>
    props.theme.mediaWidth.screenMd(
      () => css`
        padding: 5.125rem 0 8rem 0;
        background-size: auto 100%;
      `,
    )}
  .proTitle {
    text-align: center;
    color: #00477c;
    font-size: 3rem;
  }
  .listMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2.5rem auto 3.5rem auto;
    max-width: 1200px;
    border-bottom: 1px solid #9596a5;
    ${(props) =>
      props.theme.mediaWidth.screenMd(
        () => css`
          max-width: 100%;
          border-bottom: transparent;
        `,
      )}
    .menuItem {
      cursor: pointer;
      color: #87868d;
      font-size: 1.375rem;
      box-sizing: border-box;
      height: 3.25rem;
      &:not(:first-child) {
        margin-left: 3rem;
      }
      &.active {
        color: #00c28e;
        border-bottom: 3px solid #00c28e;
      }
      &:hover {
        color: #00c28e;
        border-bottom: 3px solid #00c28e;
      }
    }
  }
  .proList {
    display: flex;
    justify-content: start;
    /* padding: 1.75rem; */
    max-width: 1200px;
    margin: 0 auto;
    ${(props) =>
      props.theme.mediaWidth.screenMd(
        () => css`
          max-width: 100%;
          padding: 1.75rem;
        `,
      )}
    .proItem {
      &:not(:first-child) {
        margin-left: 2.25rem;
      }
      cursor: pointer;
      width: calc((100% - 5rem) / 4);
      border-radius: 1rem;
      background-color: white;
      /* .img {
        height: 7.5rem;
        width: 100%;
        object-fit: cover;
        @media only screen and (max-width: 1440px) {
          height: 11rem !important;
        }
      } */
      .name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #9596a5;
        font-size: 1rem;
        padding-top: 1.3125rem;
        .left {
          flex: 0.6;
          padding: 0 0.875rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .right {
          flex: 0.4;
          padding: 0 0.875rem;
          text-align: right;
        }
      }

      .price {
        color: #37383d;
        font-size: 1.25rem;
        font-weight: 600;
        font-family: DINPro-Bold;
        padding: 0.75rem 0.875rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          margin-right: 5px;
        }
      }
    }
  }
`

export const Dao = styled.div`
  width: 100%;
  background-color: #f7f8fa;
  padding: 70px 0;
  .daoTitle {
    text-align: center;
    color: #00477c;
    font-size: 3rem;
    /* ${({ theme }) => theme.mediaWidth.screenMd`
       font-size:3rem;
    `} */
  }
  .daoTips {
    color: #87868d;
    font-size: 1.375rem;
    width: 30.25rem;
    margin: 2rem auto;
    text-align: center;
    ${({ theme }) => theme.mediaWidth.screenMd`
       font-size:1.625rem;
       padding:0 1rem;
    `}
  }
  .daoBtn {
    width: 10rem;
    height: 3.18rem;
    line-height: 3.18rem;
    text-align: center;
    margin: 0 auto;
    background-color: #00c28e;
    color: white;
    border-radius: 1.625rem;
    cursor: pointer;
    .alink {
      color: white;
    }
  }
  .daoContent {
    margin-top: 3.5rem;
    display: flex;
    justify-content: center;
    .left {
      justify-content: flex-end;
      margin-right: 2.6rem;
    }
    .right {
      margin-left: 2.6rem;
    }
    .left,
    .right {
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      width: 14.6rem;
      .tag {
        width: 14.6rem;
        text-align: center;
        height: 5.56rem;
        line-height: 5.56rem;
        border: 1px solid #00adff;
        border-radius: 1rem;
        font-size: 1.25rem;
        color: #1471fb;
      }
    }
    img {
      width: 35rem;
      ${({ theme }) => theme.mediaWidth.screenMd`
      width:calc(100% - 8.75rem);
    `}
    }
  }
`

export const More = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 70px 0 0;
  .daoTitle {
    text-align: center;
    color: #00477c;
    font-size: 3rem;
    /* ${({ theme }) => theme.mediaWidth.screenMd`
       font-size:3rem;
    `} */
  }
  .daoTips {
    color: #87868d;
    font-size: 1.375rem;
    width: 30.25rem;
    margin: 2rem auto;
    text-align: center;
    ${({ theme }) => theme.mediaWidth.screenMd`
       font-size:1.625rem;
       padding:0 1rem;
    `}
  }
  .daoBtn {
    position: relative;
    z-index: 50;
    width: 10rem;
    height: 3.18rem;
    line-height: 3.18rem;
    text-align: center;
    margin: 0 auto;
    background-color: #00c28e;
    color: white;
    border-radius: 1.625rem;
    cursor: pointer;
    .alink {
      color: white;
    }
  }
  .daoContent {
    display: flex;
    justify-content: center;
    ${({ theme }) => theme.mediaWidth.screenMd`
      margin-bottom: 7.0625rem;
    `}
    img {
      margin-top: -4rem;
      width: 100%;
      ${({ theme }) => theme.mediaWidth.screenMd`
      margin: 3.5rem 0;
      width:calc(100% - 8.75rem);
    `}
    }
  }
`

export const IntroduceDiv = styled.div`
  display: flex;
  align-items: center;
  /* min-height: 57.06rem; */
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.screenMd`
    // min-height: 56.56rem;
  `}
`

export const IntroduceTitle = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: #363639;
  margin-top: 6.25rem;
  margin-bottom: 3.13rem;
  span {
    color: #363639;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    margin-bottom: 2.69rem;
    margin-top: 3.31rem;
    text-align: center;
  `}
`

export const IntroduceSpan = styled.div`
  font-size: 1.13rem;
  font-weight: 400;
  color: ${(props) => props.theme.gray};
  margin-bottom: 3.75rem;
  ${({ theme }) => theme.mediaWidth.screenMd`
    width: 35.13rem;
    margin-bottom: 2.06rem;
    text-align: center;
    font-size: 1.5rem;
  `}
`

export const IntroduceList = styled.div`
  display: flex;
  margin-bottom: 6.25rem;
  ${({ theme }) => theme.mediaWidth.screenMd`
    justify-content: space-between;
    margin: 0 1.38rem;
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    flex-wrap: wrap;
    justify-content: center;
  `}
`

export const ImageDiv = styled.div`
  width: 100%;
  height: 20.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ListInfo = styled.div`
  width: 21rem;
  min-height: 26.13rem;
  background: #ffffff;
  border: 0.06rem solid #5746fe;
  border-radius: 0.63rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  :nth-child(1) {
    margin-right: 2.19rem;
  }
  img {
    width: 100%;
    height: 20.13rem;
    max-height: 20.13rem;
    border-radius: 0.63rem 0.63rem 0 0;
    object-fit: cover;
  }
  span {
    font-size: 1.25rem;
    font-weight: 400;
    color: #363639;
    text-align: center;
    margin-top: 1.88rem;
    margin-bottom: 1.88rem;
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    :nth-child(1) { margin-right: 0; }
    span {font-size: 1.5rem;}
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
    :nth-child(1) { margin-bottom: 1.5rem; }
  `}
`

export const AboutDiv = styled.div`
  position: relative;
  margin-bottom: 9.81rem;
  min-height: 25rem;
  ${({ theme }) => theme.mediaWidth.screenMd`
    margin-top: 3.44rem;
    margin-bottom: 2.81rem;
  `}
`

export const AboutDivContent = styled(ComLayoutTwo)`
  min-height: 25rem;
  border: 0.06rem solid #e5e5e5;
  border-radius: 0.63rem;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  ul {
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 4.5rem;
    list-style-type: none;
    padding-inline-start: 0;
    li {
      font-size: 1.25rem;
      font-weight: 400;
      color: ${(props) => props.theme.gray};
      margin-bottom: 1.75rem;
      list-style-type: none;
      &::marker {
        content: '';
      }
    }
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    width: calc(100% - 1.38rem);
    min-height: 36.13rem;
    height: auto;
    border-radius: 0.63rem;
    margin-left: 0.69rem;
    ul {
      width: calc(100% - 8.75rem);
      margin-left: 4.375rem;
      li {
        font-size: 1.5rem;
      }
    }
  `}
  ${({ theme }) => theme.mediaWidth.screenSm`
      ul {
        margin-left: 5%;
        width: 90%;
      }
    `}
`

export const AboutTitle = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: #363639;
  margin: 3.75rem 0;
  position: relative;
  ${({ theme }) => theme.mediaWidth.screenMd`
    margin: 2.75rem 0;
  `}
`
