import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import Web3 from 'web3'
import MON from '@/assets/svg/usdt.svg'
import centreusdc from '@/assets/centre-usdc_28.png'
import cc from '@/assets/cc.png'
const web3 = new Web3(Web3.givenProvider)

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
  NetWork = 'NetWork',
}

export interface ConnectorNamesType {
  src: 'Injected' | 'WalletConnect' | 'NetWork'
}

export const RPC_URLS: { [chainId: number]: string } = {
  5: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
}

export const injected = new InjectedConnector({ supportedChainIds: [5] })

export const walletconnect = (rpc: any, chainId: number) => {
  return new WalletConnectConnector({
    rpc,
    chainId,
    qrcode: true,
    infuraId: '9aa3d95b3bc440fa88ea12eaa4456161',
  })
}

export const network = new NetworkConnector({
  urls: { 5: RPC_URLS[5] },
  defaultChainId: 5,
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.NetWork]: network,
}

export const defaultChainId = 5

export const useConstant = {
  5: {
    CHAIN_ID: 5,
    Blockchain: 'Goerli',
    RPC_URL: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    Token_ADDRESS: '0x4b3429B5981901FCCdaA311a6AC71937856994D5',
    SharedToken_ADDRESS: '0xC9393B5e75b614205AE8e34BE3c10e97d0A9f4BF',
    Shared1155Token_ADDRESS: '0x5648B44d458F7d53a67C19855f19B32F033c557c',
    FundSplitter_ADDRESS: '0x30D3F35Bf8253eeA3Ff142D270A3547Ec145C25B',
    Member_ADDRESS: '0x2e7698e461407d3E9d9aA8330C5B9803f2831b42',
    Categories_ADDRESS: '0x6C5525b3c48a3192d89A8ad012749c803C013971',
    Market_ADDRESS: '0x6Ade69a74d910D500C0CE8f10CE473aB307f577c',

    payTokenOptions: [
      {
        value: '0x4b3429B5981901FCCdaA311a6AC71937856994D5',
        label: 'USDT',
        ico: MON,
        type: 'ether',
      },
      {
        value: '0x9170FCa7Cdb3866c147975086eb9ae7b16cCbe3E',
        label: 'USDC',
        ico: centreusdc,
        type: 'mwei',
      },
      {
        value: '0x692cB1bd333AbE7006BCceE3d040A3143Be93FD1',
        label: 'CC',
        ico: cc,
        type: 'mwei',
      },
    ],
    apiUrl: '',
    apiKey: '',
    role: '0x5db53b6955dc773ef555b3cc1835ee528ea8da7adbe1cd523fbff73209b77e0b',
    reqUrl: 'https://carbon-exchange.com/CTC/',
    readUrl: 'https://eth-goerli.g.alchemy.com/v2/QWA7Z_FDoslnQD3tkUbBBA3r6kiA2c1V',
  },
}

export const netWorks = {
  5: {
    chainId: web3.utils.numberToHex(5),
    isSwitch: true,
  },
}
