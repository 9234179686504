import styled from 'styled-components'

export const UserMyNftWrapper = styled.div`
  margin-top: 5rem;

  .pagination-nft {
    height: 8.31rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-pagination-item,
    .ant-pagination-item-link {
      border-radius: 0.25rem;
    }
  }
  .mynft-details {
    padding-top: 0;
  }
  .content-nft {
    display: flex;

    ${({ theme }) => theme.mediaWidth.screenMd`
       padding: 2rem 0 0 0;
       flex-wrap: wrap;
    `}
    .nft-left {
      width: 21.3125rem;
      ${({ theme }) => theme.mediaWidth.screenMd`
       width:100%;
    `}
    }
    .nft-right {
      width: calc(100% - 21.3125rem);
      padding: 0 4rem;
      ${({ theme }) => theme.mediaWidth.screenMd`
     width:100%;
     padding:0 1.75rem;
    `}
    }
  }
  .content-nft-info {
    width: 20%;
    display: flex;
    justify-content: center;
    .ant-spin-nested-loading{
      width: 100%;
    }
    ${({ theme }) => theme.mediaWidth.screebXll`
      width: 25%;
    `}
    ${({ theme }) => theme.mediaWidth.screenMd`
      width: 50%;
    `}
    ${({ theme }) => theme.mediaWidth.screenXl`
      width: 33%;
    `}
    ${({ theme }) => theme.mediaWidth.screenSm`
      width: 50%;
    `}
  }
`

export const TitleVice = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
  color: #5d6175;
  width: 100%;
  height: 3.75rem;
  border-radius: 0.63rem;
  display: flex;
  /* padding-left: 2.5rem; */
  align-items: center;
  margin-bottom: 1.88rem;
  .titlesssssss {
    width: 90%;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }
  ${({ theme }) => theme.mediaWidth.screenMd`
    margin-top: 1.88rem;
    background-color: #F5F6F8;
    padding:1rem 1.5625rem;
    height:auto;
    .titlesssssss{
      width:100%;
      color:#5D6175;
      font-size:1.75rem;
    }
  `}
`

export const StateList = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #dedede;
  .under {
    ${({ theme }) => theme.mediaWidth.screenMd`
    width:5rem;
    height:0.3125rem;
    margin:0.875rem auto 0 auto;
    border-radius: 0.1875rem;
    &.ac{
      background:#00477C;
    }

  `}
  }
  .span {
    font-size: 1.375rem;
    font-weight: 400;
    color: ${(props) => props.theme.gray};
    padding: 1.625rem 0 1.625rem 3rem;
    cursor: pointer;
    width: 100%;
  }
  .span.active {
    color: #00477c;
    background-color: white;
    .spanItem {
      border-left: 3px solid #00477c;
      line-height: 1.5625rem;
      ${({ theme }) => theme.mediaWidth.screenMd`
     border-left: none;
    //  border-bottom:none;
  `}
    }
  }
  background: ${(props) => props.theme['gary-4']};
  height: 100vh;
  ${({ theme }) => theme.mediaWidth.screenMd`
    height:auto;
    border:none;
    background:none;
    .span {
      margin-top: 0;
      padding:1.625rem 0 ;
      width:calc(100% / 3);
      text-align: center;
      font-size: 2.125rem;
      color:#00477C;
    }
    flex-direction: row-reverse;
  `}
`

export const NftList = styled.div`
  display: flex;
  flex-wrap: wrap;
`
