const statement = {
  'statement.title': 'Carbon-Carbon-Exchange Disclaimer',
  'statement.content1':
    'Before accepting the services of Carbon-Exchange\'s official website ("Carbon-Exchange," "we," "our"), please read these terms carefully and agree to this statement. The visitor\'s behavior in visiting Carbon-Exchange and using Carbon-Exchange in various ways will be regarded as unobjectionable recognition of the entire content of this statement: If there is any objection, please consult with Carbon-Exchange immediately and obtain Written consent of Carbon-Exchange.',
  'statement.content2':
    '1. Visitors must act in good faith and prudent manner when engaging in all activities related to Carbon-Exchange (including but not limited to visiting, browsing, utilizing, reprinting, publicity and introduction); visitors shall not intentionally or negligently damage or weaken the various legal rights and interests of Carbon-Exchange, and shall not use Carbon-Exchange to directly or indirectly engage in behaviors that violate Chinese laws, international conventions and social ethics in any way. Visitors should abide by the following commitments:',
  'statement.content3':
    '1. The transmission and use of information conform to Chinese laws, international conventions, public order, and good customs;',
  'statement.content4': '2. Do not use Carbon-Exchange and its related network services for illegal or illegal purposes;',
  'statement.content5': '3. Do not interfere and disrupt Carbon-Exchange and its related network services;',
  'statement.content6':
    '4. Comply with the agreements, regulations, procedures, and practices related to Carbon-Exchange and its related network services.',
  'statement.content7':
    '2.Carbon-Exchange carbon assets, Carbon-Exchange, and carbon project parties share the copyright unless we have stated otherwise or local laws have mandatory provisions. Written authorization, unauthorized reproduction, or other commercial use is strictly prohibited.',
  'statement.content8':
    '3.Carbon-Exchange has the right to use the works published in Carbon-Exchange for other purposes, including websites, electronic magazines, etc., unless the results have a copyright statement.',
  'statement.content9':
    '4. Without the mutual consent of Carbon-Exchange and the creator, any other organization shall not infringe the copyright of its works in any form, including but not limited to: unauthorized copying, linking, illegal use, reprinting, or creating mirror images of employment in any way.',
  'statement.content10':
    '5.  The works published by Carbon-Exchange in various forms (including but not limited to text, pictures, and graphics) are for reference only, and it does not mean that Carbon-Exchange agrees with its statement or description. Does not constitute any investment advice. For all behaviors made by visitors based on the information provided by Carbon-Exchange, Carbon-Exchange does not assume any form of responsibility unless there is an express written commitment.',
  'statement.content11':
    '6. When Carbon-Exchange recommends the content of other websites in the form of links, Carbon-Exchange is not responsible for the availability of these websites or resources and does not guarantee the authenticity of any content, products, services, or other materials obtained from these websites, Carbon-Exchange is not responsible for any direct or indirect damages whatsoever caused (or alleged to be caused) by the use of or reliance on the content, products, services or other materials obtained from such sites or resources.',
  'statement.content12':
    '7. We do not warrant that the functions contained in the materials contained in Carbon-Exchange will be uninterrupted or error-free, that defects will be corrected, or that Carbon-Exchange or the server that provides it will be free of viruses or represent the full functionality, accuracy, and functionality of the materials. Reliability. In no event shall we be liable for any loss or damage, including, without limitation, indirect or consequential loss or damage, or any loss or damage arising out of or in connection with the use or use of data or profits.',
  'statement.content13':
    '8. For some personal information provided by visitors when they register with Carbon-Exchange, Carbon-Exchange will not disclose any information of users to any party in any way except with your consent and the provisions of Article 9.',
  'statement.content14':
    '9.  When government departments, judicial authorities, etc., require Carbon-Exchange to disclose personal data per legal procedures, Carbon-Exchange will provide personal data as required by law enforcement agencies or for public safety. Carbon-Exchange assumes no responsibility for any disclosure under this circumstance.',
  'statement.content15':
    '10. Carbon-Exchange is not responsible for any leakage of personal data or loss of funds due to the user informing others of the wallet Private Key or Recovery Phrase or sharing the account with others.',
  'statement.content16':
    '1. Some of the content of Carbon-Exchange comes from the Internet. If you inadvertently infringe the intellectual property rights of any media, company, enterprise, or individual, please call or write to inform, and Carbon-Exchange will delete it within the specified time. If there are issues involving copyright fees, please provide relevant certificates and other materials promptly, contact us, and resolve disputes through friendly negotiation and fairness.',
  'statement.content17':
    '12. The final interpretation right of the contents of the above statement belongs to the Carbon-Exchange website.',
}

export default statement
