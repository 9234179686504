import React, { memo, useEffect, useState, useRef } from 'react'
import { CreateDetailsModalWrapper, DescribeDiv, CurrentThemeDiv, ImageDiv, PriceDiv, Details } from './styled'
import { CardModalWrapper } from '@/components/Card/styled'
import type { CardType } from '@/common/data.d'
import { Form, message, Modal, Select, InputNumber, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { Row, Col, Image, Button } from 'antd'
import { ImageError } from '@/common/init'
import ConnectWallet from '@/components/ConnectWallet'
import { useDispatch, useSelector } from 'react-redux'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { AdaptFontSize, Adapth5, formatStrAddress, proLink, validateValue } from '@/utils'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import Modelviewer from '@/components/ModelViewer'
import NoData from '@/components/NoData'
import { nftPriceIcon, nftPriceIconColor } from '@/contracts/init'
import detail from '@/assets/page_slices/detail.png'
import detail1 from '@/assets/page_slices/detail1.png'
import Current from '@/assets/page_slices/current.png'
import Current1 from '@/assets/page_slices/current1.png'
import features from '@/assets/page_slices/features.png'
import features1 from '@/assets/page_slices/features1.png'
import des from '@/assets/page_slices/des.png'
import des1 from '@/assets/page_slices/des1.png'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'
import { GetWeb3StorageJsonOne, GetWeb3StorageJsonOneNew, retrieveFiles } from '@/common'
import DEFAULT_IMG from '@/assets/default.png'
import BigNumber from 'bignumber.js'
import copy from '@/assets/copy.png'
import Web3 from 'web3'
import { DelSpining, SaveSpining } from '@/store/user/action'
import { getData } from '@/api'

interface Type {
  details: CardType
  returnRefreshData: () => void
  serviceCharge?: string
}

const { Option } = Select

export default memo(function CreateDetailsModalPage(props: Type) {
  const modalRef = useRef<any>(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { details, returnRefreshData, serviceCharge = '2.5' } = props
  const context = useWeb3React<Web3Provider>()
  const { active } = context
  const myAddress = useSelector((state: any) => state.userInfo.address)
  const { windowSize } = useWindowSizeHooks()

  const [itemDetail, setItemDetail] = useState<any>({})
  const [orgData, setOrgData] = useState<any>({})
  const [metaLoading, setMetaLoading] = useState(false)

  const nftData: ConstantInitTypes = useDataHooks()
  const { minimumSaleAmount, SharedToken_ADDRESS, Blockchain, payTokenOptions, constant, Market_ADDRESS, web3, Shared1155Token_ADDRESS } =
    nftData

  const [onMynftShow, setOnMynftShow] = useState(false)
  const [type, setType] = useState<string>('.png')
  const [uintSelect, setUintSelect] = useState<string>(() => {
    return payTokenOptions[0].label
  })

  const [isCancelLoading, setIsCancelLoading] = useState(false)
  const [isSellLoading, setIsSellloading] = useState(false)

  useEffect(() => {
    init(details)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details])

  const init = async (item: any) => {
    // setIsCancelLoading(true)
    dispatch(SaveSpining(true))
    try {
      if (item.categoriesId || item.categories_cid) {
        let navThemeCidV3 = Web3.utils.hexToAscii(item.categoriesId || item.categories_cid)
        const pro_data: any = await GetWeb3StorageJsonOne(navThemeCidV3, false, 'Collection')

        let uri =
          item.type === 1
            ? await constant.ContractMarketSharedTokenRead.methods.tokenURI(item.tokenID).call()
            : await constant.ContractShared1155TokenRead.methods.uri(item.tokenID).call()
        const data: any = await GetWeb3StorageJsonOneNew(uri)

        let royaltyCall: any = await constant.ContractMarketSharedTokenRead.methods.royaltyInfo(item.tokenID, web3.utils.toWei('1')).call()
        let royaltyB = royaltyCall[1] === '0' ? '0' : new BigNumber(royaltyCall[1]).div(web3.utils.toWei('1')).toFixed(6)
        let royalty = royaltyB === '0' ? '0' : new BigNumber(royaltyB).multipliedBy(100).toFixed(4)

        let obj: any = {
          address: item.ownner || item.minter || item.address || item.seller,
          categoriesName: item.categoriesId || item.categories_cid,
          name: data.name ? data.name : 'NFT-' + item.tokenID,
          image: data.imageFiles ? data.imageFiles : DEFAULT_IMG,
          cover: data.coverFiles ? data.coverFiles : DEFAULT_IMG,
          description: data.description || '',
          nameTheme: pro_data.name ? pro_data.name : pro_data.axiosData.name,
          price: item.price || '0',
          time: '',
          royalty,
          isImport: false,
          status: item.status,
          rotAddress: data.myAddress || '',
          num: item.num || item.amount,
          type: item.type || (item.contractAddress.toLowerCase() === Shared1155Token_ADDRESS.toLowerCase() ? 2 : 1),
          tokenID: item.tokenID,
          unit:
            item.unit ||
            (item.ERC20_address
              ? payTokenOptions.find((item1) => item1.value.toLowerCase() === item.ERC20_address.toLowerCase()).label
              : '') ||
            '',
          serialNumber: data.projectOffcialLink || '',
          attributes: data.attributes || '',
        }
        if (data.imageFiles) {
          let s = data.imageFiles.substr(data.imageFiles.lastIndexOf('.'))
          setType(s)
        } else {
          setType('.png')
        }

        setItemDetail(obj)
        dispatch(DelSpining(false))
        setMetaLoading(true)

        let cidV1 = Web3.utils.hexToAscii(item.metadata_cid)
        setOrgData(await retrieveFiles(cidV1))
        setMetaLoading(false)
      }
    } catch {
      dispatch(DelSpining(false))
    }
  }

  const onFinish = async (values: any) => {
    let isTrueNumber = validateValue(values.pledge)
    if (!isTrueNumber) {
      message.warning({
        content: t('mynft.message.tips1', { msg: minimumSaleAmount }),
        className: 'message-global',
      })
      return false
    }
    if (Number(values.pledge) < minimumSaleAmount) {
      message.warning({
        content: t('mynft.message.tips1', { msg: minimumSaleAmount }),
        className: 'message-global',
      })
      return false
    }
    sellClick(values)
  }

  /** my nft sell click */
  const sellClick = async (values: any) => {
    try {
      setIsSellloading(true)

      let isAuthorize =
        details.type === 2
          ? await constant.ContractShared1155TokenRead.methods.isApprovedForAll(myAddress, Market_ADDRESS).call()
          : await constant.ContractMarketSharedTokenRead.methods.isApprovedForAll(myAddress, Market_ADDRESS).call()
      if (!isAuthorize) {
        const contarct: any = itemDetail.type === 2 ? constant.ContractShared1155Token : constant.ContractMarketSharedToken
        contarct.methods
          .setApprovalForAll(Market_ADDRESS, true)
          .send({
            from: myAddress,
          })
          .on('transactionHash', function (hash: any) {
            console.log(hash)
          })
          .on('receipt', async (receipt: any) => {
            sellImplement(values)
          })
          .on('error', function (error: any, receipt: any) {
            message.error({
              content: error.message,
              className: 'message-global',
            })
            setIsSellloading(false)
          })
      } else {
        sellImplement(values)
      }
    } catch (error) {
      console.log('error', error)
      setIsSellloading(false)
    }
  }

  const sellImplement = async (values: any) => {
    try {
      // let price = web3.utils.toWei(values.pledge.toString(), 'ether')
      let price = web3.utils.toWei(values.pledge.toString(), payTokenOptions.find((item) => item.label === values.uint).type)
      let currency = payTokenOptions.find((item) => item.label === values.uint).value

      constant.ContractMarket.methods
        .addCollectible(
          itemDetail.categoriesName,
          itemDetail.isImport ? itemDetail.contracts : itemDetail.type === 1 ? SharedToken_ADDRESS : Shared1155Token_ADDRESS,
          itemDetail.tokenID,
          itemDetail.type === 2 ? values.amount : '1',
          currency,
          price,
        )
        .send({
          from: myAddress,
        })
        .on('transactionHash', function (hash: any) {
          console.log(hash)
        })
        .on('receipt', async (receipt: any) => {
          message.success({
            content: t('mynft.message.tips2'),
            className: 'message-global',
          })
          setOnMynftShow(false)
          returnRefreshData()
        })
        .on('error', function (error: any, receipt: any) {
          message.error({
            content: error.message,
            className: 'message-global',
          })
          setIsSellloading(false)
        })
    } catch (error) {
      console.log('error', error)
      setIsSellloading(false)
    }
  }

  const uintOnChange = (e: any) => setUintSelect(e)

  /** my nft cancel click */
  const cancelClick = async () => {
    try {
      dispatch(SaveSpining(true))
      // setIsCancelLoading(true)
      let isAuthorize =
        details.type === 2
          ? await constant.ContractShared1155TokenRead.methods.isApprovedForAll(myAddress, Market_ADDRESS).call()
          : await constant.ContractMarketSharedTokenRead.methods.isApprovedForAll(myAddress, Market_ADDRESS).call()
      if (!isAuthorize) {
        const contract: any = details.type === 2 ? constant.ContractShared1155Token : constant.ContractMarketSharedToken
        contract.methods
          .setApprovalForAll(Market_ADDRESS, true)
          .send({
            from: myAddress,
          })
          .on('transactionHash', function (hash: any) {
            console.log(hash)
          })
          .on('receipt', async (receipt: any) => {
            cancelImplement()
          })
          .on('error', function (error: any, receipt: any) {
            message.error({
              content: error.message,
              className: 'message-global',
            })
            // setIsCancelLoading(false)
            dispatch(DelSpining(false))
          })
      } else {
        cancelImplement()
      }
    } catch (error) {
      console.log('error', error)
      // setIsCancelLoading(false)
      dispatch(DelSpining(false))
    }
  }

  const cancelImplement = () => {
    try {
      constant.ContractMarket.methods
        .removeCollectible(details.id)
        .send({ from: myAddress })
        .on('transactionHash', function (hash: any) {
          console.log(hash)
        })
        .on('receipt', async (receipt: any) => {
          message.success({
            content: t('mynft.message.tips3'),
            className: 'message-global',
          })
          // setIsCancelLoading(false)
          dispatch(DelSpining(false))
          returnRefreshData()
        })
        .on('error', function (error: any, receipt: any) {
          message.error({
            content: error.message,
            className: 'message-global',
          })

          // setIsCancelLoading(false)
          dispatch(DelSpining(false))
          init(details)
        })
    } catch (error) {
      console.log('error', error)
      // setIsCancelLoading(false)
      dispatch(DelSpining(false))
    }
  }

  const copyAddress = (val: any) => {
    let aux = document.createElement('input')
    aux.setAttribute('value', val)
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
    message.success({
      content: t('igonft.apply.copy.success'),
      className: 'message-global',
    })
  }

  return (
    <Spin spinning={isCancelLoading} tip="Loading..." className="antd-loadings">
      <CreateDetailsModalWrapper ref={modalRef}>
        <div className="meta-data" style={{ marginBottom: '1.75rem' }}>
          <div className="main-content">
            <div className="content-left">
              <ImageDiv>
                {(type === '.jpg' || type === '.jpeg' || type === '.png' || type === '.gif' || type === '.svg' || '.soogif') && (
                  <img src={itemDetail.image || DEFAULT_IMG} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                )}
                {(type === '.mp4' || type === '.webm') && <video src={itemDetail.image || DEFAULT_IMG} controls autoPlay loop></video>}
                {(type === '.mp3' || type === '.wav' || type === '.ogg') && (
                  <audio controls>
                    <source src={itemDetail.image || DEFAULT_IMG} />
                  </audio>
                )}
                {type === '.gltf' && <Modelviewer src={itemDetail.image || DEFAULT_IMG} />}
              </ImageDiv>
              {windowSize.innerWidth > Adapth5 && (
                <DescribeDiv style={{ marginTop: '1.5rem' }}>
                  <h3>
                    <img src={features} alt="" />
                    {t('market.details.vice.title2')}
                  </h3>
                  <div className="info" style={{ marginTop: '1rem' }}>
                    <div className="span">{t('market.details.vice.title2.list1')}</div>
                    <div
                      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                      onClick={() =>
                        copyAddress(itemDetail.type ? (itemDetail.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '--')
                      }
                    >
                      {formatStrAddress(
                        6,
                        4,
                        itemDetail.type ? (itemDetail.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '--',
                      )}
                      <img src={copy} alt="" style={{ height: '1.5rem', marginLeft: '0.5rem' }} />
                    </div>
                  </div>
                  <div className="info">
                    <div className="span">{t('Token.ID')}</div>
                    {itemDetail.tokenID}
                  </div>
                  <div className="info">
                    <div className="span">{t('market.details.vice.title2.list2')}</div>
                    {itemDetail.type === 2 ? 'ERC1155' : 'ERC721'}
                  </div>
                  <div className="info">
                    <div className="span">{t('market.details.vice.title2.list3')}</div>
                    {Blockchain}
                  </div>
                  <div className="info">
                    <div className="span">{t('market.details.vice.title2.list4')}</div>
                    {t('market.details.vice.title2.list.info3')}
                  </div>
                </DescribeDiv>
              )}
            </div>
            <div className="content-right">
              <CurrentThemeDiv>
                {t('market.details.theme.title')}
                {itemDetail.nameTheme}
              </CurrentThemeDiv>
              <h2>{itemDetail.name}</h2>
              <DescribeDiv style={{ marginBottom: '1.8125rem' }}>
                <h3>
                  <img src={windowSize.innerWidth <= Adapth5 ? Current1 : Current} alt="" /> {t('market.details.price.title')}
                </h3>
                <div className="price-content">
                  <PriceDiv style={{ color: nftPriceIconColor[itemDetail.unit || ''], display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      {itemDetail.status === '2' ? (
                        <>
                          <img src={nftPriceIcon[itemDetail.unit || '']} width={16} alt="" style={{ marginRight: '5px' }} />
                          {Number(
                            web3.utils.fromWei(
                              itemDetail.price ? itemDetail.price.toString() : '0',
                              payTokenOptions.find((item) => item.label === itemDetail.unit)?.type,
                            ),
                          ) || 0}
                          <span style={{ marginLeft: '5px' }}> {itemDetail.unit || ''}</span>
                        </>
                      ) : (
                        '0'
                      )}
                    </div>
                    <div>x{itemDetail.amount || itemDetail.num}</div>
                  </PriceDiv>
                  {/* <h5>{t('market.details.price.title.vice')}</h5> */}

                  {!active && <ConnectWallet status="shop" isTop={true} close={() => {}} />}
                  {active &&
                    itemDetail.address &&
                    itemDetail.status !== '2' &&
                    itemDetail.address.toLowerCase() === myAddress.toLowerCase() && (
                      <div className="sell_btn" onClick={() => setOnMynftShow(true)}>
                        {t('mynft.sell.btn')}
                      </div>
                    )}
                  {active &&
                    itemDetail.address &&
                    itemDetail.status === '2' &&
                    itemDetail.address.toLowerCase() === myAddress.toLowerCase() && (
                      <div className="sell_btn" onClick={cancelClick}>
                        {t('mynft.sell.cancel')}
                      </div>
                    )}
                </div>
              </DescribeDiv>
              <DescribeDiv className="addDes" style={{ marginBottom: '1.8125rem' }}>
                {itemDetail.type === 1 && (
                  <>
                    <div className="info">
                      <div className="span">{t('market.details.vice.title3')}</div>
                      <span
                        style={{
                          width: windowSize.innerWidth > AdaptFontSize ? '100%' : 'auto',
                          textAlign: windowSize.innerWidth <= AdaptFontSize ? 'left' : 'end',
                        }}
                      >
                        {Number(itemDetail.royalty)}%
                      </span>
                    </div>
                    <div className="info">
                      <div className="span">{t('market.details.vice.title5')}</div>
                      <span
                        style={{
                          width: windowSize.innerWidth > AdaptFontSize ? '100%' : 'auto',
                          textAlign: windowSize.innerWidth <= AdaptFontSize ? 'left' : 'end',
                        }}
                      >
                        {' '}
                        {itemDetail.rotAddress ? formatStrAddress(6, 4, itemDetail.rotAddress) : '--'}
                      </span>
                    </div>
                  </>
                )}
                <div className="info">
                  <div className="span">{t('market.details.vice.title4')}</div>
                  <span
                    style={{
                      width: windowSize.innerWidth > AdaptFontSize ? '100%' : 'auto',
                      textAlign: windowSize.innerWidth <= AdaptFontSize ? 'left' : 'end',
                    }}
                  >
                    {itemDetail.time || '--'}
                  </span>
                </div>
              </DescribeDiv>
              {windowSize.innerWidth <= Adapth5 && (
                <DescribeDiv style={{ margin: '1.5rem 0' }}>
                  <h3>
                    <img src={features1} alt="" />
                    {t('market.details.vice.title2')}
                  </h3>
                  <div className="info" style={{ marginTop: '1rem' }}>
                    <div className="span">{t('market.details.vice.title2.list1')}</div>
                    <div
                      onClick={() =>
                        copyAddress(itemDetail.type ? (itemDetail.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '--')
                      }
                    >
                      {formatStrAddress(
                        6,
                        4,
                        itemDetail.type ? (itemDetail.type === 2 ? Shared1155Token_ADDRESS : SharedToken_ADDRESS) : '--',
                      )}
                      <img src={copy} alt="" style={{ height: '2rem', marginLeft: '0.5rem', cursor: 'pointer' }} />
                    </div>
                  </div>
                  <div className="info">
                    <div className="span">{t('market.details.vice.title2.list2')}</div>
                    {itemDetail.type === 2 ? 'ERC1155' : 'ERC721'}
                  </div>
                  <div className="info">
                    <div className="span">{t('market.details.vice.title2.list3')}</div>
                    {Blockchain}
                  </div>
                  <div className="info">
                    <div className="span">{t('market.details.vice.title2.list4')}</div>
                    {t('market.details.vice.title2.list.info3')}
                  </div>
                </DescribeDiv>
              )}
              <DescribeDiv>
                <h3>
                  <img src={windowSize.innerWidth <= Adapth5 ? des1 : des} alt="" />
                  {t('market.details.vice.title1')}
                </h3>
                {itemDetail.description && (
                  <ul>
                    <li>{itemDetail.description}</li>
                  </ul>
                )}
                {!itemDetail.description && (
                  <div style={{ marginBottom: '2.5rem' }}>
                    <NoData top={0} />
                  </div>
                )}
              </DescribeDiv>
              {/* 元数据详情 */}
              <Details>
                <div className="des">
                  <div className="heder">
                    <img src={windowSize.innerWidth <= Adapth5 ? detail1 : detail} alt="" />
                    {t('project.nft.detail.dataDetail')}
                  </div>
                  <Spin spinning={metaLoading} tip="Loading...">
                    <div className="metaContent">
                      <div className="metaItem">
                        <div className="detailTitle">{t('project.nft.detail.country')}</div>
                        <div className="detailValue">
                          {itemDetail.attributes
                            ? itemDetail.attributes.filter((item: any) => item.trait_type === 'country' || item.trait_type === 'nation')[0]
                                .value
                            : '--'}
                        </div>
                      </div>
                      <div className="metaItem">
                        <div className="detailTitle">{t('project.nft.detail.num')}</div>
                        <div className="detailValue">
                          {itemDetail.attributes ? (
                            <a href={itemDetail.serialNumber || proLink} target="_blank" rel="noreferrer">
                              {itemDetail.attributes.filter((item: any) => item.trait_type === 'serialNum')[0].value || proLink}
                            </a>
                          ) : (
                            '--'
                          )}
                        </div>
                      </div>
                      <div className="metaItem">
                        <div className="detailTitle">{t('project.nft.detail.stan')}</div>
                        <div className="detailValue">
                          {itemDetail.attributes
                            ? itemDetail.attributes.filter((item: any) => item.trait_type === 'standard')[0].value
                            : '--'}
                        </div>
                      </div>
                      <div className="metaItem">
                        <div className="detailTitle">{t('project.nft.detail.amount')}</div>
                        <div className="detailValue">
                          {itemDetail.attributes
                            ? itemDetail.attributes.filter((item: any) => item.trait_type === 'amount' || item.trait_type === 'num')[0]
                                .value
                            : '--'}
                        </div>
                      </div>
                      <div className="metaItem">
                        <div className="detailTitle">{t('project.nft.detail.type')}</div>
                        <div className="detailValue">
                          {itemDetail.attributes ? itemDetail.attributes.filter((item: any) => item.trait_type === 'type')[0].value : '--'}
                        </div>
                      </div>
                      <div className="metaItem">
                        <div className="detailTitle">{t('project.nft.detail.time')}</div>
                        <div className="detailValue">
                          {itemDetail.attributes
                            ? itemDetail.attributes.filter((item: any) => item.trait_type === 'vintage' || item.trait_type === 'cycle')[0]
                                .value
                            : '--'}
                        </div>
                      </div>
                      <div className="metaItem">
                        <div className="detailTitle">{t('project.nft.detail.proName')}</div>
                        <div className="detailValue">
                          {itemDetail.attributes
                            ? itemDetail.attributes.filter(
                                (item: any) => item.trait_type === 'projectName' || item.trait_type === 'name',
                              )[0].value
                            : '--'}
                        </div>
                      </div>
                      <div className="metaItem">
                        <div className="detailTitle">{t('project.nft.detail.proId')}</div>
                        <div className="detailValue">
                          {itemDetail.attributes
                            ? itemDetail.attributes.filter((item: any) => item.trait_type === 'projectID' || item.trait_type === 'id')[0]
                                .value
                            : '--'}
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </Details>
            </div>
          </div>
        </div>

        <Modal
          visible={onMynftShow}
          className="modal-mask"
          footer={null}
          onCancel={() => setOnMynftShow(false)}
          width="55.63rem"
          centered
          getContainer={modalRef.current}
          bodyStyle={{ padding: '5rem 1.5rem' }}
          destroyOnClose
        >
          <Spin tip="SellLoading..." spinning={isSellLoading}>
            <Row gutter={[16, 32]} className="sell-row">
              <Col span={24} lg={14}>
                <Form onFinish={onFinish} initialValues={{ uint: payTokenOptions[0].label }}>
                  <h2>{t('mynft.sell.modal.title')}</h2>
                  <div className="pledge-content">
                    <div className="input-titles">
                      <span>{t('mynft.sell.modal.input.title')}</span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Form.Item name="uint" rules={[{ required: true, message: t('mynft.form.item.rules1') }]}>
                        <Select className="select-before mySelect" size="large" onChange={uintOnChange}>
                          {payTokenOptions.map((item, index) => (
                            <Option value={item.label} key={index}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item name="pledge" rules={[{ required: true, message: t('mynft.form.item.rules2') }]} className="inputs">
                        <InputNumber
                          style={{ width: '100%' }}
                          type="number"
                          precision={6}
                          min={0.000001}
                          max={999999999.999999}
                          placeholder={t('mynft.sell.modal.input.placeholder')}
                          addonAfter={uintSelect}
                          size="large"
                        ></InputNumber>
                      </Form.Item>
                    </div>
                    {itemDetail.type === 2 && (
                      <div style={{ display: 'flex' }}>
                        <Form.Item
                          name="amount"
                          rules={[{ required: true, message: t('mynft.sell.modal.input.placeholder1') }]}
                          style={{ width: '100%' }}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            type="number"
                            precision={0}
                            min={1}
                            max={itemDetail.num}
                            placeholder={t('mynft.sell.modal.input.placeholder1')}
                            size="large"
                          ></InputNumber>
                        </Form.Item>
                      </div>
                    )}
                    {itemDetail.type === 1 && (
                      <>
                        <div className="input-titless" style={{ marginTop: '0' }}>
                          <span>{t('mynft.sell.modal.input.title1')}</span>
                          <span>{Number(itemDetail.royalty)}%</span>
                        </div>
                        <div className="input-min-title">{t('mynft.sell.modal.input.titles')}</div>
                      </>
                    )}

                    <div className="input-titless">
                      <span>{t('mynft.sell.modal.input.title3')}</span>
                      <span>{serviceCharge}%</span>
                    </div>
                    <Button className="my-home-btn-3" style={{ borderRadius: '1.875rem' }} htmlType="submit" type="primary">
                      {t('mynft.sell.btn')}
                    </Button>
                  </div>
                </Form>
              </Col>
              <Col span={24} lg={10}>
                <CardModalWrapper>
                  <Image src={itemDetail.cover || itemDetail.image} className="card-modal-img" preview={false} fallback={ImageError} />
                  <div className="title">{itemDetail.name}</div>
                </CardModalWrapper>
              </Col>
            </Row>
          </Spin>
        </Modal>
      </CreateDetailsModalWrapper>
    </Spin>
  )
})
