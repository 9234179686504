import React from 'react'
import ReactDOM from 'react-dom'
import './utils/i18n'
import store from './store'
import App from './App'
import './App.less'
import reportWebVitals from './reportWebVitals'
import { Provider, useSelector } from 'react-redux'
import { ThemeProvider } from './theme/index'
// HashRouter BrowserRouter
import { HashRouter } from 'react-router-dom'
import en_GB from 'antd/lib/locale/en_GB'
import { ConfigProvider, Spin } from 'antd'

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <ThemeProvider>
        <ConfigProvider locale={en_GB}>
          <Spining>
            <App />
          </Spining>
        </ConfigProvider>
      </ThemeProvider>
    </Provider>
  </HashRouter>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

function Spining({ children }: { children: JSX.Element }) {
  const spining = useSelector((state: any) => state.userInfo.spining)

  const tips = useSelector((state: any) => state.userInfo.tips)

  return (
    <Spin
      spinning={spining}
      tip={tips}
      delay={300}
      style={{ position: 'fixed', top: '0', width: '100%', maxHeight: '100vh', zIndex: '999999999999' }}
    >
      {children}
    </Spin>
  )
}
