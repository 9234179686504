import * as actionTypes from './constants'

export const SetUser = (res: string) => ({
  type: actionTypes.SAVE_USER,
  user: res,
})

export const SaveAddress = (res: string) => ({
  type: actionTypes.SAVE_ADDRESS,
  address: res,
})
export const SaveSpining = (res: boolean) => ({
  type: actionTypes.SAVE_SPINING,
  spining: res,
})
export const DelSpining = (res: boolean) => ({
  type: actionTypes.DEL_SPINING,
  spining: res,
})

export const SaveLoadingTips = (res: string) => ({
  type: actionTypes.SAVE_SPING_TIPS,
  tips: res,
})
