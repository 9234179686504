import Web3 from 'web3'
import {
  useConstant,
  FundSplitter_ABI,
  Market_ABI,
  Categories_ABI,
  MarketSharedToken_ABI,
  Token_ABI,
  Member_ABI,
  Shared1155Token_ABI,
} from './constant'

export interface ConstantBallTypes {
  ContractFundSplitter: any
  ContractFundSplitterRead: any
  ContractMarketSharedToken: any
  ContractMarketSharedTokenRead: any
  ContractShared1155Token: any
  ContractShared1155TokenRead: any
  ContractCategories: any
  ContractCategoriesRead: any
  ContractMarket: any
  ContractMarketRead: any
  ContractToken: any
  ContractTokenRead: any
  ContractMember: any
  ContractMemberRead: any
}

export interface ConstantInitTypes {
  web3: Web3
  readWeb3: Web3
  Token_ADDRESS: string
  SharedToken_ADDRESS: string
  Shared1155Token_ADDRESS: string
  FundSplitter_ADDRESS: string
  Member_ADDRESS: string
  Categories_ADDRESS: string
  Market_ADDRESS: string
  constant: ConstantBallTypes
  toWeiFromWei: (s: any) => void
  toWeiFromMwei: (s: any) => void
  minimumSaleAmount: number
  marketPageSize: number
  myNftPageSize: number
  license: string
  Blockchain: string
  payTokenOptions: any[]
  apiUrl: string
  apiKey: string
  role: string
  reqUrl: string
}

export class ConstantInit {
  web3: Web3
  readWeb3: Web3
  Token_ADDRESS: string
  FundSplitter_ADDRESS: string
  SharedToken_ADDRESS: string
  Shared1155Token_ADDRESS: string
  Categories_ADDRESS: string
  Market_ADDRESS: string
  Member_ADDRESS: string
  constant: ConstantBallTypes
  minimumSaleAmount: number
  marketPageSize: number
  myNftPageSize: number
  license: string
  Blockchain: string
  payTokenOptions: any[]
  apiUrl: string
  apiKey: string
  role: string
  reqUrl: string

  constructor(provider: any, chainId: string) {
    const {
      Token_ADDRESS,
      FundSplitter_ADDRESS,
      SharedToken_ADDRESS,
      Shared1155Token_ADDRESS,
      Categories_ADDRESS,
      Market_ADDRESS,
      Member_ADDRESS,
      Blockchain,
      payTokenOptions,
      apiUrl,
      apiKey,
      role,
      reqUrl,
    } = useConstant[chainId]
    this.reqUrl = reqUrl
    this.web3 = new Web3(provider)
    this.readWeb3 = new Web3(provider)
    this.payTokenOptions = payTokenOptions
    this.Blockchain = Blockchain
    this.apiKey = apiKey
    this.apiUrl = apiUrl
    this.Token_ADDRESS = Token_ADDRESS
    this.FundSplitter_ADDRESS = FundSplitter_ADDRESS
    this.SharedToken_ADDRESS = SharedToken_ADDRESS
    this.Shared1155Token_ADDRESS = Shared1155Token_ADDRESS
    this.Market_ADDRESS = Market_ADDRESS
    this.Categories_ADDRESS = Categories_ADDRESS
    this.Member_ADDRESS = Member_ADDRESS
    this.role = role
    this.constant = {
      ContractToken: new this.web3.eth.Contract(Token_ABI, Token_ADDRESS),
      ContractTokenRead: new this.web3.eth.Contract(Token_ABI, Token_ADDRESS),
      ContractFundSplitter: new this.web3.eth.Contract(FundSplitter_ABI, FundSplitter_ADDRESS),
      ContractFundSplitterRead: new this.web3.eth.Contract(FundSplitter_ABI, FundSplitter_ADDRESS),
      ContractMarketSharedToken: new this.web3.eth.Contract(MarketSharedToken_ABI, SharedToken_ADDRESS),
      ContractMarketSharedTokenRead: new this.web3.eth.Contract(MarketSharedToken_ABI, SharedToken_ADDRESS),
      ContractMarket: new this.web3.eth.Contract(Market_ABI, Market_ADDRESS),
      ContractMarketRead: new this.web3.eth.Contract(Market_ABI, Market_ADDRESS),
      ContractCategories: new this.web3.eth.Contract(Categories_ABI, Categories_ADDRESS),
      ContractCategoriesRead: new this.web3.eth.Contract(Categories_ABI, Categories_ADDRESS),
      ContractMember: new this.web3.eth.Contract(Member_ABI, Member_ADDRESS),
      ContractMemberRead: new this.web3.eth.Contract(Member_ABI, Member_ADDRESS),
      ContractShared1155Token: new this.web3.eth.Contract(Shared1155Token_ABI, Shared1155Token_ADDRESS),
      ContractShared1155TokenRead: new this.web3.eth.Contract(Shared1155Token_ABI, Shared1155Token_ADDRESS),
    }
    this.license = (process.env as any).REACT_APP_LICENSE
    this.minimumSaleAmount = (process.env as any).REACT_APP_MINIMUMSALEAMOUNT
    this.marketPageSize = (process.env as any).REACT_APP_MARKETPAGESIZE
    this.myNftPageSize = (process.env as any).REACT_APP_MYNFTPAGESIZE
  }

  toWeiFromWei = (number: any) => {
    let data = this.web3.utils.fromWei(number, 'ether')
    return Number(data).toFixed(6)
  }

  toWeiFromMwei = (number: any) => {
    let data = this.web3.utils.fromWei(number, 'mwei')
    return Number(data).toFixed(6)
  }
}
