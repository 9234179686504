const mynft = {
  'mynft.title': '我的NFT',
  'mynft.title.vice': '当前有',
  'mynft.title.vice.unit': '个',
  'mynft.title.vice.s': '(包含自创和购买的)',
  'mynft.title.vice2': '当前持有购买的有',
  'mynft.title.vice2.unit': '个 NFT',
  'mynft.title.vice3': '当前处于链接交易中的有',
  'mynft.title.vice3.unit': '个 NFT',
  'mynft.sell.btn': '出售',
  'mynft.sell.modal.title': 'NFT 资源出售',
  'mynft.sell.modal.input.title': '出售价格',
  'mynft.sell.modal.input.placeholder': '请输入',
  'mynft.sell.modal.input.placeholder1': '请输入出售数量',
  'mynft.sell.btn.success': '出售成功',
  'mynft.sell.cancel': '取消',
  'mynft.exchange.btn': 'Exchange',
  'mynft.receive.btn': 'Receive Assets',
  'mynft.receive.btn.success': 'Receive Success',
  'mynft.message.tips1': 'The selling price must be greater than or equal to {{msg}} and rounded to the nearest millionth',
  'mynft.message.tips2': '出售成功',
  'mynft.message.sell': '可前往交易大厅查看或取消该NFT资源出售',
  'mynft.message.tips3': '取消成功',
  'mynft.sell.modal.input.title1': '收益版税',
  'mynft.sell.modal.input.titles': '原创NFT作者可对于每个作品收取一定交易的版税费用(百分比)',
  'mynft.sell.modal.input.title3': '服务费',
  'mynft.form.item.rules1': '请选择币种',
  'mynft.form.item.rules2': '请输入价格',
  'mynft.sefl.title': '自建',
}

export default mynft
