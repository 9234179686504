import React, { memo, useState } from 'react'
import { StyledNavLink } from './styled'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { setListInfoSwitch } from '@/common/init'
import type { MenuListType as ListType } from '@/common/data.d'

export default memo(function SideMenuPages() {
  const { i18n } = useTranslation()
  const [{ list }] = useState<{ list: ListType[] }>(() => {
    let { list } = setListInfoSwitch()
    return { list }
  })

  return (
    <Menu mode="horizontal" style={styleMenu}>
      {list.map((item) => (
        <Menu.Item key={item.key} className="menuItemCus">
          <StyledNavLink
            to={item.url === '' ? {} : item.url}
            onClick={() => {
              if (item.key === 'blog') {
                window.open('https://medium.com/@Carbon-Exchange', '_blank')
              }
              if (item.key === 'doc') {
                window.open('https://carbon-exchange.gitbook.io/carbon-exchange-1/', '_blank')
              }
            }}
          >
            <div className="navlink-child-title">{i18n.language === 'en' ? item.enName : item.name}</div>
          </StyledNavLink>
        </Menu.Item>
      ))}
    </Menu>
  )
})

const styleMenu = {
  width: '100%',
  background: 'transparent',
  borderBottom: 'none',
  display: 'flex',
  justifyContent: 'flex-end',
}
