/* eslint-disable */
import React, { memo, useState, useEffect, useRef } from 'react'
import { IGOnftWrapper, IGOnftTitle, Title, IGOnftContent } from './styled'
import { Link } from 'react-router-dom'
import { Spin, Pagination, Select } from 'antd'
import NoData from '@/components/NoData'
import { useTranslation } from 'react-i18next'
import { showMenu } from '@/utils'
import { getData } from '@/api'
import ItemCard from '@/components/ItemCard'
import { DelSpining, SaveLoadingTips, SaveSpining } from '@/store/user/action'
import { useDispatch } from 'react-redux'
const { Option } = Select

export default memo(function IGOnftPage(props: any) {
  const { t, i18n } = useTranslation()
  const modalRef = useRef<any>(null)
  const dispatch = useDispatch()
  const [currentStatus, setCurrentStatus] = useState<'list' | 'details'>('list')
  const [type, setType] = useState('0')
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState<{
    data: []
    total: 0
  }>({
    data: [],
    total: 0,
  })

  const [pageNum, setPageNum] = useState(1)

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    initData(1)
  }, [])

  const initData = async (page: Number) => {
    dispatch(SaveSpining(true))
    setLoading(true)
    const params: any = `
      query MyQuery {
        categoriesInfos(orderBy: create_time, orderDirection: desc) {
          id
          contractAddress
          carbonType
          account
        }
      }
      `

    const data: any = await getData(params)

    var parmas: any = {
      data: [],
      total: 0,
    }
    if (data.data) {
      parmas.data = data.data.categoriesInfos
      parmas.total = data.data.categoriesInfos.length
      setListData(parmas)
    } else {
      setListData(parmas)
    }
    setLoading(false)
    dispatch(DelSpining(false))
  }

  const handleChange = async (e: any) => {
    setType(e)
  }

  return (
    <IGOnftWrapper ref={modalRef}>
      {currentStatus === 'list' && (
        <>
          <IGOnftTitle>
            <Title>
              <Link to={'/igonft'} style={{ fontWeight: '400' }}>
                {t('igonft.title')}
              </Link>
            </Title>
            {/* <>
              <Select
                id="homeMySele"
                className="igoSelect mySelect homeMySele"
                value={type}
                style={{ width: '17.5rem', height: '3rem', textAlign: 'center' }}
                onChange={handleChange}
              >
                <Option value="0">{i18n.language === 'en' ? showMenu[0].enName : showMenu[0].name}</Option>
              </Select>
            </> */}
          </IGOnftTitle>
          <IGOnftContent>
            <div className="list">
              {listData.data.length > 0 &&
                listData.data
                  .filter((item: any) => {
                    if (type) {
                      return item.carbonType + '' === type
                    } else {
                      return item
                    }
                  })
                  .filter((item: any, index: number) => {
                    if ((pageNum - 1) * 12 <= index && pageNum * 12 > index) {
                      return item
                    }
                  })
                  .map((item: any) => {
                    return (
                      <div className="listItem">
                        <ItemCard detail={item} pageNum={pageNum} />
                      </div>
                    )
                  })}
              <div className="blank" />
              <div className="blank" />
              <div className="blank" />
              <div className="blank" />
            </div>
            {!loading &&
              listData.data
                .filter((item: any) => {
                  if (type) {
                    return item.carbonType + '' === type
                  } else {
                    return item
                  }
                })
                .filter((item: any, index: number) => {
                  if ((pageNum - 1) * 12 <= index && pageNum * 12 > index) {
                    return item
                  }
                }).length <= 0 && <NoData top={0} />}
            {!loading &&
              listData.data
                .filter((item: any) => {
                  if (type) {
                    return item.carbonType + '' === type
                  } else {
                    return item
                  }
                })
                .filter((item: any, index: number) => {
                  if ((pageNum - 1) * 12 <= index && pageNum * 12 > index) {
                    return item
                  }
                }).length > 0 && (
                <div style={{ textAlign: 'center' }}>
                  <Pagination
                    defaultCurrent={pageNum}
                    showSizeChanger={false}
                    current={pageNum}
                    pageSize={12}
                    total={listData.total}
                    onChange={(current) => {
                      setPageNum(current)
                    }}
                  />
                </div>
              )}
          </IGOnftContent>
        </>
      )}
    </IGOnftWrapper>
  )
})
/* eslint-disable */
