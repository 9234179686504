import { AdaptFontSize, EchartsMenu } from '@/utils'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EchartsTest from '@/components/EchartsDome'
import { Title, EchartsWrapper, EchartsTitle, EchartsTopic, EchartsDiv } from './styled'
import { useWindowSizeHooks } from '@/hooks/useWindowSizeHooks'

export default memo(function EchartsPage(props: any) {

    const { t, i18n } = useTranslation()
    const [current, setCurrent] = useState(1)
    const [options, setOptions] = useState<any>()
    const [allPeople, setAllPeople] = useState(0)
    const [allT, setAllT] = useState(0)

    const { windowSize } = useWindowSizeHooks()

    useEffect(() => {
        initData()
    }, [current])

    const initData = async () => {
        var data: any = []
        var dataArr: any = []
        var dataArr1: any = []
        if (current === 1) {
            for (var i = 0; i > -7; i--) {
                data.push(getBeforeDate(i));
            }
            data.reverse()
            dataArr = [140, 232, 101, 264, 90, 340, 250]
            dataArr1 = [120, 282, 111, 234, 220, 340, 310]
            setAllPeople(sumAmount(dataArr))
            setAllT(sumAmount(dataArr1))
        } else if (current === 2) {
            for (var j = 0; j > -31; j--) {
                data.push(getBeforeDate(j));
            }
            data.reverse()
            dataArr = [140, 232, 101, 264, 90, 340, 250, 140, 232, 101, 264, 90, 234, 340, 250, 140, 232, 101, 264, 90, 340, 250, 140, 232, 101, 264, 90, 340, 250, 200, 100]
            dataArr1 = [120, 282, 111, 234, 220, 340, 310, 120, 282, 111, 234, 220, 340, 310, 120, 282, 111, 234, 220, 340, 234, 310, 120, 282, 111, 234, 220, 340, 310, 240, 150]
            setAllPeople(sumAmount(dataArr))
            setAllT(sumAmount(dataArr1))
        } else if (current === 3) {
            data = getBeforeDate1()
            dataArr = [80, 500, 600, 650, 750, 800, 650, 550, 450, 356, 594, 953, 659]
            dataArr1 = [100, 800, 850, 550, 150, 645, 356, 894, 716, 468, 852, 659, 659]
            setAllPeople(sumAmount(dataArr))
            setAllT(sumAmount(dataArr1))
        } else {
            const nowYear: number = new Date().getFullYear()
            for (let k = nowYear; k >= 0; k--) {
                if (k <= nowYear - 11) {
                    break
                } else {
                    data.push(k)
                }
            }
            data.reverse()
            dataArr = [800, 500, 1200, 1650, 750, 1256, 1600, 2650, 1550, 2450, 2356]
            dataArr1 = [1000, 1800, 1850, 2550, 2564, 1150, 2645, 2356, 1894, 2716, 1468]
            setAllPeople(sumAmount(dataArr))
            setAllT(sumAmount(dataArr1))
        }
        const option = {
            color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
            title: {
                text: ''
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },

            grid: {
                left: '0',
                right: '0',
                bottom: '0',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: data,
                    axisLabel: {
                        interval: "auto",
                        color: '#9596A5',
                        fontSize: windowSize.innerWidth > AdaptFontSize ? '14' : '12'
                    },
                    splitLine: {
                        lineStyle: {
                            type: "dashed"
                        }
                    },
                    axisTick: {
                        lineStyle: {
                            type: 'dashed',
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            opacity: 1,
                            color: '#dfdfdf'
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    min: 0,
                    axisLabel: {
                        textStyle: { color: '#00C28E' },//#A23400 purple
                        fontSize: windowSize.innerWidth > AdaptFontSize ? '18' : '12'
                    },
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            type: "dashed"
                        }
                    },
                    axisTick: {
                        show: false
                    }
                },
                {
                    type: 'value',
                    min: 0,
                    axisLabel: {
                        textStyle: { color: '#03497D' },//#A23400 purple
                        fontSize: windowSize.innerWidth > AdaptFontSize ? '18' : '12'
                    },
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            type: "dashed"
                        }
                    },
                    axisTick: {
                        show: false
                    }
                },
            ],
            series: [
                {
                    name: '交易人数',
                    type: 'line',
                    stack: 'Total',
                    smooth: true,
                    yAxisIndex: 0,
                    lineStyle: {
                        width: 0
                    },
                    showSymbol: false,
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: '#00477C'  // 100% 处的颜色
                            }, {
                                offset: 1, color: 'rgba(134,110,255,0)' //   0% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: dataArr
                },
                {
                    name: '交易吨数',
                    type: 'line',
                    stack: 'Total',
                    smooth: true,
                    yAxisIndex: 1,
                    lineStyle: {
                        width: 0
                    },
                    showSymbol: false,
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: 'rgba(23,210,154,0)'  // 100% 处的颜色
                            }, {
                                offset: 1, color: '#00C28E' //   0% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: dataArr1
                }
            ]
        };

        setOptions(option)
    }

    const sumAmount = (arr: any) => {
        let s = 0;
        for (let i = 0; i < arr.length; i++) {
            s += arr[i]
        }
        return s;
    }

    const getBeforeDate = (n: any) => {
        var d = new Date();
        var year = d.getFullYear();
        var mon = d.getMonth() + 1;
        var day = d.getDate();
        if (day <= n) {
            if (mon > 1) {
                mon = mon - 1;
            } else {
                year = year - 1;
                mon = 12;
            }
        }
        d.setDate(d.getDate() + n);
        year = d.getFullYear();
        mon = d.getMonth() + 1;
        day = d.getDate();
        const s = (mon < 10 ? String(year).slice(2, 4) + ('-0' + mon) : String(year).slice(2, 4) + '-' + mon) + "-" + (day < 10 ? ('0' + day) : day);
        return s;
    }

    const getBeforeDate1 = () => {
        const newArr: any = []
        var d = new Date();
        for (var i = 0; i < 12; i++) {
            d.setMonth(d.getMonth() - 1)
            var m: any = d.getMonth() + 1
            m = m < 10 ? '0' + m : m
            newArr.push(d.getFullYear() + '-' + m)
        }
        newArr.reverse()
        return newArr;
    }

    return (
        <EchartsWrapper>
            <EchartsTitle>
                <Title>
                    {t('echarts.title')}
                </Title>
            </EchartsTitle>
            <EchartsTopic>
                <div className='left'>
                    <div>
                        {t('echarts.all')}{allPeople}
                    </div>
                    <div className='allt'>
                        {t('echarts.allT')}{allT}
                    </div>
                </div>
                <div className='right'>
                    {
                        EchartsMenu.map((item: any) => {
                            return <div key={item.key} className={current === item.key ? 'item active' : "item"} onClick={() => {
                                setCurrent(item.key)
                            }}>
                                {i18n.language === 'en' ? item.enName : item.name}
                            </div>
                        })
                    }
                </div>
            </EchartsTopic>
            <EchartsDiv>
                {
                    (options) && <EchartsTest options={options} height={500} />
                }
            </EchartsDiv>
        </EchartsWrapper>
    )
})