const help = {
  'help.title': 'Help center',
  'help.type': 'Type of Problem',
  'help.type1': 'Abnormal function',
  'help.type2': 'Product Suggestions',
  'help.type3': 'Other',
  'help.title1': 'Content Feedback',
  'help.title1.placeholder': 'Please describe the problem',
  'help.title2': 'Contact Information',
  'help.title2.placeholder': 'Please enter your contact information',
  'about.title': 'About Us',
  'about.content': "Carbon-Exchange is the world's first real Carbon asset trading platform.",
  'about.content1':
    'The platform is committed to promoting the sustainable development of the global carbon neutral plan. In order to achieve the climate goal of the "Paris Agreement", the temperature rise will be controlled within 1.5 degrees Celsius. Carbon pricing is widely used to guide global users on the low-carbon road and reduce the disasters that threaten human existence caused by global warming.',
  'about.content2':
    'At the same time, the new NFT value system is used to truly anchor carbon assets, creating a sustainable development and a virtuous cycle of ecological protection. So that people can understand the terrible impact of global warming caused by environmental pollution on human living environment, and advocate low-carbon life, wake up the awareness of environmental protection.',
  'att.title': 'Precautions',
  'att.topic1': '1. Personal account security information should be kept confidential',
  'att.topic1.info1': '• Do not disclose your wallet password, private key, mnemonic and other files to anyone.',
  'att.topic1.info2':
    '• When the site is authorized, be sure to check whether it is a regular web page. You can also regularly check whether the wallet has authorized a strange site and cancel the authorization in time.',
  'att.topic1.info3':
    '• Official personnel will not ask for user security information, do not disclose account password, fund password, verification code and other security information to anyone.',
  'att.topic1.info4':
    '• When it comes to the security information of any account asset, do not "share the screen" or "take a screenshot and send it". Please be vigilant. Do not disclose your wallet password, private key, mnemonic and other files to anyone.',
  'att.topic2': '2. Please identify the login platform',
  'att.topic2.info1':
    '• Carbon trading Center activities and business changes, please refer to the official website announcement shall prevail, please pay attention to identify, beware of fraud. When logging in the official website, do not search the website through the search engine to avoid entering the fake site. You can choose to manually enter the website to log in, the official website of the carbon trading center:',
  'att.topic2.info1.link': 'carbon-exchange.com',
  'att.topic2.info2':
    '• The carbon trading Center will not send you text messages containing links, unknown links/QR codes of unofficial websites, and unknown documents are all risky. Do not click on them easily, let alone enter your account number, password, verification code and other security information to avoid being stolen by phishing websites or Trojan horses.',
  'att.topic2.info3':
    '• The DNS domain name system ensures that domain names are unique, making it nearly impossible for users to open fake websites when the domain name is real.',
  'att.topic3': '3. Prevent phishing attacks',
  'att.topic3.info1':
    'Phishing attack is a common way of network fraud, which means that criminals use various means to fake the URL address and page content of real websites, so as to cheat users of personal information such as bank account, credit card account and password, and then cheat users of assets.',
  'att.topic3.info2':
    "Common phishing attacks, in the field of encryption cheater may counterfeit platform staff, creating a phishing web site and publish false information, via text message, email, etc way, saying 'upgrade account', 'transfer', 'repel', 'trigger risk control' and 'financial risk', 'to become an international user', etc., induce the user clicks on a phishing site link or scan the qr code. Once the information such as account password or fund password is leaked, the digital assets in the user's account will be quickly stolen.",
}

export default help
