import styled, { css } from 'styled-components'
import { proTopBarAdaptation, proTopBarAdaptationMax, h5proTopBarAdaptation } from '@/common/styled'
export const ProTop = styled.div`
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  border-bottom: 1px solid #e5e5e5;
  .proName {
    min-width: 18rem;
    height: auto;

    font-size: 1.875rem;
    color: #00477c;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${({ theme }) => theme.mediaWidth.screenMd`
     min-width: 13rem;
  margin-left: 0.63rem;
  `}
  }
`

export const TopBarWrapper = styled.div`
  ${proTopBarAdaptation}
  @media (min-width: 1920px) {
    ${proTopBarAdaptationMax}
  }
  height: 5rem;
  display: flex;
  align-items: center;
  color: #5f6469;
  .logo {
    width: 6.2rem;
    height: auto;
    margin-left: 0.63rem;
    ${({ theme }) => theme.mediaWidth.screenMd`
   margin-left: 1.5rem;
  `}
  }
  .tabbar-left {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ${(props) => props.theme.mediaWidth.screenLg`
      justify-content: flex-start;
    `}
  }
  .tabbar-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .tabbar-center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-input {
    padding: 0.3125rem 0.625rem;
    display: flex;
    align-items: center;
    margin-left: 0.9375rem;
    border: 0.0625rem solid #dedfe1;
    border-radius: 0.3125rem;
    .myInput {
      margin-left: 0.625rem;
      border: transparent;
    }
  }
  ${({ theme }) =>
    theme.mediaWidth.screenMd(
      () => css`
        ${h5proTopBarAdaptation}
        justify-content: space-between;
        height: 6.25rem;
        .tabbar-center {
          justify-content: flex-start;
        }
      `,
    )}
`

export const Back = styled.div`
  padding: 0.65rem 1.3rem;
  border-radius: 0.4375rem;
  border: 1px solid #00477c;
  font-size: 0.875rem;
  color: #00477c;
  cursor: pointer;
  &:hover {
    background-color: #00c28e;
    color: white;
    border: 1px solid #00c28e;
  }
`
