const market = {
  'market.title': 'Market',
  'market.left.title': 'Filter',
  'market.left.reset': 'Reset',
  'market.left.sure': 'Sure',
  'market.left.title.vice1': 'NFT Status',
  'market.left.title.vice2': 'Price',
  'market.left.title.vice2.btn': 'Apply Price',
  'market.left.title.vice3': 'Project type',
  'market.left.title.vice4': 'Project set',
  'market.left.title.vice5': 'Fuzzy search NFT',
  'market.list.buy.now': 'Buy Now',
  'market.input.placeholder': 'Price Rank',
  'market.input.placeholder1': 'Please select',
  'market.details.theme.title': 'Current project set: ',
  'market.details.theme.hold': 'Holder',
  'market.details.vice.title1': 'Describe',
  'market.details.vice.title2': 'Features',
  'market.details.vice.title3': 'Royalty Rate',
  'market.details.vice.title4': 'Latest Time',
  'market.details.vice.title5': 'Royalty Income Address',
  'market.details.vice.title2.list1': 'Contract Address',
  'market.details.vice.title2.list2': 'Token Standard',
  'market.details.vice.title2.list3': 'Blockchain',
  'market.details.vice.title2.list4': 'Metadata',
  'market.details.vice.title2.list.info1': 'ERC721',
  'market.details.vice.title2.list.info3': 'Carbon assets',
  'market.details.price.title': 'Transaction Price',
  'market.details.price.title.vice': 'Note: When purchasing NFT resource blind boxes, you need to pay GAS fee and transaction tax',
  'market.details.price.btn': 'Buy Now',
  'market.message.tips': 'Data Error',
  'market.message.tips1': 'Price Range Cannot Be Blank',
  'market.message.tips2': 'Error in price range, please reenter',
  'market.list.theme': 'NFT Resource',
  'market.list.title': 'NFT Transaction History',
  'market.list.th1': 'Price',
  'market.list.th2': 'Sell address',
  'market.list.th3': 'Buy address',
  'market.list.th4': 'Date',
  'market.list.th5': 'Block chain',
  'market.message.tips3': 'Buying NFT Sold by Yourself Is Not Supported',
  'market.message.tips4': 'Insufficient {{msg}} in Account',
  'market.buy.success': 'Buy Success',
  'market.list.details.tips': 'See Details',
  'market.search': 'search',
}

export default market
