import styled, { css } from 'styled-components'
import nftAbout from '@/assets/img_slices/nftAbout.png'

export const SetWrapper = styled.div`
  padding-top: 5rem;
`

export const HomeContent = styled.div`
  max-width: 1200px;
  margin: 2rem auto 4rem auto;
  ${({ theme }) => theme.mediaWidth.screenMd`
    padding:1rem;
  `}
  .title {
    color: #5d6175;
    font-size: 1.625rem;
    /* display: flex; */
    margin-bottom: 2rem;
    .line {
      cursor: pointer;
      &:hover {
        color: #00c28e;
      }
    }
    .castBtn {
      border: 1px solid #9596a5;
      border-radius: 1.25rem;
      width: 5.625rem;
      height: 2.5rem;
      line-height: 2.5rem;
      text-align: center;
      font-size: 1rem;
      color: #9596a5;
      margin-left: 1rem;
      cursor: pointer;
      &:hover,
      &.active {
        background-color: #9596a5;
        color: white;
      }
    }
  }
`

export const Details = styled.div`
  width: 100%;
  min-height: calc(100vh - 21rem);
  display: flex;
  justify-content: space-between;
  .des {
    margin-top: 1.5rem;
    border: 1px solid #dedede;
    border-radius: 1rem;
    width: 50%;
    cursor: pointer;
    ${({ theme }) =>
      theme.mediaWidth.screenMd(
        () => css`
          width: 100%;
        `,
      )}
    .heder {
      display: flex;
      padding: 1rem 2rem;
      color: #37383d;
      font-size: 1.25rem;
      background-color: #f5f6f8;
      border-radius: 1rem 1rem 0 0;
      justify-content: space-between;
      align-items: center;
      .set {
        cursor: pointer;
        img {
          margin-right: 0;
        }
      }
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 1.875rem;
          `,
        )}
      img {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 10px;
      }
    }
    .desTips {
      color: #5d6175;
      font-size: 1.125rem;
      padding: 1.4rem 2rem;
      ${({ theme }) =>
        theme.mediaWidth.screenMd(
          () => css`
            font-size: 2rem;
          `,
        )}
      .nodes {
        text-align: center;
        color: #9596a5;
        font-size: 1.125rem;
      }
    }
    .metaContent {
      padding: 1.4rem 2rem;
      .metaItem {
        width: 100%;
        display: flex;
        font-size: 1.125rem;
        ${({ theme }) =>
          theme.mediaWidth.screenMd(
            () => css`
              font-size: 1.75rem;
            `,
          )}
        &:not(:first-child) {
          margin-top: 0.8rem;
        }
        .detailTitle {
          color: #9596a5;
          flex: 0.4;
        }
        .detailValue {
          color: #5d6175;
          flex: 0.6;
        }
      }
    }
  }
`

export const MyModal = styled.div`
  .modalHeader {
    font-size: 2.3125rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #37383d;
  }
  .price,
  .amount {
    font-size: 1.375rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #37383d;
    margin: 1rem 0;
  }
  .btn {
    width: 12.5rem;
    height: 3.75rem;
    background: #00c28e;
    border-radius: 1.875rem;
    text-align: center;
    line-height: 3.75rem;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin: 1.25rem auto 0 auto;
    cursor: pointer;
  }
`
