const igonft = {
  'igonft.title': '项目集',
  'igonft.title.h3': 'IEO碳NFT项目',
  'igonft.title.h5': '由理事会和碳社区发起的IEO碳交易NFT项目，有助于用户更广泛轻松的补充碳排放，实现碳中和的最终目标',
  'igonft.message.tips': 'Data Error',
  'igonft.details.introduce.title': 'Introduce',
  'igonft.details.btn': 'Participate Project',
  'igonft.apply.btn': '申请IEO碳项目',
  'igonft.apply.add': '添加IEO碳项目',
  'igonft.apply.btnAdd': '添加',
  'igonft.apply.title': '申请IEO-NFT项目',
  'igonft.add.title': '添加IEO-NFT项目',
  'igonft.apply.p1': '申请IEO-NFT项目，请通过邮件联系我们',
  'igonft.apply.p2': '申请中所需文件，我们将通过邮件发送到您的账户，请注意查收哦! ',
  'igonft.apply.email': 'email address',
  'igonft.apply.copy.btn': 'Copy email address',
  'igonft.apply.copy.success': '复制成功',
  'igo-basic-title1': '项目方基础信息',
  'igo-basic-title1-0': '名称',
  'igo-basic-title1-1': '钱包地址',
  'igo-basic-title1-2': '邮箱',
  'igo-basic-title1-3': '电话',
  'igo-basic-title2': 'IEO资产类型申请',
  'igo-type1': '碳资产',
  'igo-type2': '碳证券',
  'igo-type3': '碳期货',
  'igo-type4': '碳票据',
  'igo-btn': '提交',
  'igo-opened': '暂未开放',
  'igo-input-tips1': '请输入钱包地址',
  'igo-input-tips2': '请输入邮箱',
  'igo-input-tips3': '请输入电话',
  'igo-input-tips4': '请输入国家',
  'igo-input-tips5': '请输入图像',
  'igo-input-tips6': '请输入序列号',
  'igo-input-tips7': '请输入附加认证',
  'igo-input-tips8': '请输入认证标准',
  'igo-input-tips9': '请输入数量',
  'igo-input-tips10': '请输入项目类型',
  'igo-input-tips11': '请输入碳资产年份',
  'igo-input-tips12': '请输入项目名称',
  'igo-input-tips13': '请输入项目ID',
  'igo-input-tips14': '请输入方法论',
  'igo-input-tips15': '请输入状态',
  'igo-input-tips16': '提交成功',
  'igo-input-tips17': '添加成功',
  'igo-input-tips18': '请输入名称',
  'igo-input-tips19': '添加失败',
  'igo-input-tips20': '钱包地址填写错误',
  'igo-input-tips21': '电话号码填写错误',
  'igo-input-tips22': '邮箱填写错误',
  'igo-input-tips23': 'Ieo名称过长，汉字最长为10个，英文最长为20个',
  'igo-input-tipsNum': 'IEO名称不能为纯数字',
  'igo-input-tipsApp': '钱包地址重复提交',
  'igo-input-tips24': '国家字符最大不能超过100',
  'igo-input-tips25': '图像字符最大不能超过100',
  'igo-input-tips26': '序列号字符最大不能超过100',
  'igo-input-tips27': '附加认证字符最大不能超过100',
  'igo-input-tips28': '认证标准字符最大不能超过100',
  'igo-input-tips29': '数量输入字符最大不能超过100',
  'igo-input-tips30': '项目类型字符最大不能超过100',
  'igo-input-tips31': '碳资产年份字符最大不能超过100',
  'igo-input-tips32': '项目名称字符最大不能超过100',
  'igo-input-tips33': '项目ID字符最大不能超过100',
  'igo-input-tips34': '方法论字符最大不能超过100',
  'igo-input-tips35': '状态字符最大不能超过100',
  'igo-input-tips36': '项目集名称过长，汉字最长为10个，英文最长为20个',
  'igo-input-tips37': 'NFT名称过长，汉字最长为10个，英文最长为20个',
  'igo-input-tips38': '请输入项目官方链接',

  'project.back': '返回交易中心',
  'project.home.title': '更多NFT资源介绍',
  'project.home.tips': '每个NFT资源都是独特的，并享有普遍的访问权和特殊利益。',
  'project.home.about': '关于',
  'project.home.about.list.1': 'MetaDao从未知到未知。',
  'project.home.about.list.2':
    '未来已经到来，NFT将成为这个时代最强大的IP载体，天谷贸易星球将为人类带来一场非同寻常的NFT盛宴。想象没有边界，探索没有极限！',
  'project.home.about.list.3': '共同发现、收集和创造非凡NFT的新现实世界。',
  'project.nft.bottom': '全球领先的碳信用现货交易平台',
  'peoject.nft.title1': 'NFT ',
  'peoject.nft.title2': ' > NFT 详情',
  'project.nft.features': '特性',
  'project.nft.contentItem1': '合约地址',
  'project.nft.contentItem2': '代币标准',
  'project.nft.contentItem3': '区块链',
  'project.nft.contentItem4': '元数据',
  'project.nft.detail.header': '当前项目集：',
  'project.nft.detail.name': '碳NFT资源 — ',
  'project.nft.detail.owner': '持有者',
  'project.nft.detail.price': '交易价格',
  'project.nft.detail.nocan': '当前无法铸造',
  'project.nft.detail.attend': '注意:您需支付GAS费用、{{msg}}%的交易税费',
  'project.nft.detail.buy': '去交易中心',
  'project.nft.detail.des': '描述',
  'project.nft.detail.description': '为可持续的未来创建一个 透明的去中心化碳市场。',
  'project.nft.detail.dataDetail': '元数据详情',
  'project.nft.detail.country': '国家',
  'project.nft.detail.img': '图像',
  'project.nft.detail.num': '序列号',
  'project.nft.detail.offcial': '项目官方链接',
  'project.nft.detail.renzhen': '附加认证',
  'project.nft.detail.stan': '认证标准',
  'project.nft.detail.amount': '数量',
  'project.nft.detail.type': '项目类型',
  'project.nft.detail.time': '碳资产年份',
  'project.nft.detail.pro': '项目详情',
  'project.nft.detail.proId': '项目ID',
  'project.nft.detail.proName': '项目名称',
  'project.nft.detail.method': '方法论',
  'project.nft.detail.status': '状态',
  Singapore: '新加坡',
  'admin.title': '管理',
  'admin.isopen': '是否铸造：',
  'admin.btn': '是',
  'admin.btn1': '否',
  'admin.success': '已开启铸造室',
  'admin.fail': '铸造室开启失败',
  'admin.success1': '已关闭铸造室',
  'admin.fail1': '铸造室关闭失败',
  'admin.set': '设置',
  'admin.price': '价格',
  'admin.amount': '数量',
  'admin.plo': '请输入',
  'admin.tips': '设置成功',
  'admin.tips1': '设置失败',
  'admin.btn2': '完成',
  'admin.config.tips': '请输入有效值',
  'admin.amount.warning': '铸造数量不足',
  'admin.amount.success': '铸造成功',
  'admin.amount.tips': '可前往我的NFT查看',
  'admin.amount.btn': '前往查看',
  'admin.cast': '可铸造数量:',
}

export default igonft
