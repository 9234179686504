import React, { memo, useState, useRef, useEffect } from 'react'
import { CreateContent, CreateType, Title, TitleTrue, UploadCusDiv, Apply } from './styled'
import { Input, Button, Form, Col, Row, Upload, Select, message, Image, Modal, InputNumber, Radio } from 'antd'
import { bucket, everlandUrl, getBase64, params } from '@/utils'
import { useTranslation } from 'react-i18next'
import { ImageError } from '@/common/init'
import { FormInstance } from 'antd/es/form'
import { useHistory } from 'react-router-dom'
import Modelviewer from '@/components/ModelViewer'
import useWeb3StoreHooks from '@/hooks/useWeb3StoreHooks'
import type { StorageClientTypes } from '@/contracts/web3StorageInit'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import { useCreateHooks } from '@/hooks/useCreateHooks'
import { useDispatch, useSelector } from 'react-redux'
import { BigNumber } from 'bignumber.js'
import UPLOAD from '@/assets/page_slices/upload.png'
import { S3 } from '@aws-sdk/client-s3'
import Web3 from 'web3'
import { DelSpining, SaveLoadingTips, SaveSpining } from '@/store/user/action'
import { retrieveFiles } from '@/common'
import { getData } from '@/api'

const { Option } = Select

const acceptType = 'image/jpeg,image/png,image/svg+xml,image/gif,video/mp4,video/webm,audio/ogg,audio/wav,audio/mpeg,.gltf'

interface TypeDetails {
  cover: any
  describe: any
  image: any
  name: string
}

const initDetails: TypeDetails = {
  cover: undefined,
  describe: undefined,
  image: undefined,
  name: '',
}

interface Type {
  cur?: string
}
const getLength = (str: string) => {
  var realLength = 0,
    len = str.length,
    charCode = -1
  for (var i = 0; i < len; i++) {
    charCode = str.charCodeAt(i)
    if (charCode >= 0 && charCode <= 128) realLength += 1
    else realLength += 2
  }
  return realLength
}

export default memo(function CreateOrEditPage(props: Type) {
  const { cur } = props
  const dispatch = useDispatch()
  const modalRef = useRef<any>(null)
  let history = useHistory()
  const web3Store: StorageClientTypes = useWeb3StoreHooks()
  const { client, makeFileObjects } = web3Store
  const nftData: ConstantInitTypes = useDataHooks()
  const { constant, SharedToken_ADDRESS } = nftData
  const myAddress = useSelector((state: any) => state.userInfo.address)

  const { t } = useTranslation()
  let formRef = useRef<FormInstance>()
  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [current, setCurrent] = useState(0)

  const [imageUrl, setImageUrl] = useState<string>('')
  const [imageUrlSource, setImageUrlSource] = useState<string>('')
  const [imageUrlType, setImageUrlType] = useState<any>(undefined)
  const [imageCid, setImageCid] = useState<any>(undefined)

  const [imageUrlCover, setImageUrlCover] = useState<string>('')
  const [imageUrlCoverSource, setImageUrlCoverSource] = useState<string>('')
  const [imageCidCover, setImageCidCover] = useState<any>(undefined)
  const [formData, setFormData] = useState<{
    describe: any
    earnAddress: any
    gender: any
    image: any
    name: any
    proportion: any
    type: any
    amount: number
  }>({
    describe: undefined,
    earnAddress: undefined,
    gender: undefined,
    image: undefined,
    name: undefined,
    proportion: undefined,
    type: 1,
    amount: 1,
  })

  const [onShow, setOnShow] = useState<boolean>(false)
  const [details, setDetails] = useState<TypeDetails>(JSON.parse(JSON.stringify(initDetails)))
  const [isRefreshData, setIsRefreshData] = useState(false)

  const [canUse, setCanUse] = useState(false)

  const { portfolioList, protfolioLoading } = useCreateHooks({ myAddress, isRefreshData })

  useEffect(() => {
    if (canUse && cur) {
      setCurrent(Number(cur))
    }
  }, [props, canUse, cur])

  useEffect(() => {
    if (myAddress) {
      isIeo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myAddress])

  const isIeo = async () => {
    dispatch(SaveSpining(true))
    try {
      const data: any = await constant.ContractMemberRead.methods
        .hasRole('0xd3f5f54249adadbb7fd180a54bbaf003a88df753ddaa8e68f99644325ccadc93', myAddress)
        .call()
      dispatch(DelSpining(false))
      setCanUse(data)
    } catch (error) {
      console.log(error)

      dispatch(DelSpining(false))
    }
  }

  const onFinish = async (values: any) => {
    try {
      if (current === 1 && formData.type === 1 && (formData.proportion === null || formData.proportion === undefined)) {
        message.warning(t('create.from6.por'))
        return
      }
      if (current === 1) {
        if (!imageCid) {
          message.warning({
            content: `Image ${t('create.from1.rules')}`,
            className: 'message-global',
          })
          return false
        }
        if (
          (imageUrlType === 'video/mp4' ||
            imageUrlType === 'video/webm' ||
            imageUrlType === 'audio/mpeg' ||
            imageUrlType === 'audio/wav' ||
            imageUrlType === 'audio/ogg' ||
            imageUrlType === '.gltf') &&
          !imageCidCover
        ) {
          message.warning({
            content: `Cover ${t('create.from2.rules')}`,
            className: 'message-global',
          })
          return false
        }
      } else {
        if (!imageCidCover) {
          message.warning({
            content: `Cover ${t('create.from2.rules')}`,
            className: 'message-global',
          })
          return false
        }
      }
      dispatch(SaveLoadingTips(t('spin.create')))
      dispatch(SaveSpining(true))
      setDetails(values)

      if (current === 0) {
        let obj = {
          name: formData.name,
          cover: imageCidCover || imageCid,
          coverFiles: imageUrlCoverSource ? imageUrlCoverSource : imageUrlSource,
          contract: SharedToken_ADDRESS,
        }
        portfolioClick(formData.name, obj.cover, obj, formData)
      } else {
        if (!formData.gender) {
          message.warning(t('create.from5.placeholder1'))
          dispatch(DelSpining(false))
          return
        }
        const newData: any = await getIeo()
        let cidV1 = Web3.utils.hexToAscii(newData[0].ipfs_cid)
        const a1: any = await retrieveFiles(cidV1)

        let obj = a1.projectOffcialLink
          ? {
              name: formData.name,
              description: formData.describe,
              image: imageUrlCoverSource ? imageUrlCoverSource : imageUrlSource,
              cover: imageCidCover || imageCid,
              coverFiles: imageUrlCoverSource ? imageUrlCoverSource : imageUrlSource,
              imageFiles: imageUrlSource,
              projectOffcialLink: a1.projectOffcialLink,
              attributes: [
                { trait_type: 'country', value: a1.country || '' },
                { trait_type: 'serialNum', value: a1.serialNum || '' },
                { trait_type: 'standard', value: a1.standard || '' },
                { trait_type: 'amount', value: a1.amount || '' },
                { trait_type: 'type', value: a1.type || '' },
                { trait_type: 'vintage', value: a1.vintage || '' },
                { trait_type: 'projectName', value: a1.projectName || '' },
                { trait_type: 'projectID', value: a1.projectID || '' },
              ],
            }
          : {
              name: formData.name,
              description: formData.describe,
              image: imageUrlCoverSource ? imageUrlCoverSource : imageUrlSource,
              cover: imageCidCover || imageCid,
              coverFiles: imageUrlCoverSource ? imageUrlCoverSource : imageUrlSource,
              imageFiles: imageUrlSource,
              attributes: [
                { trait_type: 'country', value: a1.country || '' },
                { trait_type: 'serialNum', value: a1.serialNum || '' },
                { trait_type: 'standard', value: a1.standard || '' },
                { trait_type: 'amount', value: a1.amount || '' },
                { trait_type: 'type', value: a1.type || '' },
                { trait_type: 'vintage', value: a1.vintage || '' },
                { trait_type: 'projectName', value: a1.projectName || '' },
                { trait_type: 'projectID', value: a1.projectID || '' },
              ],
            }
        createNftClick(formData.gender, obj, formData)
      }
    } catch (error) {
      console.log('err', error)
      dispatch(DelSpining(false))
    }
  }

  const portfolioClick = async (name: string, cover: string, nftObj: any, values: any) => {
    try {
      let obj = {
        ...nftObj,
        myAddress,
      }
      let files: any = makeFileObjects(obj, name)

      const s3 = new S3({
        endpoint: params.endpoint,
        credentials: {
          accessKeyId: params.accessKey,
          secretAccessKey: params.secretKey,
        },
        region: 'us-west-2',
      })

      const reader = new FileReader()
      reader.readAsArrayBuffer(files)
      reader.onload = async () => {
        //文件数据
        const fileBody: any = reader.result
        const data_time = `${Date.now()}_${Math.ceil(Math.random() * 1000000000)}`
        const putObject = await s3.putObject({
          Bucket: bucket,
          Key: 'Collection/res_' + data_time,
          Body: fileBody,
        })
        console.log(putObject)

        s3.headObject(
          {
            Bucket: bucket,
            Key: 'Collection/res_' + data_time,
          },
          async (err, data: any) => {
            if (!err) {
              try {
                constant.ContractCategories.methods
                  .add(Web3.utils.asciiToHex('res_' + data_time), '0', SharedToken_ADDRESS)
                  .send({
                    from: myAddress,
                  })
                  .on('transactionHash', function (hash: any) {
                    console.log(hash)
                  })
                  .on('receipt', async (receipt: any) => {
                    message.success({
                      content: t('create.message.success'),
                      className: 'message-global',
                    })
                    setIsRefreshData(!isRefreshData)
                    setOnShow(true)
                    dispatch(DelSpining(false))
                  })
                  .on('error', function (error: any, receipt: any) {
                    message.error({
                      content: error.message,
                      className: 'message-global',
                    })
                    dispatch(DelSpining(false))
                  })
              } catch (error) {
                console.log('error', error)
              }
            }
          },
        )
      }
    } catch (error) {
      console.log('err', error)
      dispatch(DelSpining(false))
    }
  }

  const createNftClick = async (categoriesName: string, nftObj: any, values: any) => {
    try {
      let obj = {
        ...nftObj,
        categoriesName,
        myAddress,
      }
      let files: any = await makeFileObjects(obj, categoriesName)
      const s3 = new S3({
        endpoint: params.endpoint,
        credentials: {
          accessKeyId: params.accessKey,
          secretAccessKey: params.secretKey,
        },
        region: 'us-west-2',
      })
      const reader = new FileReader()
      reader.readAsArrayBuffer(files)
      reader.onload = async () => {
        //文件数据
        const fileBody: any = reader.result
        const putObject = await s3.putObject({
          Bucket: bucket,
          // Key: 'Nft/' + files.name,
          Key: 'Nft/' + (formData.type === 1 ? '721/' : '1155/') + files.name,
          Body: fileBody,
        })
        console.log(putObject)
        s3.headObject(
          {
            Bucket: bucket,
            // Key: 'Nft/' + files.name,
            Key: 'Nft/' + (formData.type === 1 ? '721/' : '1155/') + files.name,
          },
          async (err, data: any) => {
            if (!err) {
              try {
                if (formData.type === 1) {
                  let royaltyFraction = new BigNumber(formData.proportion).multipliedBy(100)
                  constant.ContractCategories.methods
                    .cast(categoriesName, files.name, formData.earnAddress, Number(royaltyFraction))
                    .send({
                      from: myAddress,
                    })
                    .on('transactionHash', function (hash: any) {
                      console.log(hash)
                    })
                    .on('receipt', async (receipt: any) => {
                      message.success({
                        content: t('create.message.success'),
                        className: 'message-global',
                      })
                      setIsRefreshData(!isRefreshData)
                      setOnShow(true)
                      dispatch(DelSpining(false))
                    })
                    .on('error', function (error: any, receipt: any) {
                      message.error({
                        content: error.message,
                        className: 'message-global',
                      })
                      dispatch(DelSpining(false))
                    })
                } else if (formData.type === 2) {
                  constant.ContractCategories.methods
                    .cast1155(categoriesName, files.name, formData.amount)
                    .send({
                      from: myAddress,
                    })
                    .on('transactionHash', function (hash: any) {
                      console.log(hash)
                    })
                    .on('receipt', async (receipt: any) => {
                      message.success({
                        content: t('create.message.success'),
                        className: 'message-global',
                      })
                      setIsRefreshData(!isRefreshData)
                      setOnShow(true)
                      dispatch(DelSpining(false))
                    })
                    .on('error', function (error: any, receipt: any) {
                      message.error({
                        content: error.message,
                        className: 'message-global',
                      })
                      dispatch(DelSpining(false))
                    })
                }
              } catch (error) {
                dispatch(DelSpining(false))
                console.log('error', error)
              }
            }
          },
        )
      }
    } catch (error) {
      console.log('err', error)
      dispatch(DelSpining(false))
    }
  }

  const imageOrBase641 = async (info: any) =>
    getBase64(info, (img: any) => {
      setImageUrlCover(img)
    })

  const imageOrBase64 = async (info: any, str: '1' | '2') =>
    getBase64(info, (img: any) => {
      if (str === '1') setImageUrl(img)
      if (str === '2') setImageUrlCover(img)
    })

  const beforeUpload = (file: any, str: '1' | '2') => {
    let isType = false
    if (str === '1') {
      let types = file.name.substring(file.name.length - 5)
      isType =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif' ||
        // file.type === 'image/svg+xml' ||
        file.type === 'video/mp4' ||
        file.type === 'video/webm' ||
        file.type === 'audio/mpeg' ||
        file.type === 'audio/wav' ||
        file.type === 'audio/ogg' ||
        types === '.gltf'
    }
    if (str === '2') {
      isType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
    }

    if (!isType) {
      message.error({
        content: str === '1' ? t('create.message.tips1') : t('create.message.tips3'),
        className: 'message-global',
      })
    }
    const isLt100M = file.size / 1024 / 1024 < 100
    if (!isLt100M) {
      message.error({
        content: t('create.message.tips2'),
        className: 'message-global',
      })
    }
    // setLoadingText('Uploading...')
    dispatch(SaveLoadingTips(t('spin.upload')))
    dispatch(SaveSpining(true))
    if (current === 1) {
      if (isType && isLt100M && str === '1') {
        let types1 = file.name.length > 5 ? (file.name.substring(file.name.length - 5) === '.gltf' ? '.gltf' : file.type) : file.type
        if (client) manualUploadOne(file, types1)
      }
      if (isType && isLt100M && str === '2') {
        let isType2 = file.type
        if (client) manualUploadTwo(file, isType2)
      }
    } else {
      if (isType && isLt100M) {
        let isType2 = file.type
        if (client) manualUpload(file, isType2)
      }
    }
    return false
  }

  const manualUpload = async (s: any, type: string) => {
    try {
      const s3 = new S3({
        endpoint: params.endpoint,
        credentials: {
          accessKeyId: params.accessKey,
          secretAccessKey: params.secretKey,
        },
        region: 'us-west-2',
      })
      const reader = new FileReader()
      reader.readAsArrayBuffer(s)

      reader.onload = async () => {
        try {
          //文件数据
          const fileBody: any = reader.result

          const data_time = `${Date.now()}_${Math.ceil(Math.random() * 1000000000)}`
          const putObject = await s3.putObject({
            Bucket: bucket,
            Key: 'Collection/res_' + data_time,
            Body: fileBody,
          })
          console.log(putObject)
          s3.headObject(
            {
              Bucket: bucket,
              Key: 'Collection/res_' + data_time,
            },
            async (err, data: any) => {
              if (!err) {
                setImageCidCover(data.Metadata['ipfs-hash'])
                setImageUrlCoverSource(`${everlandUrl}/Collection/res_` + data_time)
                if (type === 'image/jpeg' || type === 'image/png' || type === 'image/gif' || type === 'image/svg+xml') {
                  imageOrBase641(s)
                } else {
                  setImageUrlCover(`${everlandUrl}/Collection/res_` + data_time)
                }
                dispatch(DelSpining(false))
              }
            },
          )
        } catch {
          dispatch(DelSpining(false))
        }
      }
    } catch (error) {
      console.log(error)
      dispatch(DelSpining(false))
    }
  }

  const manualUploadOne = async (s: any, type: string) => {
    try {
      const s3 = new S3({
        endpoint: params.endpoint,
        credentials: {
          accessKeyId: params.accessKey,
          secretAccessKey: params.secretKey,
        },
        region: 'us-west-2',
      })
      const reader = new FileReader()
      reader.readAsArrayBuffer(s)
      reader.onload = async () => {
        try {
          //文件数据
          const fileBody: any = reader.result
          const data_time = `${Date.now()}_${Math.ceil(Math.random() * 1000000000)}.${s.name.split('.')[1]}`
          const putObject = await s3.putObject({
            Bucket: bucket,
            Key: 'Nft/res_' + data_time,
            Body: fileBody,
          })
          console.log(putObject, type)
          s3.headObject(
            {
              Bucket: bucket,
              Key: 'Nft/res_' + data_time,
            },
            async (err, data: any) => {
              if (!err) {
                setImageCid(data.Metadata['ipfs-hash'])
                setImageUrlType(type)
                setImageUrlSource(`${everlandUrl}/Nft/res_` + data_time)
                if (type === 'image/jpeg' || type === 'image/png' || type === 'image/gif' || type === 'image/svg+xml') {
                  imageOrBase64(s, '1')
                } else {
                  setImageUrl(`${everlandUrl}/Nft/res_` + data_time)
                }
                dispatch(DelSpining(false))
              }
            },
          )
        } catch (err) {
          dispatch(DelSpining(false))
        }
      }
    } catch (error) {
      console.log(error)
      dispatch(DelSpining(false))
    }
  }

  const manualUploadTwo = async (s: any, type: string) => {
    try {
      const s3 = new S3({
        endpoint: params.endpoint,
        credentials: {
          accessKeyId: params.accessKey,
          secretAccessKey: params.secretKey,
        },
        region: 'us-west-2',
      })

      const reader = new FileReader()
      reader.readAsArrayBuffer(s)
      reader.onload = async () => {
        try {
          //文件数据
          const fileBody: any = reader.result
          const data_time = `${Date.now()}_${Math.ceil(Math.random() * 1000000000)}.${s.name.split('.')[1]}`
          const putObject = await s3.putObject({
            Bucket: bucket,
            Key: 'Nft/res_' + data_time,
            Body: fileBody,
          })
          console.log(putObject)
          s3.headObject(
            {
              Bucket: bucket,
              Key: 'Nft/res_' + data_time,
            },
            async (err, data: any) => {
              if (!err) {
                setImageCidCover(data.Metadata['ipfs-hash'])
                setImageUrlCoverSource(`${everlandUrl}/Nft/res_` + data_time)
                if (type === 'image/jpeg' || type === 'image/png' || type === 'image/gif' || type === 'image/svg+xml') {
                  imageOrBase64(s, '1')
                } else {
                  setImageUrlCover(`${everlandUrl}/Nft/res_` + data_time)
                }
                dispatch(DelSpining(false))
              }
            },
          )
        } catch {
          dispatch(DelSpining(false))
        }
      }
    } catch (error) {
      console.log(error)
      dispatch(DelSpining(false))
    }
  }

  const uploadButton = (
    <div className="upload-btn-hover">
      <p className="ant-upload-drag-icon" style={{ margin: '0' }}>
        <img src={UPLOAD} alt="" />
      </p>
      <p className="ant-upload-text">{t('create.upload.btn')}</p>
    </div>
  )

  const uploadExhibit = (str: '1' | '2') => (
    <UploadCusDiv>
      {str === '1' && (
        <>
          {(imageUrlType === 'image/jpeg' ||
            imageUrlType === 'image/png' ||
            imageUrlType === 'image/gif' ||
            imageUrlType === 'image/svg+xml') && (
            <Image src={imageUrl || imageUrlCover} preview={false} height="12.25rem" width="auto"></Image>
          )}
          {(imageUrlType === 'video/mp4' || imageUrlType === 'video/webm') && <video src={imageUrl} controls autoPlay loop></video>}
          {(imageUrlType === 'audio/mpeg' || imageUrlType === 'audio/wav' || imageUrlType === 'audio/ogg') && (
            <audio controls>
              <source src={imageUrl} />
            </audio>
          )}
          {imageUrlType === '.gltf' && <Modelviewer src={imageUrl} />}
        </>
      )}
      {str === '2' && <Image src={imageUrlCover || imageUrl} preview={false} height="12.25rem" width="auto"></Image>}
    </UploadCusDiv>
  )

  const uploadExhibit1 = (
    <UploadCusDiv>
      <Image src={imageUrlCover} preview={false} height="8.25rem" width="auto"></Image>
    </UploadCusDiv>
  )

  const getIeo = async () => {
    try {
      const params = `
      query MyQuery {
        metadataInfos {
          ipfs_cid
          id
          carbonType
          account
        }
      }
      `
      const data: any = await getData(params)
      return data.data.metadataInfos.filter((item: any) => item.account.toLowerCase() === myAddress.toLowerCase())
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <>
      {canUse ? (
        <CreateContent ref={modalRef} className="myCreate">
          <CreateType>
            <div
              className={current === 0 ? 'item active' : 'item'}
              onClick={() => {
                // formRef.current!.resetFields()
                form1.resetFields()
                setDetails(JSON.parse(JSON.stringify(initDetails)))
                setCurrent(0)
                setImageUrl('')
                setImageUrlType(undefined)
                setImageCid(undefined)
                setImageUrlCover('')
                setImageCidCover(undefined)
                setImageUrlCoverSource('')
                setImageUrlSource('')
                setFormData({
                  ...formData,
                  type: 1,
                })
              }}
            >
              {t('create.menu1')}
            </div>
            <div
              className={current === 1 ? 'item active' : 'item'}
              onClick={() => {
                // formRef.current!.resetFields()
                form1.resetFields()
                setDetails(JSON.parse(JSON.stringify(initDetails)))
                setCurrent(1)
                setImageUrl('')
                setImageUrlType(undefined)
                setImageCid(undefined)
                setImageUrlCover('')
                setImageCidCover(undefined)
                setImageUrlCoverSource('')
                setImageUrlSource('')
                setFormData({
                  ...formData,
                  type: 1,
                })
              }}
            >
              {t('create.menu2')}
            </div>
          </CreateType>
          {current === 0 && (
            <Row>
              <Col span={3} lg={{ span: 4 }} xl={{ span: 2 }}></Col>
              <Col span={18} lg={{ span: 16 }} xl={{ span: 20 }}>
                <Title>{t('create.title1')}</Title>
                <TitleTrue>
                  <span>*</span>
                  {t('create.title.f1')}
                </TitleTrue>
                <Form form={form} preserve={false} ref={formRef as any} name="create" onFinish={onFinish} layout="vertical">
                  <Form.Item
                    label={
                      <span style={{ color: '#87868D' }}>
                        <span style={{ color: '#37383D', fontSize: '1.375rem' }}>
                          {t('create.project.title1')} <span style={{ color: '#ff4d4f', marginRight: '4px', lineHeight: '1.3rem' }}>*</span>
                        </span>
                        <br />
                        {t('create.project.title2')}
                      </span>
                    }
                    name="image"
                    valuePropName="fileList"
                    style={{ position: 'relative' }}
                  >
                    {imageCidCover && uploadExhibit1}
                    <Upload.Dragger
                      className="uploader-files"
                      name="files"
                      beforeUpload={(s) => beforeUpload(s, '2')}
                      maxCount={1}
                      accept={acceptType}
                      showUploadList={false}
                    >
                      {imageCidCover ? (
                        <Button type="text" size="large" className="modal-btns">
                          {t('create.upload.btn1')}
                        </Button>
                      ) : (
                        uploadButton
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                  <Form.Item
                    label={t('create.from3.label')}
                    name="name"
                    rules={[
                      { required: true, message: t('create.from3.rules') },
                      {
                        required: true,
                        validator: (index, value) => {
                          return getLength(value) > 20 ? Promise.reject(t('igo-input-tips36')) : Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder={t('create.from3.placeholder')}
                      autoComplete="off"
                      onChange={(e) => {
                        // getLength(e.target.value)
                        setFormData({
                          ...formData,
                          name: e.target.value,
                        })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="carbonType"
                    label={t('create.from5.label1')}
                    rules={[{ required: true, message: t('create.from5.rules') }]}
                  >
                    <Select
                      placeholder={t('create.from5.placeholder')}
                      loading={protfolioLoading}
                      className="mySele"
                      style={{ borderRadius: '0.4375rem' }}
                    >
                      <Option value="0">碳资产</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="myBtn">
                      {t('create.btn')}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={3} lg={{ span: 4 }} xl={{ span: 2 }}></Col>
            </Row>
          )}
          {current === 1 && (
            <Row>
              <Col span={3} lg={{ span: 4 }} xl={{ span: 2 }}></Col>
              <Col span={18} lg={{ span: 16 }} xl={{ span: 20 }}>
                <Title>{t('create.title')}</Title>
                <TitleTrue>
                  <span>*</span>
                  {t('create.title.f1')}
                </TitleTrue>
                <Form form={form1} preserve={false} ref={formRef as any} name="create" onFinish={onFinish} layout="vertical">
                  <Form.Item
                    label={
                      <span>
                        {t('create.from1.label')}
                        <br />
                        <span style={{ color: '#87868D' }}>{t('create.from1.f1')}</span>
                      </span>
                    }
                    name="image"
                    valuePropName="fileList1"
                    style={{ position: 'relative' }}
                  >
                    {imageCid && uploadExhibit('1')}
                    <Upload.Dragger
                      className="uploader-files"
                      name="files"
                      beforeUpload={(s) => beforeUpload(s, '1')}
                      maxCount={1}
                      accept={acceptType}
                      showUploadList={false}
                    >
                      {imageCid ? (
                        <Button type="text" size="large" className="modal-btns">
                          {t('create.upload.btn1')}
                        </Button>
                      ) : (
                        uploadButton
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                  {(imageUrlType === 'video/mp4' ||
                    imageUrlType === 'video/webm' ||
                    imageUrlType === 'audio/mpeg' ||
                    imageUrlType === 'audio/wav' ||
                    imageUrlType === 'audio/ogg' ||
                    imageUrlType === '.gltf') && (
                    <>
                      <Form.Item
                        label={
                          <span>
                            <span style={{ color: '#ff4d4f', marginRight: '4px' }}>*</span>
                            {t('create.from2.label')}
                          </span>
                        }
                        name="cover"
                        valuePropName="fileList1"
                        style={{ position: 'relative' }}
                      >
                        {imageCidCover && uploadExhibit('2')}
                        <Upload.Dragger
                          className="uploader-files"
                          name="files"
                          beforeUpload={(s) => beforeUpload(s, '2')}
                          maxCount={1}
                          accept={acceptType}
                          showUploadList={false}
                        >
                          {imageCidCover ? (
                            <Button type="text" size="large" className="modal-btns">
                              {t('create.upload.btn1')}
                            </Button>
                          ) : (
                            uploadButton
                          )}
                        </Upload.Dragger>
                      </Form.Item>
                      <TitleTrue>{t('create.from2.f1')}</TitleTrue>
                    </>
                  )}
                  <Form.Item label={t('create.from9.label')} name="type">
                    <Radio.Group
                      onChange={(e) => {
                        form1.resetFields()
                        setFormData({
                          ...formData,
                          type: e.target.value,
                          amount: 1,
                        })
                      }}
                      defaultValue={formData.type}
                      value={formData.type}
                    >
                      <Radio value={1}>ERC721</Radio>
                      <Radio value={2}>ERC1155</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label={t('create.from3.label')}
                    name="name"
                    rules={[
                      { required: true, message: t('create.from3.rules') },
                      {
                        required: true,
                        validator: (index, value) => {
                          return getLength(value) > 20 ? Promise.reject(t('igo-input-tips36')) : Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder={t('create.from3.placeholder')}
                      autoComplete="off"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          name: e.target.value,
                        })
                      }}
                    />
                  </Form.Item>
                  {formData.type === 2 && (
                    <Form.Item
                      label={t('create.from8.label')}
                      name="amount"
                      rules={[{ required: true, message: t('create.from8.placeholder') }]}
                    >
                      <InputNumber
                        precision={0}
                        min={1}
                        max={100000}
                        placeholder={t('create.from8.placeholder')}
                        style={{ width: '100%' }}
                        defaultValue={formData.amount}
                        value={formData.amount}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            amount: e,
                          })
                        }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item label={t('create.from4.label')} name="describe">
                    <div style={{ fontSize: '1.125rem', color: '#87868D', marginBottom: '0.625rem' }}>{t('create.from4.f1')}</div>
                    <Input.TextArea
                      autoSize={{ minRows: 6, maxRows: 8 }}
                      maxLength={200}
                      placeholder={t('create.from4.placeholder')}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          describe: e.target.value,
                        })
                      }}
                    />
                  </Form.Item>

                  <Form.Item name="gender" label={t('create.from5.label')}>
                    <div style={{ fontSize: '1.125rem', color: '#87868D', marginBottom: '0.625rem' }}>
                      {' '}
                      <span style={{ color: 'red' }}>*</span>
                      {t('create.set')}
                    </div>
                    <Select
                      placeholder={t('create.from5.placeholder1')}
                      loading={protfolioLoading}
                      className="mySele"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          gender: e,
                        })
                      }}
                    >
                      {portfolioList.map((item) => (
                        <Option key={item.key} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {formData.type === 1 && (
                    <Form.Item name="proportion" label={t('create.from6.label')}>
                      <TitleTrue>
                        <span>*</span> {t('create.from6.f1')}
                      </TitleTrue>
                      <InputNumber
                        precision={2}
                        min={0}
                        max={30}
                        placeholder={t('create.from6.placeholder')}
                        addonAfter="%"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            proportion: e,
                          })
                        }}
                      />
                    </Form.Item>
                  )}
                  {formData.type === 1 && (
                    <Form.Item
                      label={t('create.from7.label')}
                      name="earnAddress"
                      rules={[{ required: true, message: t('create.from7.rules') }]}
                    >
                      <Input
                        placeholder={t('create.from7.placeholder')}
                        autoComplete="off"
                        minLength={42}
                        maxLength={42}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            earnAddress: e.target.value,
                          })
                        }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="myBtn">
                      {t('create.btn')}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={3} lg={{ span: 4 }} xl={{ span: 2 }}></Col>
            </Row>
          )}
          <Modal
            visible={onShow}
            style={{ borderRadius: '0.63rem' }}
            footer={null}
            onCancel={() => setOnShow(false)}
            width="36.88rem"
            wrapClassName="wallets"
            centered
            getContainer={modalRef.current}
            afterClose={() => {
              formRef.current!.resetFields()
              setDetails(JSON.parse(JSON.stringify(initDetails)))
              setCurrent(0)
              setImageUrl('')
              setImageUrlType(undefined)
              setImageCid(undefined)
              setImageUrlCover('')
              setImageCidCover(undefined)
              setImageUrlCoverSource('')
              setImageUrlSource('')
            }}
          >
            <h2 style={{ textAlign: 'center' }}>{t('create.modal.title')}</h2>
            <div className="choose-span" style={{ textAlign: 'center' }}>
              {t('create.modal.title1')}
              <span style={{ fontWeight: 600 }}>{details.name}</span>
            </div>
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Image
                  style={{ borderRadius: '0.63rem', marginTop: '1.25rem' }}
                  src={imageUrlCover || imageUrl}
                  preview={false}
                  height="12.25rem"
                  width="auto"
                  fallback={ImageError}
                ></Image>
              </Col>
            </Row>
            <Row style={{ flexDirection: 'column' }}>
              <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  type="primary"
                  className="my-create-btn"
                  style={{
                    marginTop: 'calc(1.88rem + 1.25rem)',
                    color: 'white',
                    border: '1px solid#00C28E',
                    fontSize: '1.375rem',
                    fontWeight: '400',
                  }}
                  onClick={() => {
                    if (current === 1) {
                      history.replace('/mynft')
                    } else {
                      history.replace('/myproject')
                    }
                  }}
                >
                  {current === 1 ? t('create.modal.btn3') : t('create.modal.btn1')}
                </Button>
              </Col>
              <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  className="my-create-btn"
                  style={{ color: '#00C28E', border: '1px solid#00C28E', fontSize: '1.375rem', fontWeight: '400' }}
                  danger
                  onClick={() => setOnShow(false)}
                >
                  {t('create.modal.btn2')}
                </Button>
              </Col>
            </Row>
          </Modal>
        </CreateContent>
      ) : (
        <Apply>
          <div
            className="applyBtn"
            onClick={() => {
              history.replace('/home?key=create')
            }}
          >
            {t('create.apply')}
          </div>
          <div className="applyTips">{t('create.apply.tips')}</div>
        </Apply>
      )}
    </>
  )
})
