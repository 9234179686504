import { useEffect, useState } from 'react'
import type { CardType } from '@/common/data.d'
import type { ConstantInitTypes } from '@/contracts/constantInit'
import useDataHooks from '@/hooks/useDataHooks'
import { getData } from '@/api'

interface Type {
  myAddress: string
  isRefreshData: boolean
}

export interface PortfolioImportType {
  key?: string | number
  label: string
  value: string
  blockNumber: number
  cover: string
  coverFiles: string
  isImport: boolean
  contracts: any
}

export const useMyNftHooks1 = (props: Type) => {
  const nftData: ConstantInitTypes = useDataHooks()
  const { payTokenOptions, apiKey, apiUrl, Shared1155Token_ADDRESS } = nftData
  const { myAddress, isRefreshData } = props

  const [myNftList, setMyNftList] = useState<CardType[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (myAddress) {
      setMyNftList([])
      // getMyNftList()
      getMyNftList1()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myAddress, isRefreshData, apiKey, apiUrl])

  const getMyNftList1 = async () => {
    try {
      const params = `
      query MyQuery {
        nftinfos(
          where: {ownner: "${myAddress}"}
        ) {
          carbonType
          categoriesId
          categoriesOwener
          id
          metadata_cid
          minter
          num
          ownner
          tokenID
          type
        }
        marketItemInfos(where: {seller: "${myAddress}"}) {
          ERC20_address
          amount
          carbonType
          categoriesId
          categoriesOwener
          contractAddress
          id
          metadata_cid
          price
          seller
          time
          tokenID
        }
      }
      
      `
      const { data }: any = await getData(params)

      data.nftinfos.forEach((item: any) => {
        if (item.minter.toLowerCase() === myAddress.toLowerCase()) {
          item.status = ''
        } else {
          item.status = '1'
          item.amount = item.num
        }
      })

      data.marketItemInfos.forEach((item: any) => {
        item.status = '2'
        item.isSell = true
        item.unit = payTokenOptions.find((pi) => pi.value.toLowerCase() === item.ERC20_address.toLowerCase()).label
        item.type = item.contractAddress.toLowerCase() === Shared1155Token_ADDRESS.toLowerCase() ? 2 : 1
      })

      let myNft = [...data.nftinfos, ...data.marketItemInfos]

      setMyNftList(myNft)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('error', error)
    }
  }

  return { myNftList, loading }
}
