const statement = {
  'statement.title': 'Carbon-Exchange免责声明',
  'statement.content1':
    '访问者在接受Carbon-Exchange官网（“Carbon-Exchange”“我们”）服务之前，请务必仔细阅读本条款并同意本声明。访问者访问Carbon-Exchange的行为以及通过各类方式利用Carbon-Exchange的行为，都将被视作是对本声明全部内容的无异议的认可:如有异议。请立即跟Carbon-Exchange协商，并取得Carbon-Exchange的书面同意意见。',
  'statement.content2':
    '第一条访问者在从事与Carbon-Exchange相关的所有行头(包括但不限于访问浏览、利用、转载、宣传介绍)时，必须以善意且谨慎的态度行事;访问者不得故意或者过失的损害或者弱化Carbon-Exchange的各类合法权利与利益，不得利用Carbon-Exchange以任何方式直接或者间接的从事违反中国法律、国际公约以及社会公德的行为，且访问者应当恪守下述承诺:',
  'statement.content3': '1、传输和利用信息符合中国法律、国际公约的规定、符合公序良俗;',
  'statement.content4': '2、不将Carbon-Exchange以及与之相关的网络服务用作非法用途以及非正当用途;',
  'statement.content5': '3、不干扰和扰乱Carbon-Exchange以及与之相关的网络服务;',
  'statement.content6': '4、遵守与Carbon-Exchange以及与之相关的网络服务的协议、规定、程序和惯例等。',
  'statement.content7':
    '第二条除我们另有明确说明或者地方法律有强制性规定外，Carbon-Exchange碳资产，Carbon-Exchange及碳项目方共同享有版权，其他网站及传统媒体如需使用，须取得Carbon-Exchange的书面授权，未经授权严禁转载或用于其它商业用途。',
  'statement.content8':
    '第三条Carbon-Exchange有权将在Carbon-Exchange内发表的作品用于其他用途，包括网站、电子杂志等，作品有附带版权声明者除外。',
  'statement.content9':
    '第四条未经常Carbon-Exchange和创作者共同同意，其他任何机构不得以任何形式侵犯其作品著作权，包括但不限于:擅自复制、链接、非法使用或转载，或以任何方式建立作品镜像。',
  'statement.content10':
    '第五条Carbon-Exchange所刊载的各类形式(包括但不仅限于文字、图片、图表)的作品仅供参考使用，并不代表Carbon-Exchange同意其说法或描述，仅为提供更多信息，也不构成任何投资建议。对于访问者根据Carbon-Exchange提供的信息所做出的一切行为，除非另有明确的书面承诺文件，否则Carbon-Exchange不承担任何形式的责任。',
  'statement.content11':
    '第六条当Carbon-Exchange以链接形式推荐其他网站内容时，Carbon-Exchange并不对这些网站或资源的可用性负责，且不保证从这些网站获取的任何内容、产品、服务或其他材料的真实性、合法性，对于任何因使用或信赖从此类网站或资源上获取的内容、产品、服务或其他材料而造成(或声称造成)的任何直接或间接损失，Carbon-Exchange均不承担任何责任。',
  'statement.content12':
    '第七条我们不保证Carbon-Exchange所含材料中包含的功能不会中断或没有错误，不保证缺陷将得到纠正，或Carbon-Exchange或提供它的服务器没有病毒或代表全部功能、准确性，材料的可靠性。在任何情况下，我们均不对任何损失或损害负责，包括但不限于间接或后果性损失或损害，或因使用或使用数据或利润而产生的或与之相关的任何损失或损害。',
  'statement.content13':
    '第八条访问者在Carbon-Exchange注册时提供的一些个人资料，Carbon-Exchange除您本人同意及第九条规定外不会将用户的任何资料以任何方式泄露给任何一方。',
  'statement.content14':
    '第九条当政府部门、司法机关等依照法定程序要求Carbon-Exchange披露个人资料时，Carbon-Exchange将根据执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，Carbon-Exchange均得免责。',
  'statement.content15':
    '第十条由于用户将钱包私钥或是助记词告知他人或与他人共享账户，由此导致的任何个人资料泄露，或资金损失Carbon-Exchange不负任何责任。',
  'statement.content16':
    '第十一条Carbon-Exchange有部分内容来自互联网，如无意中侵犯了哪个媒体、公司、企业或个人等的知识产权，请来电或致函告之，Carbon-Exchange将在规定时间内给予删除等相关处理，若有涉及版权费等问题，请及时提供相关证明等材料并与我们联系，通过友好协商公平公正原则处理纠纷。',
  'statement.content17': '第十二条以上声明内容的最终解释权归Carbon-Exchange网站所有。',
}

export default statement
