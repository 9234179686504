/* eslint-disable */
import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MainWrapper } from './styled'

export default memo(function PrivacyPage(props: any) {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])
  return (
    <MainWrapper>
      <div className="title">
        <h1> {t('privacy.title')}</h1>
      </div>
      <div className="main">{t('privacy.content1')}</div>
      <br />
      <div className="main">{t('privacy.content2')}</div>
      <br />
      <div className="main">{t('privacy.content3')}</div>
      <br />
      <div className="main">{t('privacy.content4')}</div>
      <br />
      <div>
        <h3>{t('privacy.content5')}</h3>
      </div>
      <div>{t('privacy.content6')}</div>
      <div>{t('privacy.content7')}</div>
      <div>{t('privacy.content8')}</div>
      <br />
      <div>
        <h3> {t('privacy.content9')}</h3>
      </div>
      <div>{t('privacy.content10')}</div>
      <div>{t('privacy.content11')}</div>
      <div>{t('privacy.content12')}</div>
      <br />
      <div>
        <h3> {t('privacy.content13')}</h3>
      </div>
      <div>{t('privacy.content14')}</div>
      <div>{t('privacy.content15')}</div>
      <div>{t('privacy.content16')}</div>
      <div>{t('privacy.content17')}</div>
      <div>{t('privacy.content18')}</div>
      <div>{t('privacy.content19')}</div>
      <div>{t('privacy.content20')}</div>
      <div>{t('privacy.content21')}</div>
      <br />
      <div>
        <h3> {t('privacy.content22')}</h3>
      </div>
      <div className="main">{t('privacy.content23')}</div>
      <br />
      <div>
        <h3> {t('privacy.content24')}</h3>
      </div>
      <div>{t('privacy.content25')}</div>
      <div>{t('privacy.content26')}</div>
      <br />
      <div>
        <h3> {t('privacy.content27')}</h3>
      </div>
      <div>{t('privacy.content28')}</div>
      <div>{t('privacy.content29')}</div>
    </MainWrapper>
  )
})
/* eslint-disable */
